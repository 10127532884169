// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clinicalstaff-mini-calendar .ant-picker-calendar-header .ant-radio-group {
  display: none !important;
}

.clinicalstaff-mini-calendar table{
  margin: 0;
}

.clinicalstaff-mini-calendar th {
  background-color: initial;
  border: 0;
}

.clinicalstaff-mini-calendar tr {
  background: initial;
}

.clinicalstaff-mini-calendar td {
  padding: 0 !important;
  border: 0;
}

.clinicalstaff-mini-calendar-cell-date {
  border-radius: 50% !important;
}

.clinicalstaff-mini-calendar-cell-date::before {
  border-radius: 50% !important;
}

.clinicalstaff-mini-calendar-cell-dot, .clinicalstaff-mini-calendar-cell-nodot{
  margin: auto;
  height: 0.4rem;
  width: 0.4rem;

  border-radius: 100%;
}

.clinicalstaff-mini-calendar-cell-dot{
  background-color: blue;
}
`, "",{"version":3,"sources":["webpack://./src/screens/ClinicalStaffPanelNew/ClinicalStaffMiniCalendar.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,SAAS;AACX;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,SAAS;AACX;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,aAAa;;EAEb,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".clinicalstaff-mini-calendar .ant-picker-calendar-header .ant-radio-group {\n  display: none !important;\n}\n\n.clinicalstaff-mini-calendar table{\n  margin: 0;\n}\n\n.clinicalstaff-mini-calendar th {\n  background-color: initial;\n  border: 0;\n}\n\n.clinicalstaff-mini-calendar tr {\n  background: initial;\n}\n\n.clinicalstaff-mini-calendar td {\n  padding: 0 !important;\n  border: 0;\n}\n\n.clinicalstaff-mini-calendar-cell-date {\n  border-radius: 50% !important;\n}\n\n.clinicalstaff-mini-calendar-cell-date::before {\n  border-radius: 50% !important;\n}\n\n.clinicalstaff-mini-calendar-cell-dot, .clinicalstaff-mini-calendar-cell-nodot{\n  margin: auto;\n  height: 0.4rem;\n  width: 0.4rem;\n\n  border-radius: 100%;\n}\n\n.clinicalstaff-mini-calendar-cell-dot{\n  background-color: blue;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
