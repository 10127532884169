import React from "react";
import { Box, Typography } from "@mui/material";
import ECG from "./ECG";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useEffect } from "react";
import ECGChart from "./ECG";
import { BlueButton } from "../../PatientPanel/PatientPortal";
import PressureChart from "../../PatientPanel/DataVisualization/PressureChart";
import Temperature from "./Temperature";

const typeFontFamily = { fontFamily: "Mulish, sans-serif" };
const dropdownFontFamily = {
  fontSize: "0.9rem",
  fontWeight: 500,
  fontFamily: "Poppins, sans-serif",
};

export const RealtimeSignalModule = () => {
  const [component, setComponent] = React.useState("ECG");
  const [start, setStart] = React.useState(false);
  const [data, setData] = React.useState([]);
  const handleChange = (event) => {
    setComponent(event.target.value);
  };

  const handleChart = () => {
    setStart(!start);
  };
  useEffect(() => {}, [component]);

  // https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/users/getRaspberryData
  useEffect(() => {
    let eventSource;

    if (start) {
      eventSource = new EventSource(
        "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/users/getRaspberryData"
      );

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log("SSE Data", data);
        setData(data);
      };
    } else {
      if (eventSource) {
        eventSource.close();
      }
    }

    return () => {
      if (eventSource) {
        eventSource.close();
      }
    };
  }, [start]);

  return (
    <Box sx={{ height: "100%" }}>
      <Typography variant="h3" component="h1">
        Realtime Signal Analysis
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            ml: "3em",
          }}
        >
          <Typography
            sx={{
              ...typeFontFamily,
              fontWeight: "500",
              fontSize: "0.9rem",
              color: "#4F4E4E",
              mr: "1em",
            }}
          >
            Select Chart Type:
          </Typography>
          <FormControl sx={{ m: 1, minWidth: 135 }} size="small">
            <Select
              value={component}
              onChange={handleChange}
              sx={{
                color: "#002284",
                "& .MuiSvgIcon-root": {
                  color: "#002284",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#002284",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#002284",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#002284",
                },
              }}
            >
              <MenuItem value={"ECG"} sx={{ ...dropdownFontFamily }}>
                ECG
              </MenuItem>
              <MenuItem value={"spo2"} sx={{ ...dropdownFontFamily }}>
                SPO2
              </MenuItem>
              <MenuItem value={"temperature"} sx={{ ...dropdownFontFamily }}>
                Temperature
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ mr: "3em" }}>
          <BlueButton
            buttonTitle={start ? "Stop" : "Start"}
            onClick={handleChart}
          >
            Start
          </BlueButton>
        </Box>
      </Box>
      <Box
        sx={{
          height: "80%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {{
          ECG: <ECGChart start={start} data={data} />,
          spo2: <PressureChart start={start} data={data} />,
          temperature: <Temperature start={start} data={data} />,
        }[component] || <Typography>Please select a chart type.</Typography>}
      </Box>
    </Box>
  );
};
