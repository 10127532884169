import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

const typeFontFamily = { fontFamily: "Mulish, sans-serif" };

const Weight = () => {
  const getOption = () => {
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: {
        type: "category",
        data: [
          "Jan",
          "Feb",
          "March",
          "April",
          "May",
          "June",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yAxis: {
        name: "kg",
        nameTextStyle: {
          color: "#C4C4C4",
          fontSize: "1rem",
          fontWeight: 500,
          ...typeFontFamily,
          align: "right",
          lineHeight: 20,
          padding: [0, 0, 10, 0],
        },
        type: "value",
        interval: 5,
        min: 70,
        max: 85,
      },
      series: [
        {
          data: [72, 73, 75, 72, 78, 77, 77, 80, 82, 83, 81, 80],
          type: "bar",
          itemStyle: {
            color: {
              type: "linear", // 指定渐变类型为线性渐变
              x: 0,
              y: 1,
              x2: 0,
              y2: 0,
              colorStops: [
                {
                  offset: 0,
                  color: "white",
                },
                {
                  offset: 0.3,
                  color: "rgba(255, 169, 0, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(255, 169, 0, 1)", // 100% 处的颜色，橙色
                },
              ],
              global: false, // false表示渐变的范围限制在单个bar内}
            },
            borderRadius: [8, 8, 0, 0],
          },
        },
      ],
      grid: {
        top: "15%", // 图表距离容器顶部的距离
        bottom: "8%",
        right: "5%",
        left: "5%",
      },
    };
  };
  return (
    <ReactECharts
      option={getOption()}
      style={{ height: "35vh", width: "100%" }}
    />
  );
};
export default Weight;
