// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doctor-dashboard-screen {
  display: flex;
  justify-content: space-between;
  height: 100vh;
}

.doctor-dashboard-column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.doctor-dashboard-left-column {
  width: calc(58% - 0.75rem);
  min-width: 30rem;
}

.doctor-dashboard-right-column {
  width: calc(42% - 0.75rem);
}

.doctor-dashboard-quick-link-button {
  width: 100%;
  min-height: 3rem;
  border-radius: 1rem;
  background-color: #1a4eba;
  color: white;

  display: inline-block;
}

.doctor-dashboard-right-column-card {
  height: 100%;
  margin-bottom: 1rem;
  background-color: white;
  border-radius: 1.5rem;
}

.doctor-dashboard-right-column-card .ant-card-body {
  height: 100%;
  display: flex;
  flex-direction: column;

  padding-bottom: 0;
}

@media screen and (max-width: 600px) {
  .doctor-dashboard-screen {
    flex-direction: column;
  }
  .doctor-dashboard-column {
    width: 100%;
    min-width: 10rem;
  }

  .doctor-dashboard-quick-link-button {
    display: none;
  }
}

`, "",{"version":3,"sources":["webpack://./src/screens/DoctorNew/DoctorDashboard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;;EAEZ,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;;EAEtB,iBAAiB;AACnB;;AAEA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".doctor-dashboard-screen {\n  display: flex;\n  justify-content: space-between;\n  height: 100vh;\n}\n\n.doctor-dashboard-column {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.doctor-dashboard-left-column {\n  width: calc(58% - 0.75rem);\n  min-width: 30rem;\n}\n\n.doctor-dashboard-right-column {\n  width: calc(42% - 0.75rem);\n}\n\n.doctor-dashboard-quick-link-button {\n  width: 100%;\n  min-height: 3rem;\n  border-radius: 1rem;\n  background-color: #1a4eba;\n  color: white;\n\n  display: inline-block;\n}\n\n.doctor-dashboard-right-column-card {\n  height: 100%;\n  margin-bottom: 1rem;\n  background-color: white;\n  border-radius: 1.5rem;\n}\n\n.doctor-dashboard-right-column-card .ant-card-body {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n\n  padding-bottom: 0;\n}\n\n@media screen and (max-width: 600px) {\n  .doctor-dashboard-screen {\n    flex-direction: column;\n  }\n  .doctor-dashboard-column {\n    width: 100%;\n    min-width: 10rem;\n  }\n\n  .doctor-dashboard-quick-link-button {\n    display: none;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
