// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-management-card-title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 1px;
  text-align: left;
}

.admin-management-table {
  width: 100%;
}

.admin-management-table th, .admin-management-table td {
  border: 0;
  padding: 0;
  text-align: left;
}

.admin-management-table .ant-table-container {
  max-width: initial;
  margin: initial;
  padding: initial;
  box-shadow: none;
}

.admin-management-doctor-verification-modal .ant-modal-content{
  padding: 50px;
}

.admin-management-doctor-verification-modal .ant-modal-title {
  text-align: center;
}

.admin-management-doctor-verification-time-remaining {
  font-family: Poppins;
  font-size: 8px;
  font-weight: 600;
  line-height: 23.8px;
  text-align: center;
}

.admin-management-potential-issues-item {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 54px;
  text-align: left;
  color: #6A707E;
}

.admin-management-potential-issues-item-button {
  border: none;
  background-color: #EEEEEE;
  width: 7.25rem;
  height: 2.743rem;
  opacity: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/AdminNew/AdminManagementPage.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,SAAS;EACT,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB,cAAc;EACd,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,cAAc;EACd,gBAAgB;EAChB,YAAY;AACd","sourcesContent":[".admin-management-card-title {\n  font-family: Poppins;\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 17px;\n  letter-spacing: 1px;\n  text-align: left;\n}\n\n.admin-management-table {\n  width: 100%;\n}\n\n.admin-management-table th, .admin-management-table td {\n  border: 0;\n  padding: 0;\n  text-align: left;\n}\n\n.admin-management-table .ant-table-container {\n  max-width: initial;\n  margin: initial;\n  padding: initial;\n  box-shadow: none;\n}\n\n.admin-management-doctor-verification-modal .ant-modal-content{\n  padding: 50px;\n}\n\n.admin-management-doctor-verification-modal .ant-modal-title {\n  text-align: center;\n}\n\n.admin-management-doctor-verification-time-remaining {\n  font-family: Poppins;\n  font-size: 8px;\n  font-weight: 600;\n  line-height: 23.8px;\n  text-align: center;\n}\n\n.admin-management-potential-issues-item {\n  font-family: Poppins;\n  font-size: 15px;\n  font-weight: 400;\n  line-height: 54px;\n  text-align: left;\n  color: #6A707E;\n}\n\n.admin-management-potential-issues-item-button {\n  border: none;\n  background-color: #EEEEEE;\n  width: 7.25rem;\n  height: 2.743rem;\n  opacity: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
