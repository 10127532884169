import React, { useEffect, useState, useRef } from "react";
import { List, ListItem, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

function NoteView() {
  const [showCreateReferralModal, setShowCreateReferralModal] = useState(false);

  // Define the styles for each item
  const listItemStyle = {
    borderRadius: "20px",
    margin: "8px 0",
    padding: "10px 20px",
    backgroundColor: "#E3F2FD",
    display: "flex",
    flexDirection: "column", // Stack elements vertically
    justifyContent: "center",
    alignItems: "center", // Align items to the left
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    width: "90%",
  };

  // Define the styles for the list container
  const listStyle = {
    width: "100%",
    padding: 0,
    maxHeight: 300, // To make the list take full available height
    overflow: "auto",
  };

  // Temporary mock data to simulate referrals
  const referrals = [
    { type: "Monthly Check", date: "09:20AM - 11:30 2023/3/11" },
    { type: "Observation", date: "09:20AM - 11:30 2023/2/11" },
    { type: "Notes", date: "09:20AM - 11:30 2023/2/11" },
  ];

  return (
    <>
      <List style={listStyle}>
        {referrals.map((referral, index) => (
          <ListItem key={index} style={listItemStyle}>
            <Typography variant="h6">{referral.type}</Typography>
            <Typography
              variant="body1"
              style={{ marginBottom: "16px" }} // Spacing between date and button
            >
              {referral.date}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              style={{ alignSelf: "flex-end" }}
            >
              View Notes
            </Button>
          </ListItem>
        ))}
      </List>
    </>
  );
}

export default NoteView;
