import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../constants";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import "./strokePrediction.css"

function StrokePrediction() {
    const location = useLocation();
    const state = location.state;
    const patientInfo = state;
    const [strokePredictionData, setStrokePredictionData] = useState(null);
    const [prediction, setPrediction] = useState("");
    const [predictionLoader, setPredictionLoader] = useState(false);
    console.log("state", state);
    const order = [
        "gender",
        "age",
        "hypertension",
        "heart_disease",
        "ever_married",
        "work_type",
        "Residence_type",
        "avg_glucose_level",
        "bmi",
        "smoking_status",
        "stroke"
    ];

    useEffect(() => {
        async function getstrokePredictionData() {
            try {
                const id = patientInfo.id;
                const { data } = await axios.post(`${BASE_URL}/getStrokePredictionData`, { patientId: id });
                const features = order.map(key => data[0][key]);
                console.log("features", features);
                setStrokePredictionData(features);
            } catch (err) {
                console.error(err);
            }
        }
        if (patientInfo.id) {
            getstrokePredictionData();
        }
    }, [patientInfo]);

    async function predict(strokePredictionData) {
        setPredictionLoader(true);
        const requestBody = strokePredictionData.reduce((acc, currentValue, index) => {
            acc[order[index]] = currentValue;
            return acc;
        }, {});

        console.log("requestBody", requestBody);
        try {
            console.log('Before axios.post');
            const { data } = await axios.post(
                "https://strokeprediction-2024-221db23dd9a1.herokuapp.com/predict_stroke",
                requestBody
            );
            console.log('After axios.post', data);
            setPrediction(data);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setPredictionLoader(false);
        }
    }

    function renderPredictionCell() {
        if (predictionLoader) {
            return <div style={{ color: "#59748A", fontFamily: "helvetica" }}>Loading...</div>;
        }

        if (!prediction && strokePredictionData) {
            return (
                <button
                    className="predictButton"
                    onClick={() => predict(strokePredictionData)}
                >
                    Predict
                </button>
            );
        }
        console.log("prediction", prediction)
        if (prediction !== undefined && prediction !== null) {
            return <div style={{ fontSize: "1.3rem", fontWeight: "700", color: "#59748A", fontFamily: "helvetica" }
            }>{prediction.stroke_prediction ? "TRUE" : "FALSE"}</div>;
        }

        return null;
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100vh", background: "#F8FAFC" }}>
            <div style={{ fontSize: "2rem", marginBottom: "3rem", fontFamily: "helvetica", fontWeight: "700", color: "#555555", marginTop: "5rem" }}>Stroke Prediction</div>
            <TableContainer sx={{ width: "80%", borderRadius: "10px", border: "1px solid rgba(224, 224, 224, 1)", overflow: "hidden" }}>
                <Table sx={{
                    minWidth: 650
                }} aria-label="customized table">
                    <TableHead>
                        <TableRow sx={{
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}>
                            <TableCell align="center" sx={{ fontSize: "1.2rem", fontWeight: "700", background: "#C6DFFF80", color: "#59748A" }}>Patient Information</TableCell>
                            <TableCell align="center" sx={{ fontSize: "1.2rem", fontWeight: "700", background: "#C6DFFF80", color: "#59748A" }}>Data</TableCell>
                            <TableCell align="center" sx={{ fontSize: "1.2rem", fontWeight: "700", background: "#C6DFFF80", color: "#59748A" }}>Prediction</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow sx={{
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}>
                            <TableCell align="center" sx={{ fontSize: "1.2rem", color: "#59748A", background: "#F3F8FC" }}>
                                {patientInfo.FName} {patientInfo.MName} {patientInfo.LName}
                            </TableCell>
                            <TableCell align="center" sx={{ background: "#F3F8FC" }} >
                                {strokePredictionData && (
                                    <div style={{ fontSize: "1.2rem", color: "#59748A", fontFamily: "helvetica", whiteSpace: "pre-wrap", display: "flex", flexWrap: "wrap", alignItems: "center", marginLeft: "2rem" }}>
                                        {strokePredictionData.map((value, index) => (
                                            <div key={index} style={{ flex: "0 0 calc(30% - 20px)", marginRight: "20px", minWidth: "12rem", marginBottom: "10px", display: "flex", alignItems: "flex-start", color: "#59748A", fontFamily: "helvetica" }}>
                                                <span style={{ fontWeight: 700, color: "#59748A", fontFamily: "helvetica" }}>
                                                    {order[index]}
                                                    :&nbsp;</span>
                                                {value}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </TableCell>
                            <TableCell align="center" sx={{ background: "#F3F8FC" }}>{renderPredictionCell()}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <a style={{ fontSize: "1.5rem", color: "#59748A", fontFamily: "helvetica", marginTop: "3rem", border: "2px solid rgba(224, 224, 224, 1)", padding: "10px", borderRadius: "12px", background: "" }} href="https://strokePrediction2024-e0742f4746f8.herokuapp.com/">website</a>
        </div>
    );
}

export default StrokePrediction;