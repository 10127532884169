import React, { createContext, useContext, useState } from "react";

const SelectedKeyContext = createContext();

export const SelectedKeyProvider = ({ children }) => {
    const [selectedKey, setSelectedKey] = useState("");
    return (
        <SelectedKeyContext.Provider value={{ selectedKey, setSelectedKey }}>
            {children}
        </SelectedKeyContext.Provider>
    )
}

export const useSelectedKey = () => useContext(SelectedKeyContext);