import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    responsiveFontSizes,
} from "@mui/material";
const tableColumn = {
    age: "age",
    sex: "sex",
    chest_pain_type: "chest_pain_type",
    resting_bps: "resting_bps",
    cholesterol: "cholesterol",
    fasting_blood_sugar: "fasting_blood_sugar",
    rest_ecg: "rest_ecg",
    max_heart_rate: "max_heart_rate",
    exercise_angina: "exercise_angina",
    oldpeak: "oldpeak",
    ST_slope: "ST_slope",
};

//const API_ROOT = `https://e-react-node-backend-22ed6864d5f3.herokuapp.com`;  //TODO: Need to replace with the backend server address
const API_ROOT = `http://127.0.0.1:8080`;  //TODO: Need to replace with the backend server address

const HEART_FAIL_API_ENDPOINT = `https://nioushaml-2024-1e2e83037d69.herokuapp.com`;

const HeartFail5 = () => {
    const location = useLocation();
    const patientId = location.state.id;

    const [predictionData, setPredictionData] = useState([]);
    const [heartFail, setHeartFail] = useState();

    const predictHeartFail = async () => {
        const predictionResponse = await axios.post("https://chronic-kidney-2024-95ac50c1ccf1.herokuapp.com/predict", {

            "age": predictionData[0].age,
            "blood_pressure": predictionData[0].blood_pressure,
            "specific_gravity": predictionData[0].specific_gravity,
            "albumin": predictionData[0].albumin,
            "sugar": predictionData[0].sugar,
            "red_blood_cells": predictionData[0].red_blood_cells,
            "pus_cell": predictionData[0].pus_cell,
            "pus_cell_clumps": predictionData[0].pus_cell_clumps,
            "bacteria": predictionData[0].bacteria,
            "blood_glucose_random": predictionData[0].blood_glucose_random,
            "blood_urea": predictionData[0].blood_urea,
            "serum_creatinine": predictionData[0].serum_creatinine,
            "sodium": predictionData[0].sodium,
            "potassium": predictionData[0].potassium,
            "haemoglobin": predictionData[0].haemoglobin,
            "packed_cell_volume": predictionData[0].packed_cell_volume,
            "white_blood_cell_count": predictionData[0].white_blood_cell_count,
            "red_blood_cell_count": predictionData[0].red_blood_cell_count,
            "hypertension": predictionData[0].hypertension,
            "diabetes_mellitus": predictionData[0].diabetes_mellitus,
            "coronary_artery_disease": predictionData[0].coronary_artery_disease,
            "appetite": predictionData[0].appetite,
            "peda_edema": predictionData[0].peda_edema,
            "anemia": predictionData[0].anemia

        });
        setHeartFail(predictionResponse.data);
    }

    useEffect(() => {
        (async () => {
            //const strokeDataResponse = await axios.get(`${API_ROOT}/getCoronaryArteryDisease/${patientId}`);
            const strokeDataResponse = await axios.post("https://e-react-node-backend-22ed6864d5f3.herokuapp.com/getChronicKidney", {
                patientId
            });
            console.log(strokeDataResponse)
            setPredictionData(strokeDataResponse.data);
        })();
    }, []);

    const displayTableHead = () => {
        return Object.keys(tableColumn).map((columnKey) => {
            const columnValue = tableColumn?.[columnKey];
            return <th>{columnValue}</th>
        });
    };

    const renderTableData = () => {
        return Object.keys(tableColumn).map((columnKey) => {
            if (predictionData?.[columnKey] === undefined) {
                return;
            }
            return <td>{predictionData?.[columnKey]}</td>
        });
    };

    return (
        <React.Fragment>
            <br /><h2>Chronic Kidney</h2><br />
            <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
                <h4 style={{ clear: "both" }}><br />Result: {heartFail?.prediction}</h4>
                <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>    <button onClick={predictHeartFail}>Diagnose</button></div>
            </div>
            <TableContainer
                component={Paper}
                sx={{ minWidth: 550, minHeight: 400 }}
            >
                <Table
                    sx={{ minWidth: 1250, minHeight: 400 }}
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="right">
                                patient_id
                                </TableCell>
                            <TableCell align="right">
                                age
                                </TableCell>
                            <TableCell align="right">
                                blood_pressure
                                </TableCell>
                            <TableCell align="right">
                                specific_gravity
                                </TableCell>
                            <TableCell align="right">
                                albumin
                                </TableCell>
                            <TableCell align="right">
                                red_blood_cells
                                </TableCell>
                            <TableCell align="right">pus_cell</TableCell>
                            <TableCell align="right">
                                pus_cell_clumps
                                </TableCell>
                            <TableCell align="right">bacteria</TableCell>
                            <TableCell align="right">blood_glucose_random</TableCell>
                            <TableCell align="right">blood_urea</TableCell>
                            <TableCell align="right">serum_creatinine</TableCell>
                            <TableCell align="right">sodium</TableCell>
                            <TableCell align="right">potassium</TableCell>
                            <TableCell align="right">haemoglobin</TableCell>
                            <TableCell align="right">packed_cell_volume</TableCell>
                            <TableCell align="right">white_blood_cell_count</TableCell>
                            <TableCell align="right">red_blood_cell_count</TableCell>
                            <TableCell align="right">hypertension</TableCell>
                            <TableCell align="right">diabetes_mellitus</TableCell>
                            <TableCell align="right">coronary_artery_disease</TableCell>
                            <TableCell align="right">appetite</TableCell>
                            <TableCell align="right">peda_edema</TableCell>
                            <TableCell align="right">anemia</TableCell>
                            <TableCell align="right">class</TableCell>
                            <TableCell align="right">sugar</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {predictionData.map((prescription) => (
                            <TableRow
                                key={prescription.id}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {prescription.id}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.patient_id}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.age}
                                </TableCell>

                                <TableCell align="right">
                                    {prescription.blood_pressure}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.specific_gravity}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.albumin}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.red_blood_cells}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.pus_cell}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.pus_cell_clumps}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.bacteria}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.blood_glucose_random}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.blood_urea}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.serum_creatinine}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.sodium}
                                </TableCell>

                                <TableCell align="right">
                                    {prescription.potassium}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.haemoglobin}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.packed_cell_volume}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.white_blood_cell_count}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.red_blood_cell_count}
                                </TableCell>

                                <TableCell align="right">
                                    {prescription.hypertension}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.diabetes_mellitus}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.coronary_artery_disease}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.appetite}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.peda_edema}
                                </TableCell>

                                <TableCell align="right">
                                    {prescription.anemia}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.class}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.sugar}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
};

export default HeartFail5;
