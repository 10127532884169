// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clinicalstaff-calendar-toolbar {
  display: flex;
  flex-direction: row;
}

.clinicalstaff-calendar-toolbar > :first-child{
  flex: 0 0 fit-content;
}

.clinicalstaff-calendar-toolbar > :last-child{
  flex: 1 1 max-content;
}

.clinicalstaff-calendar-toolbar-select .ant-select-selector{
  border: 0 !important;
  background-color: transparent !important;
  text-decoration: underline;
}

.clinicalstaff-calendar-categories {
  margin-left: 7.17%;
  width: 89.4%;
}

.clinicalstaff-calendar-categories .ant-tag {
  color: black;
  width: 100%;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/screens/ClinicalStaffPanelNew/ClinicalStaffCalendar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,oBAAoB;EACpB,wCAAwC;EACxC,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[".clinicalstaff-calendar-toolbar {\n  display: flex;\n  flex-direction: row;\n}\n\n.clinicalstaff-calendar-toolbar > :first-child{\n  flex: 0 0 fit-content;\n}\n\n.clinicalstaff-calendar-toolbar > :last-child{\n  flex: 1 1 max-content;\n}\n\n.clinicalstaff-calendar-toolbar-select .ant-select-selector{\n  border: 0 !important;\n  background-color: transparent !important;\n  text-decoration: underline;\n}\n\n.clinicalstaff-calendar-categories {\n  margin-left: 7.17%;\n  width: 89.4%;\n}\n\n.clinicalstaff-calendar-categories .ant-tag {\n  color: black;\n  width: 100%;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
