import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    responsiveFontSizes,
} from "@mui/material";
const tableColumn = {
    age: "age",
    sex: "sex",
    chest_pain_type: "chest_pain_type",
    resting_bps: "resting_bps",
    cholesterol: "cholesterol",
    fasting_blood_sugar: "fasting_blood_sugar",
    rest_ecg: "rest_ecg",
    max_heart_rate: "max_heart_rate",
    exercise_angina: "exercise_angina",
    oldpeak: "oldpeak",
    ST_slope: "ST_slope",
};

//const API_ROOT = `https://e-react-node-backend-22ed6864d5f3.herokuapp.com`;  //TODO: Need to replace with the backend server address
const API_ROOT = `http://127.0.0.1:8080`;  //TODO: Need to replace with the backend server address

const HEART_FAIL_API_ENDPOINT = `https://nioushaml-2024-1e2e83037d69.herokuapp.com`;

const HeartFail2 = () => {
    const location = useLocation();
    const patientId = location.state.id;

    const [predictionData, setPredictionData] = useState([]);
    const [heartFail, setHeartFail] = useState();

    console.log(predictionData[0])
    const predictHeartFail = async () => {

        const predictionResponse = await axios.post("https://nioushaml-2024-1e2e83037d69.herokuapp.com/predict", {
            "input": {
                "age": predictionData[0].age,
                "sex": predictionData[0].gender == "Female" ? 1 : 0,
                "chest_pain_type": predictionData[0].chest_pain_type,
                "resting_bps": predictionData[0].resting_bp_s,
                "cholesterol": predictionData[0].cholesterol,
                "fasting_blood_sugar": predictionData[0].fasting_blood_sugar,
                "rest_ecg": predictionData[0].resting_ecg,
                "max_heart_rate": predictionData[0].max_heart_rate,
                "exercise_angina": predictionData[0].exercise_angina,
                "oldpeak": predictionData[0].oldpeak,
                "ST_slope": predictionData[0].st_slope
            }


        });
        setHeartFail(predictionResponse.data);
    }

    useEffect(() => {
        (async () => {
            //const strokeDataResponse = await axios.get(`${API_ROOT}/getCoronaryArteryDisease/${patientId}`);
            const strokeDataResponse = await axios.post("https://e-react-node-backend-22ed6864d5f3.herokuapp.com/getCoronaryArteryDisease", {
                patientId
            });
            console.log(strokeDataResponse)
            setPredictionData(strokeDataResponse.data);
        })();
    }, []);

    const displayTableHead = () => {
        return Object.keys(tableColumn).map((columnKey) => {
            const columnValue = tableColumn?.[columnKey];
            return <th>{columnValue}</th>
        });
    };

    const renderTableData = () => {
        return Object.keys(tableColumn).map((columnKey) => {
            if (predictionData?.[columnKey] === undefined) {
                return;
            }
            return <td>{predictionData?.[columnKey]}</td>
        });
    };

    return (
        <React.Fragment>
            <br /><h2>Coronary Artery Disease</h2><br />
            <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
                <h4 style={{ clear: "both" }}><br />Heart Failure risk for the patient is {heartFail?.prediction}</h4>
                <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>    <button onClick={predictHeartFail}>Diagnose</button></div>
            </div>
            <TableContainer
                component={Paper}
                sx={{ minWidth: 550, minHeight: 400 }}
            >
                <Table
                    sx={{ minWidth: 1250, minHeight: 400 }}
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="right">
                                patient_id
                                </TableCell>
                            <TableCell align="right">
                                age
                                </TableCell>
                            <TableCell align="right">
                                gender
                                </TableCell>
                            <TableCell align="right">
                                chest_pain_type
                                </TableCell>
                            <TableCell align="right">
                                resting_bp_s
                                </TableCell>
                            <TableCell align="right">
                                cholesterol
                                </TableCell>
                            <TableCell align="right">fasting_blood_sugar</TableCell>
                            <TableCell align="right">
                                resting_ecg
                                </TableCell>
                            <TableCell align="right">max_heart_rate</TableCell>
                            <TableCell align="right">exercise_angina</TableCell>
                            <TableCell align="right">oldpeak</TableCell>
                            <TableCell align="right">st_slope</TableCell>
                            <TableCell align="right">target</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {predictionData.map((prescription) => (
                            <TableRow
                                key={prescription.id}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {prescription.id}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.patient_id}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.age}
                                </TableCell>

                                <TableCell align="right">
                                    {prescription.gender}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.chest_pain_type}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.resting_bp_s}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.cholesterol}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.fasting_blood_sugar}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.resting_ecg}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.max_heart_rate}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.exercise_angina}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.oldpeak}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.st_slope}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.target}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
};

export default HeartFail2;
