import React from 'react';
import './navbar.css';
import Logo from './logo.svg';
import {Link} from 'react-scroll'
import { Link as RouterLink } from 'react-router-dom';
const navbar = () => {

    return (
        <nav className="navbar">
            <div className="navbar-brand">
                <a href="/"><img src={Logo} alt="Your Logo"/></a> {/* Use imported logo */}
            </div>
            <ul className="navbar-nav">
                <li className="nav-item">
                    <Link
                        activeClass="active"
                        to="home"
                        smooth={true}
                        duration={500}
                        offset={-70}
                    >
                   Home
                        </Link>
                </li>
                <li className="nav-item">
                    <Link
                        activeClass="active"
                        to="about"
                        smooth={true}
                        duration={500}
                        offset={-70}
                    >
                        About
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        activeClass="active"
                        to="services"
                        smooth={true}
                        duration={500}
                        offset={-70}
                    >
                        Services
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        activeClass="active"
                        to="ai-solution"
                        smooth={true}
                        duration={500}
                        offset={-70}
                    >
                        AI Solution
                    </Link>
                </li>

                <li className="nav-item">
                    <Link
                        activeClass="active"
                        to="contact"
                        smooth={true}
                        duration={500}
                        offset={-70}
                    >
                        Contact
                    </Link>
                </li>


            </ul>

                <div className="sign-button">
                    <RouterLink to="/LogIn">
                        <button className="btn-signin_up">Portal
                        </button>
                    </RouterLink>

                </div>



        </nav>
    );
};

export default navbar;