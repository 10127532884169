// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skin-cancer-container {
  width: 50vw;
  height: 50vh;
}

td {
  text-align: center;
}

.predictButton {
  background-color: #ef5350 !important;
}

.saveButton {
  background-color: #42a5f5 !important;
  width: -moz-fit-content;
  width: fit-content;
}

.skin-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  row-gap: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/screens/skinCancerMl.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;EACpC,uBAAkB;EAAlB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,cAAc;EACd,aAAa;AACf","sourcesContent":[".skin-cancer-container {\n  width: 50vw;\n  height: 50vh;\n}\n\ntd {\n  text-align: center;\n}\n\n.predictButton {\n  background-color: #ef5350 !important;\n}\n\n.saveButton {\n  background-color: #42a5f5 !important;\n  width: fit-content;\n}\n\n.skin-page {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 20px 0;\n  row-gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
