// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-nav-mobile-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color:#1A4FBA;
  padding: 15px 30px;
}

.top-nav-mobile-logo-container {
  display: flex;
  align-items: center;
}
.top-nav-mobile-logo {
  width: 40px;
}
.top-nav-mobile-logo-container h4 {
  color: white;
}

@media only screen and (min-width: 605px) {
    .top-nav-mobile-main {
      display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/SideBar/TopNavMobile.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,wBAAwB;EACxB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,WAAW;AACb;AACA;EACE,YAAY;AACd;;AAEA;IACI;MACE,aAAa;IACf;AACJ","sourcesContent":[".top-nav-mobile-bar {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color:#1A4FBA;\n  padding: 15px 30px;\n}\n\n.top-nav-mobile-logo-container {\n  display: flex;\n  align-items: center;\n}\n.top-nav-mobile-logo {\n  width: 40px;\n}\n.top-nav-mobile-logo-container h4 {\n  color: white;\n}\n\n@media only screen and (min-width: 605px) {\n    .top-nav-mobile-main {\n      display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
