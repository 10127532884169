// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 1025px) {
  .page-layout-main {
    /* width of sidebar and margin */
    margin-left: calc(16rem + 2.5rem);
    margin-right: 2rem;
    padding-bottom: 3rem;
  }
}

@media only screen and (max-width: 1024px) {
  .page-layout-main {
    margin-left: calc(5.5rem + 2.5rem);
    margin-right: 2rem;
    padding-bottom: 3rem;
  }
}

@media only screen and (max-width: 605px) {
  .page-layout {
    flex-direction: column;
  }
  .page-layout-main {
    margin-left: 1rem;
    margin-left: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/PageLayout/PageLayout.css"],"names":[],"mappings":"AAAA;EACE;IACE,gCAAgC;IAChC,iCAAiC;IACjC,kBAAkB;IAClB,oBAAoB;EACtB;AACF;;AAEA;EACE;IACE,kCAAkC;IAClC,kBAAkB;IAClB,oBAAoB;EACtB;AACF;;AAEA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,iBAAiB;IACjB,iBAAiB;EACnB;AACF","sourcesContent":["@media only screen and (min-width: 1025px) {\n  .page-layout-main {\n    /* width of sidebar and margin */\n    margin-left: calc(16rem + 2.5rem);\n    margin-right: 2rem;\n    padding-bottom: 3rem;\n  }\n}\n\n@media only screen and (max-width: 1024px) {\n  .page-layout-main {\n    margin-left: calc(5.5rem + 2.5rem);\n    margin-right: 2rem;\n    padding-bottom: 3rem;\n  }\n}\n\n@media only screen and (max-width: 605px) {\n  .page-layout {\n    flex-direction: column;\n  }\n  .page-layout-main {\n    margin-left: 1rem;\n    margin-left: 1rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
