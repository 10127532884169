import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";

function SclerosisDiseaseChecker() {
  const [showDiagnose, setShowDiagnose] = useState(false);
  const [dataEntry, setDataEntry] = useState([]);
  const [dataDisplay, setDataDisplay] = useState([]);
  const location = useLocation();
  const [prediction, setPrediction] = useState("");
  const patient_id = location.state?.id;
  const BASE_URL = "https://e-react-node-backend-22ed6864d5f3.herokuapp.com"; // Update with your node backend app URL
  // const BASE_URL = "https://e-react-node-backend-22ed6864d5f3.herokuapp.com"; // Update with your node backend app URL

  useEffect(() => {
    console.log(dataEntry);
  }, [dataEntry]);

  useEffect(() => {
    const getSclerosisDiseaseData = async () => {
      try {
        console.log("patient found ", patient_id);
        const response = await axios.post(
          `${BASE_URL}/getSclerosisDiseaseData`,
          {
            patient_id,
          }
        );
        console.log("response", response);
        if (response.data && response.data.length == 1) {
          const dataDisplay = transformDataToDisplay(response.data[0]);
          const dataEntries = transformDataToEntries(response.data[0]);
          console.log("dataDisplay", dataDisplay);
          setDataDisplay(dataDisplay);
          setDataEntry(dataEntries);
        } else {
          alert("Test Data Not Found");
        }
      } catch (error) {
        console.log("error", error);
        alert(`Error: ${error.response.data.error}`);
      }
    };

    getSclerosisDiseaseData();
  }, [patient_id]);

  function transformDataToEntries(data) {
    const input = {
      gender: data.Gender == "Male" ? 1 : 2,
      varicella: data.Varicella,
      initial_symptom: data.Initial_Symptom,
      llssep: data.LLSSEP,
      ulssep: data.ULSSEP,
      vep: data.VEP,
      baep: data.BAEP,
      periventricular_mri: data.Periventricular_MRI,
      cortical_mri: data.Cortical_MRI,
      infratentorial_mri: data.Infratentorial_MRI,
      spinal_cord_mri: data.Spinal_Cord_MRI,
    };

    console.log("Input", input);
    return input;
  }

  function transformDataToDisplay(data) {
    const varicella_map = new Map([
      [1, "Positive"],
      [2, "Negative"],
      [3, "Unknown"],
    ]);
    const initial_symptom_map = new Map([
      [1, "Visual"],
      [2, "Sensory"],
      [3, "Motor"],
      [4, "Other"],
      [5, "Visual and Sensory"],
      [6, "Visual and Motor"],
      [7, "Visual and Others"],
      [8, "Sensory and Motor"],
      [9, "Sensory and Other"],
      [10, "Motor and Other"],
      [11, "Visual, Sensory and Motor"],
      [12, "Visual, Sensory and Other"],
      [13, "Visual, Motor and Other"],
      [14, "Sensory, Motor and Other"],
      [15, "Visual, Sensory, Motor and Other"],
    ]);
    const binary_map = new Map([
      [0, "Negative"],
      [1, "Positive"],
    ]);
    const entries = [
      { label: "Gender", value: data.Gender },
      {
        label: "Varicella",
        value: varicella_map.get(data.Varicella, "Unknown"),
      },
      {
        label: "Initial Symptom",
        value: initial_symptom_map.get(data.Initial_Symptom, "Unknown"),
      },
      { label: "Llssep", value: binary_map.get(data.LLSSEP, "Unknown") },
      { label: "Ulssep", value: binary_map.get(data.ULSSEP, "Unknown") },
      { label: "Vep", value: binary_map.get(data.VEP, "Unknown") },
      { label: "Baep", value: binary_map.get(data.BAEP, "Unknown") },
      {
        label: "Periventricular MRI",
        value: binary_map.get(data.Periventricular_MRI, "Unknown"),
      },
      {
        label: "Cortical MRI",
        value: binary_map.get(data.Cortical_MRI, "Unknown"),
      },
      {
        label: "Infratentorial MRI",
        value: binary_map.get(data.Infratentorial_MRI, "Unknown"),
      },
      {
        label: "Spinal Cord MRI",
        value: binary_map.get(data.Spinal_Cord_MRI, "Unknown"),
      },
      { label: "Group", value: data.group == 1 ? "CDMS" : "non-CDMS" },
    ];
    return entries;
  }

  const predict = async () => {
    console.log("dataEntry", dataEntry);
    try {
      const response = await axios.post(
        "https://bmg5111-adam-2024-45729daa2d7c.herokuapp.com/predict",
        dataEntry,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setPrediction(response.data?.is_positive);
      setShowDiagnose(true);
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <>
      <div className="bc-container">
        <h2 className="title"> Sclerosis Diagnosis Results</h2>
        <div className="diagnosis-result">
          <strong>Diagnosis:</strong>
          {showDiagnose ? `${prediction}` : ""}
        </div>

        <table className="results-tablea">
          <thead>
            <tr>
              <th>Parameters</th>
              <th>Values</th>
            </tr>
          </thead>
          <tbody>
            {dataDisplay.map((entry, index) => (
              <tr key={index}>
                <td>{entry.label}</td>
                <td>{entry.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <center>
          <button onClick={() => predict()}>Diagnose</button>
          <br />
          <br />
          <br />
        </center>
        <br />
      </div>
    </>
  );
}

export default SclerosisDiseaseChecker;
