import React, { useState , useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./PharmaceuticalsDashboard.css";
import { ClinicalTrialsStatus } from './DashUtils';
import { getClinicalTrialsList } from '../../../api/user';
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Card, Grid,IconButton  } from '@mui/material';
//临床试验模块标题
const ClinicalTrialListTableTitle = () => (
  <div className="pharmaceuticals-dashboard-table-title">
  <span>Clinical Trial List</span>
  </div>
);

//隐藏表头
const getMuiTheme = () => createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          display: 'none',
        },
      },
    },
  },
});

// 根据状态获取标签组件
const getColorForStatus = (status) => {
  let color;
  switch (status) {
    case 'Under Review':
      color = 'warning';
      break;
    case 'Ongoing':
      color = 'info';
      break;
    case 'Completed':
      color = 'success';
      break;
    case 'Rejected':
      color = 'error';
      break;
    default:
      color = 'default';
  }
  return <ClinicalTrialsStatus color={color} status={status} />;
};

//点击特定试验时的操作
const SpecificTrialClicked = async (trial_id, navigate) => {
  try {
    navigate('/pharmaceuticals/clinicaltrial');
    // 延时，确保导航栏已经更新，再导航到特定试验页面
    setTimeout(() => {
      navigate(`/pharmaceuticals/clinicaltrial/specificclinicaltrial/${trial_id}`);
    }, 0); // 可以根据实际需求设置适当的延时
  } catch (error) {
    console.error('Error opening specific trial!:', error);
  }
};

// 自定义列渲染
const tableTrialListRender = (value, tableMeta, navigate) => {
  const styles = {
    trialName: { color: 'black', cursor: 'pointer' },
    trialStatus: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: '10px', minWidth: '150px' }
  };

  return tableMeta.columnIndex === 0 ? (
    <div
      style={styles.trialName}
      onClick={() => SpecificTrialClicked(tableMeta.rowData[2], navigate)}
    >
      {value}
    </div>
  ) : (
    <div style={styles.trialStatus}>
      {getColorForStatus(value)}
    </div>
  );
};
// 表头定义
const createColumns = (navigate) => [
  { name: 'trial_name', label: 'Trial Name', options: { customBodyRender: (value, tableMeta) => tableTrialListRender(value, tableMeta, navigate), filter: false } },
  { name: 'trial_status', label: 'Trial Status', options: { customBodyRender: (value, tableMeta) => tableTrialListRender(value, tableMeta) } },
  { name: 'trial_id', options: { display: false, filter: false} } // 隐藏列
];

//点击more按钮
const onMoreClick = async(navigate) => {
  try {
    navigate('/pharmaceuticals/clinicaltrial');
  } catch (error) {
    console.error('Error opening the clinical trial list!:', error);
  }
};
// 自定义工具栏
const CustomToolbar = ({ navigate }) => {
  return (
    <IconButton onClick={() => onMoreClick(navigate) } aria-label="more">
      <MoreHorizIcon />
    </IconButton>
  );
};

// 生成 options 对象
const createOptions = (navigate) => {

  return {
    selectableRows: 'none',
    download: false,
    viewColumns: false,
    print: false,
    elevation: 0,
    rowsPerPage: 5, // 每页显示10条数据
    rowsPerPageOptions: [5], // 设置分页选项，只显示每页10条
    setRowProps: () => ({
      style: { backgroundColor: '#FFFFFF' },
    }),
    setCellProps: () => ({
      style: { backgroundColor: '#FFFFFF' },
    }),
    customToolbar: () => <CustomToolbar navigate={navigate} />
  };
};


//主体部分
const Pharmaceuticals_ClinicalTrialTable = ({ companyId }) => {
  const [trials, setTrials] = useState([]);
  const navigate = useNavigate();

  // 调用 createOptions和createColumns 函数，确保点击时能正确导航到其他部分
  const options = createOptions(navigate);
  const columns = createColumns(navigate);
  
  //获取临床试验列表
  useEffect(() => {
    const fetchTrials = async () => {
      try {
        const trialsList = await getClinicalTrialsList(companyId);
        setTrials(trialsList);
      } catch (error) {
        console.error('Error fetching clinical trials:', error);
      }
    };
    fetchTrials();
  }, [companyId]);

  return (
    <Grid item xs={12} md={7}>
      <Card sx={{ position: 'relative', p: 3, borderRadius: '19px' ,height: '100%'}}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={<ClinicalTrialListTableTitle />}
            data={trials}
            columns={columns}
            options={options}
          />
      </ThemeProvider>   
      </Card>
      
    </Grid>
  
  );
};

export default Pharmaceuticals_ClinicalTrialTable;
