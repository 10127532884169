import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import '../../styles/screens/diagonostic.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function GlaucomaDetection() {

    const location = useLocation();
    //console.log(location.state.MobileNumber);
    const [recordList, setRecordList] = useState([]);
    const [diagnosis, setDiagnosis] = useState();


    useEffect(() => {
        // Function to retrieve patient records
        const getPatientRecords = async () => {
            try {
                // const searchParams = new URLSearchParams(window.location.search);
                //const phoneNumber = searchParams.get('phoneNumber');
                console.log(location)
                const patientId = location.state.id;
                //const patientId = 211;
                //console.log("parm found ", patientId);
                //local backend api link (https://e-react-node-backend-22ed6864d5f3.herokuapp.com/imageRetrieveByPhoneNumber)
                const response = await axios.post('https://e-react-node-backend-22ed6864d5f3.herokuapp.com/imageRetrieveByPatientId', {
                    patientId,
                    recordType: 'Optic-Disc-X-Ray_Eye',
                });
                console.log(response)
                const { data } = response;
                if (data.error) {
                    alert(JSON.stringify(data.error));
                } else {
                    setRecordList(data.success);
                }
            } catch (error) {
                alert(`Error: ${error.message}`);
            }
        };

        getPatientRecords();
    }, []);

    // Function to send the image for prediction
    const predict = async (index) => {
        console.log(recordList);
        const record = recordList[index];
        console.log(record);
        try {
            const imageBlob = await fetch(`data:image/jpeg;base64,${record.file.buffer}`).then((response) =>
                response.blob()
            );
            const formData = new FormData();
            formData.append('image', imageBlob, record.file.originalname);
            //skincancer ml model api link deployed on heroku via git
            const response = await axios.post('https://glaucomaapi-2024-6fc75a38c6ac.herokuapp.com/predict', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            console.log(response);

            // let blob = response.data;
            //const imageUrl = URL.createObjectURL(blob);
            setDiagnosis(response.data.Glaucoma);

            /*
            const { data } = response;
            if (data.error) {
                alert(JSON.stringify(data.error));
            } else {
                console.log(66601);
                console.log(data);
                const diagnosisMessage = data.message || 'No diagnosis available';
                setDiagnosis(data);
            }
            */
        } catch (error) {
            alert(`Error: ${error.message}`);
        }
    };

    // Function to store the prediction result
    /*
    const storePrediction = async (result, id) => {
        try {
            // const searchParams = new URLSearchParams(window.location.search);
            // const phoneNumber = searchParams.get('phoneNumber');
            const phoneNumber = location.state.MobileNumber;
            const today = new Date().toISOString();
            const offset = new Date().getTimezoneOffset();
    
            const variable = result.message === 'Cancer' ? 1 : 0;
            //local backend api link (https://e-react-node-backend-22ed6864d5f3.herokuapp.com/updateDisease)
            const response = await axios.post('https://e-react-node-backend-22ed6864d5f3.herokuapp.com/updateDisease', {
                phoneNumber,
                disease: 'cancers',
                date: today,
                prediction: variable,
                description: 'Skin Cancer',
                accuracy: result.accuracy || null,
                recordType: 'Skin_Images',
                recordId: id || null,
            });
    
            const { data } = response;
            if (data.error) {
                alert(JSON.stringify(data.error));
            } else {
                alert(data.success);
            }
        } catch (error) {
            alert(`Error: ${error.message}`);
        }
    };
    */



    return (



        <>
            <br />   <br />   <br />   <br />
            <center> <div>
                <strong style={{ fontSize: '24px' }}>Glaucoma Detection</strong>
                <table>
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Record Date</th>
                            <th>Action</th>
                        </tr>

                    </thead>
                    <tbody>
                        {recordList.map((record, index) => (
                            <tr key={record._id}>
                                <td>
                                    <img src={`data:image/jpeg;base64,${record.file.buffer}`} alt="PCOS Image" width="150" height="150" />
                                </td>
                                <td>{record.RecordDate}</td>
                                <td>
                                    <button onClick={() => predict(index)}>Diagnose</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <br />
                <br />
                <div>

                    Glaucoma Prediction result:{diagnosis}
                </div>
            </div></center>   <br />   <br />   <br />   <br />   <br />

        </>
    )
}

export default GlaucomaDetection;