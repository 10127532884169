import React from "react";
import { useNavigate } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import LogoutIcon from "@mui/icons-material/Logout";
import Sidebar from "./SideBar";
import { useMemo } from "react";
import HomeIcon from "@mui/icons-material/Home";

const ClinicalStaffSideBar = (props) => {
  const navigate = useNavigate();

  const getItem = (label, key, icon, action, children, type) => ({
    key,
    icon,
    children,
    label,
    type,
    action,
  });

  const menuItems= useMemo(
    () => [
      getItem("Dashboard", "Dashboard", <HomeIcon />, "/ClinicalStaff/dashboard"),
      getItem(
        "Calendar",
        "Calendar",
        <CalendarMonthIcon />,
        "/ClinicalStaff/calendar"
      ),
      getItem("Log out", "Logout", <LogoutIcon />, () => {
        props.clearUser();
        navigate("/LogIn");
      }),
    ],
    [navigate, props]
  );

  return <Sidebar menuItems={menuItems} />;
};

export default ClinicalStaffSideBar;
