import React, { useState } from "react";
import { Button, Grid, Container, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ViewSurgeryPlans } from "../../../components/PatientComponents/SurgeryPlanningGrid";
import { Widget, addResponseMessage } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import axios from 'axios';

const CustomButton = styled(Button)({
  backgroundColor: "#c6dfff",
  width: "190px",
  height: "144px",
  borderRadius: "10px",
  color: "#000",
  fontSize: "17px",
  fontWeight: "700",
  "&:hover": {
    backgroundColor: "#b0d0f0",
  },
});

const PatientPlanningButton = ({ title, onClick }) => (
  <CustomButton variant="contained" disableElevation onClick={onClick}>
    {title}
  </CustomButton>
);

export function PatientPlanning() {
  const navigate = useNavigate();
  const patientId = useOutletContext();
  const [viewSurgeryPlansModal, setViewSurgeryPlansModal] = useState(false);
  const [lastTimeoutId, setLastTimeoutId] = useState(null);

  const buttons = [
    { title: "My Surgery Plan" },
    { title: "Book an Appointment" },
    { title: "My Appointments" },
    { title: "My Referrals" },
    { title: "Prescription Refill" },
  ];

  const handleButtonClick = (title) => {
    switch (title) {
      case "My Surgery Plan":
        setViewSurgeryPlansModal(!viewSurgeryPlansModal);
        break;
      case "Book an Appointment":
        navigate("/patient/calendar");
        break;
      case "My Appointments":
        navigate("/patient/calendar");
        break;
      case "My Referrals":
        navigate("/patient/referrals");
        break;
      case "Prescription Refill":
        navigate("/patient/prescriptionRefills");
        break;
      default:
        console.log(`Clicked on ${title}`);
    }
  };


  const callDeleteDoctorConversationAPI = async () => {
    try {
      const url = `https://chatbot-2024-90539106da8b.herokuapp.com/delete_patient_app_conversation/132`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(), // Assuming requestData is defined globally
      });
      if (!response.ok) {
        console.error('Error:', response.status);
      }
    } catch (error) {
      console.error('Error calling delete_doctor_app_conversation API:', error);
    }
  };
  
  const handleNewUserMessage = async (newMessage) => {
    
    clearTimeout(lastTimeoutId);
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
    try {
      const requestData = {
        userObject: {
          userInput: {
            message: newMessage,
            timezone: userTimezone,
          },
        },
      };
      // TODO: replace 58 with docID
      const url = `https://chatbot-2024-90539106da8b.herokuapp.com/appointment_patient/${patientId}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
      if (response.ok) {
        const responseData = await response.json();
        addResponseMessage(responseData);
        const timeoutId = setTimeout(() => {
          console.log("Chat inactive for 5 minutes. Closing chat or showing message...");
          callDeleteDoctorConversationAPI(); // Call the common function here
          addResponseMessage("Chat reset due to inactivity");
        }, 5*60 * 1000); // 5 minutes in milliseconds
        setLastTimeoutId(timeoutId);
      } else {
        console.error('Error:', response.status);
      }
    } catch (error) {
      console.error('Error fetching response from API:', error);
      addResponseMessage("Sorry, something went wrong. Please try again later.");
    }
  };
  
  window.addEventListener('beforeunload', function (event) {
    callDeleteDoctorConversationAPI(); // Call the common function here
    event.preventDefault();
  });
  
  return (
    <Container maxWidth="lg" sx={{ mt: 8, mb: 4 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3} justifyContent="center">
          {buttons.map((button) => (
            <Grid item key={button.title} xs={12} md={2.4}>
              <PatientPlanningButton
                title={button.title}
                onClick={() => handleButtonClick(button.title)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <ViewSurgeryPlans
        open={viewSurgeryPlansModal}
        onClose={() => setViewSurgeryPlansModal(false)}
        patientId={patientId}
      />
      <Widget
        handleNewUserMessage={handleNewUserMessage}
      />
    </Container>
  );
}
