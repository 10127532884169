import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

const typeFontFamily = { fontFamily: "Mulish, sans-serif" };
const Glucose = () => {
  const getOption = () => {
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          // type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: ["1", "3", "5", "7", "9", "11", "13"],
      },
      yAxis: {
        name: "mmol/L",
        nameTextStyle: {
          color: "#C4C4C4",
          fontSize: "1rem",
          fontWeight: 500,
          ...typeFontFamily,
          align: "right",
          lineHeight: 20,
          padding: [0, 0, 10, 0],
        },
        type: "value",
        min: 7,
        max: 3,
      },
      series: [
        {
          data: [4.1, 5.2, 6.3, 5.9, 5.5, 6.5, 6],
          type: "line",
          smooth: true,
          symbolSize: 0,
          lineStyle: {
            color: "#3377DD",
          },
          areaStyle: { color: "rgba(51,119,221, 0.5)" },
        },
      ],
      grid: {
        top: "15%",
        bottom: "8%",
        right: "5%",
        left: "5%",
        containLabel: true,
      },
    };
  };

  return (
    <ReactECharts
      option={getOption()}
      style={{ height: "35vh", width: "100%" }}
    />
  );
};
export default Glucose;
