// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.predictButton {
    background-color: #3498db !important;
    font-size: 1.2rem;
}`, "",{"version":3,"sources":["webpack://./src/screens/Models/strokePrediction.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,iBAAiB;AACrB","sourcesContent":[".predictButton {\n    background-color: #3498db !important;\n    font-size: 1.2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
