import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Card,
  Flex,
} from "antd";

import RecentPatientList from "./RecentPatientList";
import ClinicalStaffMiniCalendar from './ClinicalStaffMiniCalendar';
import ClinicalStaffUpcomingTasks from "./ClinicalStaffUpcomingTasks";
import PageLayout from "./PageLayout";

import "./ClinicalStaffDashboard.css";

const LeftColumn = (navigate) => {
  return (
    <>
      <Card
        bordered={false}
        style={{
          width: "100%",
          minHeight: "5rem",
          backgroundColor: "#1a4fba",
        }}
      >
        <h2
          style={{
            color: "#ced5dc",
            marginTop: "0.8rem",
            fontSize: "1.65rem",
            textAlign: "left",
          }}
        >
          Hello <span style={{ color: "white" }}>Mr.White,</span>
        </h2>
        <p
          style={{
            color: "#d4e3ef",
            marginTop: "1rem",
            marginBottom: "2rem",
            fontSize: "1rem",
            textAlign: "left",
          }}
        >
          Have a good day at work.
        </p>
      </Card>
      <RecentPatientList/>
    </>
  );
};

const RightColumn = () => {
  const navigate = useNavigate();
  const [ dataRefresh, setDataRefresh ] = useState(false);
  const [ selectedDate, setSelectedDate ] = useState(new Date());

  const handleDataChange = () => {
    setDataRefresh(true);
    setTimeout(() => {
      setDataRefresh(false);
    });
  };

  return <>
    <Card
      bordered={false}
      className="clinicalstaff-dashboard-right-column-card"
    >
      { dataRefresh ? null : (<>
        <ClinicalStaffMiniCalendar date={selectedDate} onSelectDate={(date) => setSelectedDate(date)} />
        <ClinicalStaffUpcomingTasks date={selectedDate} onDataChange={handleDataChange}/>
      </>)}
    </Card>
  </>;
};

const ClinicalStaffDashboard = (props) => {
  return (
    <>
      <PageLayout clearUser={props.clearUser}>
        <Flex justify="space-evenly" style={{ height: "100%", marginTop: "4rem" }}>
          <Flex vertical gap="1rem" style={{ display: "flex", width: "52.3%" }}>
            {LeftColumn()}
          </Flex>
          <Flex vertical gap="1rem" style={{ display: "flex", width: "37.7%" }}>
            {RightColumn()}
          </Flex>
        </Flex>
      </PageLayout>
    </>
  );
};

export default ClinicalStaffDashboard;
