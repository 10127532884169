// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doctor-dashboard-mobile-appointment-list {
    display: flex;
    overflow-x: auto;
    gap: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/screens/DoctorNew/DoctorAppointmentListMobile.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,SAAS;EACX","sourcesContent":[".doctor-dashboard-mobile-appointment-list {\n    display: flex;\n    overflow-x: auto;\n    gap: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
