import { useLocation } from "react-router-dom";
import "../styles/screens/BreastCancer.css";
import React, { useState, useEffect } from "react";
import axios from "axios";

function KidneyFailureML() {
  const [showDiagnose, setShowDiagnose] = useState(false);
  const [data, setData] = useState({});
  const location = useLocation();
  const [prediction, setPrediction] = useState("");
  const patient_id = location.state?.id;
  const BASE_URL = "https://e-react-node-backend-22ed6864d5f3.herokuapp.com"; // Update with your node backend app URL
  // const BASE_URL = "https://e-react-node-backend-22ed6864d5f3.herokuapp.com"; // Update with your node backend app URL

  useEffect(() => {
    // Function to retrieve breast cancer data
    const getKidneyFailureData = async () => {
      try {
        console.log("patient found ", patient_id);
        const response = await axios.post(`${BASE_URL}/getKindeyFailureData`, {
          patient_id,
        });
        setData(response.data[0]);
      } catch (error) {
        alert(`Error: ${error.message}`);
      }
    };

    getKidneyFailureData();
  }, [patient_id]);

  const predict = async () => {
    console.log("data", data);
    const { age, gender, eGFR, upcr, phosphate } = data; // destruct attributes from the data object
    const genderValue = gender === "male" ? 0 : 1; // convert gender to numerical value
    const input = {
      input: [age, eGFR, phosphate, genderValue, upcr],
    };
    console.log("input", input);
    try {
      const response = await axios.post(
        "https://kfmodel-kidney-2024-2fa245c204b8.herokuapp.com/predict",
        input,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setPrediction(response.data.message);
      setShowDiagnose(true);
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <>
      <div className="bc-container">
        <h2 className="title"> Kidney Failure Prediction Results</h2>
        <div className="diagnosis-result">
          <strong>Diagnosis:</strong>
          {showDiagnose ? `${prediction}` : ""}
        </div>

        <table className="results-tablea">
          <thead>
            <tr>
              <th>Parameters</th>
              <th>Values</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Age</td>
              <td>{data.age}</td>
            </tr>

            <tr>
              <td>Gender</td>
              <td>{data.gender}</td>
            </tr>

            <tr>
              <td>eGFR</td>
              <td>{data.eGFR}</td>
            </tr>

            <tr>
              <td>upcr</td>
              <td>{data.upcr}</td>
            </tr>
            <tr>
              <td>phosphate</td>
              <td>{data.phosphate}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <center>
          <button onClick={() => predict()}>Diagnose</button>
          <br />
          <br />
          <br />
        </center>
        <br />
      </div>
    </>
  );
}

export default KidneyFailureML;
