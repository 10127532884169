import React, { useState, useRef } from "react";
import {
  Button,
  TextField,
  Grid,
  Card,
  CardContent,
  Typography,
  Snackbar,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  ListItemIcon,
  FormControl,
  ListItemSecondaryAction,
  Divider,
} from "@mui/material";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
export function MedicalTestRequisitionForm({
  patientId,
  doctorId,
  patientData,
  doctorDetails,
}) {
  const medicalFormToPrintRef = useRef();
  const [formValues, setFormValues] = useState({
    practitionerName:
      doctorDetails.Fname +
      " " +
      doctorDetails.Mname +
      " " +
      doctorDetails.Lname,
    practitionerAddress:
      doctorDetails.Location1 +
      ", " +
      doctorDetails.Location2 +
      ", " +
      doctorDetails.PostalCode,
    practitionerNumber: "",
    practitionerRegistrationNumber: "",
    insuranceCheck: "insured",
    clinicalInformation: "",
    copyToPractitioner: 0,
    otherPractitionerName: "",
    otherPractitionerAddress: "",
    practitionerPhoneNumber: "",
    patientHealthNumber: patientData.HCardNumber,
    patientHealthVersion: "",
    gender: patientData?.Gender?.toLowerCase(),
    DOB: "",
    province: patientData.Province,
    otherProvRegistrationNumber: "",
    patientLastName: patientData.LName,
    patientFirstName: patientData.FName,
    patientMiddleInitial: patientData.MName,
    patientMobileNumber: patientData.MobileNumber,
    patientAddress:
      patientData.Address +
      ", " +
      patientData.Location +
      ", " +
      patientData.City +
      ", " +
      patientData.PostalCode,
    signature: "",
    signatureDate: "",
    glucoseRadio: "",
    hbA1C: 0,
    creatinine: 0,
    uricAcid: 0,
    sodium: 0,
    potassium: 0,
    alt: 0,
    alk: 0,
    bilirubin: 0,
    albumin: 0,
    lipidAssessment: 0,
    albuminCreatineRatio: 0,
    urinalysis: 0,
    neonatalBilirubin: 0,
    childAge: 0,
    childAgeDays: 0,
    childAgeHours: 0,
    clinician_tel_no: "",
    patient24hrTelNo: "",
    therapeuticDrugMonitoring: 0,
    drugName1: "",
    drugName2: "",
    timeCollected1: "",
    timeCollected2: "",
    timeOfLastDose1: "",
    timeOfLastDose2: "",
    timeOfNextDose1: "",
    timeOfNextDose2: "",
    otherTests: "",
    collectionTime: "",
    collectionDate: "",
    cbc: 0,
    prothrombinTime: 0,
    pregnancyTestUrine: 0,
    mononucleosis: 0,
    rubella: 0,
    prenatal: 0,
    repeatPrenatal: 0,
    cervical: 0,
    vaginal: 0,
    vaginalRectal: 0,
    chlamydia: 0,
    chSource: "",
    gc: 0,
    gcSource: "",
    sputum: 0,
    throat: 0,
    wound: 0,
    wdSwabSource: "",
    urine: 0,
    stoolCulture: 0,
    stoolOva: 0,
    otherSwabs: 0,
    oSwabsSource: "",
    accuteHepatitis: 0,
    chronicHepatitis: 0,
    immuneStatus: 0,
    immuneHepA: 0,
    immuneHepB: 0,
    immuneHepC: 0,
    totalPSA: 0,
    freePSA: 0,
    insuredPSA: 0,
    uninsuredPSA: 0,
    insuredVitaminD: 0,
    uninsuredVitaminD: 0,
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value.toString(),
    });
  };

  const handleCheckboxChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.checked ? 1 : 0,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const medicalTestRequest = {
      patientId,
      doctorId,
      ...formValues,
    };

    // Sending the data to the backend API
    try {
      //console.log(medicalTestRequest)
      //'https://e-react-node-backend-22ed6864d5f3.herokuapp.com/
      //https://e-react-node-backend-22ed6864d5f3.herokuapp.com
      const response = await axios.post(
        "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/saveMedicalTest",
        medicalTestRequest
      );
      console.log(response.data);
      setSnackbarOpen(true); // Show success message
    } catch (error) {
      console.error("Error sending data to the backend:", error);
    }
    handleMedicalFormToPrint();
  };
  const handleMedicalFormToPrint = useReactToPrint({
    content: () => medicalFormToPrintRef.current,
  });
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div ref={medicalFormToPrintRef}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          {/* Practitioner Information Section */}
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography variant="h6">Laboratory Requisition</Typography>
                <Typography variant="h7">
                  Requisitioning Clinician/Practitioner
                </Typography>
                <TextField
                  label="Name"
                  name="practitionerName"
                  onChange={handleChange}
                  value={formValues.practitionerName}
                  fullWidth
                  margin="normal"
                  multiline
                />
                <TextField
                  label="Address"
                  name="practitionerAddress"
                  onChange={handleChange}
                  value={formValues.practitionerAddress}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={2}
                />
                <TextField
                  label="Clinician/Practicioner Number"
                  name="practitionerNumber"
                  onChange={handleChange}
                  sx={{ width: "60%" }}
                  value={formValues.practitionerNumber}
                  margin="normal"
                  multiline
                />
                <TextField
                  label="CPSO/Registration Number"
                  name="practitionerRegistrationNumber"
                  onChange={handleChange}
                  sx={{ width: "40%" }}
                  value={formValues.practitionerRegistrationNumber}
                  margin="normal"
                  multiline
                />
                <Typography variant="p">Check one</Typography>
                <RadioGroup
                  row
                  name="insuranceCheck"
                  value={formValues.insuranceCheck}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="insured"
                    control={<Radio />}
                    label="OHIP/Insured"
                  />
                  <FormControlLabel
                    value="uninsured"
                    control={<Radio />}
                    label="Third Party/Uninsured"
                  />
                  <FormControlLabel
                    value="wsib"
                    control={<Radio />}
                    label="WSIB"
                  />
                </RadioGroup>
                <TextField
                  label="Additional Clinical Information (e.g diagnosis)"
                  name="clinicalInformation"
                  onChange={handleChange}
                  value={formValues.clinicalInformation}
                  fullWidth
                  margin="normal"
                  multiline
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="copyToPractitioner"
                      checked={formValues.copyToPractitioner}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Copy To Pratitioner"
                />
                <TextField
                  label="Name"
                  name="otherPractitionerName"
                  onChange={handleChange}
                  value={formValues.otherPractitionerName}
                  fullWidth
                  margin="normal"
                  multiline
                />
                <TextField
                  label="Address"
                  name="otherPractitionerAddress"
                  onChange={handleChange}
                  fullWidth
                  value={formValues.otherPractitionerAddress}
                  margin="normal"
                  multiline
                  rows={2}
                />
              </CardContent>
            </Card>
          </Grid>
          {/* Patient side   & Lab Side*/}
          <Grid item xs={12} sm={8}>
            <Card>
              <CardContent>
                {/* Lab Use */}
                <Card sx={{ backgroundColor: "#F5F5F5" }}>
                  <Typography variant="h6">For lab use only</Typography>
                  <TextField
                    label="Lab Notes"
                    name="labNotes"
                    margin="normal"
                    multiline
                    disabled
                    defaultValue={"..."}
                    rows={5}
                    sx={{ mx: 2, width: "70%" }}
                  />
                  <TextField
                    label="Service Date"
                    name="labServiceDate"
                    type="date"
                    margin="normal"
                    multiline
                    disabled
                    defaultValue={"yyyy-mm-dd"}
                  />
                </Card>
                <TextField
                  label="Clinician/Practitioner's Contact Number for Urgent Results"
                  name="practitionerPhoneNumber"
                  onChange={handleChange}
                  value={formValues.practitionerPhoneNumber}
                  multiline
                  fullWidth
                  margin="normal"
                />
                {/* Lab Use End */}
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      label="Health Number"
                      name="patientHealthNumber"
                      onChange={handleChange}
                      value={formValues.patientHealthNumber}
                      fullWidth
                      margin="normal"
                      multiline
                    />
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <TextField
                      label="Version"
                      name="patientHealthVersion"
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      value={formValues.patientHealthVersion}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={6} sm={2} container alignItems="center">
                    <RadioGroup
                      row
                      name="gender"
                      value={formValues.gender}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="F"
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="M"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={6} sm={3} container>
                    <TextField
                      label="Date of Birth"
                      type="date"
                      name="DOB"
                      value={formValues.DOB}
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1}>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Province"
                      name="province"
                      onChange={handleChange}
                      value={formValues.province}
                      fullWidth
                      margin="normal"
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Other Provisional Registration Number"
                      name="otherProvRegistrationNumber"
                      onChange={handleChange}
                      value={formValues.otherProvRegistrationNumber}
                      fullWidth
                      margin="normal"
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} container>
                    <TextField
                      label="Patient Contact Phone number"
                      name="patientMobileNumber"
                      onChange={handleChange}
                      value={formValues.patientMobileNumber}
                      margin="normal"
                      multiline
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <TextField
                  label="Patient Last Name (as per OHIP card)"
                  name="patientLastName"
                  onChange={handleChange}
                  value={formValues.patientLastName}
                  margin="normal"
                  multiline
                  fullWidth
                />
                <Grid item xs={12} container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Patient First Name (as per OHIP card)"
                      name="patientFirstName"
                      onChange={handleChange}
                      value={formValues.patientFirstName}
                      fullWidth
                      margin="normal"
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Patient Middle Name (as per OHIP card)"
                      name="patientMiddleInitial"
                      onChange={handleChange}
                      value={formValues.patientMiddleInitial}
                      fullWidth
                      margin="normal"
                      multiline
                    />
                  </Grid>
                </Grid>
                <TextField
                  label="Patient's Address (Including Postal Code)"
                  name="patientAddress"
                  value={formValues.patientAddress}
                  onChange={handleChange}
                  multiline
                  rows={2}
                  fullWidth
                />
                <Typography>
                  Note: separate Requisitions are required for cytology,
                  histology/pathology, ColonCancerCheck FIT Test, and test
                  performed by Public Health Laboratory
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {/**  *****                      ****** */}
          <Grid item xs={12} container>
            {/**_________________________Biochemistry_________________________ **/}
            <Grid item xs={12} sm={4}>
              <List>
                <ListSubheader sx={{ backgroundColor: "WhiteSmoke" }}>
                  Biochemistry
                </ListSubheader>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox edge="start" name="glucose" />
                  </ListItemIcon>
                  <ListItemText primary="Glucose" />
                  <ListItemSecondaryAction>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        name="glucoseRadio"
                        value={formValues.glucoseRadio}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="random"
                          control={<Radio />}
                          label="Random"
                        />
                        <FormControlLabel
                          value="fasting"
                          control={<Radio />}
                          label="Fasting"
                        />
                      </RadioGroup>
                    </FormControl>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="hbA1C"
                      checked={formValues.hbA1C}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="hbA1C" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="creatinine"
                      checked={formValues.creatinine}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Creatinine (eGFR)" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="uricAcid"
                      checked={formValues.uricAcid}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Uric Acid" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="sodium"
                      checked={formValues.sodium}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Sodium" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="potassium"
                      checked={formValues.potassium}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Potassium" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="alt"
                      checked={formValues.alt}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="ALT" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="alk"
                      checked={formValues.alk}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Alk. Phophasate" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="bilirubin"
                      checked={formValues.bilirubin}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Bilirubin" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="albumin"
                      checked={formValues.albumin}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Albumin" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="lipidAssessment"
                      value={formValues.lipidAssessment}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary='Lipid Assessment(Includes Cholesterol, HDL-C,
                  Triglycerides, Calculated LDL-C & Chol/HDL-C ratio; individual lipid test
                  must be odered in the "Other Tests" section of this form   '
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="albuminCreatine"
                      checked={formValues.albuminCreatineRatio}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Albumin/Creatine Ratio, Urine" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="urinalysis"
                      checked={formValues.urinalysis}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Urinalysis" />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="neonatalBilirubin"
                      checked={formValues.neonatalBilirubin}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Neonatal Bilirubin" />
                </ListItem>
                <ListItem>
                  <TextField
                    label="Child's Age"
                    type="number"
                    multiline
                    variant="standard"
                    name="childAge"
                    value={formValues.childAge}
                  />
                  <TextField
                    label="days"
                    type="number"
                    multiline
                    variant="standard"
                    name="childAgeDays"
                    value={formValues.childAgeDays}
                  ></TextField>
                  <TextField
                    label="hours"
                    type="number"
                    multiline
                    variant="standard"
                    name="childAgeHours"
                    value={formValues.childAgeHours}
                  ></TextField>
                </ListItem>
                <ListItem>
                  <TextField
                    label="Clinician/practicional tel no."
                    fullWidth
                    multiline
                    variant="standard"
                    name="clinician_tel_no"
                    value={formValues.clinician_tel_no}
                  />
                </ListItem>
                <ListItem>
                  <TextField
                    label="Patient's 24hr tel no."
                    fullWidth
                    multiline
                    variant="standard"
                    name="patient24hrTelNo"
                    value={formValues.patient24hrTelNo}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="therapeuticDrugMonitering"
                      value={formValues.therapeuticDrugMonitoring}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Therapeutic Drug Monitoring" />
                </ListItem>
                <ListItem>
                  <TextField
                    label="Name of Drug #1"
                    fullWidth
                    multiline
                    variant="standard"
                    name="drugName1"
                    value={formValues.drugName1}
                  />
                </ListItem>
                <ListItem>
                  <TextField
                    label="Name of Drug #2"
                    fullWidth
                    multiline
                    variant="standard"
                    name="drugName2"
                    value={formValues.drugName2}
                  />
                </ListItem>
                <ListItem>
                  <TextField
                    label="Time Collected #1"
                    helperText="hr."
                    multiline
                    variant="standard"
                    name="timeCollected1"
                    value={formValues.timeCollected1}
                  />
                  <TextField
                    label="#2"
                    helperText="hr."
                    multiline
                    variant="standard"
                    name="timeCollected2"
                    value={formValues.timeCollected2}
                  />
                </ListItem>
                <ListItem>
                  <TextField
                    label="Time Of Last Dose #1"
                    helperText="hr."
                    multiline
                    variant="standard"
                    name="timeOfLastDose1"
                    value={formValues.timeOfLastDose1}
                  />
                  <TextField
                    label="#2"
                    helperText="hr."
                    multiline
                    variant="standard"
                    name="timeOfLastDose2"
                    value={formValues.timeOfLastDose2}
                  />
                </ListItem>
                <ListItem>
                  <TextField
                    label="Time Of Next Dose #1"
                    helperText="hr."
                    multiline
                    variant="standard"
                    name="timeOfNextDose1"
                    value={formValues.timeOfNextDose1}
                  />
                  <TextField
                    label="#2"
                    helperText="hr."
                    multiline
                    variant="standard"
                    name="timeOfNextDose2"
                    value={formValues.timeOfNextDose2}
                  />
                </ListItem>
                <Typography>
                  I hereby certify the tests ordered are not for registered in
                  or out patients of a hospital{" "}
                </Typography>
                <ListItem>
                  <TextField
                    label="X Signature"
                    multiline
                    variant="standard"
                    sx={{ width: "60%" }}
                    name="signature"
                    value={formValues.signature}
                  />
                  <TextField
                    label="Date"
                    type="date"
                    variant="standard"
                    sx={{ width: "40%" }}
                    name="signatureDate"
                    value={formValues.signatureDate}
                  />
                </ListItem>
              </List>
            </Grid>
            {/*_____________________Hematology etc____________________ */}
            <Grid item xs={12} sm={4}>
              <List>
                <ListSubheader sx={{ backgroundColor: "WhiteSmoke" }}>
                  Hematology
                </ListSubheader>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="cbc"
                      value={formValues.cbc}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="CBC" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="prothrobinTime"
                      value={formValues.prothrombinTime}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Prothrobin Time (INR)" />
                </ListItem>
              </List>
              {/* Immunology */}
              <List>
                <ListSubheader sx={{ backgroundColor: "WhiteSmoke" }}>
                  Immunology
                </ListSubheader>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="pregnancyTestUrine"
                      value={formValues.pregnancyTestUrine}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Pregnancy Test (Urine)" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="mononucleousis"
                      value={formValues.mononucleosis}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Mononucleosis Screen" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="rubella"
                      value={formValues.rubella}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Rubella" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="prenatal"
                      value={formValues.prenatal}
                      onChange={handleChange}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Prenatal: ABO, RhD, Antibody Screen
                  (Titre and Ident. if positive) "
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="repeatPrenatal"
                      value={formValues.repeatPrenatal}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Repeat Prenatal Antibodies " />
                </ListItem>
              </List>
              {/* Microbiology */}
              <List>
                <ListSubheader sx={{ backgroundColor: "WhiteSmoke" }}>
                  Microbiology ID & Sensitivities (If Warranted)
                </ListSubheader>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="cervical"
                      value={formValues.cervical}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Cervical" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="vaginal"
                      value={formValues.vaginal}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Vaginal" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="vaginalRectal"
                      value={formValues.vaginalRectal}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Vaginal Rectal Group B Strep" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="chlamydia"
                      value={formValues.chlamydia}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Chlamydia (specify source):" />
                  <TextField
                    label="source"
                    name="chSource"
                    multiline
                    variant="standard"
                    value={formValues.chSource}
                    onChange={handleChange}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="gc"
                      value={formValues.gc}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="GC (specify source):" />
                  <TextField
                    name="gcSource"
                    label="source"
                    multiline
                    variant="standard"
                    value={formValues.gcSource}
                    onChange={handleChange}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="sputum"
                      value={formValues.sputum}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Sputum" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="throat"
                      value={formValues.throat}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Throat" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="wound"
                      value={formValues.wound}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Wound (specify source):" />
                  <TextField
                    name="wdSource"
                    label="source"
                    multiline
                    variant="standard"
                    value={formValues.wdSource}
                    onChange={handleChange}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="urine"
                      value={formValues.urine}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Urine" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="stoolCulture"
                      value={formValues.stoolCulture}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Stool Culture" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="stoolOva"
                      value={formValues.stoolOva}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Stool Ova & Parasites " />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="otherSwabs"
                      value={formValues.otherSwabs}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Other Swabs / Pus (specify sources) " />
                  <TextField
                    label="source"
                    name="oSwabsSource"
                    multiline
                    variant="standard"
                    value={formValues.oSwabsSource}
                    onChange={handleChange}
                  />
                </ListItem>
              </List>
              <ListSubheader sx={{ backgroundColor: "WhiteSmoke" }}>
                Specimen Collection
              </ListSubheader>
              <TextField
                label="time"
                type="time"
                sx={{ width: "60%" }}
                name="collectionTime"
                value={formValues.collectionTime}
                onChange={handleChange}
              />
              <TextField
                label="date"
                type="date"
                sx={{ width: "40%" }}
                name="collectionDate"
                value={formValues.collectionDate}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              {/* Hepatitis */}
              <List>
                <ListSubheader sx={{ backgroundColor: "WhiteSmoke" }}>
                  Viral Hepatitis( Check one only)
                </ListSubheader>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="acuteHepatitis"
                      value={formValues.accuteHepatitis}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Acute Hepatitis" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="chronicHepatitis"
                      value={formValues.chronicHepatitis}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Chronic Hepatitis" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="immuneStatus"
                      value={formValues.immuneStatus}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Immune Status/Previous Exposure, Specify:" />
                  <List>
                    <ListItem>
                      <Checkbox
                        name="immuneHepA"
                        value={formValues.immuneHepA}
                        onChange={handleCheckboxChange}
                      />
                      <ListItemText primary="Hepatitis A" />
                    </ListItem>
                    <ListItem>
                      <Checkbox
                        name="immuneHepB"
                        value={formValues.immuneHepB}
                        onChange={handleCheckboxChange}
                      />
                      <ListItemText primary="Hepatitis B" />
                    </ListItem>
                    <ListItem>
                      <Checkbox
                        name="immuneHepC"
                        value={formValues.immuneHepC}
                        onChange={handleCheckboxChange}
                      />
                      <ListItemText primary="Hepatitis C" />
                    </ListItem>
                    Or other individual Hepatitis tests in the "Other tests"
                    section below
                  </List>
                </ListItem>
              </List>
              {/* ------------ PSA -------------- */}
              <List>
                <ListSubheader sx={{ backgroundColor: "WhiteSmoke" }}>
                  Prostate Specific Antigen (PSA)
                </ListSubheader>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="totalPSA"
                      value={formValues.totalPSA}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Total PSA" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="freePSA"
                      value={formValues.freePSA}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Free PSA" />
                </ListItem>
                Specify one below:
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="insuredPSA"
                      value={formValues.insuredPSA}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Insured - Meets OHIP eligibility Criteria" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="uninsuredPSA"
                      value={formValues.uninsuredPSA}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Uninsured - Screening: Patient responsible for payment" />
                </ListItem>
              </List>
              <List>
                <ListSubheader sx={{ backgroundColor: "WhiteSmoke" }}>
                  Vitamin D (25-Hydroxy)
                </ListSubheader>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="insuredVitaminD"
                      value={formValues.insuredVitaminD}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Insured - Meets OHIP eligibility criteria: Osteopenia;
                  Osteoporosis; rickets; renal diseas; malabsorption syndromes; medications
                  affecting vitamin D metabolism"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      name="uninsuredVitamin"
                      value={formValues.uninsuredVitaminD}
                      onChange={handleCheckboxChange}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Uninsured - Patient responsible for payment" />
                </ListItem>
              </List>
              <List>
                <ListSubheader sx={{ backgroundColor: "WhiteSmoke" }}>
                  Other Tests - One Test Per Line
                </ListSubheader>
                <TextField
                  name="otherTests"
                  fullWidth
                  multiline
                  rows={11}
                  value={formValues.otherTests}
                  onChange={handleChange}
                />
              </List>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
                style={{width:'max-content',
                  flex:'wrap',
                  margin:'50px',
                  color:'white',
                  backgroundColor:'#1A4FBA',
                  border:'none',
                  fontFamily:'Poppins'}}
                type="submit" variant="contained" color="primary">
              Submit Lab Request and Print
            </Button>
          </Grid>
        </Grid>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message="Form submitted successfully"
          anchorOrigin={{ vertical: "botton", horizontal: "right" }}
        />
      </form>
    </div>
  );
}
export default MedicalTestRequisitionForm;
