import React, { useState } from "react";
import {
  Typography,
  Button,
  Grid,
  Paper,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar,
  Box,
  Modal,
  buttonBaseClasses,
  ListItemButton,
} from "@mui/material";
import { Card as AntCard } from "antd";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { readLoginData } from "../../loginData";
import FloatingChatWindow from "../../components/FloatingChatWindow";
import { useOutletContext } from "react-router-dom";
import { PatientMedicalHistory } from "../../components/DoctorComponents/PatientMedicalHistory";
import axios from "axios";
import PatientPortalCalendar from "./PatientPortalCalendar";
import EventNoteIcon from "@mui/icons-material/EventNote";
import EditNoteIcon from "@mui/icons-material/EditNote";
import GridLayout from "./DataVisualization/GridLayout";
import DoctorMiniCalendar from "./PatientCalendar/PatientMiniCalendar";
import DoctorUpcomingTasks from "./PatientCalendar/PatientUpcomingTasks";
import { HealthOverview } from "./DataVisualization/HealthOverview";
import { PatientReminder } from "../../components/PatientComponents/patient-reminder/PatientReminder";
import { RecentMedicalRecords } from "./RecentMedicalRecords";
import PatientPortalMobile from "./PatientPortalMobile";
import { useNavigate } from "react-router-dom";
const handleOpenNewTab = (path) => {
  const url = window.location.origin + path;
  window.open(url, "_blank");
};

export const BlueButton = ({
  buttonTitle,
  width,
  height,
  fontSize,
  onClick,
}) => {
  return (
    <Button
      variant="contained"
      sx={{
        textTransform: "none",
        borderRadius: "0.8em",
        padding: "0.9em 2em 0.9em 2em",
        width: width ? width : "auto",
        height: height ? height : "auto",
        fontSize: fontSize ? fontSize : "auto",
        backgroundColor: "#1A4EBA",
        fontFamily: "Mulish, Roboto ,Helvetica,Arial, sans-serif",
      }}
      onClick={onClick}
    >
      {buttonTitle}
    </Button>
  );
};

export function PatientPortal() {
  const loginData = readLoginData();
  const navigate = useNavigate();
  const [windowOpen, setwindowOpen] = React.useState(false);
  const patientId = useOutletContext();
  const [isMedicalHistoryModalOpen, setIsMedicalHistoryModalOpen] =
    React.useState(false);
  const [profileData, setProfileData] = React.useState({});
  const [calendarData, setCalendarData] = React.useState([]);

  React.useEffect(() => {
    const getData = async () => {
      try {
        // https://e-react-node-backend-22ed6864d5f3.herokuapp.com
        // https://e-react-node-backend-22ed6864d5f3.herokuapp.com
        const response = await axios.post(
          "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/users/getPatientPortalInfoById",
          {
            patientId,
          }
        );
        const { data } = response;
        if (data.error) {
          alert(JSON.stringify(data.error));
          console.log("error ");
        } else {
          console.log("data", data);
          setProfileData(data);
        }
      } catch (error) {
        console.log(
          `Error With request getting auth  recent : ${error.message}`
        );
      }
    };
    getData();
  }, [patientId]);

  const openMedicalHistoryModal = () => {
    setIsMedicalHistoryModalOpen(true);
  };

  const toggleChatWindow = () => {
    setwindowOpen(!windowOpen);
  };

  const styleMini = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    minHeight: "50%",
    pt: 2,
    px: 4,
    pb: 3,
    overflowY: "auto",
  };
  const typoColorStyle = {
    color: "#FFFFFF",
  };

  //right second column
  const [dataRefresh, setDataRefresh] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDataChange = () => {
    setDataRefresh(true);
    setTimeout(() => {
      setDataRefresh(false);
    });
  };
  let width = window.innerWidth;
  if (width <= 605) {
    return (
      <>
        <PatientPortalMobile />
      </>
    );
  } else {
    return (
      <Box
        sx={{
          width: "100%",
          overflowX: "hidden",
        }}
      >
        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Grid item sx={{ width: "58%" }}>
            <Grid container direction="column" spacing={2}>
              <Grid item sx={{ height: "160px" }}>
                <Card
                  elevation={0}
                  style={{
                    padding: "20px",
                    backgroundColor: "#0E45B7",
                    textAlign: "left",
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    borderRadius: "1em",
                  }}
                >
                  <Typography variant="h5" gutterBottom style={typoColorStyle}>
                    Hello {profileData.FName},
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={typoColorStyle}
                  >
                    Welcome to the patient portal. Here you can access your
                    medical information and more.
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <PatientReminder />
              </Grid>
              <Grid item sx={{ height: "auto" }}>
                <Card
                  elevation={0}
                  style={{
                    padding: "20px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "1em",
                    height: "100%",
                  }}
                >
                  <HealthOverview />
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ width: "42%" }}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={6}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <div>
                      <BlueButton
                        buttonTitle={"Live Text Chat"}
                        width="100%"
                        height="42px"
                        onClick={toggleChatWindow}
                      />
                      {windowOpen && (
                        <FloatingChatWindow
                          patientId={loginData.id}
                          closeChat={toggleChatWindow}
                          identity="patient"
                        />
                      )}
                    </div>
                  </Grid>

                  {/* should pass doctor ID and patient ID here */}
                  <Grid
                    item
                    xs={6}
                    justifyContent="flex-end"
                    alignItems="flex-start"
                  >
                    <BlueButton
                      buttonTitle={"Video Call"}
                      width="100%"
                      height="42px"
                      onClick={() =>
                        handleOpenNewTab(
                          `/DoctorVideo?patientID=${loginData.id}`
                        )
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    justifyContent="flex-start"
                    alignItems="flex-end"
                  >
                    <BlueButton
                      buttonTitle={"Medical Chatbot"}
                      width="100%"
                      height="42px"
                      onClick={() => handleOpenNewTab(`/Chatbot`)}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <BlueButton
                      buttonTitle={"Medical Records"}
                      width="100%"
                      height="42px"
                      onClick={() => navigate(`/patient/report`)}
                    />
                  </Grid>
                  <Modal
                    open={isMedicalHistoryModalOpen}
                    onClose={() => setIsMedicalHistoryModalOpen(false)}
                  >
                    <Box style={styleMini}>
                      <Card>
                        <CardContent>
                          <PatientMedicalHistory patientId={patientId} />
                          <Button
                            onClick={() => setIsMedicalHistoryModalOpen(false)}
                            fullWidth
                            variant="contained"
                            color="secondary"
                          >
                            Exit
                          </Button>
                        </CardContent>
                      </Card>
                    </Box>
                  </Modal>
                </Grid>
              </Grid>
              <Grid item sx={{ height: "100%" }}>
                <AntCard
                  bordered={false}
                  className="doctor-dashboard-right-column-card"
                >
                  {dataRefresh ? null : (
                    <>
                      <DoctorMiniCalendar
                        date={selectedDate}
                        onSelectDate={(date) => setSelectedDate(date)}
                      />
                      <DoctorUpcomingTasks
                        date={selectedDate}
                        onDataChange={handleDataChange}
                      />
                    </>
                  )}
                </AntCard>
              </Grid>
              <Grid item>
                <Card
                  elevation={0}
                  style={{
                    padding: "20px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "1em",
                    height: "100%",
                    minHeight: "10vh",
                  }}
                >
                  <RecentMedicalRecords />
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
