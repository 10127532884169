import React, { useState } from "react";
import { IoMdFolderOpen } from "react-icons/io";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import PriscriptionForm from "../PriscriptionForm";
import MedicalTestView from "../MedicalTestView.jsx";
import { MedicalTestRequisitionForm } from "../MedicalTestRequisitionForm";
import DownloadUploadForm from "../../../components/DoctorComponents/DownloadUploadForm";
import { Typography, TextField, Grid, Snackbar } from "@mui/material";

const EncounterButton = ({ patientId, doctorId, closeHandler }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowData, setRowData] = useState({
    medicationName: "",
    dose: "",
    startDate: "",
    endDate: "",
  });
  const [patientData, setPatientData] = React.useState({});

  const [doctorDetails, setDoctorDetails] = useState({});
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const [showCreatePrescriptionModal, setShowCreatePrescriptionModal] =
    useState(false);
  const [showExitConfirmationModal, setShowExitConfirmationModal] =
    useState(false);
  const handleOpenExitModal = () => {
    setShowExitConfirmationModal(true);
  };

  const handleCloseExitModal = () => {
    setShowExitConfirmationModal(false);
  };
  const handleConfirmExit = () => {
    setShowExitConfirmationModal(false);
    if (closeHandler) {
      closeHandler();
    }
  };

  const stylepri = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "310px",
    height: "350px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    backgroundColor: "#F2F8FD",
    borderRadius: "4px",
  };

  const handlePrescriptionSubmit = async () => {
    console.log("Form data submitted", rowData);
    handleCloseModal();
  };
  const handleChange = (e, field) => {
    setRowData({ ...rowData, [field]: e.target.value });
  };

  const handleSign = async () => {
    setShowCreatePrescriptionModal(false);

    try {
      const response = await fetch(
        "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/users/submitPrescription",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(rowData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result);
    } catch (error) {
      console.error("Failed to send data to the backend", error);
    }
  };
  const [showRequestMedicalTestModal, setShowRequestMedicalTestModal] =
    useState(false);

  const handleShowCreatePrescription = () => {
    setShowCreatePrescriptionModal(true);
  };

  const inputStylePrescription = {
    border: "none",
    backgroundColor: "transparent",
    padding: "0px",
    margin: "0px",
    height: "15px",
    boxSizing: "border-box",
  };
  const headerStylePrescription = {
    backgroundColor: "rgb(25, 78, 186)",
    color: "white",
    padding: "2px 4px",
    borderColor: "rgb(25, 78, 186)",
    Padding: "0px 20px 0px 20px",
    
  };

  const tableStylePrescription = {
    borderRadius: "10px",
    marginLeft: "2em",
  };
  const tableStyleTest = {
    borderRadius: "10px",
    marginLeft: "2em",
  };
  const styleRequestForMedical = {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    minHeight: "50%",
    maxHeight: "100%",
    pt: 2,
    px: 4,
    pb: 3,
    overflowY: "auto",
  };

  const stylebar = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "310px",
    height: "350px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    backgroundColor: "#F2F8FD",
    borderRadius: "4px",
  };

  return (
    <form style={{ marginLeft: "20px", marginRight: "20px" }}>
      <section style={{ display: "flex" }}>
        <p
          style={{
            color: "rgb(25, 78, 186)",
            display: "flex",
            marginRight: "0.2em",
            marginTop: "0.5em",
            fontSize: "22px",
            weight: "500",
          }}
        >
          <IoMdFolderOpen
            style={{
              color: "rgb(25, 78, 186)",
              fontSize: "22px",
              marginRight: "0.5em",
              weight: "500",
            }}
          />
          Notes
        </p>
        <textarea
          style={{
            height: "120px",
            width: "80%",
            marginLeft: "13em",
            border: "2px solid rgb(25, 78, 186)",
            borderRadius: "3px",
          }}
          placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing eli"
        ></textarea>
      </section>
      <hr
        style={{
          border: "0.5px solid rgb(44, 44, 44)",
          width: "93%",
          marginLeft: "3em",
          marginTop: "0.5em",
        }}
      />
      <section style={{ display: "flex", marginTop: "0.7" }}>
        <p
          style={{
            color: "rgb(25, 78, 186)",
            display: "flex",
            marginRight: "0.2em",
            marginTop: "0.5em",
            fontSize: "22px",
            weight: "500",
          }}
        >
          <IoMdFolderOpen
            style={{
              color: "rgb(25, 78, 186)",
              fontSize: "22px",
              weight: "500",
              marginRight: "0.5em",
            }}
          />
          Diagnosis
        </p>
        <textarea
          style={{
            height: "120px",
            width: "80%",
            marginLeft: "13em",
            border: "2px solid rgb(25, 78, 186)",
            borderRadius: "3px",
          }}
          placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing eli"
        ></textarea>
      </section>
      <hr
        style={{
          border: "0.5px solid rgb(44, 44, 44)",
          width: "93%",
          marginLeft: "3em",
          marginTop: "0.5em",
        }}
      />
      <section>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1em",
            marginTop: "0.7em",
          }}
        >
          <p
            style={{
              color: "rgb(25, 78, 186)",
              display: "flex",
              alignItems: "center",
              fontSize: "22px",
              weight: "500",
            }}
          >
            <IoMdFolderOpen
              style={{
                color: "rgb(25, 78, 186)",
                fontSize: "22px",
                weight: "500",
                marginRight: "0.5em",
              }}
            />
            Prescription
          </p>
        </div>
        <div>
          <Button
            variant="text"
            startIcon={<AddCircleOutlineIcon />}
            style={{
              marginTop: "-50px",
              color: "rgb(25, 78, 186)",
              fontWeight: "bold",
            }}
            onClick={handleOpen}
          >
            Add Prescription
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "50%",
                minHeight: "50%",
                pt: 2,
                px: 4,
                pb: 3,
                overflowY: "auto",
              }}
            >
              <Card>
                <CardContent>
                  <PriscriptionForm
                    patientId={patientId}
                    doctorId={doctorId}
                    patientData={patientData}
                    closeModal={() => setShowCreatePrescriptionModal(false)}
                  />
                </CardContent>
              </Card>
            </Box>
          </Modal>
        </div>

        <p
          style={{
            color: "#6E7191",
            fontSize: "16px",
            fontFamily: "popins",
            fontWeight: "400",
            marginTop: "9px",
            textAlign: "left",
            marginLeft: "20px",
            marginBottom: "5px",
          }}
        >
          New Prescriptions:{" "}
        </p>

        <table
          style={{
            width: "90%",
            marginLeft: "20px",
            height: "10px",
            borderCollapse: "separate",
            borderSpacing: "0",
            borderRadius: "6px 6px 0px 0px",
            borderColor: "black",
          }}
        >
          <thead>
            <tr style={{ height: "20px" }}>
              <th style={headerStylePrescription}>Medication Name</th>
              <th style={headerStylePrescription}>Dose</th>
              <th style={headerStylePrescription}>Starting Date</th>
              <th style={headerStylePrescription}>End Date</th>
            </tr>
          </thead>
          <tbody>
            <tr
              style={{
                height: "20px",
                backgroundColor: "white",
                borderColor: "black",
              }}
            >
              <td
                style={{
                  padding: "2px 4px",
                  borderLeft: "1px solid Black",
                  borderRight: "none",
                  borderBottom: "none",
                  borderColor: "black",
                }}
              >
                Value
              </td>
              <td
                style={{
                  padding: "2px 4px",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "none",
                  borderColor: "black",
                }}
              >
                Value
              </td>
              <td
                style={{
                  padding: "2px 4px",
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "none",
                  borderColor: "black",
                }}
              >
                Value
              </td>
              <td
                style={{
                  padding: "2px 4px",
                  borderRight: "1px solid black",
                  borderLeft: "none",
                  borderBottom: "none",
                  borderColor: "black",
                }}
              >
                Value
              </td>
            </tr>
            {/* Second row starts here */}
            <tr
              style={{
                height: "20px",
                backgroundColor: "white",
                borderColor: "black",
              }}
            >
              <td
                style={{
                  padding: "2px 4px",
                  borderLeft: "1px solid black",
                  borderRight: "none",
                  borderColor: "black",
                }}
              >
                Value
              </td>
              <td
                style={{
                  padding: "2px 4px",
                  borderRight: "none",
                  borderLeft: "none",
                  borderColor: "black",
                }}
              >
                Value
              </td>
              <td
                style={{
                  padding: "2px 4px",
                  borderRight: "none",
                  borderLeft: "none",
                  borderColor: "black",
                }}
              >
                Value
              </td>
              <td
                style={{
                  padding: "2px 4px",
                  borderRight: "1px solid black",
                  borderLeft: "none",
                  borderColor: "black",
                }}
              >
                Value
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <hr
        style={{
          border: "0.5px solid rgb(44, 44, 44)",
          width: "93%",
          marginLeft: "3em",
          marginTop: "0.5em",
        }}
      />
      <section>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1em",
            marginTop: "0.7em",
          }}
        >
          <p
            style={{
              color: "rgb(25, 78, 186)",
              display: "flex",
              alignItems: "center",
              fontSize: "22px",
              weight: "500",
            }}
          >
            <IoMdFolderOpen
              style={{
                color: "rgb(25, 78, 186)",
                fontSize: "22px",
                weight: "500",
                marginRight: "0.5em",
              }}
            />
            Medical Tests
          </p>
        </div>
        <div>
          <Button
            variant="text"
            startIcon={<AddCircleOutlineIcon />}
            style={{
              marginTop: "-30px",
              color: "rgb(25, 78, 186)",
              fontWeight: "bold",
            }}
            onClick={() => setShowRequestMedicalTestModal(true)}
          >
            Request Medical Test
          </Button>
          <Modal
            open={showRequestMedicalTestModal}
            onClose={() => setShowRequestMedicalTestModal(false)}
          >
            <Box>
              <MedicalTestView />
              <Card>
                <Typography variant="outlined">
                  {" "}
                  (Scroll down and up to navigate through our web form){" "}
                </Typography>
              </Card>
              <Card sx={styleRequestForMedical}>
                <CardContent>
                  <MedicalTestRequisitionForm
                    patientId={patientId}
                    doctorId={doctorId}
                    patientData={patientData}
                    doctorDetails={doctorDetails}
                  />
                </CardContent>
              </Card>
              <Card sx={{ position: "relative" }}>
                <DownloadUploadForm />
                <Button
                  onClick={() => setShowRequestMedicalTestModal(false)}
                  color="secondary"
                  variant="contained"
                >
                  Exit
                </Button>
              </Card>
            </Box>
          </Modal>
        </div>

        <p
          style={{
            color: "#6E7191",
            fontSize: "16px",
            fontFamily: "poppins",
            fontWeight: "400",
            marginTop: "15px",
            textAlign: "left",
            marginLeft: "20px",
            marginBottom: "5px",
          }}
        >
          New Tests:
        </p>

        <table
          style={{ tableLayout: "fixed", width: "50%", marginLeft: "20px" }}
        >
          <thead>
            <tr>
              <th style={headerStylePrescription}>Test</th>
              <th style={headerStylePrescription}> Date</th>
            </tr>
          </thead>
          <tbody style={{ height: "5px" }}>
            <tr
              style={{
                height: "20px",
                backgroundColor: "white",
                borderColor: "black",
              }}
            >
              <td
                style={{
                  padding: "2px 4px",
                  borderLeft: "1px solid Black",
                  borderRight: "none",

                  borderColor: "black",
                }}
              >
                Value
              </td>
              <td
                style={{
                  padding: "2px 4px",

                  borderLeft: "none",

                  borderColor: "black",
                }}
              >
                Value
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <hr
        style={{
          border: "0.5px solid rgb(44, 44, 44)",
          width: "93%",
          marginLeft: "3em",
          marginTop: "0.5em",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "16px",
          marginBottom: "16px",
          marginRight: "16px",
        }}
      >
        <Button
          variant="contained"
          onClick={handleOpenExitModal}
          sx={{
            backgroundColor: "rgb(25, 78, 186)",
            color: "white",

            "&:hover": {
              backgroundColor: "rgb(22, 70, 167)",
            },
            width: "80px",
          }}
        >
          Exit
        </Button>
        <Button
          variant="contained"
          onClick={() => setShowCreatePrescriptionModal(true)}
          style={{
            marginLeft: "8px",
            backgroundColor: "rgb(25, 78, 186)",
            width: "80px",
          }}
        >
          Sign
        </Button>
      </div>
      <Modal
        open={showExitConfirmationModal}
        onClose={handleCloseExitModal}
        aria-labelledby="exit-modal-title"
        aria-describedby="exit-modal-description"
      >
        <Box sx={stylebar}>
          <p
            style={{
              Font: "Poppins",
              Weight: "600",
              fontSize: "20px",
              textAlign: "left",
            }}
          >
            Are you sure?
          </p>
          <p
            style={{
              font: "Poppins",
              fontSize: "17px",
              weight: "150",
              textAlign: "left",
              color: "#6E7191",
              marginTop: "20px",
              lineHeight: "25px",
            }}
          >
            Exiting will save your work in progress and allow you to come back
            to work on it later.
          </p>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              onClick={handleCloseExitModal}
              style={{
                marginRight: "8px",
                marginTop: "80px",
                backgroundColor: "rgb(25, 78, 186)",
                color: "white",
              }}
            >
              Cancel
            </button>
            <button
              onClick={handleConfirmExit}
              style={{
                marginRight: "8px",
                marginTop: "80px",
                backgroundColor: "rgb(25, 78, 186)",
                color: "white",
                width: "100px",
              }}
            >
              OK
            </button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={showCreatePrescriptionModal}
        onClose={() => setShowCreatePrescriptionModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={stylebar}>
          <p
            style={{
              Font: "Poppins",
              Weight: "600",
              fontSize: "20px",
              textAlign: "left",
            }}
          >
            Are you sure?
          </p>
          <p
            style={{
              font: "Poppins",
              fontSize: "17px",
              weight: "150",
              textAlign: "left",
              color: "#6E7191",
              marginTop: "20px",
              lineHeight: "25px",
            }}
          >
            Signed encounters cannot be modified and are immediately saved to
            the database with a timestamp for record keeping purposes.
          </p>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              onClick={() => setShowCreatePrescriptionModal(false)}
              style={{
                marginRight: "8px",
                marginTop: "80px",
                backgroundColor: "rgb(25, 78, 186)",
                color: "white",
              }}
            >
              Cancel
            </button>
            <button
              onClick={handleSign}
              style={{
                marginRight: "8px",
                marginTop: "80px",
                width: "100px",
                backgroundColor: "rgb(25, 78, 186)",
                color: "white",
              }}
            >
              Sign
            </button>
          </div>
        </Box>
      </Modal>
    </form>
  );
};

export default EncounterButton;
