import React from "react";
import { Box, Card, CardContent, Typography, Grid } from "@mui/material";
import { Modal, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
// SurgeryPlanning.jsx
export function ViewSurgeryPlans({ open, onClose, patientId }) {
  const [surgeryPlans, setSurgeryPlans] = useState([]);

  // Helper function to format date in 'YYYY-MM-DD' format without time zone conversion
  const formatDate = (dateString) => {
    if (!dateString) return "";

    // Splitting the date string into components
    const [year, month, day] = dateString
      .split("-")
      .map((num) => parseInt(num, 10));

    // Creating a new date object with the exact year, month, and day
    // Note: Month in JavaScript Date is 0-indexed, so subtract 1
    const date = new Date(year, month - 1, day);

    // Formatting the date in a more readable format
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  useEffect(() => {
    if (open) {
      fetchSurgeryPlans();
    }
  }, [open, patientId]);

  const handleDetailsModalClose = () => {
    setSelectedPlan(null);
  };

  const columns = [
    { field: "surgery_type", headerName: "Surgery Type", flex: 1 },
    {
      field: "surgery_date",
      headerName: "Date Of Surgery",
      flex: 1,
      valueFormatter: (params) => formatDate(params?.value),
    },
    {
      field: "patient_id",
      headerName: "Patient ID",
      flex: 0.5,
    },
    {
      field: "view",
      headerName: "View Details",
      flex: 0.5,
      renderCell: (params) => (
        <Button onClick={() => viewSurgeryPlanDetails(params.row)}>View</Button>
      ),
    },
  ];

  const [selectedPlan, setSelectedPlan] = useState(null);
  const viewSurgeryPlanDetails = async (plan) => {
    try {
      const patientResponse = await axios.post(
        "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/patientOverview",
        { patientId: plan.patient_id }
      );
      if (patientResponse.data && patientResponse.data.patient_data) {
        setSelectedPlan({
          ...plan,
          patientName: `${patientResponse.data.patient_data.FName} ${patientResponse.data.patient_data.LName}`,
          age: patientResponse.data.patient_data.Age,
          gender: patientResponse.data.patient_data.Gender,
          weight: patientResponse.data.patient_data.weight,
        });
      }
    } catch (error) {
      console.error("Error fetching patient data:", error);
      // Handle error
    }
  };

  const fetchSurgeryPlans = async () => {
    try {
      console.log("Fetching surgery plans for patient ID:", patientId);
      // https://e-react-node-backend-22ed6864d5f3.herokuapp.com
      const response = await axios.post(
        "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/getSurgeryPlanByPatientID",
        { patientId }
      );
      setSurgeryPlans(response.data);
    } catch (error) {
      console.error("Error fetching surgery plans:", error);
    }
  };

  useEffect(() => {
    fetchSurgeryPlans();
  }, []);

  const style1 = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    minHeight: "100%",
    boxShadow: 24,
    pt: 2,
    pb: 3,
    overflowY: "auto",
  };

  const SurgeryPlanModalContent = () => (
    <Box sx={style1}>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h4" sx={{ mt: 2, mx: 2 }}>
              Surgery Plan
            </Typography>
            <Card>
              <CardContent>
                <DataGrid
                  rows={surgeryPlans}
                  columns={columns}
                  pageSize={5}
                  sx={{ maxHeight: 650 }}
                />
              </CardContent>
            </Card>
            <center>
              <Button
                  variant="contained"
                  color="secondary"
                  onClick={onClose}
                  sx={{mt:2, mb:2}}
                  style={{width:'200px',
                    color:'white',
                    backgroundColor:'#1A4FBA',
                    border:'none',
                    fontFamily:'Poppins'}}
              >
                Close
              </Button>
            </center>
          </Grid>
        </Grid>
      </Card>
      <SurgeryPlanDetailsModal />
    </Box>
  );

  const SurgeryPlanDetailsModal = () => (
    <Modal open={selectedPlan !== null} onClose={handleDetailsModalClose}>
      <Box sx={style1}>
        <Card
          sx={{
            width: "50%",
            transform: "translate(50%, 50%)",
            textAlign: "center",
          }}
        >
          <CardContent>
            {/* Display details of selectedPlan here */}
            <Typography variant="h6">
              <strong>Surgery Type: </strong> {selectedPlan?.surgery_type}
            </Typography>
            <Typography variant="h6">
              <strong>Patient ID: </strong> {selectedPlan?.patient_id}
            </Typography>
            <Typography variant="h6">
              <strong>Patient Name: </strong>
              {selectedPlan?.patientName}
            </Typography>
            <Typography variant="h6">
              <strong>Age: </strong>
              {selectedPlan?.age}
            </Typography>
            <Typography variant="h6">
              <strong>Gender: </strong>
              {selectedPlan?.gender}
            </Typography>
            <Typography variant="h6">
              <strong>Weight: </strong>
              {selectedPlan?.weight}
            </Typography>
            <Typography variant="h6">
              <strong>Consultation Details: </strong>
              {selectedPlan?.pre_surgery_consultation_details}
            </Typography>
            <Typography variant="h6">
              <strong>Risk Assestment Details: </strong>
              {selectedPlan?.risk_assessment_details}
            </Typography>
            <Typography variant="h6">
              <strong>Post Operative Care Plan: </strong>
              {selectedPlan?.post_operative_care_plan}
            </Typography>
            <Typography variant="h6">
              <strong>Surgery Date: </strong>{" "}
              {(console.log(selectedPlan), null)}
              {selectedPlan?.surgery_date &&
              selectedPlan.surgery_date !== "0000-00-00"
                ? new Date(selectedPlan.surgery_date)
                    .toISOString()
                    .split("T")[0]
                : "Date not set"}
            </Typography>
            <Typography variant="h6">
              <strong>Plan Created on: </strong>
              {new Date(selectedPlan?.record_time).toLocaleString()}
            </Typography>
            <Button onClick={handleDetailsModalClose}>Exit</Button>
            {/* ...other details */}
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );

  return (
    <Modal open={open} onClose={onClose}>
      <SurgeryPlanModalContent />
    </Modal>
  );
}
