// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doctor-profile-page h1 {
  text-align: left;
  color: #1A4EBA;
}

.doctor-profile-page .doctor-profile-switch {
  width: 100%;
  height: 2.961rem;
}

.doctor-profile-page .doctor-profile-switch > label {
  width: 33%;
  height: 100%;
}

.doctor-profile-page .doctor-profile-switch > label.ant-radio-button-wrapper-checked {
  background: #1A4FBA;
}

.doctor-profile-page .doctor-profile-form-group .ant-form-item {
  margin-bottom: 0;
}

.doctor-profile-page .doctor-profile-form-group .doctor-profile-form-row {
  width: 100%;
  border-radius: 0;
}

.doctor-profile-page .doctor-profile-form-group > *:first-child .doctor-profile-form-row {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.doctor-profile-page .doctor-profile-form-group > *:last-child .doctor-profile-form-row {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.doctor-profile-page .doctor-profile-form-label {
  width: 100%;
  background: #1A4EBA;
  color: #FFFFFF;
  border-radius: 8px 8px 0 0;
  padding-left: 8px;
}

.doctor-profile-page .doctor-profile-form-input-date input::placeholder {
  color: black !important;
  opacity: 1; /* for Firefox */
}
`, "",{"version":3,"sources":["webpack://./src/screens/DoctorNew/DoctorProfilePage.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,4BAA4B;AAC9B;;AAEA;EACE,8BAA8B;EAC9B,+BAA+B;AACjC;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,cAAc;EACd,0BAA0B;EAC1B,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;EACvB,UAAU,EAAE,gBAAgB;AAC9B","sourcesContent":[".doctor-profile-page h1 {\n  text-align: left;\n  color: #1A4EBA;\n}\n\n.doctor-profile-page .doctor-profile-switch {\n  width: 100%;\n  height: 2.961rem;\n}\n\n.doctor-profile-page .doctor-profile-switch > label {\n  width: 33%;\n  height: 100%;\n}\n\n.doctor-profile-page .doctor-profile-switch > label.ant-radio-button-wrapper-checked {\n  background: #1A4FBA;\n}\n\n.doctor-profile-page .doctor-profile-form-group .ant-form-item {\n  margin-bottom: 0;\n}\n\n.doctor-profile-page .doctor-profile-form-group .doctor-profile-form-row {\n  width: 100%;\n  border-radius: 0;\n}\n\n.doctor-profile-page .doctor-profile-form-group > *:first-child .doctor-profile-form-row {\n  border-top-left-radius: 8px;\n  border-top-right-radius: 8px;\n}\n\n.doctor-profile-page .doctor-profile-form-group > *:last-child .doctor-profile-form-row {\n  border-bottom-left-radius: 8px;\n  border-bottom-right-radius: 8px;\n}\n\n.doctor-profile-page .doctor-profile-form-label {\n  width: 100%;\n  background: #1A4EBA;\n  color: #FFFFFF;\n  border-radius: 8px 8px 0 0;\n  padding-left: 8px;\n}\n\n.doctor-profile-page .doctor-profile-form-input-date input::placeholder {\n  color: black !important;\n  opacity: 1; /* for Firefox */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
