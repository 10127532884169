import Header from '../components/Header';
import '../styles/screens/LandingPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react'; // Import useState and useEffect
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%', // Adjust the width as needed
  height: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 5,
};

const LandingPage = () => {

  const [counts, setCounts] = useState([
    { label: 'Daily Visits', dataCount: 300 },
    { label: '# of developers', dataCount: 50 },
    { label: 'Doctors', dataCount: 150 },
    { label: 'Patients', dataCount: 300 },
    { label: 'Countries', dataCount: 3 },
  ]);

  useEffect(() => {
    const countUp = () => {
      const duration = 3000;

      const updatedCounts = counts.map((item) => {
        let countTo = item.dataCount;
        return { ...item, countTo };
      });

      setCounts(updatedCounts);

      updatedCounts.forEach((item, index) => {
        const counterElement = document.getElementById(`counter-${index}`);
        const countTo = item.dataCount;

        const countInterval = countTo / (duration / 1000);
        let currentCount = 0;

        const updateCounter = () => {
          if (currentCount <= countTo) {
            counterElement.innerText = `${Math.floor(currentCount)}+`;
            currentCount += countInterval;
            setTimeout(updateCounter, 1000);
          } else {
            counterElement.innerText = `${countTo}+`;
          }
        };

        setTimeout(updateCounter, 1000 * index);
      });
    };

    countUp();
  }, []);

  const [openAboutUsModal, setOpenAboutUsModal] = useState(false);
  const [openFeaturesModal, setOpenFeaturesModal] = useState(false);

  const handleOpenAboutUsModal = () => {
    setOpenAboutUsModal(true);
  };

  const handleCloseAboutUsModal = () => {
    setOpenAboutUsModal(false);
  };

  const handleOpenFeaturesModal = () => {
    setOpenFeaturesModal(true);
  };

  const handleCloseFeaturesModal = () => {
    setOpenFeaturesModal(false);
  };
  
  
  return (
    <div>
      <section  id="container">
        <div id="box1">
          <img src="images/specialities/Untitled_design-removebg-preview.png" className='mainpic' alt="Smart Digital Medicine" />
        </div>
        <div id="box2">
          <h1>Welcome to Smart Digital Medicine</h1>
          <h2>Your Health, Our Priority</h2><br></br>
        
         {/* Button Modal */}
         <div className='modals'>
          <div>
              <Button variant='outlined' onClick={handleOpenAboutUsModal}>About Us</Button>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openAboutUsModal}
                onClose={handleCloseAboutUsModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
              >
                <Fade in={openAboutUsModal}>
                  <Box sx={style}>
                    <Typography id="transition-modal-title" variant="h4" component="h2">
                    eHospital: Where your e-Health Journey Begins


                    </Typography>
                    <Typography id="transition-modal-description" sx={{ mt: 2, textAlign:'justify'}}>
                    Welcome to eHospital, where we are dedicated to transforming the healthcare landscape by harnessing the power of cutting-edge technology. Our eHealth system is designed to provide innovative, accessible, and personalized healthcare solutions that empower individuals and healthcare providers to take control of their well-being.
                    <h3>Our Mission</h3>
                    At eHospital, our mission is to improve the quality, accessibility, and affordability of healthcare through state-of-the-art eHealth solutions. We aim to:
                    <br></br>Empower Patients: We believe that healthcare should be patient-centric. Our eHealth system puts individuals in the driver's seat of their health journey, enabling them to make informed decisions, access their health records, and interact with healthcare professionals seamlessly.
                    Support Health Care Providers: We understand the challenges healthcare professionals face daily. Our eHealth platform is designed to streamline their work, enhance communication, and provide valuable insights that aid in delivering superior patient care.
                    Ensure Data Security: Your health data is precious. We prioritize the highest standards of data security and privacy to ensure that your sensitive information remains confidential and secure.
                    <h3>Why Choose Us?</h3>
                    User Centered Design: We take user feedback seriously, continually improving our platform to ensure it's intuitive and user-friendly.<br></br>
                    Security and Privacy: We implement rigorous security measures to protect your data, complying with the highest standards in the industry.<br></br>
                    Experience: With years of experience in the eHealth field, we have a deep understanding of the intricacies of healthcare, ensuring our solutions are tailored to meet the unique needs of both patients and providers.
                    <br></br><br></br>"Join us on our journey to redefine healthcare for the digital age. Together, we can shape a healthier and more connected future. At eHospital, your well-being is our priority, and we're committed to being your partner in health."
                    </Typography>
                  </Box>
                </Fade>
              </Modal>
            </div>
            
            <div style={{ marginLeft: '10px' }}> {/* Add margin-top for spacing */}
              <Button variant='outlined' onClick={handleOpenFeaturesModal}>Features</Button>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openFeaturesModal}
                onClose={handleCloseFeaturesModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
              >
                <Fade in={openFeaturesModal}>
                  <Box sx={style}>
                    <Typography id="transition-modal-title" variant="h4" component="h2">
                    Five Major Components of System Functionalities


                    </Typography>
                    <Typography id="transition-modal-description" sx={{ mt: 2, textAlign:'justify'}}>
                    <b>1.Communication Management:</b> Messages from Doctor to Patient, Doctor to Clinic Staff, Clinic Staff to Patient, Clinic Staff to Doctor, Platform Admin to the Users (Doctor & Clinic Staff), Users (Doctor & Clinic Staff) to the Platform Admin. Video Call from Doctor to Patient, Live Text Message between Doctor & Patient, etc.
                    <br></br><b>2. User Management:</b> Registrations, Authentication, Add, Delete, Modify, Patient Check In, Patient Bills, Payments, Scheduling doctor Agenda (Appointments with Patients, Doctor Tasks, Clinic Activities),  Patients appointment with doctor, etc.
                    <br></br><b>3. EHR (Electronic Health Record) and Database Management:</b> Collect, Process and Store Patient’s Medical Data and Health Information, Datawarehouse and Data Marts, etc.
                    <br></br><b>4. Intelligent Health Information Management:</b> Different User Dashboards, all possible Visualizations, clinical works ( Prescription, Treatment Plan, Referral, Request a Medical Tests, Log Patient Visit, ... etc.) , Clinical Decision Support Systems, etc.
                    <br></br><b>5. Disease Management, Diagnostic Process and Health Wellbeing:</b> Diagnostic Tools, Catboats, Medication Management, Data Mining, other AI Applications, etc. 

                    </Typography>
                  </Box>
                </Fade>
              </Modal>
            </div>

        </div>
        </div>
     
      </section>

      <div className='sectionscards'>
         
      <a href="/specialities" className="homepage-section1-container">
              <section className="homepage-section1">
                <h1>Specialities</h1>
                <p>
                we harness the power of cutting-edge technology to provide advanced healthcare services.Our key offering includes the integration of Machine Learning (ML) models for diagnostics. Through AI-driven analysis, we ensure precise and swift medical assessments, aiding in the early detection of ailments. 
                </p>
                <br />
              </section>
            </a>
          <a href="/services" className="homepage-section1-container">
              <section className="homepage-section1">
                <h1>Services</h1>
                <p>
                At AI Hospital, we revolutionize healthcare with cutting-edge technology. Using AI-powered platforms, we provide efficient electronic health record management, ensuring quick access to critical patient information. Our commitment to innovation extends to telemedicine, making healthcare accessible from anywhere.
                </p>
                <br />
              </section>
            </a>  
          </div>

        
          <div className="company-count">
            {counts.map((item, index) => (
            <div className="count" key={index}>
              <h2 id={`counter-${index}`} className="counter">
                {item.dataCount}
              </h2>
              <p className='pp'>{item.label}</p>
            </div>
      ))}
    </div>
    
  


    </div>
  );
};

export default LandingPage;
