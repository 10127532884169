import React, { Suspense, lazy } from "react";
import { useNavigate,useParams } from 'react-router-dom';
import { Card } from "antd";
import { Typography,Box,IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { loadSavedPath } from '../LocalNavigate';
// 动态导入不同的用户主页布局
const websitestaffProfile = lazy(() => import('./SpecificWebsitestaffProfile'));
const doctorProfile = lazy(() => import('./SpecificDoctorProfile'));
const patientProfile = lazy(() => import('./SpecificPatientProfile'));
const loadProfile =(viewCategory) =>{

  switch (viewCategory) {
    case 0:
      return websitestaffProfile;
    case 1:
      return doctorProfile;
    case 2:
      return patientProfile;
    default:
      alert('User type error!')
      return null;
  }
};

const PharmaceuticalsProfileView = () => {

  const { viewCategory, viewId } = useParams();
  const navigate = useNavigate();

  // 选择不同的布局组件
  let ProfileComponent = loadProfile(Number(viewCategory));
  
  return (<>

    {/* 整体布局在一个卡片中 */}
  <Card bordered={false} style={{width: "100%",backgroundColor: "#FFFFFF",}}>
  
    {/* 导航栏功能 */}  
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
    <IconButton  aria-label="back" onClick={() => loadSavedPath(navigate)}><ArrowBackIcon sx={{ fontSize: 15 }}/></IconButton>
    <Typography style={{ color: 'grey'}}>Click back</Typography>
    </Box>

    {/* 动态渲染对应的布局组件 */}
    <Suspense fallback={<div>Loading...</div>}>
      {ProfileComponent ? <ProfileComponent viewId={viewId}/> : <Typography>No profile available</Typography>}
    </Suspense>
  </Card>
</>);

};

export default PharmaceuticalsProfileView;