import moment from 'moment';
import dayjs from 'dayjs';
import React, { useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, List, Space, Spin, DatePicker, Radio, Flex } from 'antd';
import { doctorGetCalendar } from '../../api/calendar';
import { readLoginData } from '../../loginData';
import PageLayout from '../../components/PageLayout/PageLayout';
import TimeSegmentDialog from '../DoctorNew/TimeSegmentDialog';

import 'react-big-calendar/lib/css/react-big-calendar.css';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const { RangePicker } = DatePicker;

const getTimeSegmentState = (event) => {
  const { category, status, appointmentStatus } = event;
  if(status < 0){
    return 'Approved';
  }else if(status > 0){
    return 'PendingApproval';
  }else{
    return 'NotBooked';
  }
};

const getTimeSegmentColor = (event) => {
  const { category, status, appointmentStatus } = event;
  if(appointmentStatus === undefined || appointmentStatus === 1 || (appointmentStatus === null && status === 0)){
    if(category === 1){ return '#EB00FF' }
    else if(category === 3){ return '#D9D9D9' }
    else if(category === 4){ return '#FFA900' }
    else if(category === 5){ return '#3377DD' }
    else{ return '#40DC82' }
  }else if(appointmentStatus === 0){
    return '#ADEDCD';
  }else if(appointmentStatus === null){
    return '#DD5233';
  }
};

const TimeSegmentsView = (props) => {
  const navigate = useNavigate();

  const [ status, setStatus ] = useState('All');
  const [ detailId, setDetailId ] = useState(null);

  console.log("Data", props.data);

  const eventStatusFilter = ((status) => {
    switch(status){
      default:
      case 'All':
        return (e) => true;
      case 'Approved':
        return (e) => e.status < 0;
      case 'PendingApproval':
        return (e) => e.status > 0;
      case 'NotBooked':
        return (e) => e.status === 0;
    }
  })(status);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleRangeChange = (dates) => {
    if(dates){
      props.onRangeChange(dates[0].toDate(), dates[1].toDate());
    }else{
      props.onRangeChange(moment().startOf('week'), moment().endOf('week'));
    }
  }

  const eventsList = props.data.filter(e => e.category !== 1 && eventStatusFilter(e))
  .sort((a, b) => moment(a.start).isAfter(moment(b.start)))
  .map(e => ({
    ...e,
    start: moment(e.start).toDate(),
    end: moment(e.end).toDate(),
    color: getTimeSegmentColor(e),
  }));

  return (
    <>
      <Space
        direction="vertical"
        style={{ marginBottom: '20px' }}
        size="middle">
          <Space
            direction="horizontal"
            style={{ justifyContent: 'flex-end', marginRight: '20px' }}>
              <RangePicker
                value={[dayjs(props.range[0]), dayjs(props.range[1])]}
                onChange={handleRangeChange} />
              <Radio.Group value={status} onChange={handleStatusChange}>
                <Radio.Button value="All">All</Radio.Button>
                <Radio.Button value="Approved">Approved</Radio.Button>
                <Radio.Button value="PendingApproval">PendingApproval</Radio.Button>
                <Radio.Button value="NotBooked">NotBooked</Radio.Button>
              </Radio.Group>
              <Button type="primary" onClick={() => navigate('/doctor/calendar')}>Edit Appointments</Button>
          </Space>
      </Space>
      <List
        itemLayout="horizontal"
        pagination={{ position: 'bottom', align: 'center', pageSize: 10 }}
        dataSource={eventsList}
        renderItem={(item, index) => (
          <List.Item style={{ padding: '20px', fontSize: '20px' }}>
            <List.Item.Meta
              title={<Button type="link" style={{ color: item.color, fontWeight: "bold", padding: "0" }} onClick={() => setDetailId(item.id)}>
                { moment(item.start).format(dateFormat)} {moment.duration(moment(item.end).diff(item.start)).humanize() }
                { item.patient.id ? ` Patient: ${item.patient.name}` : null }</Button>}
              description={<>{getTimeSegmentState(item.status)}<br/>Description: {item.description}</>}/>
          </List.Item>
        )}
      />
      {detailId ? (
          <TimeSegmentDialog
            id={detailId}
            onClose={() => setDetailId(null)}
            onApprove={props.onNeedLoad}
          />
      ) : null}
    </>
  );
}

const DoctorAppointmentList = (props) => {
  const navigate = useNavigate();

  const loginData = readLoginData();
  let [ needLoad, setNeedLoad ] = useState(true);
  let [ loading, setLoading] = useState(true);
  let [ data, setData ] = useState([]);
  let [ currentStart, setCurrentStart ] = useState(moment().startOf('week'));
  let [ currentEnd, setCurrentEnd ] = useState(moment().endOf('week'));

  const setCurrentRange = (start, end) => {
    setCurrentStart(start);
    setCurrentEnd(end);
    setNeedLoad(true);
  }

  const fetchData = async () => {
    setLoading(true);
    setData([]);
    const response = await doctorGetCalendar(loginData, currentStart.toDate(), currentEnd.toDate());
    setData(response);
    setLoading(false);
  };

  const handleRangeChange = useCallback((start, end) => {
    setCurrentRange(moment(start), moment(end));
  }, [])

  if(needLoad){
    setNeedLoad(false);
    fetchData();
  }

  const handleSelect = (event) => {
    console.log(event);
    if(event.type === 1){
      navigate(`/Tasks/${event.id}`)
    }else if(event.type === 2){
      navigate(`/calendar/timesegment/${event.id}`)
    }
  };

  return <>
      <Spin spinning={loading}>
        <TimeSegmentsView
          range={[currentStart, currentEnd]}
          onRangeChange={handleRangeChange}
          data={data}
          onSelect={handleSelect}
          onNeedLoad={() => setNeedLoad(true)}/>
      </Spin>
    </>;
};

export const DoctorAppointmentListPage = (props) => {
  return <>
    <PageLayout clearUser={props.clearUser} className="doctor-appointment-list-page">
      <Flex
        justify="space-evenly"
        style={{ height: "100%", marginTop: "2rem" }}
      >
        <Flex vertical gap="1rem" style={{ display: "flex", width: "100%" }}>
          <DoctorAppointmentList {...props} />
        </Flex>
      </Flex>
    </PageLayout>
  </>;
}

export default DoctorAppointmentListPage;
