import React from "react";
import { List, ListItem, Typography, Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
function AllNotesBox(props) {
  const listItemStyle = {
    borderRadius: "10px",
    padding: "15px 20px",
    backgroundColor: "#D3E5FC",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  };
  const listStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography
          variant="h6"
          style={{ color: "#59748A", marginLeft: "1.25rem" }}
        >
          {props.title}
        </Typography>
        <Button
          onClick={props.onClick}
          startIcon={<AddCircleIcon />}
          sx={{
            height: "100%",
            color: "#1A4EBA",
            fontWeight: "bold",
            textTransform: "none",
            paddingTop: "0",
            marginLeft: "1rem",
          }}
        >
          {props.buttonText}
        </Button>
        <List style={listStyle}>
          {props.listData.map((diagnosis, index) => (
            <ListItem key={index} style={listItemStyle}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    variant="body1"
                    sx={{ color: "#5F7488", fontWeight: "600" }}
                  >
                    {diagnosis.type}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ textAlign: "right" }}
                    color="textSecondary"
                    sx={{ color: " #9E9E9E" }}
                  >
                    {diagnosis.date}
                  </Typography>
                </div>
                <Button
                  sx={{
                    backgroundColor: "#6CA0EE",
                    color: "#FFF",
                      width:"10px",
                    textTransform: "none",
                    padding: "1.5rem",
                    height: "2rem",
                    lineHeight: "1.5",
                    borderRadius: "0.5rem",
                    "&:hover": {
                      backgroundColor: "#507CCE",
                    },
                  }}
                >
                  View Notes
                </Button>
              </div>
            </ListItem>
          ))}
        </List>
      </div>
    </>
  );
}

export default AllNotesBox;
