import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

const TCHChart = () => {
  const [gaugeData, setGaugeData] = useState([
    {
      value: 30,
      detail: {
        valueAnimation: true,
        offsetCenter: ["0%", "0%"],
      },
    },
  ]);

  useEffect(() => {
    setGaugeData([
      {
        ...gaugeData[0],
        value: 30,
      },
    ]);
  }, []);

  const getOption = () => {
    return {
      series: [
        {
          type: "gauge",
          startAngle: 90,
          endAngle: -270,
          min: 0,
          max: 100,
          pointer: {
            show: false,
          },
          progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: false,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#464646",
            },
          },
          axisLine: {
            lineStyle: {
              width: 20,
            },
          },
          splitLine: {
            show: false,
            distance: 0,
            length: 10,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
            distance: 50,
          },
          data: gaugeData,
          title: {
            fontSize: 16,
          },
          detail: {
            width: 50,
            height: 25,
            fontSize: 20,
            color: "inherit",
            // borderColor: "inherit",
            // borderRadius: 20,
            // borderWidth: 1,
            formatter: "{value}%",
          },
        },
      ],
    };
  };

  return <ReactECharts option={getOption()} style={{ height: "20vh" }} />;
};

export default TCHChart;
