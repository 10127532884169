import axios from "axios";

import { BASE_URL } from "../constants";

//----This is the patientPortal get calendar function-----------------
export const patientMainPageGetCalendar = async (
  loginData,
  start,
  end,
  timezone
) => {
  try {
    console.log(
      "patientMainPageGetCalendarPOST",
      loginData,
      start,
      end,
      timezone
    );
    const response = await axios.post(
      `${BASE_URL}/api/appointments/patientMainPageGetCalendar`,
      // "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/appointments/patientMainPageGetCalendar",
      {
        loginData,
        start: start.toISOString(),
        end: end.toISOString(),
        timezone,
      }
    );

    if (response?.data?.status !== "OK") {
      throw new Error(response?.data?.status);
    }
    console.log("patientMainPageGetCalendar", response?.data);
    return response?.data?.result;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};
//------END OF PATIENT PORTAL GET CALENDAR FUNCTION-------------------

export const doctorGetCalendar = async (loginData, start, end) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/appointments/doctorGetCalendar`,
      {
        loginData,
        start: start.toISOString(),
        end: end.toISOString(),
      }
    );
    console.log("doctorGetCalendar", response.data);
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const patientGetCalendar = async (loginData, start, end) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/appointments/patientGetCalendar`,
      {
        loginData,
        start: start.toISOString(),
        end: end.toISOString(),
      }
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getTimeSegmentDetail = async (loginData, id) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/appointments/getTimeSegmentDetail`,
      {
        loginData,
        id,
      }
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const doctorCreateAvailableTimeSegment = async (
  loginData,
  category,
  start,
  end,
  description
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/appointments/doctorCreateAvailableTimeSegment`,
      {
        loginData,
        category,
        start: start.toISOString(),
        end: end.toISOString(),
        description,
      }
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const doctorApproveRequest = async (loginData, id) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/appointments/doctorApproveRequest`,
      {
        loginData,
        id,
      }
    );
    if (response.data.status !== "OK") {
      throw new Error(response.data.status);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const patientSearchForTimeSegments = async (loginData, start, end) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/appointments/patientSearchForTimeSegments`,
      {
        loginData,
        start: start.toISOString(),
        end: end.toISOString(),
      }
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const patientBookTime = async (loginData, id, category, description) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/appointments/patientBookTime`,
      {
        loginData,
        id,
        category,
        description,
      }
    );
    if (response.data.status !== "OK") {
      throw new Error(response.data.status);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getDoctors = async (loginData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/appointments/getDoctors`,
      {
        loginData,
      }
    );
    if (response.data.status !== "OK") {
      throw new Error(response.data.status);
    }
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getPatients = async (loginData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/appointments/getPatients`,
      {
        loginData,
      }
    );
    if (response.data.status !== "OK") {
      throw new Error(response.data.status);
    }
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const cancelAppointmentRequest = async (loginData, id) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/appointments/cancelAppointmentRequest`,
      {
        loginData,
        id,
      }
    );
    if (response.data.status !== "OK") {
      throw new Error(response.data.status);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const doctorGetRecentPatients = async (loginData, start, end) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/appointments/doctorGetRecentPatients`,
      {
        loginData,
        start,
        end,
      }
    );

    if (response.data.status !== "OK") {
      throw new Error(response.data.status);
    }
    console.log(response.data);
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const clinicalStaffGetRecentPatients = async (
  loginData,
  doctorId,
  start,
  end
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/appointments/clinicalStaffGetRecentPatients`,
      {
        loginData,
        doctorId,
        start,
        end,
      }
    );

    if (response.data.status !== "OK") {
      throw new Error(response.data.status);
    }
    console.log(response.data);
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const doctorGetMiniCalendar = async (
  loginData,
  start,
  end,
  timezone
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/appointments/doctorGetMiniCalendar`,
      {
        loginData,
        start: start.toISOString(),
        end: end.toISOString(),
        timezone,
      }
    );

    if (response.data.status !== "OK") {
      throw new Error(response.data.status);
    }
    console.log(response.data);
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const doctorGetTasks = async (loginData, start, end) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/appointments/doctorGetTasks`,
      {
        loginData,
        start: start.toISOString(),
        end: end.toISOString(),
      }
    );
    console.log("doctorGetCalendar", response.data);
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const clinicalStaffCreateAvailableTimeSegment = async (
  loginData,
  doctorId,
  category,
  start,
  end,
  description
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/appointments/clinicalStaffCreateAvailableTimeSegment`,
      {
        loginData,
        doctorId,
        category,
        start: start.toISOString(),
        end: end.toISOString(),
        description,
      }
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
