// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-result-table td {
  padding: 10px 20px 10px 20px;
  background: #FFF;
  color: #37384D;
}

.search-result-table tr td:first-child {
  font-weight: 700;
  background: #E6F1F8;
  color: #494A63;
}


.service button {

  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

section {
  display: flex;
  flex-direction: column;
}

section .service {
  position: relative;
}

table {
  border-collapse: collapse;
}

th {
  background: #ccc;
}

th,
td {
  border: 1px solid #ccc;
  padding: 8px;
}

tr:nth-child(even) {
  background: #efefef;
}

tr:hover {
  background: #d1d1d1;
}

.center {

  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}

h2 {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/screens/searchresult.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;AAChB;;;AAGA;;EAEE,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;EAEE,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".search-result-table td {\n  padding: 10px 20px 10px 20px;\n  background: #FFF;\n  color: #37384D;\n}\n\n.search-result-table tr td:first-child {\n  font-weight: 700;\n  background: #E6F1F8;\n  color: #494A63;\n}\n\n\n.service button {\n\n  position: absolute;\n  bottom: 20px;\n  left: 50%;\n  transform: translateX(-50%);\n}\n\nsection {\n  display: flex;\n  flex-direction: column;\n}\n\nsection .service {\n  position: relative;\n}\n\ntable {\n  border-collapse: collapse;\n}\n\nth {\n  background: #ccc;\n}\n\nth,\ntd {\n  border: 1px solid #ccc;\n  padding: 8px;\n}\n\ntr:nth-child(even) {\n  background: #efefef;\n}\n\ntr:hover {\n  background: #d1d1d1;\n}\n\n.center {\n\n  margin-top: 20px;\n  margin-bottom: 10px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\nh2 {\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
