import React from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Card } from "antd";
import SpecificTrialInfo from "../View/SpecificTrialInfo";
import { Typography,Box,IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';

//返回临床试验列表
const BackTrialsList = (navigate) =>{
  navigate(`/pharmaceuticals/clinicaltrial`);
};

//编辑试验
const EditTrial = (navigate) => {
  alert('Edit button clicked');
};

const PharmaceuticalsSpecificClinicalTrail = () => {
  const { trial_id } = useParams();
  const navigate = useNavigate();

  return (<>
          {/* 整体布局在一个卡片中 */}
  <Card bordered={false} style={{width: "100%",backgroundColor: "#FFFFFF",}}>
        {/* 导航栏功能 */}  
  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
  <IconButton  aria-label="back" onClick={() => BackTrialsList(navigate)}><ArrowBackIcon sx={{ fontSize: 15 }}/></IconButton>
  <Typography style={{ color: 'grey'}}>Click back to the list</Typography>
  <IconButton aria-label="edit" onClick={() => EditTrial(navigate)} style={{ marginLeft: 'auto' }}><EditIcon sx={{ fontSize: 15 }} /></IconButton>
  </Box>
        {/* 试验信息展示部分 */}
  <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 1, paddingLeft: '15px' }}>
  <SpecificTrialInfo trial_id={trial_id}/>
  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
  </Box>
  </Box>
  </Card>
</>);
};

export default PharmaceuticalsSpecificClinicalTrail;