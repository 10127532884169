import React from 'react';
import { Paper, 
        Container, 
        Grid, 
        TableCell,
        TableRow, 
        TableBody,
        Table,
        TableHead} from '@mui/material';

export function PatientRecordViewImages({ recordData }) {
    return (
        <Container sx={{ minHeight: '80vh' }}>
        <Grid container spacing={4} >
          <Grid item xs={12}>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Imaging</TableCell>
                    <TableCell>Record Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recordData.map((record, index) => (
                    <TableRow key={record._id}>
                      <TableCell>
                        <img src={`data:image/jpeg;base64,${record.file.buffer}`} alt="ctscan" width="300" height="300" />
                      </TableCell>
                      <TableCell>{record.RecordDate}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
        </Container>
    );
}
