// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-backgroundsolutions {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  
  .fullscreen-videosolutions {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/components/LandingPageStyles/LandingPageSolutionsVideo.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;EACnB","sourcesContent":[".video-backgroundsolutions {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: -1;\n  }\n  \n  .fullscreen-videosolutions {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
