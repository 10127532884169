import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, responsiveFontSizes } from '@mui/material';
import { readLoginData } from '../../loginData';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getPatientPrescriptionsById } from '../../utilities/apis';
import {Button} from "antd";
import {Link} from "react-router-dom";
const rows = [
    { id: 1, name: 'Product A', price: '$20.00' },
    { id: 2, name: 'Product B', price: '$40.00' },
    { id: 3, name: 'Product C', price: '$60.00' },
];
function formatDate(isoDateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return new Date(isoDateString).toLocaleDateString('en-US', options);
}

function MyComponent({ isoDateString }) {
    return <div>{formatDate(isoDateString)}</div>;
}
const loginData = readLoginData();
export function PatientPrescriptionRefill() {
    const [prescriptions, setPrescriptions] = useState([]);

    useEffect(() => {
        // 创建一个函数来加载数据
        const fetchData = async () => {
            try {
                const response = await getPatientPrescriptionsById(loginData.id) // 使用你的API endpoint
                console.log(response);
                setPrescriptions(response); // 假设后台返回的数据是直接可用的

            } catch (error) {
                console.error("Error fetching data: ", error);
                // 处理错误情况
            }
        };

        fetchData(); // 调用函数来加载数据
    }, []);


    return (


        <TableContainer component={Paper}>
            <Link to="/patient/planning">
                <Button
                    style={{width:'50px',
                        margin:'20px',
                        color:'white',
                        backgroundColor:'#1A4FBA',
                        border:'none'}}>
                    Back
                </Button>
            </Link>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Prescription ID</TableCell>
                        <TableCell align="right">Doctor First Name</TableCell>
                        <TableCell align="right">Doctor Last Name</TableCell>
                        <TableCell align="right">Doctor's Phone</TableCell>
                        <TableCell align="right">Doctor Office Address</TableCell>
                        <TableCell align="right">Prescription</TableCell>
                        <TableCell align="right">Prescription Creat Time</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {prescriptions.map((prescription) => (
                        <TableRow
                            key={prescription.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {prescription.id}
                            </TableCell>
                            <TableCell align="right">{prescription.doctor_FName}</TableCell>
                            <TableCell align="right">{prescription.doctor_LName}</TableCell>

                            <TableCell align="right">{prescription.doctor_phone}</TableCell>
                            <TableCell align="right">{prescription.doctor_office_address}</TableCell>
                            <TableCell align="right">{prescription.prescription_description}</TableCell>
                            <TableCell align="right"><MyComponent isoDateString={prescription.prescription_creation_time} /></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}