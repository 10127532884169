// 保存当前路径到 localStorage
export const saveCurrentPath = () => {
  const currentPath = window.location.href;
  localStorage.setItem('savedPath', currentPath);
};

// 加载保存的路径并导航
export const loadSavedPath = (navigate) => {
  try {
    const savedPath = localStorage.getItem('savedPath');
    if (!savedPath) {
      throw new Error('No saved path found');
    }
    const url = new URL(savedPath);
    navigate(url.pathname + url.search + url.hash, { replace: true });
  } catch (error) {
    console.error('Error loading saved path:', error);
  }
};
