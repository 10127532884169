import React, { useState } from "react";
import {
  Button,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Spin,
  DatePicker,
} from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import { readLoginData } from "../../loginData";
import {
  getDoctorProfile,
  setDoctorProfile,
  setDoctorPassword,
} from "../../api/user";
import PageLayout from "../../components/PageLayout/PageLayout";

import "./DoctorProfilePage.css";

const DobFormat = (value) => `DoB: ${value.format("YYYY-MM-DD")}`;

const ProfileCategorySwitch = (props) => {
  return (
    <>
      <Radio.Group
        value={props.value}
        buttonStyle="solid"
        className="doctor-profile-switch"
        onChange={(e) => props.onChange(e.target.value)}
      >
        <Radio.Button value="profile">Profile</Radio.Button>
        <Radio.Button value="signature">Signature</Radio.Button>
        <Radio.Button value="security">Security</Radio.Button>
      </Radio.Group>
    </>
  );
};

const DoctorProfileForm = (props) => {
  const loginData = readLoginData();
  const [needLoad, setNeedLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();

  if (needLoad) {
    (async () => {
      setLoading(true);
      setNeedLoad(false);
      const data = await getDoctorProfile(loginData.id);
      for (const key in data) {
        console.log(key);
        if (key === "birthday" && data[key]) {
          const date = dayjs(data[key]).utc().format("YYYY-MM-DD");
          form.setFieldValue(key, dayjs(date));
        } else {
          form.setFieldValue(key, data[key]);
        }
      }

      setLoading(false);
    })();
  }

  const handleFinish = (values) => {
    (async () => {
      console.log("DoctorProfileForm: ", values);
      await setDoctorProfile(loginData.id, values);
      setNeedLoad(true);
    })();
  };

  return (
    <>
      <Spin spinning={loading}>
        <Form
          {...props}
          form={form}
          name="doctor-profile"
          onFinish={handleFinish}
        >
          <h1>Doctor Personal Information</h1>
          <ProfileCategorySwitch value="profile" onChange={props.onCategory} />
          <div
            style={{ marginTop: "1.226rem" }}
            className="doctor-profile-form-group"
          >
            <Form.Item name="firstName" rules={[{ required: true }]}>
              <Input
                size="large"
                prefix={<>First Name:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>
            <Form.Item name="lastName" rules={[{ required: true }]}>
              <Input
                size="large"
                prefix={<>Last Name:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true }, { type: "email" }]}
            >
              <Input
                size="large"
                prefix={<>Email Address:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>




            <Form.Item
              name="birthday"
              rules={[
                { required: true },
                { type: "date" },
              ]}
            >



              <DatePicker
                prefix={<>birthday:</>}
                size="large"
                format="YYYY-MM-DD"
                placeholder="Select Date"
                className="doctor-profile-form-row"
              />
              {/*<DatePicker size="large" format={DobFormat} placeholder='DoB: ' className='doctor-profile-form-input-date'/>*/}
            </Form.Item>
            <Form.Item name="mobile" rules={[{ required: true }]}>
              <Input
                size="large"
                prefix={<>Mobile:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>
            <Form.Item name="address1" rules={[{ required: true }]}>
              <Input
                size="large"
                prefix={<>Address Line 1:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>
            <Form.Item name="address2">
              <Input
                size="large"
                prefix={<>Address Line 2:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>
            <div
              style={{ display: "flex", flexDirection: "row" }}
              className="doctor-profile-form-row"
            >
              <Form.Item
                name="city"
                rules={[{ required: true }]}
                style={{ flex: "1 1 47.57%" }}
              >
                <Input
                  size="large"
                  prefix={<>City:</>}
                  style={{ borderRadius: "0" }}
                />
              </Form.Item>
              <Form.Item
                name="zip"
                rules={[{ required: true }]}
                style={{ flex: "1 1 52.43%" }}
              >
                <Input
                  size="large"
                  prefix={<>Zip:</>}
                  style={{ borderRadius: "0" }}
                />
              </Form.Item>
            </div>
            <Form.Item name="specialization" rules={[{ required: true }]}>
              <Input
                size="large"
                prefix={<>Specialization:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>
          </div>
          <div
            style={{ marginTop: "4.188rem" }}
            className="doctor-profile-form-group"
          >
            <div className="doctor-profile-form-label">
              Medical Identification:
            </div>
            <Form.Item name="medicalLicenseNumber" rules={[{ required: true }]}>
              <Input
                size="large"
                prefix={<>Medical License Number:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>
            <Form.Item name="billingNumber" rules={[]}>
              <Input
                size="large"
                prefix={<>Billing Number:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>
          </div>
          <Flex justify="center" style={{ marginTop: "4.188rem" }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Settings
              </Button>
            </Form.Item>
          </Flex>
        </Form>
      </Spin>
    </>
  );
};

const DoctorSignatureForm = (props) => {
  const loginData = readLoginData();
  const [needLoad, setNeedLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(true);
  const [form] = Form.useForm();

  if (needLoad) {
    (async () => {
      setLoading(true);
      setNeedLoad(false);
      const data = await getDoctorProfile(loginData.id);
      setData(data);
      setLoading(false);
    })();
  }

  const handleFinish = (values) => {
    console.log("DoctorProfileForm: ", values);
    setDoctorProfile(loginData.id, values);
    form.resetFields();
  };

  return (
    <>
      <Spin spinning={false}>
        <Form
          {...props}
          form={form}
          name="doctor-profile"
          onFinish={handleFinish}
        >
          <h1>Doctor Personal Information</h1>
          <ProfileCategorySwitch
            value="signature"
            onChange={props.onCategory}
          />
          <div
            style={{ marginTop: "1.226rem" }}
            className="doctor-profile-form-group"
          >
            <div className="doctor-profile-form-label">
              Doctor: {[ data.firstName, data.lastName ].join(' ')}, { data.specialization }
            </div>
            <div style={{ background: "white", border: "1px solid #d9d9d9", padding: "7px 11px", height: "35vh" }} className="doctor-profile-form-row">
              <div style={{ height: "45%" }}></div>
              <Divider />
              <p style={{ textAlign: "center" }}>{[ data.firstName, data.lastName ].join(' ')}</p>
              <Flex justify="center" style={{ marginTop: "2rem" }}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save Settings
                </Button>
              </Form.Item>
            </Flex>
            </div>
          </div>
        </Form>
      </Spin>
    </>
  );
};

const DoctorSecurityForm = (props) => {
  const loginData = readLoginData();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    console.log("handleFinish");
    form.resetFields();
    (async () => {
      console.log("DoctorSecurityForm: ", values);
      const result = await setDoctorPassword(
        loginData.id,
        values.oldPassword,
        values.newPassword
      );
      if (result === "OK") {
        Modal.success({ content: "Password reset successfully." });
        props.clearUser();
        navigate("/LogIn");
      } else {
        Modal.error({ content: `Error: ${result}` });
      }
    })();
  };

  return (
    <>
      <Spin spinning={false}>
        <Form
          {...props}
          form={form}
          name="doctor-profile"
          onFinish={handleFinish}
        >
          <h1>Doctor Personal Information</h1>
          <ProfileCategorySwitch value="security" onChange={props.onCategory} />
          <div
            style={{ marginTop: "1.226rem" }}
            className="doctor-profile-form-group"
          >
            <div className="doctor-profile-form-label">
              Change Password
            </div>
            <Form.Item name="oldPassword" rules={[{ required: true }]}>
              <Input.Password
                size="large"
                prefix={<>Current Password:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>
            <Form.Item name="newPassword" rules={[{ required: true }]}>
              <Input.Password
                size="large"
                prefix={<>New Password:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>
            <Form.Item
              name="newPasswordRepeat"
              dependencies={["newPassword"]}
              rules={[
                {
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                size="large"
                prefix={<>Confirm New Password:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>
          </div>
          <div
            style={{ marginTop: "1.226rem" }}
            className="doctor-profile-form-group"
          >
            <div className="doctor-profile-form-label">
              Timeout Duration Time
            </div>
            <div style={{ background: "white", border: "1px solid #d9d9d9", padding: "7px 11px" }} className="doctor-profile-form-row">
              If your account remains inactive for an extended period, it will log out automatically. For enhanced security, we suggest selecting a shorter timeout period."
            </div>
            <div style={{ background: "white", border: "1px solid #d9d9d9", padding: "7px 11px" }} className="doctor-profile-form-row">
              <Form.Item name="timeout" rules={[{ required: true }]}>
                I want my account to timeout after: &nbsp;
                <Select
                  defaultValue={60}
                  style={{ width: 120 }}
                  options={[
                    {
                      value: 5,
                      label: '5 minutes',
                    },
                    {
                      value: 10,
                      label: '10 minutes',
                    },
                    {
                      value: 15,
                      label: '15 minutes',
                    },
                    {
                      value: 30,
                      label: '30 minutes',
                    },
                    {
                      value: 60,
                      label: '1 hour',
                    },
                  ]}
                />
              </Form.Item>
            </div>
          </div>
          <Flex justify="center" style={{ marginTop: "4.188rem" }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Settings
              </Button>
            </Form.Item>
          </Flex>
        </Form>
      </Spin>
    </>
  );
};

const formMap = {
  profile: DoctorProfileForm,
  signature: DoctorSignatureForm,
  security: DoctorSecurityForm,
};

const DoctorProfilePage = (props) => {
  const [category, setCategory] = useState("profile");

  const ProfileForm = formMap[category];
  
  return (
    <>
      <div className="doctor-profile-page">
        <div
          style={{
            height: "100%",
            marginLeft: "23%",
            marginRight: "24.76%",
          }}
        >
          <Flex vertical gap="1rem" style={{ display: "flex", width: "100%" }}>
            <ProfileForm
              onCategory={(c) => setCategory(c)}
              clearUser={props.clearUser}
            />
          </Flex>
        </div>
      </div>
    </>
  );
};

export default DoctorProfilePage;
