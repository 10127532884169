import React from 'react';
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Card, IconButton,Typography, Stack, Grid  } from '@mui/material';
import { ArrowUpward, ArrowDownward, KeyboardArrowRight, KeyboardArrowLeft,KeyboardDoubleArrowRight  } from '@mui/icons-material';
import ReactApexChart from 'react-apexcharts';
import "./PharmaceuticalsDashboard.css";
import PropTypes from 'prop-types';

export const WelcomeRootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  backgroundColor: '#1a4fba',
  color: '#FFFFFF',
  borderRadius: '19px',
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export const ActionsRootStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  listStyle: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  '& li': {
    width: 18,
    height: 18,
    opacity: 0.32,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& li.slick-active': {
    opacity: 1,
    '& .dotActive': {
      width: 12,
      height: 12,
      borderRadius: '70%',
      backgroundColor: '#1a4fba',
    },
  },
}));

export const DotWrapStyle = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const DotStyle = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '70%',
  transition: theme.transitions.create(['width', 'height'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.short,
  }),
  backgroundColor: '#FFFFFF',
}));

export const CarouselDots = ({ index, total, onDotClick }) => {
  return (
    <ActionsRootStyle component="ul">
      {Array.from({ length: total }).map((_, i) => (
        <li key={i} onClick={() => onDotClick(i)} className={i === index ? 'slick-active' : ''}>
          <DotWrapStyle>
            <DotStyle className="dotActive" />
          </DotWrapStyle>
        </li>
      ))}
    </ActionsRootStyle>
  );
};

// 轮播图组件
export const Carousel = ({ currentIndex, handleNext, handlePrevious, handleDotClick, totalSlides, children }) => {
  return (
    <Card sx={{ position: 'relative', borderRadius: '19px', height: '100%' }} bordered={false} style={{ width: '100%', backgroundColor: '#FFFFFF' }}>
      <Box sx={{ position: 'absolute', top: 8, left: 8, right: 8, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <CarouselDots index={currentIndex} total={totalSlides} onDotClick={handleDotClick} />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handlePrevious} sx={{ color: '#1a4fba' }}>
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton onClick={handleNext} sx={{ color: '#1a4fba' }}>
            <KeyboardArrowRight />
          </IconButton>
        </Box>
      </Box>
      {children}
    </Card>
  );
};

export const DashboardSummary = ({ title, percent, total, onClick }) => {
  const theme = useTheme();

  // 定义总结组件图标
  const IconWrapperStyle = styled('div')(({ theme }) => ({
    width: 25,
    height: 25,
    display: 'flex',
    borderRadius: '70%',
    alignItems: 'center',
    justifyContent: 'center',
    color: percent > 0 ? theme.palette.success.main : percent < 0 ? theme.palette.error.main : '#1a4fba',
    backgroundColor: alpha(percent > 0 ? theme.palette.success.main : percent < 0 ? theme.palette.error.main : '#1a4fba', 0.16),
  }));

  return (
    <Grid item xs={12} md={4}>
      <Card sx={{ position: 'relative', p: 3, borderRadius: '19px' }}>
        <IconButton onClick={onClick} sx={{ position: 'absolute', top: 8, right: 8, color: theme.palette.info.main }}>
          <KeyboardDoubleArrowRight />
        </IconButton>
        <Box sx={{ flexGrow: 1, pl: 2 }}> {/* 添加左内边距以将内容稍微向右移动 */}
          <Typography variant="subtitle2">{title}</Typography>
          <Typography variant="h3" sx={{ my: 2 }}>{total.toLocaleString()}</Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconWrapperStyle>
              {percent > 0 ? (
                <ArrowUpward />
              ) : percent < 0 ? (
                <ArrowDownward />
              ) : (
                <KeyboardArrowRight />
              )}
            </IconWrapperStyle>
            <Typography component="span" variant="subtitle2">
              {percent > 0 && '+'}
              {percent}%
            </Typography>
            <Typography component="span" variant="caption" sx={{ color: 'text.secondary', ml: 1 }}>
              last 7 days
            </Typography>
          </Stack>
        </Box>
      </Card>
    </Grid>
  );
};


const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: 310,
  marginTop: theme.spacing(1),
  '& .apexcharts-canvas svg': { height: 310 },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: 70,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${310 - 45}px) !important`,
  },
}));



export const DashboardTrialsComposition = ({ data }) => {
  const theme = useTheme();

  const chartOptions = {
    colors: [
      theme.palette.primary.light,
      theme.palette.primary.main,
    ],
    labels: ['Invited', 'Apply'],
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center', position: 'bottom' },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => Intl.NumberFormat().format(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
          labels: {
            show: true,
            name: {
              offsetY: -5,
              color: theme.palette.primary.main,
              fontSize: '13px',
            },
            value: {
              offsetY: 10,
              fontSize: '19px',
              color: theme.palette.text.primary, // 设置值颜色为黑色
              formatter: (val) => Intl.NumberFormat().format(val),
            },
            total: {
              show: true,
              label: 'Total',
              fontSize: '13px',
              color: theme.palette.primary.main,
              formatter: (w) => {
                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                return Intl.NumberFormat().format(sum);
              },
            },
          },
        },
      },
    },
  };

  return (
    <Grid item xs={12} md={5}>
      <Card sx={{ position: 'relative', p: 3, borderRadius: '19px',height: '100%' }}>
      <div className="pharmaceuticals-dashboard-table-title">
        <span>Source of patients for clinical trials</span>
      </div>
        <ChartWrapperStyle dir="ltr">
          <ReactApexChart type="donut" series={Object.values(data)} options={chartOptions} height={290} />
        </ChartWrapperStyle>
      </Card>
    </Grid>
  );
};

const StatusRootStyle = styled('span')(({ theme, ownerState }) => {
  const isLight = theme.palette.mode === 'light';
  const { color, variant } = ownerState;

  const styleFilled = (color) => ({
    color: theme.palette[color].contrastText,
    backgroundColor: theme.palette[color].main,
  });

  const styleOutlined = (color) => ({
    color: theme.palette[color].main,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette[color].main}`,
  });

  const styleGhost = (color) => ({
    color: theme.palette[color][isLight ? 'dark' : 'light'],
    backgroundColor: alpha(theme.palette[color].main, 0.16),
  });

  return {
    height: 22,
    minWidth: 100, // 增加固定宽度
    lineHeight: 0,
    borderRadius: 8,
    cursor: 'default',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    color: theme.palette.grey[800],
    fontSize: theme.typography.pxToRem(12),
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.grey[300],
    fontWeight: theme.typography.fontWeightBold,

    ...(color !== 'default'
      ? {
          ...(variant === 'filled' && { ...styleFilled(color) }),
          ...(variant === 'outlined' && { ...styleOutlined(color) }),
          ...(variant === 'ghost' && { ...styleGhost(color) }),
        }
      : {
          ...(variant === 'outlined' && {
            backgroundColor: 'transparent',
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.grey[500_32]}`,
          }),
          ...(variant === 'ghost' && {
            color: isLight ? theme.palette.text.secondary : theme.palette.common.white,
            backgroundColor: theme.palette.grey[500_16],
          }),
        }),
  };
});



export const ClinicalTrialsStatus = ({ color, status }) => {
  const Label = ({ color = 'default', variant = 'ghost', children, ...other }) => {
    return (
      <StatusRootStyle ownerState={{ color, variant }} {...other}>
        {children}
      </StatusRootStyle>
    );
  };
  
  return (
    <Label variant="ghost" color={color}>
      {status}
    </Label>
  );
};
