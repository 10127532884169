import { useLocation } from 'react-router-dom';
import '../styles/screens/Liver.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const titlesOfData = [
  "Age", "Total_Bilirubin", "Direct_Bilirubin", "Alkaline_Phosphotase", "Alamine_Aminotransferase",
  "Aspartate_Aminotransferase", "Total_Protiens", "Albumin", "Albumin_and_Globulin_Ratio", "Gender_Female",
  "Gender_Male"
];

function Liver_disease_ML() {
  const location = useLocation();
  const phoneNumber = location.state.MobileNumber;
  const [latestRecord, setLatestRecord] = useState([]);
  const [tableOfData, setTableOfData] = useState([]);
  const patientId = location.state.id;
  const fname = location.state.FName;
  const lname = location.state.LName;

  const [result, setResult] = useState(null);
  const [biomarkers, setBiomarkers] = useState(null);

  const API_ROOT = `https://e-react-node-backend-22ed6864d5f3.herokuapp.com`;  //TODO: Need to replace with the backend server address

  // Mock data
  const mockData = {
    total_bilirubin: 1.2,
    direct_bilirubin: 0.3,
    alkaline_phosphotase: 100,
    albumin: 2.8,
  };


  const fetchBiomarkers = async () => {
    try {
      console.log('Fetching biomarkers for patient ID:', patientId);
      const response =
          await axios.post('https://e-react-node-backend-22ed6864d5f3.herokuapp.com/liver_disease', { patientId });
      const data = response.data;

      // Check for error in the response
      if (data.error) {
        console.error('Error fetching biomarkers:', data.error);
        alert(`Error: ${data.error}`);
        return; // Exit the function if there's an error
      }

      console.log('Biomarkers fetched:', data);

      // Destructure biomarkers from the data array
      const [custom_age, Total_Bilirubin, Direct_Bilirubin, Alkaline_Phosphotase,
        Alamine_Aminotransferase, Aspartate_Aminotransferase, Total_Protiens, Albumin,
        Albumin_and_Globulin_Ratio, Gender_Female, Gender_Male] = data.data;

      // Set the biomarkers state
      setBiomarkers({
        total_bilirubin: Total_Bilirubin,
        direct_bilirubin: Direct_Bilirubin,
        alkaline_phosphotase: Alkaline_Phosphotase,
        albumin: Albumin
      });
    } catch (error) {
      console.error('Error fetching biomarkers:', error);
      alert(`Error: ${error.message}`);
    }
  };
  const fetchPrediction = async () => {
    try {
      const dataToSend = biomarkers || mockData;
      console.log('Sending data to prediction API:', dataToSend);
      const response = await axios.post('https://liver-disease-2024-28cca553d8f4.herokuapp.com/predict', dataToSend, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setResult(response.data);
    } catch (error) {
      console.error('Error fetching prediction:', error);
    }
  };

  const handleButtonClick = async () => {
    await fetchBiomarkers();
    await fetchPrediction();
  };

  useEffect(() => {
    const getPatientLatestRecord = async () => {
      try {
        console.log("Fetching latest record for phone number:", phoneNumber);
        console.log("Fetching latest record for id:", patientId);
        const response =
            await axios.post('https://e-react-node-backend-22ed6864d5f3.herokuapp.com/liver_disease', { patientId });
        const { data } = response;
        console.log('Latest record fetched:', response.data);
        if (data.error) {
          alert(JSON.stringify(data.error));
        } else {
          setLatestRecord(data.data);
          setTableOfData(data.data);
        }
      } catch (error) {
        console.log(`Error fetching patient records: ${error.message}`);
      }
    };

    getPatientLatestRecord();
  }, [patientId]);

  return (
      <div className="liver-container">
        <h2 className="liver-title"> {fname} {lname} - Liver disease results</h2>
        <button className="liver-diagnose-button" onClick={handleButtonClick}>Diagnose</button>
        <div className="liver-diagnosis-result">
          <strong>Diagnosis:</strong>
          <h2>Prediction Result:</h2>
          {result ? (
              <div>
                <p>Gilberts Syndrome: {result.GilbertsSyndrome ? 'Yes' : 'No'}</p>
                <p>Liver Cholestasis: {result.LiverCholestasis ? 'Yes' : 'No'}</p>
                <p>Liver Cirrhosis: {result.LiverCirrhosis ? 'Yes' : 'No'}</p>
                <p>Message: {result.message}</p>
              </div>
          ) : (
              <p>No prediction yet. Click the button to get a prediction.</p>
          )}
        </div>

      </div>
  );
}

export default Liver_disease_ML;
