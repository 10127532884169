// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about {
  margin-top: 20px;
  text-align: center;
}

.about h1 {
  font-size: 32px;
  color: #333;
  margin: 20px 0;
}

.about button {
  background-color: #1344ff;
  color: #ffffff;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  transition: background-color 0.3s ease;
}

.about button:hover {
  background-color: #174ae4;
}

.about ul {
  list-style: none;
  padding: 0;
}

.about ul li {
  background-color: #ffffff;
  padding: 10px;
  margin: 5px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.about ul li:hover {
  transform: translateY(-5px);
}





`, "",{"version":3,"sources":["webpack://./src/styles/screens/DBConnection.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,aAAa;EACb,wCAAwC;EACxC,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".about {\n  margin-top: 20px;\n  text-align: center;\n}\n\n.about h1 {\n  font-size: 32px;\n  color: #333;\n  margin: 20px 0;\n}\n\n.about button {\n  background-color: #1344ff;\n  color: #ffffff;\n  padding: 10px 20px;\n  margin-right: 10px;\n  cursor: pointer;\n  font-size: 16px;\n  border-radius: 5px;\n  border: none;\n  transition: background-color 0.3s ease;\n}\n\n.about button:hover {\n  background-color: #174ae4;\n}\n\n.about ul {\n  list-style: none;\n  padding: 0;\n}\n\n.about ul li {\n  background-color: #ffffff;\n  padding: 10px;\n  margin: 5px 0;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  border-radius: 5px;\n  transition: transform 0.3s ease;\n}\n\n.about ul li:hover {\n  transform: translateY(-5px);\n}\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
