import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Spin,
  DatePicker,
  AutoComplete,
} from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";

import { readLoginData } from "../../loginData";
import {
  setPatientPassword,
  getDoctors,
  getPatientProfile,
  setPatientProfile,
} from "../../api/user";
import { BlueButton } from "./PatientPortal";
import "./PatientProfilePage.css";
const { Group: InputGroup } = Input;
const DobFormat = (value) => `DoB: ${value.format("YYYY-MM-DD")}`;

dayjs.extend(dayjsPluginUTC);

const ProfileCategorySwitch = (props) => {
  return (
    <>
      <Radio.Group
        value={props.value}
        buttonStyle="solid"
        className="doctor-profile-switch"
        onChange={(e) => props.onChange(e.target.value)}
      >
        <Radio.Button value="profile">Profile</Radio.Button>
        <Radio.Button value="security">Security</Radio.Button>
      </Radio.Group>
    </>
  );
};

const PatientProfileForm = (props) => {
  const [doctors, setDoctors] = useState([]);
  const [options, setOptions] = useState([]);

  const loginData = readLoginData();
  const [needLoad, setNeedLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchAllDoctors = async () => {
      const response = await getDoctors();
      console.log("fetchAllDoctors:", response);
      setDoctors(response.doctors); //  { doctors: [...] }
    };

    fetchAllDoctors();
  }, []);

  const handleSearch = (value) => {
    if (!value) {
      setOptions([]);
      return;
    }

    const filteredDoctors = doctors.filter((doctor) =>
      doctor.name.toLowerCase().includes(value.toLowerCase())
    );

    const formattedOptions = filteredDoctors.map((doctor) => ({
      value: `${doctor.name}, ${doctor.id}`,
    }));
    setOptions(formattedOptions);
  };

  if (needLoad) {
    (async () => {
      setLoading(true);
      setNeedLoad(false);
      const data = await getPatientProfile(loginData.id);
      for (const key in data) {
        if (key === "dateOfBirth" && data[key]) {
          const date = dayjs(data[key]).utc().format("YYYY-MM-DD");
          form.setFieldValue(key, dayjs(date));
        } else {
          form.setFieldValue(key, data[key]);
        }
      }
      setLoading(false);
    })();
  }

  const handleFinish = (values) => {
    (async () => {
      console.log("PatientProfileForm: ", values);
      await setPatientProfile(loginData.id, values);
      setNeedLoad(true);
    })();
  };

  return (
    <>
      <Spin spinning={loading}>
        <Form
          {...props}
          form={form}
          name="doctor-profile"
          onFinish={handleFinish}
        >
          <h1>Personal Profile</h1>
          <ProfileCategorySwitch value="profile" onChange={props.onCategory} />
          <div
            style={{ marginTop: "1.226rem" }}
            className="doctor-profile-form-group"
          >
            <Form.Item name="firstName" rules={[{ required: true }]}>
              <Input
                size="large"
                prefix={<>First Name:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>
            <Form.Item name="lastName" rules={[{ required: true }]}>
              <Input
                size="large"
                prefix={<>Last Name:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true }, { type: "email" }]}
            >
              <Input
                size="large"
                prefix={<>Email Address:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>

            <InputGroup compact>
              <Input
                defaultValue="DOB:"
                disabled
                className="doctor-profile-BoD-prefix"
              />
              <Form.Item
                name="dateOfBirth"
                style={{ width: "calc(100% - 72.333px)" }}
              >
                <DatePicker
                  size="large"
                  format="YYYY-MM-DD"
                  placeholder="Select Date"
                  className="doctor-profile-form-row"
                />
              </Form.Item>
            </InputGroup>

            <Form.Item name="mobile" rules={[{ required: true }]}>
              <Input
                size="large"
                prefix={<>Mobile:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>
            <Form.Item name="address1" rules={[{ required: true }]}>
              <Input
                size="large"
                prefix={<>Address Line 1:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>
            <Form.Item name="address2">
              <Input
                size="large"
                prefix={<>Address Line 2:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>
            <div
              style={{ display: "flex", flexDirection: "row" }}
              className="doctor-profile-form-row"
            >
              <Form.Item
                name="city"
                rules={[{ required: true }]}
                style={{ flex: "1 1 47.57%" }}
              >
                <Input
                  size="large"
                  prefix={<>City:</>}
                  style={{ borderRadius: "0" }}
                />
              </Form.Item>
              <Form.Item
                name="zip"
                rules={[{ required: true }]}
                style={{ flex: "1 1 52.43%" }}
              >
                <Input
                  size="large"
                  prefix={<>Zip:</>}
                  style={{ borderRadius: "0" }}
                />
              </Form.Item>
            </div>
          </div>
          <div
            style={{ marginTop: "4.188rem" }}
            className="doctor-profile-form-group"
          >
            <div className="doctor-profile-form-label">
              Healthcare Information:
            </div>
            <Form.Item name="healthCardNumber" rules={[{ required: true }]}>
              <Input
                size="large"
                prefix={<>Healthcare Card Number:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>
            <Form.Item name="familyDoctor" rules={[]} className="form-item">
              <AutoComplete
                size="large"
                options={options}
                onSearch={handleSearch}
                className="doctor-profile-form-row"
                placeholder="Family Doctor:"
                value={form.getFieldValue("familyDoctor")}
                onChange={(value) =>
                  form.setFieldsValue({ familyDoctor: value })
                }
              />
            </Form.Item>
          </div>
          <Flex justify="center" style={{ marginTop: "4.188rem" }}>
            <Form.Item>
              <BlueButton buttonTitle="Save" onClick={() => form.submit()}>
                Save
              </BlueButton>
            </Form.Item>
          </Flex>
        </Form>
      </Spin>
    </>
  );
};

const PatientSecurityForm = (props) => {
  const loginData = readLoginData();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    console.log("handleFinish");
    form.resetFields();
    (async () => {
      console.log("PatientSecurityForm: ", values);
      const result = await setPatientPassword(
        loginData.id,
        values.oldPassword,
        values.newPassword
      );
      if (result === "OK") {
        Modal.success({ content: "Password reset successfully." });
        props.clearUser();
        navigate("/LogIn");
      } else {
        Modal.error({ content: `Error: ${result}` });
      }
    })();
  };

  return (
    <>
      <Spin spinning={false}>
        <Form
          {...props}
          form={form}
          name="doctor-profile"
          onFinish={handleFinish}
        >
          <h1>Personal Profile</h1>
          <ProfileCategorySwitch value="security" onChange={props.onCategory} />
          <div
            style={{ marginTop: "1.226rem" }}
            className="doctor-profile-form-group"
          >
            <Form.Item name="oldPassword" rules={[{ required: true }]}>
              <Input.Password
                size="large"
                prefix={<>Old Password:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>
            <Form.Item name="newPassword" rules={[{ required: true }]}>
              <Input.Password
                size="large"
                prefix={<>New Password:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>
            <Form.Item
              name="newPasswordRepeat"
              dependencies={["newPassword"]}
              rules={[
                {
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                size="large"
                prefix={<>Repeat New Password:</>}
                className="doctor-profile-form-row"
              />
            </Form.Item>
          </div>
          <Flex justify="center" style={{ marginTop: "4.188rem" }}>
            <Form.Item>
              <BlueButton
                buttonTitle="ChangePassword"
                onClick={() => form.submit()}
              />
            </Form.Item>
          </Flex>
        </Form>
      </Spin>
    </>
  );
};

const formMap = {
  profile: PatientProfileForm,
  security: PatientSecurityForm,
};

const PatientProfilePage = (props) => {
  const [category, setCategory] = useState("profile");

  const ProfileForm = formMap[category];

  return (
    <>
      <div className="patient-profile-page">
        <div
          style={{
            height: "100%",
            marginLeft: "23%",
            marginRight: "24.76%",
          }}
        >
          <Flex vertical gap="1rem" style={{ display: "flex", width: "100%" }}>
            <ProfileForm
              onCategory={(c) => setCategory(c)}
              clearUser={props.clearUser}
            />
          </Flex>
        </div>
      </div>
    </>
  );
};

export default PatientProfilePage;
