import React, { useEffect, useState } from 'react';
import { Button, Card, Checkbox, Col, Dropdown, List, Modal, Progress, Row, Space, Table, Tag } from "antd";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import moment from "moment";
import {
  PlusOutlined,
  EllipsisOutlined,
  DeleteOutlined,
  BellOutlined,
} from '@ant-design/icons';

import { adminAddTask, adminChangeTask, adminDeleteTask, adminGetTasks, getPatientList, getTickets, getUnverifiedDoctors, verifyDoctor } from '../../api/admin';
import ViewModal from '../DoctorNew/patient-details/ViewModal';
import { readLoginData } from '../../loginData';
import AddNewTask from './AddNewTask';
import "./AdminManagementPage.css";

const randomElement = (array) => array[Math.floor(Math.random() * array.length)];
const verificationMapping = (v) => {
  switch(v){
    case -1: return 1;
    case 0: return 0;
    case 1: return 2;
    default: throw new Error('Unexpected verification value');
  }
};
const compareVerification = (a, b) => verificationMapping(a) - verificationMapping(b);
const groupByPatientId = (arr) => {
  const map = new Map();
  arr.forEach(r => {
    console.log("groupByPatientId", r);
    if(!map.has(r.id)){
      map.set(r.id, r);
      r.doctors = [];
    }
    if(r.doctor_id){
      map.get(r.id).doctors.push(r.doctor_name);
    }
  });
  return Array.from(map, ([name, value]) => value);
};

const PatientList = (props) => {
  const [ needLoad, setNeedLoad ] = useState(true);
  const [ loading, setLoading ] = useState(true);
  const [ data, setData ] = useState([]);
  const [ selectedPatient, setSelectedPatient ] = useState(null);

  if(needLoad){
    setLoading(true);
    setNeedLoad(false);
    (async () => {
      try {
        const result = await getPatientList();
        console.log(result);
        setData(groupByPatientId(result));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'id',
      key: 'no',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Assigned Doctor',
      key: 'doctors',
      render: (text, record, index) => record.doctors.map(r => <div>{r}</div>),
    },
    {
      title: 'Active Date',
      key: 'activeDate',
      render: (text, record, index) => <>&nbsp;</>,
    },
    {
      title: 'Location',
      dataIndex: 'Address',
      key: 'location',
      render: (text, record, index) => <>
        <Tag bordered={false} color={randomElement([ "processing", "success", "error", "warning", "magenta", "red", "volcano", "orange", "gold", "lime", "green", "cyan", "blue", "geekblue", "purple"
])}>{text}</Tag>
      </>,
    },
    {
      title: 'Edit',
      key: 'edit',
      render: (text, record, index) => <>
        <Button type="text" onClick={() => setSelectedPatient(record.id)}>
          <MoreHorizIcon/>
        </Button>
      </>,
    },
  ];
  return <>
    <p className='admin-management-card-title'>PATIENT LIST</p>
    <Table loading={loading} columns={columns} dataSource={data} className='admin-management-table'/>
    { (selectedPatient!==null) ? <>
      <ViewModal
        visible={true}
        onClose={() => setSelectedPatient(null)}
        patientId={selectedPatient}
        doctorId={0}
      />
    </>:null}
  </>;
};

const DoctorVerificationList = (props) => {
  const [ needLoad, setNeedLoad ] = useState(true);
  const [ loading, setLoading ] = useState(true);
  const [ data, setData ] = useState([]);
  const [ selectedRow, setSelectedRow ] = useState(null);

  if(needLoad){
    setLoading(true);
    setNeedLoad(false);
    (async () => {
      try {
        const result = await getUnverifiedDoctors();
        console.log(result);
        result.sort((a,b) => compareVerification(a.verification, b.verification));
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'id',
      key: 'no',
      render: (text) => <>{text}</>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <>{text}</>,
    },
    {
      title: 'Status',
      key: 'status',
      render: (text, record, index) => <>
        { (record.verification === -1) ? <Tag color="#FA393994" style={{ color: "black" }}>Rejected</Tag> : null }
        { (record.verification === 0) ? <Tag color="#FFA90094" style={{ color: "black" }}>Pending</Tag> : null }
        { (record.verification === 1) ? <Tag color="#ADEDCD" style={{ color: "black" }}>Approved</Tag> : null }
      </>,
    },
    {
      title: 'Activation Email',
      key: 'sendEmail',
      render: (text, record, index) => <>
        { (record.verification === 0) ? <>
          <Button type="primary" onClick={() => setSelectedRow(record.id)}>Send Email</Button>
        </>: null }
      </>,
    },
    {
      title: 'Remaining Time',
      dataIndex: 'Address',
      key: 'location',
      render: (text, record, index) => <>
        { (record.verification === 0) ? <>
          <Progress percent={95.8} showInfo={false} />
          <p className='admin-management-doctor-verification-time-remaining'>23 hrs left</p>
        </>: null }
      </>,
    },
  ];

  const handleVerify = async (doctorId, newState) => {
    setSelectedRow(null);
    await verifyDoctor(doctorId, newState);
    setNeedLoad(true);
  };

  return <>
    <p className='admin-management-card-title'>DOCTOR VERIFICATION</p>
    <Table columns={columns} dataSource={data} className='admin-management-table'/>
    <Modal
      title="User Verification"
      centered
      open={selectedRow!==null}
      footer={null}
      onCancel={() => setSelectedRow(null)}
      width="40%"
      classNames={{
        wrapper: 'admin-management-doctor-verification-modal',
      }}
    >
      <p>Please choose the option to verify user's registration status. An email will be sent to user directly about this request.</p>
      <div style={{ marginTop: "20%" }}>
        <Button type="primary" danger onClick={() => handleVerify(selectedRow, -1)}>Recject</Button>
        <Button type="primary" style={{ marginLeft: "3rem" }} onClick={() => handleVerify(selectedRow, 1)}>Approve</Button>
      </div>
    </Modal>
  </>;
};

const TicketList = (props) => {
  const [ needLoad, setNeedLoad ] = useState(true);
  const [ loading, setLoading ] = useState(true);
  const [ data, setData ] = useState([]);

  if(needLoad){
    setLoading(true);
    setNeedLoad(false);
    (async () => {
      try {
        const result = await getTickets();
        console.log(result);
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }

  const columns = [
    {
      title: 'No',
      key: 'no',
      render: (text, record, index) => <>{index+1}</>,
    },
    {
      title: 'Ticket ID',
      dataIndex: 'id',
      key: 'id',
      render: (text) => <>#{text}</>,
    },
    {
      title: 'Name',
      dataIndex: 'help_name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'help_email',
      key: 'email',
    },
    {
      title: 'Subject',
      dataIndex: 'help_message',
      key: 'subject',
    },
    {
      title: 'Category',
      key: 'category',
      render: (text, record) => <>{randomElement([ 'Bug', 'Support', 'General' ])}</>,
    },
    {
      title: 'Status',
      key: 'status',
      render: (text, record, index) => {
        const r = randomElement([ -1, 0, 1 ]);
        return <>
          { (r === -1) ? <Tag color="#FA393994" style={{ color: "black" }}>On Hold</Tag> : null }
          { (r === 0) ? <Tag color="#FFA90094" style={{ color: "black" }}>In Progress</Tag> : null }
          { (r === 1) ? <Tag color="#ADEDCD" style={{ color: "black" }}>Closed</Tag> : null }
        </>;
      },
    },
    {
      title: 'Created',
      key: 'created',
      render: (text, record) => <>{moment(record.help_time).format("MM/DD/YYYY - h:mma")}</>,
    },
  ];

  return <>
    <p className='admin-management-card-title'>Tickets</p>
    <Table loading={loading} columns={columns} dataSource={data} className='admin-management-table'/>
  </>;
};

const ServiceRequestList = (props) => {
  const [needLoad, setNeedLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [newTask, setNewTask] = useState({
    date: undefined,
    description: "",
  });

  const loginData = readLoginData();
  if (needLoad) {
    (async () => {
      try {
        setLoading(true);
        setNeedLoad(false);

        const response = await adminGetTasks(loginData, moment().startOf('week').toDate(), moment().endOf('week').toDate());
        setData(response);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }

  const handleNewTaskButton = () => {
    setConfirmLoading(false);
    setNewTask({ date: undefined, description: "" });
    setIsModalOpen(true);
  };

  const handleChangeStatus = async (item) => {
    try {
      await adminChangeTask(loginData, item.id, !item.status);
      setNeedLoad(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleOk = () => {
    return (async () => {
      try {
        setConfirmLoading(true);
        await adminAddTask(
          loginData,
          newTask.date,
          newTask.date,
          newTask.description
        );
        setIsModalOpen(false);
        setNeedLoad(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div>
        <span className='admin-management-card-title'>Service Requests</span>
        <div style={{ float: "right" }}>
          <span
            style={{
              color: "#0000AC",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
            onClick={handleNewTaskButton}
          >
            New Tasks
          </span>
          <Button icon={<PlusOutlined />} onClick={handleNewTaskButton} />
        </div>
      </div>
      <List
        loading={loading}
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Dropdown menu={{
                items: [
                  {
                    label: 'Delete',
                    key: 1,
                    icon: <DeleteOutlined />,
                    danger: true,
                  },
                ],
                onClick: async () => { await adminDeleteTask(loginData, item.id); setNeedLoad(true); },
              }}>
                <EllipsisOutlined/>
              </Dropdown>
            ]}
            style={{ width: "100%", minHeight: "6.6rem" }}
          >
            <List.Item.Meta
              avatar={
                <Checkbox
                  checked={item.status === 1}
                  onChange={() => handleChangeStatus(item)}
                  className="clinicalstaff-task-list-checkbox"
                />
              }
              title={
                item.status === 1
                  ? "Task completed successfully"
                  : "Task not completed"
              }
              description={item.description}
            />
            <div>{moment(item.start).format("DD MMM YYYY")}</div>
          </List.Item>
        )}
      />
      <Modal
        title="New Task"
        open={isModalOpen}
        confirmLoading={confirmLoading}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <AddNewTask
          {...newTask}
          onChange={(e) => {
            setNewTask({ ...newTask, ...e });
          }}
        />
      </Modal>
    </>
  );
};

const PotentialIssueList = (props) => {
  const data = [
    { title: "Unusual Visits of Medical Records" },
    { title: "Unusual Log in" },
    { title: "Multiple Attempts of Passwords" },
    { title: "Potential Data Leaking" },
  ];

  return <>
    <div>
      <span style={{ fontSize: "1.516rem" }}><BellOutlined/></span>
      <span className='admin-management-card-title'>Potential Issues</span>
    </div>
    <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button className='admin-management-potential-issues-item-button'>View</Button>
            ]}
            style={{ width: "100%", minHeight: "6.6rem" }}
          >
            <List.Item.Meta
              title={<p className='admin-management-potential-issues-item'>{item.title}</p>}
            />
          </List.Item>
        )}
      />
  </>;
};

export const AdminManagementPage = (props) => {
  return <>
    <Card id="PatientList" style={{ marginTop: "5.375rem" }}><PatientList/></Card>
    <Card id="DoctorVerification" style={{ marginTop: "0.625rem" }}><DoctorVerificationList/></Card>
    <Card id="TicketList" style={{ marginTop: "0.625rem" }}><TicketList/></Card>
    <Row gutter={16} style={{ marginTop: "0.625rem" }}>
      <Col span={13}>
        <Card id="ServiceRequests"><ServiceRequestList/></Card>
      </Col>
      <Col span={11}>
        <Card id="PotentialIssues" ><PotentialIssueList/></Card>
      </Col>
    </Row>
  </>;
};
  
export default AdminManagementPage;
