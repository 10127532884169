// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-content {
    display: flex;
    justify-content: space-between;
}
.footer {
    background-color: rgb(28, 100, 231);
}
.footer-block {
    color: #ffffff;
    padding: 40px;
    flex-basis: calc(30% - 20px); /* Adjusting for margin */
    text-align: center;

}

/* Media Queries */
@media (max-width: 768px) {

    .footer-content{
        display: block;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/LandingPage/footer/footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,mCAAmC;AACvC;AACA;IACI,cAAc;IACd,aAAa;IACb,4BAA4B,EAAE,yBAAyB;IACvD,kBAAkB;;AAEtB;;AAEA,kBAAkB;AAClB;;IAEI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".footer-content {\n    display: flex;\n    justify-content: space-between;\n}\n.footer {\n    background-color: rgb(28, 100, 231);\n}\n.footer-block {\n    color: #ffffff;\n    padding: 40px;\n    flex-basis: calc(30% - 20px); /* Adjusting for margin */\n    text-align: center;\n\n}\n\n/* Media Queries */\n@media (max-width: 768px) {\n\n    .footer-content{\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
