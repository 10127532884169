import React, { useEffect, useState } from "react";
import moment from "moment";
import dayjs from "dayjs";
import { Calendar, Spin } from "antd";

import "./DoctorMiniCalendar.css";
import { doctorGetMiniCalendar } from "../../api/calendar";
import { readLoginData } from "../../loginData";

const dateFormatString = "YYYY-MM-DD";

const includesDate = (dates, d) => {
  return dates.includes(moment(d).format(dateFormatString));
};

const DoctorMiniCalendar = ({ date, onSelectDate }) => {
  const loginData = readLoginData();
  const [ loading, setLoading ] = useState(true);
  const [ data, setData ] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const startTime = moment(date).subtract('7', 'week').toDate();
      const endTime = moment(date).add('7', 'week').toDate();
      const response = await doctorGetMiniCalendar(
        loginData,
        startTime,
        endTime,
        moment().format("Z")
      );
      const data = response.map((date) => moment.utc(date).format(dateFormatString));
      setData(data);
      setLoading(false);
    })();
    return () => {};
  }, [ date ]);

  const handleChange = (date) => {
    console.log("handleChange", date);
    onSelectDate(date.toDate());
  };

  const cellRender = (date, info) => {
    if (info.type === "date") {
      return React.cloneElement(info.originNode, {
        ...info.originNode.props,
        className: `${info.originNode.props.className} doctor-mini-calendar-cell-date`,
        children: <>
          { date.get("date") }
          { (includesDate(data, date.toDate())) ?
            (<div className={ true ? 'doctor-mini-calendar-cell-dot' : 'doctor-mini-calendar-cell-nodot' }/>) : null }
        </>,
      });
    }else{
      return info.originNode;
    }
  };

  return (
    <>
      <h2 className="doctor-subtitle" style={{ textAlign: "left" }}>Calendar</h2>
      <Spin spinning={loading}>
        <Calendar
          value={dayjs(date)}
          fullscreen={false}
          className="doctor-mini-calendar"
          fullCellRender={cellRender}
          onChange={handleChange}
          mode="month"
        />
      </Spin>
    </>
  );
};

export default DoctorMiniCalendar;
