import React from 'react';
import Navbar from '../components/LandingPage/navbar/navbar';
import Content from '../components/LandingPage/content/content';
import Footer from "../components/LandingPage/footer/footer";


const landing = () => {

    return (
        <div className="landingpage">
            <Navbar />
            <Content />
            <Footer />
        </div>

    );
};

export default landing;