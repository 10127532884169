import React, { useState, useEffect } from "react";
import { Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Checkbox, FormControlLabel, TextField, FormControl, Select, MenuItem } from '@mui/material';
import { getSpecificClinicalTrialsMatchedPatients } from '../../../api/user';
import { useNavigate } from "react-router-dom";
import { saveCurrentPath } from '../LocalNavigate';
import MUIDataTable from "mui-datatables";
import SettingsIcon from '@mui/icons-material/Settings';

// 临床试验和患者匹配表的标题
const MatchedPatientsTableTitle = () => (
  <Typography variant="h5" component="div" sx={{ fontSize: 'medium', fontWeight: 'bold' }}>Matched Patients</Typography>
);

// 点击 patient_full_name 时的操作
const PatientNameClicked = async (patient_id, navigate) => {
  try {
    navigate(`/pharmaceuticals/view/${2}/${patient_id}`);
  } catch (error) {
    console.error('Error opening patient profile!:', error);
  }
};

const tableMatchedPatientsRender = (value, tableMeta = {}, navigate) => {
  if (!tableMeta || !tableMeta.columnData) {
    return <div>{value}</div>;
  }

  const columnName = tableMeta.columnData.name;
  let onClick = undefined;
  let cursorStyle = 'default';
  saveCurrentPath();

  if (columnName === 'patient_fullname') {
    onClick = () => PatientNameClicked(tableMeta.rowData ? tableMeta.rowData[1] : null, navigate);
    cursorStyle = 'pointer';
  }

  return (
    <div style={{ cursor: cursorStyle }} onClick={onClick}>{value}</div>
  );
};

// 表头定义
const createColumns = (navigate) => [
  { name: 'trial_id', options: { display: false, filter: false } },
  { name: 'patient_id', options: { display: false, filter: false } },
  { name: 'patient_fullname', label: 'Patient Name', options: { customBodyRender: (value, tableMeta) => tableMatchedPatientsRender(value, tableMeta, navigate), filter: false } },
  { name: 'detailed_description', label: 'Detailed Description', options: { customBodyRender: (value, tableMeta) => tableMatchedPatientsRender(value, tableMeta, navigate), filter: false } },
];

// 点击设置按钮
const onSettingsClick = (setDialogOpen) => {
  setDialogOpen(true);
};

// 自定义工具栏
const CustomToolbar = ({ setDialogOpen }) => (
  <IconButton onClick={() => onSettingsClick(setDialogOpen)} aria-label="settings">
    <SettingsIcon />
  </IconButton>
);

// 生成 options 对象
const createOptions = (setDialogOpen) => ({
  selectableRows: 'none',
  download: false,
  viewColumns: false,
  print: false,
  elevation: 0,
  filter: false,
  rowsPerPage: 5,
  rowsPerPageOptions: [5, 10],
  customToolbar: () => <CustomToolbar setDialogOpen={setDialogOpen} />,
  setRowProps: () => ({
    style: { backgroundColor: '#FFFFFF' },
  }),
  setCellProps: () => ({
    style: { backgroundColor: '#FFFFFF' },
  })
});

// 计算BMI条件
const getBMICondition = (bmiRange) => {
  if (bmiRange.includes('>') && bmiRange.includes('<')) {
    const [lowerBound, upperBound] = bmiRange.split(' ').filter((val) => !isNaN(val)).map(Number);
    return parseFloat(lowerBound) > parseFloat(upperBound) ? `> ${lowerBound} or < ${upperBound}` : `> ${lowerBound} and < ${upperBound}`;
  }
  if (bmiRange.includes('>')) return `> ${bmiRange.split('> ')[1].split(' ')[0]}`;
  if (bmiRange.includes('<')) return `< ${bmiRange.split('< ')[1]}`;
  return '> 1 and < 99';
};

// 主体部分
const SpecificClinicalTrialsMatchedPatients = ({ trial_info }) => {
  console.log(trial_info);
  const [matchedPatients, setMatchedPatients] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false); // 用于控制对话框的状态
  const [criteria, setCriteria] = useState({
    pathology: true,
    gender: true,
    age: true,
    diseases: true,
    bmi: true,
    priorMedications: true,
    surgeries: true,
    pregnancy: true,
  }); // 保存筛选条件
  const [criteriaValues, setCriteriaValues] = useState({
    pathology: trial_info.pathology || '',
    gender: trial_info.gender || '',
    ageRange: trial_info.age_range || '0-99',
    bmiRange: trial_info.exclusion_criteria.BMI || '> 1 and < 99',
    diseases: trial_info.exclusion_criteria.Diseases || '',
    priorMedications: trial_info.exclusion_criteria["Prior Medications"] || '',
    surgeries: trial_info.exclusion_criteria.Surgeries || '',
    pregnancy: trial_info.exclusion_criteria.Pregnancy || 'Unrestricted'
  });

  const navigate = useNavigate();

  // 调用 createOptions 和 createColumns 函数，确保点击时能正确导航到其他部分
  const options = createOptions(setDialogOpen);
  const columns = createColumns(navigate);

  // 获取匹配患者信息
  const fetchClinicalTrialsMatchedPatients = async (criteria, criteriaValues) => {
    try {
      const matchedPatients = await getSpecificClinicalTrialsMatchedPatients(criteria, criteriaValues);
      setMatchedPatients(matchedPatients || []); // 确保 matchedPatients 至少是一个空数组
    } catch (error) {
      console.error('Error fetching clinical trials:', error);
      setMatchedPatients([]); // 在错误情况下设置为空数组
    }
  };

  useEffect(() => {
    fetchClinicalTrialsMatchedPatients(criteria, criteriaValues);
  }, [trial_info.trial_id]);

  // 处理对话框关闭事件
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // 处理筛选条件变化事件
  const handleCriteriaChange = (name) => (event) => {
    setCriteria({
      ...criteria,
      [name]: event.target.checked,
    });
  };

  // 处理筛选值变化事件
  const handleCriteriaValueChange = (name) => (event) => {
    const value = event.target.value;
    setCriteriaValues({
      ...criteriaValues,
      [name]: value,
    });
  };

  // 处理对话框确认事件
  const handleDialogConfirm = () => {
    setDialogOpen(false);
    const formattedCriteriaValues = {
      pathology: criteria.pathology ? criteriaValues.pathology : '',
      gender: criteria.gender ? criteriaValues.gender : '',
      ageRange: criteria.age ? criteriaValues.ageRange : '0-99',
      bmiRange: criteria.bmi ? criteriaValues.bmiRange : '> 1 and < 99',
      diseases: criteria.diseases ? criteriaValues.diseases : '',
      priorMedications: criteria.priorMedications ? criteriaValues.priorMedications : '',
      surgeries: criteria.surgeries ? criteriaValues.surgeries : '',
      pregnancy: criteria.pregnancy ? criteriaValues.pregnancy : ''
    };
    console.log(formattedCriteriaValues);
    fetchClinicalTrialsMatchedPatients(criteria, formattedCriteriaValues);
  };

  return (
    <>
      <MUIDataTable
        title={<MatchedPatientsTableTitle />}
        data={matchedPatients}
        columns={columns}
        options={options}
      />

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        PaperProps={{
          style: {
            minWidth: '500px',
            minHeight: '600px',
            borderRadius: '10px'
          }
        }}
      >
        <DialogTitle>Custom Matching Criteria</DialogTitle>
        <DialogContent>
          <Typography variant="h6" gutterBottom>Inclusion Criteria</Typography>
          <Box display="flex" alignItems="center" mb={2}>
            <FormControlLabel
              control={<Checkbox checked={criteria.pathology} onChange={handleCriteriaChange('pathology')} />}
              label="Pathology"
            />
            <TextField
              value={criteriaValues.pathology}
              onChange={handleCriteriaValueChange('pathology')}
              placeholder="Pathology"
              style={{ marginLeft: '10px', flex: 1 }}
              disabled={!criteria.pathology}
            />
          </Box>
          <Box display="flex" alignItems="center" mb={2}>
            <FormControlLabel
              control={<Checkbox checked={criteria.gender} onChange={handleCriteriaChange('gender')} />}
              label="Gender"
            />
            <FormControl style={{ marginLeft: '10px', flex: 1 }}>
              <Select
                value={criteriaValues.gender}
                onChange={handleCriteriaValueChange('gender')}
                disabled={!criteria.gender}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Both">Both</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              control={<Checkbox checked={criteria.pregnancy} onChange={handleCriteriaChange('pregnancy')} />}
              label="Pregnancy"
              style={{ marginLeft: '20px' }}
            />
            <FormControl style={{ marginLeft: '10px', flex: 1 }}>
              <Select
                value={criteriaValues.pregnancy}
                onChange={handleCriteriaValueChange('pregnancy')}
                disabled={!criteria.pregnancy}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="Unrestricted">Unrestricted</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" mb={2}>
            <FormControlLabel
              control={<Checkbox checked={criteria.age} onChange={handleCriteriaChange('age')} />}
              label="Age"
            />
            <TextField
              value={criteriaValues.ageRange.split('-')[0]}
              onChange={(event) => handleCriteriaValueChange('ageRange')({ target: { value: `${event.target.value}-${criteriaValues.ageRange.split('-')[1]}` } })}
              placeholder="Min Age"
              type="number"
              style={{ marginLeft: '10px', flex: 1 }}
              disabled={!criteria.age}
            />
            <TextField
              value={criteriaValues.ageRange.split('-')[1]}
              onChange={(event) => handleCriteriaValueChange('ageRange')({ target: { value: `${criteriaValues.ageRange.split('-')[0]}-${event.target.value}` } })}
              placeholder="Max Age"
              type="number"
              style={{ marginLeft: '10px', flex: 1 }}
              disabled={!criteria.age}
            />
          </Box>
          <Typography variant="h6" gutterBottom mt={2}>Exclusion Criteria</Typography>
          <Box display="flex" alignItems="center" mb={2}>
            <FormControlLabel
              control={<Checkbox checked={criteria.bmi} onChange={handleCriteriaChange('bmi')} />}
              label="BMI"
            />
            <TextField
              value={criteriaValues.bmiRange.includes('>') ? criteriaValues.bmiRange.split('> ')[1].split(' ')[0] : ''}
              onChange={(event) => handleCriteriaValueChange('bmiRange')({ target: { value: `> ${event.target.value} ${criteriaValues.bmiRange.includes('<') ? criteriaValues.bmiRange.split('<')[1] : ''}` } })}
              placeholder="Min BMI"
              type="number"
              style={{ marginLeft: '10px', flex: 1 }}
              disabled={!criteria.bmi}
            />
            <TextField
              value={criteriaValues.bmiRange.includes('<') ? criteriaValues.bmiRange.split('< ')[1] : ''}
              onChange={(event) => handleCriteriaValueChange('bmiRange')({ target: { value: `${criteriaValues.bmiRange.includes('>') ? criteriaValues.bmiRange.split('>')[0] : ''} < ${event.target.value}` } })}
              placeholder="Max BMI"
              type="number"
              style={{ marginLeft: '10px', flex: 1 }}
              disabled={!criteria.bmi}
            />
          </Box>
          <Typography variant="body2" color="textSecondary">
            {getBMICondition(criteriaValues.bmiRange)}
          </Typography>
          <Box display="flex" alignItems="center" mb={2}>
            <FormControlLabel
              control={<Checkbox checked={criteria.diseases} onChange={handleCriteriaChange('diseases')} />}
              label="Diseases"
            />
            <FormControl style={{ marginLeft: '10px', flex: 1 }}>
              <Select
                value={criteriaValues.diseases}
                onChange={handleCriteriaValueChange('diseases')}
                disabled={!criteria.diseases}
              >
                <MenuItem value="Cardiovascular Diseases">Cardiovascular Diseases</MenuItem>
                <MenuItem value="Endocrine Diseases">Endocrine Diseases</MenuItem>
                <MenuItem value="Respiratory Diseases">Respiratory Diseases</MenuItem>
                <MenuItem value="Digestive Diseases">Digestive Diseases</MenuItem>
                <MenuItem value="Renal Disease">Renal Disease</MenuItem>
                <MenuItem value="Neurological Diseases">Neurological Diseases</MenuItem>
                <MenuItem value="Immunological Diseases">Immunological Diseases</MenuItem>
                <MenuItem value="Infectious Diseases">Infectious Diseases</MenuItem>
                <MenuItem value="Cancer">Cancer</MenuItem>
                <MenuItem value="Liver Disease">Liver Disease</MenuItem>
                <MenuItem value="Dermatological Diseases">Dermatological Diseases</MenuItem>
                <MenuItem value="Musculoskeletal Diseases">Musculoskeletal Diseases</MenuItem>
                <MenuItem value="Mental Health Disorders">Mental Health Disorders</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" mb={2}>
            <FormControlLabel
              control={<Checkbox checked={criteria.surgeries} onChange={handleCriteriaChange('surgeries')} />}
              label="Surgeries"
            />
            <FormControl style={{ marginLeft: '10px', flex: 1 }}>
              <Select
                value={criteriaValues.surgeries}
                onChange={handleCriteriaValueChange('surgeries')}
                disabled={!criteria.surgeries}
              >
                <MenuItem value="Recent surgeries">Recent surgeries</MenuItem>
                <MenuItem value="Recent abdominal surgery">Recent abdominal surgery</MenuItem>
                <MenuItem value="Recent brain surgery">Recent brain surgery</MenuItem>
                <MenuItem value="Thoracic surgery">Thoracic surgery</MenuItem>
                <MenuItem value="Recent lung surgery">Recent lung surgery</MenuItem>
                <MenuItem value="Joint replacement surgery">Joint replacement surgery</MenuItem>
                <MenuItem value="Recent joint surgery">Recent joint surgery</MenuItem>
                <MenuItem value="Recent breast surgery">Recent breast surgery</MenuItem>
                <MenuItem value="Recent thoracic surgery">Recent thoracic surgery</MenuItem>
                <MenuItem value="Recent bowel surgery">Recent bowel surgery</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" mb={2}>
            <FormControlLabel
              control={<Checkbox checked={criteria.priorMedications} onChange={handleCriteriaChange('priorMedications')} />}
              label="Prior Medications"
            />
            <TextField
              value={criteriaValues.priorMedications}
              onChange={handleCriteriaValueChange('priorMedications')}
              placeholder="Prior Medications"
              style={{ marginLeft: '10px', flex: 1 }}
              disabled={!criteria.priorMedications}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogConfirm} color="primary">
            Confirm
          </Button>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SpecificClinicalTrialsMatchedPatients;
