import React from "react";
import { 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  Grid, 
  CardActionArea, 

} from "@mui/material";
import {  useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import HealingIcon from '@mui/icons-material/Healing';
import EventNoteIcon from '@mui/icons-material/EventNote';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import { SurgeryPlanning } from "../../components/DoctorComponents/SurgeryPlanningCreate";
import { ViewSurgeryPlans } from "../../components/DoctorComponents/SurgeryPlanningGrid";
import { Widget, addResponseMessage } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
export function DoctorServices() {
    const navigate = useNavigate();
    const doctorId = useOutletContext();

    const services = [
        { icon: HealingIcon, title: "Create A Surgery Plan", description: "Expert surgical planning and post-operative care." },
        { icon: HealingIcon, title: "View Surgery Plans", description: "Expert surgical planning and post-operative care." },
        { icon: CalendarMonthIcon, title: "Calendar", description: "Doctor Calendar" },
        { icon: MedicalServicesIcon, title: "Doctor Task Management System", description: "All Tasks of the Doctor" },
        { icon: EventNoteIcon, title: "Doctor Appointment List", description: "List of All Appointments of the Doctor" },
        // Add more services as needed
        { icon:  EventNoteIcon, title: "Some Other Planning", description: "The Description of other planning" },
    ];

    const [openSurgeryModal, setOpenSurgeryModal] = useState(false);
    const [viewSurgeryPlansModal, setViewSurgeryPlansModal] = useState(false);

    const handleCardClick = (title) => {
        // Placeholder for click handler logic
        if (title === "Create A Surgery Plan") {
            setOpenSurgeryModal(true);
        } else if (title === "View Surgery Plans"){
            setViewSurgeryPlansModal(true);
        } else if (title === "Calendar") {
            navigate('/calendar');
        } else if (title === "Doctor Task Management System") {
            navigate('/TasksList');
        } else if (title === "Doctor Appointment List") {
            navigate('/calendar/list');
        }
        console.log(`Clicked on ${title}`);
    };

    const handleNewUserMessage = async (newMessage) => {
        try {
          const requestData = {
            userObject: {
              userInput: {
                message: newMessage,
              },
            },
          };
          const url = `https://chatbot-2024-90539106da8b.herokuapp.com/appointment_doctor/${doctorId}`;
    
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
          });
            
          const reader = response.body.getReader();
          let partialText = '';
          let i = 1;
          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              break;
            }
            const newChunk = new TextDecoder('utf-8').decode(value);
            partialText += newChunk;
           
          }
    
          addResponseMessage(partialText);
        } catch (error) {
          console.error('Error fetching response from API:', error);
          addResponseMessage("Sorry, something went wrong. Please try again later.");
        }
      };

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Doctor Planning
            </Typography>
            <Grid container spacing={3}>
                {services.map((service, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card 
                          sx={{ 
                            '&:hover': {
                              boxShadow: 6, // Change the shadow when hovering
                            }
                          }}
                        >
                            <CardActionArea onClick={() => handleCardClick(service.title)}>
                                <CardContent>
                                    <service.icon fontSize="large" />
                                    <Typography variant="h6" sx={{ mt: 1 }}>
                                        {service.title}
                                    </Typography>
                                    <Typography variant="body1">
                                        {service.description}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            
            {/* SurgeryPlanning Modal */}
            <SurgeryPlanning open={openSurgeryModal} onClose={() => setOpenSurgeryModal(false)} doctorId={doctorId} />
            <ViewSurgeryPlans open={viewSurgeryPlansModal} onClose={() => setViewSurgeryPlansModal(false)} doctorId={doctorId} />
            <Widget
        handleNewUserMessage={handleNewUserMessage}
      />
        </Box>
    );
}
