import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    responsiveFontSizes,
} from "@mui/material";
const tableColumn = {
    age: "age",
    sex: "sex",
    chest_pain_type: "chest_pain_type",
    resting_bps: "resting_bps",
    cholesterol: "cholesterol",
    fasting_blood_sugar: "fasting_blood_sugar",
    rest_ecg: "rest_ecg",
    max_heart_rate: "max_heart_rate",
    exercise_angina: "exercise_angina",
    oldpeak: "oldpeak",
    ST_slope: "ST_slope",
};

//const API_ROOT = `https://e-react-node-backend-22ed6864d5f3.herokuapp.com`;  //TODO: Need to replace with the backend server address
const API_ROOT = `https://e-react-node-backend-22ed6864d5f3.herokuapp.com`;  //TODO: Need to replace with the backend server address

const HEART_FAIL_API_ENDPOINT = `https://coronary-artery-api-49aa6ea2288c.herokuapp.com/coronary_artery_prediction`;

const CoronaryArteryPrediction = () => {
    const location = useLocation();
    const patientId = location.state.id;

    const [predictionData, setPredictionData] = useState([]);
    const [heartFail, setHeartFail] = useState();

    const predictHeartFail = async () => {
        var p;

        const predictionResponse = await axios.post(HEART_FAIL_API_ENDPOINT, {


            "Typical_Chest_Pain": predictionData[0].Typical_Chest_Pain,

            "Region_RWMA": predictionData[0].Region_RWMA,

            "Current_Smoker": predictionData[0].Current_Smoker,

            "Age": predictionData[0].Age,

            "Length": predictionData[0].Length,

            "EF_TTE": predictionData[0].EF_TTE,

            "ESR": predictionData[0].ESR,

            "BMI": predictionData[0].BMI,
            "K": predictionData[0].K



        });
        console.log(predictionResponse)
        setHeartFail(predictionResponse.data);
    }

    useEffect(() => {
        (async () => {//https://e-react-node-backend-22ed6864d5f3.herokuapp.com
            //const strokeDataResponse = await axios.get(`${API_ROOT}/getCoronaryArteryDisease/${patientId}`);
            const strokeDataResponse = await axios.post("https://e-react-node-backend-22ed6864d5f3.herokuapp.com/getCoronaryArteryPrediction", {
                patientId
            });
            console.log(strokeDataResponse)
            setPredictionData(strokeDataResponse.data);
        })();
    }, []);

    const displayTableHead = () => {
        return Object.keys(tableColumn).map((columnKey) => {
            const columnValue = tableColumn?.[columnKey];
            return <th>{columnValue}</th>
        });
    };

    const renderTableData = () => {
        return Object.keys(tableColumn).map((columnKey) => {
            if (predictionData?.[columnKey] === undefined) {
                return;
            }
            return <td>{predictionData?.[columnKey]}</td>
        });
    };

    return (
        <React.Fragment>
            <br /><h2>Coronary Artery Prediction</h2><br />
            <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
                <h4 style={{ clear: "both" }}><br />Prediction Result: {heartFail}</h4>
                <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>    <button onClick={predictHeartFail}>Diagnose</button></div>
            </div>
            <TableContainer
                component={Paper}
                sx={{ minWidth: 550, minHeight: 400 }}
            >
                <Table
                    sx={{ minWidth: 1250, minHeight: 400 }}
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>id</TableCell>
                            <TableCell align="right">
                                patient_id
                                </TableCell>
                            <TableCell align="right">
                                Typical_Chest_Pain
                            </TableCell>
                            <TableCell align="right">
                                Region_RWMA
                                </TableCell>
                            <TableCell align="right">
                                Current_Smoker
                                </TableCell>
                            <TableCell align="right">
                                Age
                                </TableCell>
                            <TableCell align="right">
                                Length
                                </TableCell>
                            <TableCell align="right">
                                EF_TTE
                                </TableCell>
                            <TableCell align="right">ESR</TableCell>
                            <TableCell align="right">
                                BMI
                                </TableCell>
                            <TableCell align="right">K</TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {predictionData.map((prescription) => (
                            <TableRow
                                key={prescription.id}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {prescription.id}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.patient_id}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.Typical_Chest_Pain}
                                </TableCell>

                                <TableCell align="right">
                                    {prescription.Region_RWMA}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.Current_Smoker}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.Age}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.Length}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.EF_TTE}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.ESR}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.BMI}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.K}
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
};

export default CoronaryArteryPrediction;
