import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";

function DiabetesPrognosis() {
  const [showDiagnose, setShowDiagnose] = useState(false);
  const [dataDisplay, setDataDisplay] = useState([]);
  const location = useLocation();
  const [prediction, setPrediction] = useState("");
  const patient_id = location.state?.id;
  const BASE_URL = "https://e-react-node-backend-22ed6864d5f3.herokuapp.com"; // Update with your node backend app URL
  //   const BASE_URL = "https://e-react-node-backend-22ed6864d5f3.herokuapp.com"; // Update with your node backend app URL

  useEffect(() => {
    console.log("useEffect", dataDisplay);
  }, [dataDisplay]);

  useEffect(() => {
    const getSclerosisDiseaseData = async () => {
      try {
        console.log("patient found ", patient_id);
        const response = await axios.post(
          `${BASE_URL}/getDiabetesPrognosisData`,
          {
            patient_id,
          }
        );
        console.log("response", response.data[0]);
        if (response.data && response.data.length == 1) {
          const { id, patient_id, Diabetes_012, ...rest } = response.data[0];
          console.log("rest", rest);
          setDataDisplay(rest);
        } else {
          alert("Test Data Not Found");
        }
      } catch (error) {
        alert(`Error: ${error.message}`);
      }
    };

    getSclerosisDiseaseData();
  }, [patient_id]);

  const predict = async () => {
    dataDisplay.Gender = dataDisplay.Gender === "Female" ? 0 : 1;
    let symptom_values = Object.values(dataDisplay);
    const input = {
      input: symptom_values,
    };
    console.log("input", input);
    try {
      const response = await axios.post(
        "https://prognosis2024-19a0e0b41a1f.herokuapp.com/predict",
        input,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setPrediction(response.data);
      setShowDiagnose(true);
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <>
      <div className="bc-container">
        <h2 className="title"> Diabetes Prognosis Results</h2>
        <div className="diagnosis-result">
          <strong>Diagnosis:</strong>
          {showDiagnose ? `${prediction}` : ""}
        </div>

        <table className="results-tablea">
          <thead>
            <tr>
              <th>Parameters</th>
              <th>Values</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(dataDisplay).map(([key, value], index) => (
              <tr key={index}>
                <td>{key}</td>
                <td>{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <center>
          <button onClick={() => predict()}>Diagnose</button>
          <br />
          <br />
          <br />
        </center>
        <br />
      </div>
    </>
  );
}

export default DiabetesPrognosis;
