import {Layout, Button, Menu, Drawer, ConfigProvider} from "antd"
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from "@mui/icons-material/Logout";
import "./TopNavMobile.css"
import noTextLogo from "../../assets/images/noTextLogo.svg";

const TopNavMobile = ({ menuItems, onLogOut }) => {
    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState(false)
    const handleClick = ({ key }) => {
      const action = menuItems.find((item) => item.key === key).action;
      if (typeof action === "function") {
        action();
      } else if (typeof action === "string") {
        navigate(action);
      }
    };

  return (
    <div
      className="top-nav-mobile-main"
    >
        <ConfigProvider
        theme={{
          components: {
            Menu: {
              activeBarBorderWidth: "0",
              itemColor: "#6F718F",
              itemSelectedBg: "#0E45B7",
              itemSelectedColor: "#FFF",
            },
          },
        }}
      >
        <nav className="top-nav-mobile-bar">
          <MenuIcon
            sx={{ color: "common.white" }}
            onClick={()=>setOpenDrawer(true)}/>
          <div className="top-nav-mobile-logo-container">
            <img className="top-nav-mobile-logo" src={noTextLogo} alt="logo" />
            <h4>eHospital</h4>
          </div>
          <LogoutIcon 
            sx={{ color: "common.white" }}
            onClick={onLogOut}/>
        </nav>
        <Drawer
          placement="top"
          closable={true}
          onClose={()=>setOpenDrawer(false)}
          open={openDrawer}
        >
        
          <Menu
            mode="inline"
            onClick={handleClick}
          >
            {menuItems.map((item) => (
              <Menu.Item key={item.key}>
                {item.label}
              </Menu.Item>
            ))}
          </Menu>
        
        </Drawer>
      </ConfigProvider>

        
    </div>
  )
}

export default TopNavMobile