import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  CardContent,
  Typography,
  Box,
  Divider,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  Modal
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MedicationLiquidTwoToneIcon from "@mui/icons-material/MedicationLiquidTwoTone";

function AITreatmentPopup({ open, onClose, patientId, doctorId, patientName }) {
  const [diagnosisDetail, setDiagnosisDetail] = useState({
    diagnosis: ''
  });
  const [intelligentTreatment, setIntelligentTreatment] = useState('');
  const [diagnosisHistory, setDiagnosisHistory] = useState([]);
  const [treatments, setTreatments] = React.useState([]);
  const [showAddTreatmentModal, setShowAddTreatmentModal] = useState(false);
  const [treatmentDetails, setTreatmentDetails] = useState({
    treatment: '',
    date: '',
    diseaseType: '',
    diseaseId: ''
  });

  useEffect(() => {
    if (open) {
      setDiagnosisDetail({ diagnosis: '' });
      setIntelligentTreatment('');
      fetchDiagnosisHistory();
      fetchTreatments();
    }
  }, [open]);

  const formatDateForDisplay = (isoDateString) => {
    const [datePart] = isoDateString.split("T");
    const [year, month, day] = datePart.split("-").map(Number);
    const date = new Date(year, month - 1, day);
    return date.toLocaleDateString();
  };

  const fetchDiagnosisHistory = async () => {
    const diagnosisData = [
      { specialty: "Migraine", date: "2023/3/11" },
      { specialty: "Coronary Artery Disease", date: "2023/2/11" },
      { specialty: "Skin Cancer", date: "2023/3/15" },
      { specialty: "Thyroid", date: "2023/4/20" },
    ];
    //API is not implemented. Component DiagonsisList is currently mocking data.
    setDiagnosisHistory(diagnosisData);
  };

  const saveTreatmentPlan = async () => {
    //save logic pending
  };

  const getIntelligentTreatment = async () => {
    if (diagnosisDetail.diagnosis !== '') {
      try {
        console.log(patientId);
        console.log(diagnosisDetail.diagnosis);
        const response = await axios.post(`https://chatbot-2024-90539106da8b.herokuapp.com/intelligent-clinical-decision-support/${patientId}/${diagnosisDetail.diagnosis}`);
        console.log(response.data);
        setIntelligentTreatment(response.data);
      } catch (error) {
        console.error('Error getting intelligent treatment:', error);
      }
    }
  };

  const fetchTreatments = async () => {
    try {
      const response = await axios.post(
        "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/patientOverview",
        { patientId }
      );
      if (response.data && response.data.treatments) {
        setTreatments(response.data.treatments);
        console.log("Treatments:", treatments);
      }
    } catch (error) {
      console.error("Error fetching treatments:", error);
    }
  };

  const handleDiagnosisInputChange = (e) => {
    setDiagnosisDetail({ ...diagnosisDetail, diagnosis: e.target.value });
    setIntelligentTreatment(''); 
  };

  const handleDiagnosisHistoryChange = (e) => {
    setDiagnosisDetail({ ...diagnosisDetail, diagnosis: e.target.value });
    setIntelligentTreatment('');
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleTreatmentChange = (event) => {
    const { name, value } = event.target;
    setTreatmentDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const saveTreatment = async () => {
    if (treatmentDetails.treatment !== "") {
      try {
        const response = await axios.post(
          "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/saveTreatment/",
          {
            ...treatmentDetails,/// user input
            patientId,
            doctorId,
          }
        );
        console.log(response.data);
        // Refetch the treatments data
        fetchTreatments();
        setShowAddTreatmentModal(false);
      } catch (error) {
        console.error("Error saving treatment:", error);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        AI Treatment Plan
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6" gutterBottom align="left">{patientName}</Typography>
        <Grid container spacing={2}>
          {/* Section 1 */}
          <Grid item xs={12} md={6}>
            <Box border={1} borderColor="grey.300" p={2} height="100%">
              <TextField
                label="Diagnosis"
                name="diagnosis"
                value={diagnosisDetail.diagnosis}
                onChange={handleDiagnosisInputChange}
                fullWidth
                multiline
                rows={3}
                variant='standard'
                sx={{ mt: 2 }}
              />
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel>Diagnosis History</InputLabel>
                <Select
                  value={diagnosisDetail.diagnosis}
                  label="Diagnosis History"
                  onChange={handleDiagnosisHistoryChange}
                >
                  {diagnosisHistory.map((history, index) => (
                    <MenuItem key={index} value={history.specialty}>{history.specialty} - {history.date}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          {/* Section 2 */}
          <Grid item xs={12} md={6}>
            <Box border={1} borderColor="grey.300" p={2} maxHeight={300} overflow="auto">
              <Typography variant="h6" gutterBottom align="left">Treatment Plan List</Typography>
              {treatments.length > 0 ? (
                <List style={{ maxHeight: 150, overflowY: "auto" }}>
                  {treatments.map((treatment, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <MedicationLiquidTwoToneIcon color="primary" />
                      </ListItemIcon>
                      <Typography variant="body1">
                        {treatment.treatment} -{" "}
                        {formatDateForDisplay(treatment.RecordDate)}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography variant="body1" sx={{ mt: 2 }}>
                  No Treatments Found...
                </Typography>
              )}
            </Box>
          </Grid>

          {/* Section 3 */}
          <Grid item xs={12}>
            <Box border={1} borderColor="grey.300" p={2}>
              <Typography variant="h6" gutterBottom align="left">AI Treatment Plan</Typography>
              {/* Explicitly setting list style to ensure bullet points are shown */}
              <Box component="ul" sx={{ listStyleType: 'disc', marginLeft: 3, padding: 0 }}>
                {intelligentTreatment ? (
                  intelligentTreatment.split('\n').map((line, index) => (
                    <Box component="li" key={index}>
                      <Typography align="left">{line}</Typography>
                    </Box>
                  ))
                ) : (
                  // If intelligentTreatment is not set, display a message or nothing
                  <Typography align="left">No intelligent treatment plan available</Typography>
                )}
              </Box>
              <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                {intelligentTreatment && (
                  <Button
                    onClick={() => copyToClipboard(intelligentTreatment)}
                    variant="outlined"
                    color="primary"
                  >
                    Copy Treatment Plan
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Box width="100%" display="flex" justifyContent="center">
          {intelligentTreatment ? (
            <Button
            onClick={() => setShowAddTreatmentModal(true)}
            variant="contained"
            color="primary"
          >
            Add Treatment Plan
          </Button>
          ) : (
            <Button onClick={getIntelligentTreatment} variant="contained" color="primary">
              Get AI Treatment Plan
            </Button>
          )}
        </Box>
      </DialogActions>

      {/* Add Treatment Modal */}
      <Modal
        open={showAddTreatmentModal}
        onClose={() => setShowAddTreatmentModal(false)}
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Add Treatment Plan
          </Typography>
          <TextField
            label="Treatment"
            name="treatment"
            value={treatmentDetails.treatment}
            onChange={handleTreatmentChange}
            fullWidth
            multiline
            rows={3}
            variant="standard"
            sx={{ mt: 2 }}
          />
          <TextField
            label="Date of Treatment"
            name="date"
            type="date"
            value={treatmentDetails.date}
            onChange={handleTreatmentChange}
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Disease Type"
            name="diseaseType"
            value={treatmentDetails.diseaseType}
            onChange={handleTreatmentChange}
            fullWidth
            multiline
            variant="standard"
            sx={{ mt: 2 }}
          />
          <TextField
            label="Disease ID"
            name="diseaseId"
            value={treatmentDetails.diseaseId}
            onChange={handleTreatmentChange}
            fullWidth
            multiline
            variant="standard"
            sx={{ mt: 2 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={saveTreatment}
              sx={{ mr: 2 }}
            >
              Save Treatment
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setShowAddTreatmentModal(false)}
            >
              Exit
            </Button>
          </Box>
        </Box>
      </Modal>
    </Dialog>
  );
}

export default AITreatmentPopup;
