import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ForumIcon from "@mui/icons-material/Forum";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LogoutIcon from "@mui/icons-material/Logout";

import Sidebar from "./SideBar";

const AdminSideBar = (props) => {
  const navigate = useNavigate();

  const getItem = (label, key, icon, action, children, type) => ({
    key,
    icon,
    children,
    label,
    type,
    action,
  });

  const menuItems= useMemo(
    () => [
      getItem("Dashboard", "Dashboard", <HomeIcon />, "/Admin/dashboard"),
      getItem("Profile", "Profile", <AccountCircleIcon />, "/Admin/profile"),
      getItem("Messages", "Messages", <ForumIcon />, "/Admin/messages"),
      getItem("Management", "Management", <CalendarMonthIcon />, "/Admin/management"),
      getItem("Help", "Help", <FavoriteIcon />, "/Admin/help"),
      getItem("Log out", "Logout", <LogoutIcon />, () => {
        props.clearUser();
        navigate("/LogIn");
      }),
    ],
    [navigate, props]
  );

  return <Sidebar menuItems={menuItems} />;
};

export default AdminSideBar;
