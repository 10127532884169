import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ImageModal = ({ images, showModal, handleCloseModal, handleImageClick, handleApiSubmit, selectedImage }) => {
  const [imageObjects, setImageObjects] = useState(['../photo/00000001_002.png']);
  useEffect(() => {
    const srcs = images.map((image) => {
      const byteCharacters = atob(image.file.buffer);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: image.file.mimetype });
      return {
        imageUrl: URL.createObjectURL(blob),
        imageId: image._id
      };
    });
    setImageObjects(srcs);


    return () => {
      srcs.forEach((src) => {
        URL.revokeObjectURL(src.inageUrl);
      });
    };
  }, [images]);


  return (
      <Modal show={showModal} onHide={handleCloseModal}>
        <span className="close" onClick={handleCloseModal}>&times;</span>
        <Modal.Header>
          <Modal.Title>Patient X-Ray Images</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div className="image-gallery" style={{
           margin:'0 auto'
          }}>
            {imageObjects.map((image, index) => (
                <img
                    key={index}
                    src={image.imageUrl}
                    alt={`Gallery image ${index + 1}`}
                    onClick={() => handleImageClick(image)}
                    style={{
                      border: selectedImage === image ? '2px solid blue' : '2px solid transparent',
                      cursor: 'pointer',
                      margin: '0 auto',
                      width: '200px',
                      height: '200px',
                    }}
                />
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{
            width: '200px',
            flex: 'wrap',
            margin: '20px',
            color: 'white',
            backgroundColor: '#1A4FBA',
            border: 'none',
            fontFamily: 'Poppins'
          }} variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button style={{
            width: '200px',
            flex: 'wrap',
            margin: '50px',
            color: 'white',
            backgroundColor: '#1A4FBA',
            border: 'none',
            fontFamily: 'Poppins'
          }} variant="primary" onClick={handleApiSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
  );
};

export default ImageModal;