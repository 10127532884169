import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, Typography, CardContent } from '@mui/material';
import { getPharmaceuticals_ActionsStatus,getPharmaceuticals_DashboardSummary,getPharmaceuticals_PatientSource } from '../../../api/user';
import { readLoginData } from '../../../loginData';
import { saveCurrentPath } from '../LocalNavigate';
import { WelcomeRootStyle, Carousel,DashboardSummary,DashboardTrialsComposition } from './DashUtils';
import Pharmaceuticals_ClinicalTrialTable from "./Pharmaceuticals_ClinicalTrialTable";

const DashboardActionsContent = ({ data, navigate }) => {
  const textStyles = [
    { backgroundColor: '#FFFFFF', color: '#1a4fba' },
    { backgroundColor: '#1a4fba', color: '#FFFFFF' }
  ];

  const handleDashboardActionsClick = () => {
    let selectedTab = 0; // 默认选项卡为Overall
    if (data.name.includes('Audit')) {
      selectedTab = 1;
    } else if (data.name.includes('Invite')) {
      selectedTab = 2;
    } else if (data.name.includes('Apply')) {
      selectedTab = 3;
    }
    navigate(`/pharmaceuticals/management`, { state: { name: data.name.replace(/Actions/g, ''), tabIndex: selectedTab } });
  };

  return (
    <Box
      sx={{ padding: 2, borderRadius: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '100%', cursor: 'pointer' }}
      onClick={handleDashboardActionsClick}
    >
      <CardContent sx={{ textAlign: 'left' }}>
        <Typography
          variant="overline"
          component="div"
          sx={{ mb: 1, opacity: 0.7, ...textStyles[0] }}
        >
          {data.name} Actions
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          noWrap
          sx={{ ...textStyles[1] }}
        >
          You have a total of {data.total} {data.name.replace(/Actions/g, '')} actions
        </Typography>
        <Typography
          variant="body2"
          noWrap
          sx={{ ...textStyles[0] }}
        >
          {data.pending} pending, {data.completed} completed
        </Typography>
      </CardContent>
    </Box>
  );
};


const DashboardWelcome = ({ companyName }) => {
  return (
    <Grid item xs={12} md={8}>
      <WelcomeRootStyle>
        <CardContent sx={{ p: { md: 0 }, pl: { md: 5 } }}>
          <Typography gutterBottom fontSize="1.9rem" mt={3}>
            Hello 👋 ,{companyName}.<br />Welcome back.
          </Typography>
          <Typography mt={1} fontSize="1.5rem" sx={{ pb: { xs: 3, xl: 5 }, maxWidth: 450, mx: 'auto' }}>
            Have a good day at work.
          </Typography>
        </CardContent>
      </WelcomeRootStyle>
    </Grid>
  );
};

const PharmaceuticalsDashboard = () => {
  const companyInfo = readLoginData();
  const [currentActionsIndex, setCurrentActionsIndex] = useState(0);
  const [actionsData, setActionsData] = useState([]);
  const [dashboardSummary, setDashboardSummary] = useState([]);
  const [patientSource, setpPatientSource] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  saveCurrentPath();

  const handleNextTypeActions = () => {
    setCurrentActionsIndex((prevIndex) => (prevIndex + 1) % actionsData.length);
  };

  const handlePreviousTypeActions = () => {
    setCurrentActionsIndex((prevIndex) => (prevIndex - 1 + actionsData.length) % actionsData.length);
  };

  const handleActionsDotClick = (index) => {
    setCurrentActionsIndex(index);
  };
  const handleSummaryClick = (taskNavigate) => {
    navigate(taskNavigate);
  };
//获取行动信息
  const fetchActions = async () => {

    const processActionsData = (data) => {
      return Object.keys(data).map((key) => {
        const completed = data[key].find(action => action.IsCompleted === 1)?.count || 0;
        const pending = data[key].find(action => action.IsCompleted === 0)?.count || 0;
        return {
          name: key,
          completed,
          pending,
          total: completed + pending,
        };
      });
    };

    try {
      const data = await getPharmaceuticals_ActionsStatus(companyInfo.id);
      const processedData = processActionsData(data);
      setActionsData(processedData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching action status:', error);
      setIsLoading(false);
    }
  };
//获取总结信息
const fetchSummary = async () => {

  try {
    const DashboardSummary = await getPharmaceuticals_DashboardSummary(companyInfo.id);
    setDashboardSummary(DashboardSummary);
  } catch (error) {
    console.error('Error fetching action status:', error);
    setIsLoading(false);
  }
};
//获取病人来源信息
const fetchPatientSource = async () => {

  try {
    const PatientSource = await getPharmaceuticals_PatientSource(companyInfo.id);
    setpPatientSource(PatientSource);
  } catch (error) {
    console.error('Error fetching source of the patients:', error);
    setIsLoading(false);
  }
};

  useEffect(() => {
    fetchActions();
    fetchSummary();
    fetchPatientSource();
  }, []);

  useEffect(() => {
    if (!isLoading && actionsData.length > 0) {
      const interval = setInterval(handleNextTypeActions, 1500);//设置轮播时间为1.5秒
      return () => clearInterval(interval);
    }
  }, [isLoading, actionsData]);

  return (
    <Grid container spacing={3}>
      <DashboardWelcome companyName={companyInfo.name} />
      {!isLoading && actionsData.length > 0 && (
        <Grid item xs={12} md={4}>
          <Carousel
            currentIndex={currentActionsIndex}
            handleNext={handleNextTypeActions}
            handlePrevious={handlePreviousTypeActions}
            handleDotClick={handleActionsDotClick}
            totalSlides={actionsData.length}
          >
            <DashboardActionsContent data={actionsData[currentActionsIndex]} navigate={navigate} />
          </Carousel>
        </Grid>
      )}
      {dashboardSummary.length > 0 ? (
        dashboardSummary.map((summary, index) => (
          <DashboardSummary
            key={index}
            title={summary.title}
            percent={parseFloat(summary.percent)}
            total={summary.total}
            onClick={() => handleSummaryClick(`/pharmaceuticals/${summary.title.toLowerCase().replace(/\s+/g, '')}`)}
          />
        ))
      ) : (
        <Typography variant="h6" align="center" color="textSecondary" sx={{ width: '100%', mt: 3 }}>
          No data available
        </Typography>
      )}
       <DashboardTrialsComposition data={patientSource} />
       <Pharmaceuticals_ClinicalTrialTable companyId={companyInfo.id} />

    </Grid>
  );
};

export default PharmaceuticalsDashboard;
