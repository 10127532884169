import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { Button, Card, Col, Divider, Flex, Modal, Row, Tag } from 'antd';

import { readLoginData } from '../../loginData';
import { patientGetCalendar } from '../../api/calendar';

import PlanningCalendarToolbar from '../../components/CalendarComponents/PlanningCalendarToolbar';
import PlanningCalendarView, { calcRange } from '../../components/CalendarComponents/PlanningCalendarView';
import PatientBookTimeDialog from './PatientBookTimeDialog';
import PageLayout from "./PageLayout";

import 'react-big-calendar/lib/css/react-big-calendar.css';
import "./PatientCalendarPage.css";

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const getAppointmentState = (event) => {
  if(event.status < 0){
    if(event.appointmentStatus === 1){
      return 'Approved'
    }else{
      return 'Declined'
    }
  }else if(event.status === 0){
    return 'Free'
  }else if(event.status > 0){
    if(event.appointmentStatus === 0){
      return 'PendingApproval'
    }else{
      return 'BookedByOthers'
    }
  }
};

const getCategoryString = ({ category }) => {
  if(category === 1){ return 'Task' }
  else if(category === 2){ return 'Available Slot' }
  else if(category === 3){ return 'Surgery' }
  else if(category === 4){ return 'General Consultation' }
  else if(category === 5){ return 'Lab Testing' }
}

const PatientCalendar = (props) => {
  const loginData = readLoginData();
  let [ needLoad, setNeedLoad ] = useState(true);
  let [ loading, setLoading] = useState(true);
  let [ data, setData ] = useState([]);

  let [ date, setDate ] = useState(moment().toDate());
  let [ view, setView ] = useState('week');

  let [ dialogOpen, setDialogOpen ] = useState(false);
  let [ dialogContent, setDialogContent ] = useState(null);

  if(needLoad){
    setNeedLoad(false);
    (async () => {
      setLoading(true);
      setData([]);
      const range = calcRange(date, view);
      const response = await patientGetCalendar(loginData, range[0], range[1]);
      setData(response.filter((record) => record.category !== 1 ));
      setLoading(false);
    })();
  }

  const closeDialog = () => {
    setDialogOpen(false);
    setDialogContent(null);
  }

  const handleOk = () => {
    closeDialog();
    setNeedLoad(true);
  }

  const handleBookTime = (event) => {
    console.log(event);
    setDialogContent({
      id: event.id,
      doctor: event.doctor.name,
      category: event.category,
      start: event.start,
      end: event.end,
      statement: event.description,
      description: loginData.name,
    });
    setDialogOpen(true);
  }

  const handleSelectEvent = (event) => {
    const data = event;
    console.log(data);
    if(data.status >= 0 && data.appointmentStatus === null){
      handleBookTime(data);
    }else{
      const startString = moment(data.start).format(dateFormat);
      const endString = moment(data.end).format(dateFormat);

      Modal.info({
        title: `${getCategoryString(data)} of ${data.doctor.name}`,
        content: <>
          Category: {getCategoryString(data)}<br/>
          From: {startString}<br/>
          To: {endString}<br/>
          Status: {getAppointmentState(data)}<br/>
          Doctor Description: {data.description}<br/>
          { (data.appointmentStatus!==null) ? (<>Patient Description: {data.patientDescription}<br/></>) : null }
          </>,
      });
    }
  }

  return <>
    <PlanningCalendarToolbar
      date={date}
      view={view}
      hasCreateButton={false}

      onNavigate={(d) => { setDate(d); setNeedLoad(true); }}
      onView={(v) => { setView(v); setNeedLoad(true); }}
    />
    <Row justify="space-between" className='calendar-categories' gutter={10}>
      <Col span={4}><Tag color="#D9D9D9FF">Surgery</Tag></Col>
      <Col span={4}><Tag color="#FFA9004D">General Consultation</Tag></Col>
      <Col span={4}><Tag color="#3377DD4D">Lab Testing</Tag></Col>
      <Col span={4}><Tag color="#DD52334D">Request Declined</Tag></Col>
      <Col span={4}><Tag color="#40DC8263">Available Slot</Tag></Col>
      <Col span={4}><Tag color="#ADEDCD80" style={{ border: "1px dotted" }}>Pending Approval</Tag></Col>
    </Row>
    <Card
      bordered={false}
      style={{
        height: "95.8%",
        marginBottom: "4.2%",
        backgroundColor: "white",
        borderRadius: "1.5rem",
      }}
    >
      <PlanningCalendarView
        selectable={false}
        loading={loading}
        data={data}
        date={date}
        view={view}

        onSelectEvent={handleSelectEvent}
      />
      {dialogOpen ? <PatientBookTimeDialog {...dialogContent} onOk={handleOk} onCancel={closeDialog}/> : null}
    </Card>
  </>;
};

const PatientCalendarPage = (props) => {
  return (
    <>
      <PageLayout clearUser={props.clearUser} className="patient-calendar-page">
        <Flex
          justify="space-evenly"
          style={{ height: "100%", marginTop: "4rem" }}
        >
          <Flex vertical gap="1rem" style={{ display: "flex", width: "90%" }}>
            <PatientCalendar/>
          </Flex>
        </Flex>
      </PageLayout>
    </>
  );
};

export default PatientCalendarPage;
