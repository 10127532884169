import { Box, Card, CircularProgress, Grid, Modal } from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import EncounterButton from "./EncounterButton.jsx";
import HealthOverview from "./HealthOverviewComponent";
import { AppointmentList, BodyProperties, PatientName, ProfileButtons, ProfileProperties } from "./PatientDetailsComponents";

function PatientDetails(props) {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({});
  const [upcomingAppointmentData, setUpcomingAppointmentData] = useState({});
  const initialValue = dayjs();
  const patientId = props.patientId;
  const doctorId = props.doctorId;
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [profileLoading, setProfileLoading] = useState(true);
 const handleToggleEncounterModal = () => {
   setIsModalOpen(!isModalOpen);
 };
  const [showCreatePrescriptionModal, setShowCreatePrescriptionModal] =
    useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNewTab = (path) => {
    const url = window.location.origin + path;
    window.open(url, "_blank");
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
 

  const open = Boolean(anchorEl);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.post(
          "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/users/getPatientPortalInfoById",
          {
            patientId,
          }
        );
        const { data } = response;
        if (data.error) {
          alert(JSON.stringify(data.error));
          console.log("error ");
        } else {
          setProfileData(data);
        }
      } catch (error) {
        console.log(
          `Error With request getting auth  recent : ${error.message}`
        );
      } finally {
        setProfileLoading(false);
      }
    };
    getData();

    const startDate = initialValue.startOf("month");
    const endDate = initialValue.endOf("month");

    const getUpcomingAppointmentData = async () => {
      try {
        const response = await axios.post(
          "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/patientGetAppointments",
          {
            patientId,
            startDate,
            endDate,
          }
        );
        const { data } = response;
        if (data.error) {
          alert(JSON.stringify(data.error));
          console.log("error ");
        } else {
          if (data.result && Array.isArray(data.result)) {
            const sortedResult = data.result.sort((a, b) => {
              return new Date(a.start) - new Date(b.start);
            });
            setUpcomingAppointmentData({ ...data, result: sortedResult });
          } else {
            setUpcomingAppointmentData(data);
          }
        }
      } catch (error) {
        console.log(
          `Error With request getting auth  recent : ${error.message}`
        );
      } finally {
        setLoading(false);
      }
    };
    getUpcomingAppointmentData();
  }, []);

  const encounterModalStyle = {
    position: "fixed",
    width: "80%",
    bgcolor: "white",
    overflowY: "auto",
    minWidth: "800px",
    top: "20px",
    right: "40px",
    bottom: "20px",

    borderRadius: "5px",
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: `calc(100%)`,
        backgroundColor: "#ffffff",
        borderRadius: "2.25rem",
        margin: "0 0 0 0",
      }}
    >
      <Grid container>
        <Grid item xs={5}>
          <Card elevation={0} style={{ padding: "20px" }}>
            {profileLoading ? (
              <CircularProgress />
            ) : (
              <>
                <PatientName profileData={profileData} />
                <ProfileProperties profileData={profileData} />
                <BodyProperties profileData={profileData} />
                <ProfileButtons
                  toMessagePatient={() => navigate("/doctor/messages")}
                  toMedicalChatbot={() => navigate(`/Chatbot?patientID=${patientId}`)}
                  toVideoCall={() =>
                    handleOpenNewTab(
                      `/DoctorVideo?doctorID=${doctorId}&patientID=${patientId}`
                    )
                  }
                  toEncounter={handleToggleEncounterModal}
                />
                <Modal
                  open={isModalOpen}
                  onClose={handleToggleEncounterModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={encounterModalStyle}>
                    <EncounterButton
                      closeHandler={handleToggleEncounterModal}
                      patientId={patientId}
                      doctorId={doctorId}
                      profileData={profileData}
                      closeModal={() => setShowCreatePrescriptionModal(false)}
                    />
                  </Box>
                </Modal>
              </>
            )}
          </Card>
          <Card elevation={0} style={{ padding: "20px" }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <AppointmentList
                handleClick={handleClick}
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                upcomingAppointmentData={upcomingAppointmentData}
              />
            )}
          </Card>
        </Grid>
        <Grid item xs={7}>
          <Box
            elevation={3}
            style={{
              textAlign: "left",
              display: "flex",
              flexDirection: "row",
            }}
          ></Box>
          <Card elevation={0} style={{ padding: "20px" }}>
            <HealthOverview
              patientId={patientId}
              doctorId={doctorId}
              profileData={profileData}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PatientDetails;
