// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doctor-mini-calendar .ant-picker-calendar-header .ant-radio-group {
  display: none !important;
}

.doctor-mini-calendar table {
  margin: 0;
}

.doctor-mini-calendar th {
  background-color: initial;
  border: 0;
}

.doctor-mini-calendar tr {
  background: initial;
}

.doctor-mini-calendar td {
  padding: 0 !important;
  border: 0;
}

.doctor-mini-calendar-cell-date {
  border-radius: 50% !important;
}

.ant-picker-cell-selected .doctor-mini-calendar-cell-date {
  background-color: #1A4EBA !important;
}

.ant-picker-cell-today .doctor-mini-calendar-cell-date::before {
  border: 1px solid #1A4EBA !important;
}

.doctor-mini-calendar-cell-dot,
.doctor-mini-calendar-cell-nodot {
  margin: auto;
  height: 0.4rem;
  width: 0.4rem;

  border-radius: 100%;
}

.doctor-mini-calendar-cell-dot {
  background-color: #97A7DC;
}

.doctor-subtitle {
  color: #1A4EBA;
  font-size: 1.4rem;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/screens/DoctorNew/DoctorMiniCalendar.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,SAAS;AACX;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,SAAS;AACX;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;;EAEE,YAAY;EACZ,cAAc;EACd,aAAa;;EAEb,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".doctor-mini-calendar .ant-picker-calendar-header .ant-radio-group {\n  display: none !important;\n}\n\n.doctor-mini-calendar table {\n  margin: 0;\n}\n\n.doctor-mini-calendar th {\n  background-color: initial;\n  border: 0;\n}\n\n.doctor-mini-calendar tr {\n  background: initial;\n}\n\n.doctor-mini-calendar td {\n  padding: 0 !important;\n  border: 0;\n}\n\n.doctor-mini-calendar-cell-date {\n  border-radius: 50% !important;\n}\n\n.ant-picker-cell-selected .doctor-mini-calendar-cell-date {\n  background-color: #1A4EBA !important;\n}\n\n.ant-picker-cell-today .doctor-mini-calendar-cell-date::before {\n  border: 1px solid #1A4EBA !important;\n}\n\n.doctor-mini-calendar-cell-dot,\n.doctor-mini-calendar-cell-nodot {\n  margin: auto;\n  height: 0.4rem;\n  width: 0.4rem;\n\n  border-radius: 100%;\n}\n\n.doctor-mini-calendar-cell-dot {\n  background-color: #97A7DC;\n}\n\n.doctor-subtitle {\n  color: #1A4EBA;\n  font-size: 1.4rem;\n  font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
