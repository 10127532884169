import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";

const ECGChart = ({ start, data, close }) => {
  const [ecgData, setEcgData] = useState({
    labels: [],
    datasets: [
      {
        label: "ECG Data",
        data: [],
        borderColor: "#5AD8A6",
        lineTension: 0,
        pointRadius: 0,
        borderWidth: 0.8,
      },
    ],
  });
  const [isLoading, setIsLoading] = useState(true);
  const options = {
    animation: false,
    scales: {
      x: {
        type: "linear",
        beginAtZero: true,
        grid: {
          display: true, // 显示网格线
          color: "rgba(0, 0, 0, 0.1)", // 网格线的颜色
          lineWidth: 1, // 网格线的宽度
        },
        ticks: {
          display: false,
          autoSkip: true,
          maxTicksLimit: 50, // 增加X轴的刻度数量，使网格线更密集
        },
      },
      y: {
        type: "linear",
        min: 20000,
        max: 60000,
        grid: {
          display: true, // 同样显示网格线
          color: "rgba(0, 0, 0, 0.1)", // 同样设置网格线的颜色
          lineWidth: 1, // 同样设置网格线的宽度
        },
        ticks: {
          display: false,
          autoSkip: true,
          maxTicksLimit: 100, // 增加Y轴的刻度数量，使网格线更密集
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  // https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/users/getRaspberryData
  useEffect(() => {
    if (data && data.ecg && data.ecg.length > 0) {
      console.log("ECG Data", data.ecg);
      const ecgDataArray = data?.ecg;
      setEcgData((currentData) => {
        // 新接收的数据添加到当前数据集
        let newData = [...currentData.datasets[0].data, ...ecgDataArray];

        // 保证newData长度不超过200个数据点
        if (newData.length > 200) {
          newData = newData.slice(-200);
        }

        // 更新标签，使其在0到99之间均匀分布
        let newLabels = Array.from(
          { length: newData.length },
          (_, index) => index
        );

        // 返回更新后的状态
        return {
          labels: newLabels,
          datasets: [
            {
              ...currentData.datasets[0],
              data: newData,
              label: "ECG Data",
              borderColor: "#5AD8A6",
              lineTension: 0,
              pointRadius: 0,
              borderWidth: 0.8,
            },
          ],
        };
      });
    }
  }, [data]);

  useEffect(() => {
    return () => {
      clearChartData();
      console.log("ECG Chart cleared");
    };
  }, []);

  function clearChartData() {
    setEcgData({
      labels: [],
      datasets: [{ data: [] }],
    });
  }

  return (
    <Line
      data={ecgData}
      options={options}
      style={{ height: "100%", width: "100%" }}
    />
  );
};

export default ECGChart;
