import React from 'react';
//import axios from 'axios';
import { Link } from 'react-router-dom';


export default function DownloadUploadForm() {
  const handleFormDownload = () => {
    const formLink = 'https://e-react-node-backend-22ed6864d5f3.herokuapp.com/download-form'; // Update with the actual path to the PDF on your server
    window.open(formLink, '_blank');
  };

/*  const handleFormUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    
    // Update with your API endpoint
    const response = await axios.post('/api/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    
    // Handle the response from the server
    console.log(response.data);
  };
*/
  return (
    <div>
     <Link onClick={handleFormDownload}> download a 4422-84 form</Link>
     
      {/* 
        <Button variant="contained" LinkComponent={Link} color="primary" onClick={handleFormDownload}>
        Download Lab Request Form
      </Button>
        <input
        type="file"
        onChange={handleFormUpload}
        accept="application/pdf"
        style={{ display: 'block', marginTop: '20px' }}
      />
      */}
    </div>

  );
}
