import React, { useState } from 'react';
import { useSpeechSynthesis } from 'react-speech-kit';

const ScreenReader =({ contentRef }) => {
    const { speak, cancel, speaking } = useSpeechSynthesis();
    const [isPlaying, setIsPlaying] = useState(false);

    const handleToggleSpeech = () => {
        if (isPlaying) {
            cancel();
        } else {
            const text = contentRef.current.innerText;
            speak({ text });
        }
        setIsPlaying(!isPlaying);
    };


    return (
        <div>
            <button
                onClick={handleToggleSpeech}
                style={{
                    position: 'fixed',
                    top: '150px',
                    right: '20px',
                    backgroundColor: '#314cc9',
                    color: 'white',
                    borderRadius: '50%',
                    width: '60px',
                    height: '60px',
                    border: 'none',
                    fontSize: '24px'
                }}
                aria-label="Open settings"
                tabIndex="0"
            >
                {isPlaying ? '🔇' : '🔊'}
            </button>
        </div>
    );
};

export default ScreenReader;