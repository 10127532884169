import React from "react";
import doctor_img from "../styles/components/LandingPageStyles/doctor_func.jpg";
import patient_img from "../styles/components/LandingPageStyles/patient_func.jpg";
import client_img from "../styles/components/LandingPageStyles/client_func.jpg";
import bg_img from "../styles/components/LandingPageStyles/people.png";

const LandingIntro = () => {
  return (
    <div>
      <div
        id="section1"
        style={{
          position: "relative",
          width: "100vw",
          height: "100vh",
          backgroundColor: "#F7F7F8",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        <div
          style={{
            width: "40%",
            height: "30%",
            left: "8%",
            top: "30%",
            position: "absolute",
            fontFamily: " sans-serif",
            fontSize: "2.2vw",
            margin: "0 auto",
            textAlign: "left",
            lineHeight: "1.3",
          }}
        >
          <p>Doctor Services </p>
          <div style={{ height: "20px" }}></div>
          <p style={{ fontSize: "1.5vw" }}>
            A centralized healthcare platform streamlining patient care by
            offering virtual consultations, efficient record management, and
            secure communication to enhance accessibility and efficiency in
            comprehensive healthcare delivery.
          </p>
        </div>
        <img
          src={bg_img}
          style={{
            left: "55%",
            width: "35%",
            height: "60%",
            position: "absolute",
            justifyContent: "right",
            top: "20%",
          }}
        ></img>
      </div>

      <div
        style={{
          position: "relative",
          backgroundImage: `url(${doctor_img})`,
          margin: "0 auto",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "56vw",
          width: "100vw",
        }}
      >
        <button
          onClick={() => {
            window.location.href = "JoinUs";
          }}
          style={{
            position: "absolute",
            zIndex: "5",
            top: "47%",
            right: "5%",
            borderRadius: "36px",
            fontSize: "2vw",
            backgroundColor: "#004AB3",
            color: "white",
            cursor: "pointer",
          }}
        >
          Join {">>"}
        </button>
      </div>

      <div
        id="section2"
        style={{
          position: "relative",
          width: "100vw",
          height: "100vh",
          backgroundColor: "#F7F7F8",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        <div
          style={{
            width: "40%",
            height: "30%",
            left: "55%",
            top: "30%",
            position: "absolute",
            fontFamily: " sans-serif",
            fontSize: "2.2vw",
            margin: "0 auto",
            textAlign: "left",
            lineHeight: "1.3",
          }}
        >
          <p>Patient Services </p>
          <div style={{ height: "20px" }}></div>
          <p style={{ fontSize: "1.5vw" }}>
            Access medical records, schedule virtual appointments, and
            communicate with healthcare providers. Enjoy personalized,
            convenient, and secure healthcare services from the comfort of your
            home, promoting active participation in your well-being. 
          </p>
        </div>
        <img
          src={bg_img}
          style={{
            left: "10%",
            width: "35%",
            height: "60%",
            position: "absolute",
            justifyContent: "right",
            top: "20%",
          }}
        ></img>
      </div>

      <div
        style={{
          position: "relative",
          backgroundImage: `url(${patient_img})`,
          margin: "0 auto",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "56vw",
          width: "100vw",
        }}
      >
        <button
          onClick={() => {
            window.location.href = "JoinUs";
          }}
          style={{
            position: "absolute",
            zIndex: "5",
            top: "47%",
            right: "5%",
            borderRadius: "36px",
            fontSize: "2vw",
            backgroundColor: "#004AB3",
            color: "white",
            cursor: "pointer",
          }}
        >
          Join {">>"}
        </button>
      </div>

      <div
        id="section3"
        style={{
          position: "relative",
          width: "100vw",
          height: "100vh",
          backgroundColor: "#F7F7F8",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        <div
          style={{
            width: "40%",
            height: "30%",
            left: "55%",
            top: "30%",
            position: "absolute",
            fontFamily: " sans-serif",
            fontSize: "2.2vw",
            margin: "0 auto",
            textAlign: "left",
            lineHeight: "1.3",
          }}
        >
          <p>Clinic Services</p>
          <div style={{ height: "20px" }}></div>
          <p style={{ fontSize: "1.5vw" }}>
            We also offer clinics a work platform to facilitate better
            communication with doctors and patients, allowing for management and
            data observation.The platform provides a comprehensive healthcare
            management suite with the four main features.
          </p>
        </div>
        <img
          src={client_img}
          style={{
            left: "5%",
            width: "47%",
            height: "80%",
            top: "5%",
            position: "absolute",
            justifyContent: "right",
          }}
        ></img>
      </div>
    </div>
  );
};

export default LandingIntro;
