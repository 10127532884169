import axios from "axios";

import { BASE_URL } from "../constants";

export const clinicalStaffGetTasks = async (loginData, start, end) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/clinicalStaff/getTasks`,
      {
        loginData,
        start,
        end,
      }
    );
    console.log("clinicalStaffGetTasks", response.data);
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const clinicalStaffGetMiniCalendar = async (
  loginData,
  start,
  end,
  timezone
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/clinicalStaff/getMiniCalendar`,
      {
        loginData,
        start: start.toISOString(),
        end: end.toISOString(),
        timezone,
      }
    );

    if (response.data.status !== "OK") {
      throw new Error(response.data.status);
    }
    console.log(response.data);
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const clinicalStaffAddTask = async (
  loginData,
  start,
  end,
  description,
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/clinicalStaff/addTask`,
      {
        loginData,
        start: start.toISOString(),
        end: end.toISOString(),
        description,
      }
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const clinicalStaffChangeTask = async (
  loginData,
  id,
  status,
) => {
  try {
    await axios.post(
      `${BASE_URL}/api/clinicalStaff/changeTask`,
      {
        loginData,
        id,
        status,
      }
    );
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const clinicalStaffGetDoctorCalendar = async (loginData, doctor, start, end) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/appointments/clinicalStaffGetDoctorCalendar`,
      {
        loginData,
        doctor,
        start: start.toISOString(),
        end: end.toISOString(),
      }
    );
    console.log("clinicalStaffGetDoctorCalendar", response.data);
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
