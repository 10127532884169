import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card } from "antd";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  styled
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { readLoginData } from '../../../loginData';
import { ClinicalTrialsStatus } from '../Dashboard/DashUtils';
import { saveCurrentPath } from '../LocalNavigate';
import { getPharmaceuticals_DetailedActions, updateResponseReadStatus, updateRequestReadStatus, updateActionResponse } from '../../../api/user';

const getColorForCompleteStatus = (status) => {
  switch (status) {
    case 'Yes':
      return 'success';
    case 'No':
      return 'error';
    default:
      return 'default';
  }
};

const getColorForResponseStatus = (status) => {
  switch (status) {
    case 'Approved':
      return 'success';
    case 'Agreed':
      return 'success';
    case 'Rejected':
      return 'error';
    case 'Pending':
      return 'warning';
    case 'Read':
      return 'success';
    case 'Unread':
      return 'error';
    case 'N/A':
      return 'info';
    default:
      return 'default';
  }
};

const actionTypes = [
  { label: 'Overall', type: 'all' },
  { label: 'Audit Response', type: "audit" },
  { label: 'Invite Response', type: "invite" },
  { label: 'Apply Request', type: "apply" },
];

const CustomSelect = styled(Select)(({ theme }) => ({
  minWidth: 100,
  borderRadius: 8,
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    height: 22,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.palette.grey[300],
    '&.Mui-focused': {
      backgroundColor: theme.palette.action.focus,
    },
  },
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[500_32],
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.grey[800],
  '&.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
  },
  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: theme.typography.pxToRem(20),
  textAlign: 'center',
  paddingTop: theme.spacing(3),
}));

const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
  justifyContent: 'center',
  paddingBottom: theme.spacing(3),
}));

const CustomDialogButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  fontWeight: theme.typography.fontWeightBold,
  padding: theme.spacing(1, 3),
}));

const PharmaceuticalsManagement = () => {
  const companyInfo = readLoginData();
  const navigate = useNavigate();
  const location = useLocation();
  const [actions, setActions] = useState({ auditActions: [], inviteActions: [], applyActions: [] });
  const [tabIndex, setTabIndex] = useState(location.state?.tabIndex || 0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState('');
  const [dialogNote, setDialogNote] = useState(''); // 添加Note状态
  const [selectedAction, setSelectedAction] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [expandedRow, setExpandedRow] = useState(null); // 跟踪展开的行
  const [overallData, setOverallData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [inviteData, setInviteData] = useState([]);
  const [applyData, setApplyData] = useState([]);
  const [incompleteCounts, setIncompleteCounts] = useState({ all: 0, audit: 0, invite: 0, apply: 0 });

  saveCurrentPath();

  useEffect(() => {
    const fetchActions = async () => {
      try {
        const actionsList = await getPharmaceuticals_DetailedActions(companyInfo.id);
        setActions(actionsList);
        processActions(actionsList);
        console.log(actionsList);
      } catch (error) {
        console.error('Error fetching clinical trials:', error);
      }
    };
    fetchActions();
  }, [companyInfo.id]);

  const processActions = (actionsList) => {
    const overallData = [];
    const auditData = [];
    const inviteData = [];
    const applyData = [];

    const incompleteCounts = { all: 0, audit: 0, invite: 0, apply: 0 };

    const processRequests = (action, actionData, targetData) => {
      const requestsData = action.requests.map((request, index) => {
        const response = action.responses ? action.responses.find(res => res.ResponseUserType === request.ReceivedUserType && res.ResponseUserID === request.ReceivedUserID) : null;
        const requestData = {
          ActionID: action.action.ActionID,
          ActionType: action.action.ActionType,
          trialName: action.trialName,
          IsCompleted: action.action.IsCompleted,
          Timestamp: action.action.Timestamp,
          receiver: {
            id: request.ReceivedUserID,
            name: request.fullName,
            type: ['Web staff', 'Company', 'Doctor', 'Patient'][request.ReceivedUserType]
          },
          sender: {
            id: action.action.InitiatorID,
            name: action.action.initiatorName,
            type: ['Web staff', 'Company', 'Doctor', 'Patient'][action.action.InitiatorType]
          },
          readStatus: request.ReadStatus === 1 ? 'Read' : 'Unread',
          responseStatus: getResponseStatus(response),
          responseReadStatus: response ? (response.ReadStatus === 1 ? 'Read' : 'Unread') : 'N/A',
          requestNote: request.Note,
          responseNote: response ? response.Note : 'N/A',
          rowIndex: index,
        };
        targetData.push(requestData);
        return requestData;
      });
      actionData.requests = requestsData;
      overallData.push(actionData);
    };

    actionsList.auditActions.forEach(action => {
      const baseData = {
        ActionID: action.action.ActionID,
        ActionType: action.action.ActionType,
        trialName: action.trialName,
        IsCompleted: action.action.IsCompleted,
        Timestamp: action.action.Timestamp,
      };
      incompleteCounts.all += action.action.IsCompleted === 0 ? 1 : 0;
      processRequests(action, baseData, auditData);
      incompleteCounts.audit += action.requests[0].ReadStatus === 0 ? 1 : 0;
    });

    actionsList.inviteActions.forEach(action => {
      const baseData = {
        ActionID: action.action.ActionID,
        ActionType: action.action.ActionType,
        trialName: action.trialName,
        IsCompleted: action.action.IsCompleted,
        Timestamp: action.action.Timestamp,
      };
      incompleteCounts.all += action.action.IsCompleted === 0 ? 1 : 0;
      processRequests(action, baseData, inviteData);
      action.requests.forEach(request => {
        incompleteCounts.invite += request.ReadStatus === 0 ? 1 : 0;
      });
    });

    actionsList.applyActions.forEach(action => {
      const baseData = {
        ActionID: action.action.ActionID,
        ActionType: action.action.ActionType,
        trialName: action.trialName,
        IsCompleted: action.action.IsCompleted,
        Timestamp: action.action.Timestamp,
      };
      incompleteCounts.all += action.action.IsCompleted === 0 ? 1 : 0;
      const requestsData = action.requests.map((request, index) => {
        const response = action.responses ? action.responses.find(res => res.ResponseUserType === request.ReceivedUserType && res.ResponseUserID === request.ReceivedUserID) : null;
        const requestData = {
          ActionID: action.action.ActionID,
          ActionType: action.action.ActionType,
          trialName: action.trialName,
          IsCompleted: action.action.IsCompleted,
          Timestamp: action.action.Timestamp,
          receiver: {
            id: request.ReceivedUserID,
            name: request.fullName,
            type: ['Web staff', 'Company', 'Doctor', 'Patient'][request.ReceivedUserType]
          },
          sender: {
            id: action.action.InitiatorID,
            name: action.action.initiatorName,
            type: ['Web staff', 'Company', 'Doctor', 'Patient'][action.action.InitiatorType]
          },
          readStatus: request.ReadStatus === 1 ? 'Read' : 'Unread',
          responseStatus: getResponseStatus(response),
          responseReadStatus: response ? (response.ReadStatus === 1 ? 'Read' : 'Unread') : 'N/A',
          requestNote: request.Note,
          responseNote: response ? response.Note : 'N/A',
          rowIndex: index,
        };
        if (request.ReceivedUserType === 1) {
          applyData.push(requestData);
          incompleteCounts.apply += request.ReadStatus === 1 ? 0 : 1;
        }
        return requestData;
      });
      baseData.requests = requestsData;
      overallData.push(baseData);
    });

    setOverallData(overallData);
    setAuditData(auditData);
    setInviteData(inviteData);
    setApplyData(applyData);
    setIncompleteCounts(incompleteCounts);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedAction(null);
    setSnackbarOpen(false);
  };

  const handleDialogConfirm = async () => {
    if (selectedAction) {
      const timestamp = new Date().toISOString();

      try {
        const result = await updateActionResponse({
          actionId: selectedAction.ActionID,
          responseUserType: selectedAction.receiver.type,
          responseUserId: selectedAction.receiver.id,
          readStatus: false,
          responseStatus: dialogAction,
          note: dialogNote,
          timestamp
        });

        if (result === true) {
          selectedAction.responseStatus = dialogAction;
          selectedAction.responseNote = dialogNote;
          selectedAction.responseReadStatus = 'Read';
          selectedAction.timestamp = timestamp;

          setSnackbarMessage(`Response to ${selectedAction.sender.name} successfully`);
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        } else {
          setSnackbarMessage(`Failed to update response to ${selectedAction.sender.name}`);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      } catch (error) {
        setSnackbarMessage(`Failed to update response to ${selectedAction.sender.name}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }

      setDialogOpen(false);
      setSelectedAction(null);
    }
  };

  const handleDialogCancel = () => {
    setDialogOpen(false);
    setSelectedAction(null);
    setSnackbarMessage('Action cancelled');
    setSnackbarSeverity('info');
    setSnackbarOpen(true);
  };

  const getResponseStatus = (response) => {
    if (response) {
      if (tabIndex === 1) {
        return response.ResponseStatus === 0 ? 'Approved' : 'Rejected';
      } else {
        return response.ResponseStatus === 0 ? 'Agreed' : 'Rejected';
      }
    }
    return 'N/A';
  };

  const handleResponseChange = (action, newValue) => {
    setSelectedAction(action);
    setDialogAction(newValue);
    setDialogOpen(true);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setExpandedRow(null); // 切换tab时，重置展开行
  };

  const updateReadStatus = async (action) => {
    if (tabIndex === 1 || tabIndex === 2) { // Audit 和 Invite
      if (action.responseStatus !== "N/A" && action.responseReadStatus === "Unread") {
        const result = await updateResponseReadStatus(action.ActionID, action.receiver.type, action.receiver.id);
        if (result === true) {
          action.responseReadStatus = 'Read';
          setSnackbarMessage('Response read status updated');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        }
      }
    } else if (tabIndex === 3) { // Apply
      if (action.readStatus === "Unread") {
        const result = await updateRequestReadStatus(action.ActionID, action.receiver.type, action.receiver.id);
        if (result === true) {
          action.readStatus = 'Read';
          setSnackbarMessage('Request read status updated');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        }
      }
    }
  };

  const columns = tabIndex === 0
    ? [
      { name: "ActionType", label: "Action Type", options: { customBodyRender: (value) => ['Audit', 'Invite', 'Apply'][value] } },
      { name: "trialName", label: "Trial Name", options: { customBodyRender: (value) => value || 'N/A' } },
      { name: "IsCompleted", label: "Completed", options: { customBodyRender: (value) => <ClinicalTrialsStatus color={getColorForCompleteStatus(value ? 'Yes' : 'No')} status={value ? 'Completed' : 'In Progress'} /> } },
      { name: "Timestamp", label: "Timestamp", options: { customBodyRender: (value) => new Date(value).toLocaleString() } },
    ]
    : tabIndex === 1 || tabIndex === 2
      ? [
        { name: "receiver", label: "Receiver", options: { customBodyRender: (value) => (<div><div>{value.name}</div><div style={{ color: 'gray', fontSize: 'small' }}>{value.type}</div></div>) } },
        { name: "trialName", label: "Trial Name", options: { customBodyRender: (value) => value || 'N/A' } },
        { name: "readStatus", label: "Receiver Read Status", options: { customBodyRender: (value) => <ClinicalTrialsStatus color={value === 'Read' ? 'success' : 'error'} status={value} /> } },
        { name: "responseStatus", label: "Response Status", options: { customBodyRender: (value, tableMeta) => (<ClinicalTrialsStatus color={getColorForResponseStatus(value)} status={value} />) } },
        { name: "responseReadStatus", label: "Read Status", options: { customBodyRender: (value) => <ClinicalTrialsStatus color={getColorForResponseStatus(value)} status={value} /> } },
        { name: "Timestamp", label: "Timestamp", options: { customBodyRender: (value) => new Date(value).toLocaleString() } },
      ]
      : [
        { name: "sender", label: "Sender", options: { customBodyRender: (value) => (<div><div>{value.name}</div><div style={{ color: 'gray', fontSize: 'small' }}>{value.type}</div></div>) } },
        { name: "trialName", label: "Trial Name", options: { customBodyRender: (value) => value || 'N/A' } },
        { name: "readStatus", label: "Read Status", options: { customBodyRender: (value) => <ClinicalTrialsStatus color={value === 'Read' ? 'success' : 'error'} status={value} /> } },
        { name: "responseStatus", label: "Response Status", options: { customBodyRender: (value, tableMeta) => value === 'N/A' ? (
          <CustomSelect
            value={value}
            onChange={(event) => handleResponseChange(data[tableMeta.rowIndex], event.target.value)}
            displayEmpty
          >
            <CustomMenuItem value="N/A">Select your response</CustomMenuItem>
            <CustomMenuItem value="Agreed">Agreed</CustomMenuItem>
            <CustomMenuItem value="Rejected">Rejected</CustomMenuItem>
          </CustomSelect>
        ) : (
          <ClinicalTrialsStatus color={getColorForResponseStatus(value)} status={value} />
        ) } },
        { name: "responseReadStatus", label: "Applicant Read Status", options: { customBodyRender: (value) => <ClinicalTrialsStatus color={getColorForResponseStatus(value)} status={value} /> } },
        { name: "Timestamp", label: "Timestamp", options: { customBodyRender: (value) => new Date(value).toLocaleString() } },
      ];

  const getDataByTabIndex = () => {
    switch (tabIndex) {
      case 0:
        return overallData;
      case 1:
        return auditData;
      case 2:
        return inviteData;
      case 3:
        return applyData;
      default:
        return overallData;
    }
  };

  const data = getDataByTabIndex();

  const options = {
    filter: false,
    responsive: "standard",
    selectableRows: "none",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    rowsPerPage: 10,
    pagination: true,
    customToolbarSelect: () => { },
    setRowProps: (row, dataIndex) => ({
      style: {
        backgroundColor: '#FFFFFF'
      },
    }),
    setCellProps: () => ({
      style: {
        padding: '10px 0',
        textAlign: 'center',
        borderBottom: 'none',
      },
    }),
    elevation: 0,
    customTableBodyFooterRender: () => null,
    customHeadRender: (columnMeta) => (
      <th style={{ padding: '0', height: '0', borderBottom: 'none', textAlign: 'center' }}>{columnMeta.label}</th>
    ),
    expandableRows: true,
    expandableRowsHeader: false,
    renderExpandableRow: (rowData, rowMeta) => {
      const rowIndex = rowMeta.dataIndex;
      const action = data[rowIndex];
      if (tabIndex === 0) {
        return (
          <tr>
            <td colSpan={rowData.length}>
              <Box sx={{ padding: 2, backgroundColor: '#f5f5f5', textAlign: 'left' }}>
                <Typography variant="h6" gutterBottom>Details for Action ID: {action.ActionID}</Typography>
                <Typography variant="body1"><strong>Trial Name:</strong> {action.trialName}</Typography>
                <Typography variant="body1"><strong>Action Type:</strong> {['Audit', 'Invite', 'Apply'][action.ActionType]}</Typography>
                <Typography variant="body1"><strong>Initiator:</strong></Typography>
                <Typography variant="body1" color="textPrimary">{action.requests[0]?.sender?.name}</Typography>
                <Typography variant="body2" color="textSecondary">{action.requests[0]?.sender?.type}</Typography>
                <Typography variant="body1"><strong>Status:</strong> {action.IsCompleted ? 'Completed' : 'In Progress'}</Typography>
                <Typography variant="body1"><strong>Timestamp:</strong> {new Date(action.Timestamp).toLocaleString()}</Typography>
                <Box sx={{ marginTop: 2 }}>
                  <Typography variant="h6" gutterBottom>Requests</Typography>
                  {action.requests.map((request, index) => (
                    <Box key={index} sx={{ marginBottom: 2 }}>
                      <Typography variant="body1"><strong>Receiver:</strong></Typography>
                      <Typography variant="body1" color="textPrimary">{request.receiver.name}</Typography>
                      <Typography variant="body2" color="textSecondary">{request.receiver.type}</Typography>
                      <Typography variant="body1"><strong>Sender Note:</strong> {request.requestNote}</Typography>
                      <Typography variant="body1"><strong>Receiver Response:</strong> {request.responseStatus}</Typography>
                      <Typography variant="body1"><strong>Response Note:</strong> {request.responseNote}</Typography>
                      <Typography variant="body1"><strong>Request Timestamp:</strong> {new Date(request.Timestamp).toLocaleString()}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </td>
          </tr>
        );
      } else {
        updateReadStatus(action);
        return (
          <tr>
            <td colSpan={rowData.length}>
              <Box sx={{ padding: 2, backgroundColor: '#f5f5f5', textAlign: 'left' }}>
                <Typography variant="h6" gutterBottom>Details for Request</Typography>
                <Typography variant="body1"><strong>Trial Name:</strong> {action.trialName}</Typography>
                <Typography variant="body1"><strong>Sender:</strong></Typography>
                <Typography variant="body1" color="textPrimary">{action.sender.name}</Typography>
                <Typography variant="body2" color="textSecondary">{action.sender.type}</Typography>
                <Typography variant="body1"><strong>Sender Note:</strong> {action.requestNote}</Typography>
                <Typography variant="body1"><strong>Receiver:</strong></Typography>
                <Typography variant="body1" color="textPrimary">{action.receiver.name}</Typography>
                <Typography variant="body2" color="textSecondary">{action.receiver.type}</Typography>
                <Typography variant="body1"><strong>Receiver Response:</strong> {action.responseStatus}</Typography>
                <Typography variant="body1"><strong>Response Note:</strong> {action.responseNote}</Typography>
                <Typography variant="body1"><strong>Timestamp:</strong> {new Date(action.Timestamp).toLocaleString()}</Typography>
              </Box>
            </td>
          </tr>
        );
      }
    },
  };

  return (
    <Card sx={{ position: 'relative', borderRadius: '19px', height: '100%', width: '100%', backgroundColor: '#FFFFFF', boxShadow: 'none' }} bordered={false}>
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="pharmaceuticals management tabs">
          {actionTypes.map((action, index) => (
            <Tab
              key={action.type}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2" sx={{ marginRight: 1 }}>
                    {action.label}
                  </Typography>
                  <ClinicalTrialsStatus color={tabIndex === index ? "primary" : "error"} status={incompleteCounts[action.type]} />
                </Box>
              }
            />
          ))}
        </Tabs>
      </Box>

      <MUIDataTable
        key={tabIndex}
        title={""}
        data={data}
        columns={columns}
        options={options}
        components={{
          TableCell: (props) => (
            <td {...props} style={{ ...props.style, borderBottom: 'none', textAlign: 'center', padding: '10px 0' }} />
          ),
          TableRow: (props) => (
            <tr {...props} style={{ borderBottom: 'none' }} />
          ),
        }}
      />

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        PaperProps={{
          style: {
            borderRadius: 20,
          },
        }}
      >
        <CustomDialogTitle>Confirm Action</CustomDialogTitle>
        <DialogContent>
          <DialogContentText sx={{ textAlign: 'center', fontSize: 16, fontWeight: 400 }}>
            Are you sure you want to {dialogAction} this request?
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="note"
            label="Note"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={4} // 设置多行输入
            value={dialogNote}
            onChange={(e) => setDialogNote(e.target.value)}
          />
        </DialogContent>
        <CustomDialogActions>
          <CustomDialogButton variant="contained" color="primary" onClick={handleDialogConfirm}>
            Confirm
          </CustomDialogButton>
          <CustomDialogButton variant="outlined" color="inherit" onClick={handleDialogCancel}>
            Cancel
          </CustomDialogButton>
        </CustomDialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default PharmaceuticalsManagement;
