import React, { useEffect, useState, useRef } from "react";
import { List, ListItem, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const referrals = [
  { name: "Dr. William James", specialty: "Cardiologist", date: "2023/3/11" },
  {
    name: "Dr. William James",
    specialty: "General Practitioner",
    date: "2023/2/11",
  },
  { name: "Asprin", date: "2023/3/15" },
  { name: "lbuprofen", date: "2023/4/20" },
  { name: "Asprin", date: "2023/5/18" },
  { name: "Advil", date: "2023/6/22" },
  { name: "Advil", date: "2023/7/30" },
  { name: "Advil", date: "2023/8/5" },
];
function PriscriptionView() {
  const [showCreateReferralModal, setShowCreateReferralModal] = useState(false);

  const listItemStyle = {
    borderRadius: "20px", // Rounded corners
    margin: "8px 0", // Spacing between items
    padding: "10px 20px", // Padding inside the shape
    backgroundColor: "#E3F2FD", // Light blue background color
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Optional shadow for depth
    width: "90%",
  };
  const listStyle = {
    width: "100%", // Set the desired width of the list

    padding: 0, // Resets padding
    maxHeight: 100,
    overflow: "auto",
  };
  return (
    <>
      <List style={listStyle}>
        {referrals.map((referral, index) => (
          <ListItem key={index} style={listItemStyle}>
            <Typography variant="body1" style={{}}>
              {referral.name}
            </Typography>
            <Typography
              variant="body2"
              style={{ textAlign: "right" }}
              color="textSecondary"
            >
              {referral.date}
            </Typography>
          </ListItem>
        ))}
      </List>
    </>
  );
}

export default PriscriptionView;
