import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

const BloodPressureChart = () => {
  //   const [gaugeData, setGaugeData] = useState([
  //     {
  //       value: 500,
  //       name: "EDA",
  //       title: {
  //         offsetCenter: ["0%", "-25%"],
  //       },
  //       detail: {
  //         valueAnimation: true,
  //         offsetCenter: ["0%", "15%"],
  //       },
  //     },
  //   ]);

  //   useEffect(() => {
  //     const eventSource = new EventSource(
  //       "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/users/PressureChartEvents"
  //     );

  //     eventSource.onmessage = (event) => {
  //       const receivedData = JSON.parse(event.data);
  //       setGaugeData([
  //         {
  //           ...gaugeData[0],
  //           value: receivedData.value,
  //           name: receivedData.value < 500 ? "Relaxed" : "Stressed",
  //         },
  //       ]);
  //     };

  //     return () => {
  //       eventSource.close();
  //     };
  //   }, []);

  const getOption = () => {
    return {
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        axisLabel: { show: false }, // 隐藏 x 轴标签
      },
      yAxis: {
        type: "value",
        axisLabel: { show: false }, // 隐藏 y 轴标签
      },
      series: [
        {
          data: [820, 932, 901, 934, 650, 700, 800],
          type: "line",
          // itemStyle: {
          //   color: "#3FC979", // 数据点的颜色
          //   borderColor: "#3FC979", // 数据点边框颜色
          //   // borderWidth: 8, // 数据点边框宽度
          //   borderType: "solid", // 数据点边框类型
          // },
          symbolSize: 0, // 数据点大小
          // emphasis: {
          //   itemStyle: {
          //     color: "#3FC979", // 鼠标悬浮时数据点的颜色
          //     borderColor: "#3FC979", // 鼠标悬浮时数据点边框颜色
          //   },
          // },
          lineStyle: {
            color: "#3FC979",
          },
          areaStyle: { color: "rgba(63, 201, 121, 0.5)" },
        },
      ],
      grid: {
        // top: "5%", // 图表距离容器顶部的距离
        top: "10%",
        bottom: "10%",
        containLabel: true, // 此选项确保标签和轴标题也包含在绘图区域内，防止内容溢出
      },
    };
  };

  return <ReactECharts option={getOption()} style={{ height: "20vh" }} />;
};

export default BloodPressureChart;
