// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clinicalstaff-dashboard-quick-link-button {
  width: 100%;
  min-height: 3rem;
  border-radius: 1rem;
  background-color: #1a4eba;
  color: white;

  display: inline-block;
}

.clinicalstaff-dashboard-right-column-card {
  height: 100%;
  margin-bottom: 1rem;
  background-color: white;
  border-radius: 1.5rem;
}

.clinicalstaff-dashboard-right-column-card .ant-card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  
  padding-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/screens/ClinicalStaffPanelNew/ClinicalStaffDashboard.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;;EAEZ,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;;EAEtB,iBAAiB;AACnB","sourcesContent":[".clinicalstaff-dashboard-quick-link-button {\n  width: 100%;\n  min-height: 3rem;\n  border-radius: 1rem;\n  background-color: #1a4eba;\n  color: white;\n\n  display: inline-block;\n}\n\n.clinicalstaff-dashboard-right-column-card {\n  height: 100%;\n  margin-bottom: 1rem;\n  background-color: white;\n  border-radius: 1.5rem;\n}\n\n.clinicalstaff-dashboard-right-column-card .ant-card-body {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  \n  padding-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
