// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upcoming-task-item .ant-avatar {
  margin-left: 0.768rem;
  width: 2.94rem;
  height: 2.78rem;
  padding: 6px;

  border: 0;
  background: linear-gradient(66.15deg, #57B4DE -5.25%, #58B6DD 35.43%, #3B29AB 98.77%);
}

.upcoming-task-item .ant-avatar > span {
  line-height: calc(2.78rem - 14px) !important;
  font-size: 1.3rem;
}

.upcoming-task-item .ant-list-item-meta-title {
  font-weight: 500;
  font-size: 1.024rem !important;
  line-height: 1.536rem !important;
}

.upcoming-task-item .ant-list-item-meta-description {
  font-weight: 400;
  font-size: 0.768rem !important;
  line-height: 1.152rem !important;
  color: #0D0D0D99 !important;
}

.upcoming-task-item.ant-list-item {
  background: #F0F9FD;
  border-radius: 0.64rem;
  margin-bottom: 1.054rem;

  border-block-end: 0;
}

.upcoming-task-container {
  flex: 1 1;
  flex-basis: 0;

  min-height: 20vh;
  overflow: auto;
  scrollbar-color: #D9D9D9 transparent;
  scrollbar-width: thin;
}

.upcoming-task-create-button {
  display: block;

  margin-left: auto;
  margin-right: 0;
}

.upcoming-task-create-button, .upcoming-task-create-button .MuiSvgIcon-root {
  font-family: 'Poppins';
  font-size: 0.875rem ;
  font-weight: 600;
  line-height: 1.313rem;
}
`, "",{"version":3,"sources":["webpack://./src/screens/DoctorNew/DoctorUpcomingTasks.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,cAAc;EACd,eAAe;EACf,YAAY;;EAEZ,SAAS;EACT,qFAAqF;AACvF;;AAEA;EACE,4CAA4C;EAC5C,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,8BAA8B;EAC9B,gCAAgC;AAClC;;AAEA;EACE,gBAAgB;EAChB,8BAA8B;EAC9B,gCAAgC;EAChC,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;EACnB,sBAAsB;EACtB,uBAAuB;;EAEvB,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,aAAa;;EAEb,gBAAgB;EAChB,cAAc;EACd,oCAAoC;EACpC,qBAAqB;AACvB;;AAEA;EACE,cAAc;;EAEd,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,oBAAoB;EACpB,gBAAgB;EAChB,qBAAqB;AACvB","sourcesContent":[".upcoming-task-item .ant-avatar {\n  margin-left: 0.768rem;\n  width: 2.94rem;\n  height: 2.78rem;\n  padding: 6px;\n\n  border: 0;\n  background: linear-gradient(66.15deg, #57B4DE -5.25%, #58B6DD 35.43%, #3B29AB 98.77%);\n}\n\n.upcoming-task-item .ant-avatar > span {\n  line-height: calc(2.78rem - 14px) !important;\n  font-size: 1.3rem;\n}\n\n.upcoming-task-item .ant-list-item-meta-title {\n  font-weight: 500;\n  font-size: 1.024rem !important;\n  line-height: 1.536rem !important;\n}\n\n.upcoming-task-item .ant-list-item-meta-description {\n  font-weight: 400;\n  font-size: 0.768rem !important;\n  line-height: 1.152rem !important;\n  color: #0D0D0D99 !important;\n}\n\n.upcoming-task-item.ant-list-item {\n  background: #F0F9FD;\n  border-radius: 0.64rem;\n  margin-bottom: 1.054rem;\n\n  border-block-end: 0;\n}\n\n.upcoming-task-container {\n  flex: 1 1;\n  flex-basis: 0;\n\n  min-height: 20vh;\n  overflow: auto;\n  scrollbar-color: #D9D9D9 transparent;\n  scrollbar-width: thin;\n}\n\n.upcoming-task-create-button {\n  display: block;\n\n  margin-left: auto;\n  margin-right: 0;\n}\n\n.upcoming-task-create-button, .upcoming-task-create-button .MuiSvgIcon-root {\n  font-family: 'Poppins';\n  font-size: 0.875rem ;\n  font-weight: 600;\n  line-height: 1.313rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
