import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import overviewIcon from "../../../assets/images/health-overview.svg";
import Weight from "./Weight";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import BloodPressure from "./BloodPressure";
import Glucose from "./Glucose";

const typeFontFamily = { fontFamily: "Mulish, sans-serif" };
const dropdownFontFamily = {
  fontSize: "0.9rem",
  fontWeight: 500,
  fontFamily: "Poppins, sans-serif",
};
export const HealthOverview = () => {
  const [component, setComponent] = React.useState("weight");
  const handleChange = (event) => {
    setComponent(event.target.value);
  };
  useEffect(() => {}, [component]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: "2em",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            gutterBottom
            sx={{ 
              fontFamily: "Poppins",
              color: "#1A4EBA", 
              fontWeight: "500", 
              fontSize: "1.4rem",
              textAlign: "left",
            }}
            mb={0}
          >
            Health Overview
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          ml: "3em",
        }}
      >
        <Typography
          sx={{
            ...typeFontFamily,
            fontWeight: "500",
            fontSize: "0.9rem",
            color: "#4F4E4E",
            mr: "1em",
          }}
        >
          Select Chart Type:
        </Typography>
        <FormControl sx={{ m: 1, minWidth: 135 }} size="small">
          <Select
            value={component}
            onChange={handleChange}
            sx={{
              color: "#002284",
              "& .MuiSvgIcon-root": {
                color: "#002284",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#002284",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#002284",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#002284",
              },
            }}
          >
            <MenuItem value={"weight"} sx={{ ...dropdownFontFamily }}>
              Weight
            </MenuItem>
            <MenuItem value={"blood pressure"} sx={{ ...dropdownFontFamily }}>
              Blood Pressure
            </MenuItem>
            <MenuItem value={"glucose"} sx={{ ...dropdownFontFamily }}>
              Glucose
            </MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box>
        {{
          weight: <Weight />,
          "blood pressure": <BloodPressure />,
          glucose: <Glucose />,
        }[component] || <Typography>Please select a chart type.</Typography>}
      </Box>
    </>
  );
};
