import React from "react";
import Home from "./home.png"
import './login.css';
import Email from "./email.png"
import Password from "./password.png"
import {Link as RouterLink} from "react-router-dom";
import { withRouter } from "../../../withRouter";
import "./login.css";
import "tachyons";
import Logo from "./logo.svg";

class LogIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logInEmail: "",
            logInPassword: "",
            selectedOption: null,
            wrongCredentials: false,
            accountNotVerified: false,
            rememberMe: false,
        };
    }
    onEmailChange = (event) => {
        this.setState({ logInEmail: event.target.value });
    };
    onPasswordChange = (event) => {
        this.setState({ logInPassword: event.target.value });
    };
    onOptionChange = (option) => {
        this.setState({ selectedOption: option });
    };
    onRememberMeChange = (event) => {
        this.setState({ rememberMe: event.target.checked }, () => {});
    };

    onSubmitLogIn = () => {
        if (!this.state.logInEmail.includes("@")) {
            return alert("Please enter a valid email");
        }
        if (
            this.state.logInEmail === "" ||
            this.state.logInPassword === "" ||
            this.state.selectedOption === null
        ) {
            return alert("Please enter valid credentials");
            //https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/users/login
            //https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/users/activeOnlineUser
            //https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/users/login
        }
        this.setState({ wrongCredentials: false, accountNotVerified: false });
        fetch(
            "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/users/login",
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: this.state.logInEmail,
                    password: this.state.logInPassword,
                    selectedOption: this.state.selectedOption,
                }),
            }
        )
            .then((response) => {
                if (response.status === 400) {
                    return response.json().then(body => {
                        if(body === "Account hasn't been verified"){
                            throw new Error("AccountNotVerified");
                        } else {
                            throw new Error("Wrong credentials");
                        }
                    });
                }
                return response.json();
            })
            .then((user) => {
                if (this.state.selectedOption === "Admin") {
                    if (user.admin_id) {
                        let new_user = {
                            type: "Admin",
                            id: user.admin_id,
                            name: user.full_name,
                            email: user.email,
                            startInPage: "/Admin",
                        };
                        if (this.state.rememberMe) {
                            this.props.loadUser(new_user);
                            this.setState({ rememberMe: false });
                        } else {
                            this.props.loadTempUser(new_user);
                        }
                    }
                } else if (this.state.selectedOption === "Patient") {
                    if (user.id) {
                        let new_user = {
                            type: "Patient",
                            id: user.id,
                            name: user.FName,
                            email: user.EmailId,
                            startInPage: "/patient/dashboard",
                        };
                        fetch(
                            "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/users/activeOnlineUser",
                            {
                                method: "POST",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify({
                                    id: new_user.id,
                                    name: new_user.name,
                                    email: new_user.email,
                                }),
                            }
                        )
                            .then((res) => {
                                if (res.status === 200) {
                                    if (this.state.rememberMe) {
                                        this.props.loadUser(new_user);
                                        this.setState({ rememberMe: false });
                                    } else {
                                        this.props.loadTempUser(new_user);
                                    }
                                } else {
                                    throw new Error("Error in processing request");
                                }
                            })
                            .catch((error) => {
                                console.error("There was an error during the fetch:", error);
                            });
                    }
                } else if (this.state.selectedOption === "Doctor") {
                    if (user.id) {
                        let new_user = {
                            type: "Doctor",
                            id: user.id,
                            name: user.Fname,
                            email: user.EmailId,
                            startInPage: "/doctor/dashboard",
                        };
                        if (this.state.rememberMe) {
                            this.props.loadUser(new_user);
                            this.setState({ rememberMe: false });
                        } else {
                            this.props.loadTempUser(new_user);
                        }
                    }
                } else if (this.state.selectedOption === "Clinic") {
                    if (user.id) {
                        let new_user = {
                            type: "Clinic",
                            id: user.id,
                            name: user.Fname,
                            email: user.EmailId,
                            startInPage: "/clinicalstaff/dashboard",
                        };
                        if (this.state.rememberMe) {
                            this.props.loadUser(new_user);
                            this.setState({ rememberMe: false });
                        } else {
                            this.props.loadTempUser(new_user);
                        }
                    }
                }else if (this.state.selectedOption === "Pharma") {
                    if (user.id) {
                        let new_user = {
                            type: "Pharma",
                            id: user.id,
                            name: user.name,
                            email: user.email,
                            startInPage: "/pharmaceuticals/dashboard",
                        };
                        if (this.state.rememberMe) {
                            this.props.loadUser(new_user);
                            this.setState({ rememberMe: false });
                        } else {
                            this.props.loadTempUser(new_user);
                        }
                    }
                } else {
                    if (user.id) {
                        let new_user = {
                            id: user.id,
                            name: user.Hospital_Name,
                            email: user.Email_Id,
                            startInPage: "/",
                        };
                        if (this.state.rememberMe) {
                            this.props.loadUser(new_user);
                            this.setState({ rememberMe: false });
                        } else {
                            this.props.loadTempUser(new_user);
                        }
                    }
                }
            })
            .catch((error) => {
                if (error.message === "Wrong credentials") {
                    this.setState({ wrongCredentials: true });
                } else if (error.message === "AccountNotVerified"){
                    this.setState({ accountNotVerified: true });
                } else {
                    console.error("There was an error during the fetch:", error);
                }
            });
    };
    render() {
        return (
            <div className="login-page">
                <nav className="navbar">
                    <div className="navbar-brand">
                        <a href="/"><img src={Logo} alt="Your Logo"/></a> {/* Use imported logo */}
                    </div>

                    <div className="sign-button">
                        <RouterLink to="/">
                            <button className="btn-signin_up">Home
                            </button>
                        </RouterLink>

                    </div>


                </nav>
                <section id="login" className="section login-section">
                    <div className="login-content">
                        <div className="left">
                            <div className="login-title">
                                <h1>e-Hospital</h1>
                                <h4>Smart Digital Medicine Solutions</h4>
                            </div>
                            <div className="login-frame">
                                <form>
                                    <h1>Login</h1>
                                    <h4>Login as: </h4>
                                    <div className="login-info">
                                        <div className="row">
                                            <button
                                                type="button"
                                                className={`option-btn ${
                                                    this.state.selectedOption === "Admin" ? "selected" : ""
                                                }`}
                                                onClick={() => this.onOptionChange("Admin")}
                                            >
                                                Admin
                                            </button>
                                            <button
                                                type="button"
                                                className={`option-btn ${
                                                    this.state.selectedOption === "Patient" ? "selected" : ""
                                                }`}
                                                onClick={() => this.onOptionChange("Patient")}
                                            >
                                                Patient
                                            </button>
                                            <button
                                                type="button"
                                                className={`option-btn ${
                                                    this.state.selectedOption === "Doctor" ? "selected" : ""
                                                }`}
                                                onClick={() => this.onOptionChange("Doctor")}
                                            >
                                                Doctor
                                            </button>
                                        </div>
                                        <div className="row">
                                            <button
                                                type="button"
                                                className={`option-btn ${
                                                    this.state.selectedOption === "Hospital" ? "selected" : ""
                                                }`}
                                                onClick={() => this.onOptionChange("Hospital")}
                                            >
                                                Hospital
                                            </button>
                                            <button
                                                type="button"
                                                className={`option-btn ${
                                                    this.state.selectedOption === "Clinic" ? "selected" : ""
                                                }`}
                                                onClick={() => this.onOptionChange("Clinic")}
                                            >
                                                Clinic Staff
                                            </button>
                                            <button
                                                type="button"
                                                className={`option-btn ${
                                                    this.state.selectedOption === "Pharma" ? "selected" : ""
                                                }`}
                                                onClick={() => this.onOptionChange("Pharma")}
                                            >
                                                Pharmaceutical Office
                                            </button>
                                        </div>
                                    </div>

                                    <img className="email-icon" style={{maxWidth: '18px'}} src={Email}
                                         alt="Passwor Image"/>
                                    <input

                                        placeholder="Email address..."
                                        type="email"
                                        onChange={this.onEmailChange}
                                        className="input-box"

                                    />

                                    <br/>
                                    <img className="password-icon" style={{maxWidth: '18px'}} src={Password}
                                         alt="Passwor Image"/>
                                    <input
                                        placeholder="********"
                                        type="password"
                                        onChange={this.onPasswordChange}
                                        className="input-box"
                                    />
                                    <br/>
                                    <div className="remember-me">
                                        <input
                                            type="checkbox"
                                            id="remember"
                                            className="larger-checkbox "
                                            checked={this.state.rememberMe}
                                            onChange={this.onRememberMeChange}
                                        />
                                        <label htmlFor="remember">Remember Me</label>
                                    </div>
                                    {this.state.wrongCredentials && (
                                        <div className="error-message">
                                            {" "}
                                            ⚠️ Incorrect Email or Password
                                        </div>
                                    )}
                                    {this.state.accountNotVerified && (
                                        <div className="error-message">
                                            {" "}
                                            ⚠️ Account hasn't been verified
                                        </div>
                                    )}


                                    <button className="btn-login"
                                            type="button"
                                            onClick={() => this.onSubmitLogIn()}>Login
                                    </button>


                                    <p>Don’ have an account?
                                        <a href="/SignUp"> Sign up</a>
                                    </p>
                                </form>
                            </div>

                        </div>
                        <div className="right">
                            <img src={Home} alt="Home Image"/>
                        </div>
                    </div>

                </section>

            </div>
        );
    }
}

export default withRouter(LogIn);
