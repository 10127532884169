import React, { useEffect, useState } from "react";
import moment from "moment";
import dayjs from "dayjs";
import { Calendar, Spin } from "antd";

import "./PatientMiniCalendar.css";
import { patientMainPageGetCalendar } from "../../../api/calendar";
import { readLoginData } from "../../../loginData";

const dateFormatString = "YYYY-MM-DD";

const includesDate = (dates, d) => {
  return dates.includes(moment(d).format(dateFormatString));
};

const PatientMiniCalendar = (props) => {
  const loginData = readLoginData();
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(props.date);
  const [panelDate, setPanelDate] = useState(moment().toDate());
  const [data, setData] = useState([]);

  useEffect(() => {
    props.onSelectDate(selectedDate);
    return () => {};
  }, [selectedDate]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const startTime = moment(panelDate).subtract("7", "week").toDate();
      const endTime = moment(panelDate).add("7", "week").toDate();
      const response = await patientMainPageGetCalendar(
        loginData,
        startTime,
        endTime,
        moment().format("Z")
      );
      if (response && response.length > 0) {
        const data = response.map((appointment) =>
          moment.utc(appointment.start).format(dateFormatString)
        );
        setData(data);
        setLoading(false);
      } else {
        console.log("No data found");
      }
    })();
    return () => {};
  }, [panelDate]);

  const handleChange = (date) => {
    console.log("handleChange", date);
    setSelectedDate(date.toDate());
  };

  const handlePanelChange = (date, mode) => {
    setPanelDate(date.toDate());
  };

  const cellRender = (date, info) => {
    if (info.type === "date") {
      return React.cloneElement(info.originNode, {
        ...info.originNode.props,
        className: `${info.originNode.props.className} doctor-mini-calendar-cell-date`,
        children: (
          <>
            {date.get("date")}
            {includesDate(data, date.toDate()) ? (
              <div
                className={
                  true
                    ? "doctor-mini-calendar-cell-dot"
                    : "doctor-mini-calendar-cell-nodot"
                }
              />
            ) : null}
          </>
        ),
      });
    } else {
      return info.originNode;
    }
  };

  return (
    <>
      <h2 className="doctor-subtitle" style={{ textAlign: "left" }}>
        Calendar
      </h2>
      <Spin spinning={loading}>
        <Calendar
          value={dayjs(selectedDate)}
          fullscreen={false}
          className="doctor-mini-calendar"
          fullCellRender={cellRender}
          onChange={handleChange}
          onPanelChange={handlePanelChange}
        />
      </Spin>
    </>
  );
};

export default PatientMiniCalendar;
