import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu, ConfigProvider } from "antd";
import noTextLogo from "../../assets/images/noTextLogo.svg";
import { useSelectedKey } from "../../contexts/SelectedKeyContext";

import "./SideBar.css";
import Logo from "../LandingPage/navbar/logo.svg";

const SideBar = ({ menuItems }) => {
  const navigate = useNavigate();
  const { selectedKey, setSelectedKey } = useSelectedKey();

  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const currentKey = menuItems.find(
      (item) =>
        typeof item.action === "string" &&
        location.pathname.endsWith(item.action)
    )?.key;
    if (currentKey && currentKey !== selectedKey) {
      setSelectedKey(currentKey);
    }
  }, [location, menuItems, selectedKey, setSelectedKey]);

  const handleClick = ({ key }) => {
    const action = menuItems.find((item) => item.key === key).action;
    if (typeof action === "function") {
      action();
    } else if (typeof action === "string") {
      navigate(action);
    }
  };

  return (
    <Layout.Sider
      className="side-bar"
      collapsed={collapsed}
      onCollapse={setCollapsed}
    >
      <div className="side-bar-logo-container">
        <a href="/"><img className="side-bar-no-text-logo" src={noTextLogo}
                         alt="logo"/></a>
        <a href="/">
          <div className="side-bar-logo-text">eHospital</div></a>
      </div>
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              activeBarBorderWidth: "0",
              itemColor: "#6F718F",
              itemSelectedBg: "#0E45B7",
              itemSelectedColor: "#FFF",
            },
          },
        }}
      >
        <Menu
          onClick={handleClick}
          className="side-bar-menu"
          selectedKeys={[selectedKey]}
          mode="inline"
        >
          {menuItems.map((item) => (
            <Menu.Item key={item.key} icon={item.icon}>
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      </ConfigProvider>
    </Layout.Sider>
  );
};

export default SideBar;
