import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import OperationBox from "./OperationBox.jsx";
import AllNotesBox from "./AllNotesBox.jsx";
import NoteView from "../NoteView.jsx";
import MedicalTestView from "../MedicalTestView.jsx";
import PriscriptionView from "../PriscriptionView.jsx";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import DownloadUploadForm from "../../../components/DoctorComponents/DownloadUploadForm.jsx";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { MedicalTestRequisitionForm } from "../MedicalTestRequisitionForm.jsx";
import CreateReferralForm from "../../../components/DoctorComponents/CreateReferralForm.jsx";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import addPatientInfoAction from "../../../redux/actions/addPatientInfoAction";
import { BASE_URL } from "../../../constants";
import PriscriptionForm from "../PriscriptionForm";
import {
  Typography,
  TextField,
  Grid,
  Snackbar,
  Button,
  Divider,
  Box,
  Modal,
  Card,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";

const DoctorOperations = ({ patientId, doctorId }) => {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [patientData, setPatientData] = React.useState({});
  const [doctorDetails, setDoctorDetails] = useState({});
  const prescriptionToPrintRef = useRef();
  const [showCreateReferralModal, setShowCreateReferralModal] = useState(false);
  const [prescriptionContent, setPrescriptionContent] = useState("");
  const [treatments, setTreatments] = React.useState([]);
  const [showLogVisitModal, setShowLogVisitModal] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showCreatePrescriptionModal, setShowCreatePrescriptionModal] =
    useState(false);
  const [notes, setNotes] = React.useState(
    "The patient reports feeling tired in the evenings. Recommend a follow-up appointment."
  );
  const handleOpenLogVisitModal = () => {
    setShowLogVisitModal(true);
  };

  const handleCloseLogVisitModal = () => {
    setShowLogVisitModal(false);
  };
  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const [showRequestMedicalTestModal, setShowRequestMedicalTestModal] =
    useState(false);

  const handleShowCreatePrescription = () => {
    setShowCreatePrescriptionModal(true);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.post(
          "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/users/getPatientPortalInfoById",
          { patientId }
        );
        console.log(response);
        setPatientData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, [patientId]);

  // Fetch treatments function
  const fetchTreatments = async () => {
    try {
      const response = await axios.post(
        "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/patientOverview",
        { patientId }
      );
      if (response.data && response.data.treatments) {
        setTreatments(response.data.treatments);
      }
    } catch (error) {
      console.error("Error fetching treatments:", error);
    }
  };
  //LocalTime
  const toLocalISOString = (date) => {
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
    return adjustedDate.toISOString().split("T")[0];
  };
  const [showAddTreatmentModal, setShowAddTreatmentModal] = useState(false);
  const [treatmentDetails, setTreatmentDetails] = useState({
    treatment: "",
    date: toLocalISOString(new Date()),
    diseaseType: "",
    diseaseId: "",
  });
  const handleTreatmentChange = (e) => {
    setTreatmentDetails({
      ...treatmentDetails,
      [e.target.name]: e.target.value,
    });
  };
  const saveTreatment = async () => {
    if (treatmentDetails.treatment !== "") {
      try {
        const response = await axios.post(
          "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/saveTreatment",
          {
            ...treatmentDetails,
            patientId,
            doctorId,
          }
        );
        console.log(response.data);
        // Refetch the treatments data
        fetchTreatments();
        setShowAddTreatmentModal(false);
      } catch (error) {
        console.error("Error saving treatment:", error);
      }
    }
  };
  const formatDateForDisplay = (isoDateString) => {
    const [datePart] = isoDateString.split("T");
    const [year, month, day] = datePart.split("-").map(Number);
    const date = new Date(year, month - 1, day);
    return date.toLocaleDateString();
  };
  const [visitDate, setVisitDate] = useState(toLocalISOString(new Date()));
  const savePrescription = async () => {
    const data = {
      doctorId,
      patientId,
      doctorFName: doctorDetails.Fname,
      doctorLName: doctorDetails.Lname,
      doctorPhone: doctorDetails.MobileNumber,
      doctorOfficeAddress: doctorDetails.Location1,
      patientFName: patientData.FName,
      patientLName: patientData.LName,
      patientPhone: patientData.MobileNumber,
      patientAddress: patientData.Address,
      prescription: prescriptionContent,
    };

    try {
      //https://e-react-node-backend-22ed6864d5f3.herokuapp.com
      //https://e-react-node-backend-22ed6864d5f3.herokuapp.com/
      const response = await axios.post(
        " https://e-react-node-backend-22ed6864d5f3.herokuapp.com/savePrescription",
        data
      );
      console.log(response.data);
      setSnackbarMessage("Prescription saved successfully!");
      setSnackbarOpen(true);
      setPrescriptionContent("");
    } catch (error) {
      console.error("Error saving Prescription: ", error);
      // Handle error
    }
  };
  //state variables for form fields

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [reasonForVisit, setReasonForVisit] = useState("");
  // Function to handle saving the visit
  const saveVisit = async () => {
    const visitDetails = {
      doctorId,
      patientId,
      visitDate,
      startTime,
      endTime,
      reasonForVisit,
      notes, // observations
    };
    try {
      //https://e-react-node-backend-22ed6864d5f3.herokuapp.com
      //https://e-react-node-backend-22ed6864d5f3.herokuapp.com/
      const response = await axios.post(
        "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/saveVisit",
        visitDetails
      );
      console.log(response.data);
      setSnackbarMessage("Visit logged successfully!");
      setSnackbarOpen(true);
      setStartTime("");
      setEndTime("");
      setReasonForVisit("");
    } catch (error) {
      console.error("Error saving visit:", error);
      // Handle error
    }
  };
  const styleRequestMedical = {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    minHeight: "50%",
    maxHeight: "100%",
    pt: 2,
    px: 4,
    pb: 3,
    overflowY: "auto",
  };
  const styleMini = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    minHeight: "50%",
    pt: 2,
    px: 4,
    pb: 3,
    overflowY: "auto",
  };

  const options = {
    selectableRows: false,
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [10, 13, 20],
  };

  console.log("ViewMoal data", patientData, doctorId);

  const CardUnit = ({ children, position }) => {
    return (
      <Card
        sx={{
          boxShadow: "none",
          position: "relative",
          borderRadius: "0",
          width: `calc(100%)`,
          padding: "0.8rem 2rem 0.2rem 1rem",
          border: "1px solid #D4E3F0",
          borderTop: "none",
          borderRight: "none",
          ...(position === "bottom" ? { borderBottom: "none" } : {}),
          overflow: "auto",
          height: "13.9rem",
        }}
      >
        {children}
      </Card>
    );
  };

  const AllNotesCardUnit = ({ children }) => {
    return (
      <Card
        sx={{
          boxShadow: "none",
          position: "relative",
          borderRadius: "0",
          width: `calc(100%)`,
          padding: "0.8rem 2rem 0.2rem 1rem",
          border: "none",
          overflow: "auto",
          height: "100%",
        }}
      >
        {children}
      </Card>
    );
  };

  const allNotes = [
    { type: "Monthly Check", date: "09:20AM - 11:30 2023/3/11" },
    { type: "Observation", date: "09:20AM - 11:30 2023/2/11" },
    { type: "Notes", date: "09:20AM - 11:30 2023/2/11" },
  ];

  const diagnosisData = [
    { specialty: "Migraine", date: "2023/3/11" },
    { specialty: "Coronary Artery Disease", date: "2023/2/11" },
    { specialty: "Skin Cancer", date: "2023/3/15" },
    { specialty: "Thyroid", date: "2023/4/20" },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDiagnosisClick = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/searchPatientById`, {
        patientId: patientId,
      });
      const variable = response.data;
      dispatch(addPatientInfoAction(variable));
      console.log("variable", variable);
      navigate("/searchresult", { state: variable });
    } catch (err) {
      console.error(err);
    }
  };

  const referrals = [
    { name: "Dr. William James", specialty: "Cardiologist", date: "2023/3/11" },
    {
      name: "Dr. William James",
      specialty: "General Practitioner",
      date: "2023/2/11",
    },
    { name: "Dr. Susan Smith", specialty: "Neurologist", date: "2023/3/15" },
    { name: "Dr. Ayesha Khan", specialty: "Dermatologist", date: "2023/4/20" },
    { name: "Dr. Robert Brown", specialty: "Pediatrician", date: "2023/5/18" },
    { name: "Dr. Maria Garcia", specialty: "Oncologist", date: "2023/6/22" },
    { name: "Dr. James Wilson", specialty: "Surgeon", date: "2023/7/30" },
    { name: "Dr. Lisa Adams", specialty: "Psychiatrist", date: "2023/8/5" },
  ];

  const prescriptions = [
    {
      name: "Dr. William James",
      specialty: "Dr. William James",
      date: "2023/3/11",
    },
    {
      name: "Dr. William James",
      specialty: "Dr. William James",
      date: "2023/2/11",
    },
    { specialty: "Asprin", date: "2023/3/15" },
    { specialty: "lbuprofen", date: "2023/4/20" },
    { specialty: "Asprin", date: "2023/5/18" },
    { specialty: "Advil", date: "2023/6/22" },
    { specialty: "Advil", date: "2023/7/30" },
    { specialty: "Advil", date: "2023/8/5" },
  ];

  const medicalRecords = [
    { name: "Dr. William James", specialty: "Cardiologist", date: "2023/3/11" },
    {
      name: "Dr. William James",
      specialty: "General Practitioner",
      date: "2023/2/11",
    },
    { name: "Covid Vacine", specialty: "Neurologist", date: "2023/3/15" },
    { name: "Blood Test", specialty: "Dermatologist", date: "2023/4/20" },
    { name: "Blood Test", specialty: "Pediatrician", date: "2023/5/18" },
    { name: "Covid", specialty: "Oncologist", date: "2023/6/22" },
    { name: "Covid", specialty: "Surgeon", date: "2023/7/30" },
    { name: "Covid", specialty: "Psychiatrist", date: "2023/8/5" },
  ];

  return (
    <Grid item xs={12} container>
      <Grid item xs={4}>
        <Card
          sx={{
            boxShadow: "none",
            padding: "0",
            position: "relative",
          }}
        >
          <div
            style={{
              color: "#59748A",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "5rem",
              fontSize: "1.2rem",
              gap: "2rem",
            }}
          >
            2023/4/1 - 2023/4/30 {<CalendarMonthOutlinedIcon />}
          </div>
          <Divider sx={{ borderBottomWidth: 1, borderColor: "#D4E3F0" }} />
          <AllNotesCardUnit>
            <AllNotesBox
              title={"All Notes/Past Visits"}
              buttonText={"Add Notes"}
              listData={allNotes}
              onClick={() => setShowLogVisitModal(true)}
            />
          </AllNotesCardUnit>
          <Modal open={showLogVisitModal} onClose={handleCloseLogVisitModal}>
            <Box sx={styleMini}>
              <Card  style={{
                padding:'50px'
              }}>
                <Typography variant="h6">Log Visit</Typography>
                <TextField
                  label="Date of Visit"
                  type="date"
                  value={visitDate}
                  onChange={(e) => setVisitDate(e.target.value)}
                  sx={{ width: "100%", mt: 2 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="standard"
                />
                <TextField
                  label="Start Time"
                  type="time"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  sx={{ width: "48%", mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="standard"
                  required
                />
                <TextField
                  label="End Time"
                  type="time"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  sx={{ width: "48%", ml: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="standard"
                  required
                />
                <TextField
                  label="Reason for Visit"
                  value={reasonForVisit}
                  onChange={(e) => setReasonForVisit(e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  multiline
                  required
                />
                {/* Observations */}
                <TextField
                  label="Observations"
                  multiline
                  rows={4}
                  value={notes}
                  onChange={handleNotesChange}
                  variant="standard"
                  fullWidth
                  margin="normal"
                />

                <Button
                    style={{width:'200px',
                      flex:'wrap',
                      margin:'50px',
                      color:'white',
                      backgroundColor:'#1A4FBA',
                      border:'none',
                      fontFamily:'Poppins'}}
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ mt: 2 }}
                  onClick={saveVisit}
                >
                  Save Visit
                </Button>

                <Button
                    style={{width:'200px',
                      flex:'wrap',
                      margin:'50px',
                      color:'white',
                      backgroundColor:'#1A4FBA',
                      border:'none',
                      fontFamily:'Poppins'}}
                  variant="contained"
                  onClick={handleCloseLogVisitModal}
                  color="secondary"
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Close
                </Button>
              </Card>
            </Box>
          </Modal>

          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
          />
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Grid item xs={12}>
          <CardUnit>
            <OperationBox
              title={"Diagnosis"}
              buttonText={"Add Diagnosis"}
              listData={diagnosisData}
              onClick={handleDiagnosisClick}
            />
          </CardUnit>
        </Grid>
        <Grid item xs={12}>
          <Card style={{ boxShadow: "none", position: "relative" }}>
            <Modal
                style={{ position: 'relative',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '100%',
                  minHeight: '100%',
                  boxShadow: 24,
                  pt: 2,
                  pb: 3,
                  overflowY: 'auto', }}
              open={showCreateReferralModal}
              onClose={() => setShowCreateReferralModal(false)}
            >
              <Box style={{ background: "white" }}>
                <card style={{padding:'100px'}}>
                  <Typography variant="h6">Create Referral</Typography>
                  <CreateReferralForm
                      style={{padding:'100px'}}
                    onClose={() => setShowCreateReferralModal(false)}
                    patientId={patientData.id}
                    doctorId={doctorId}
                  />
                </card>
              </Box>
            </Modal>
          </Card>
          <CardUnit position={"bottom"}>
            <OperationBox
              title={"Referrals"}
              buttonText={"Add Referrals"}
              listData={referrals}
              onClick={() => setShowCreateReferralModal(true)}
            />
          </CardUnit>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid item xs={12}>
          <CardUnit>
            <OperationBox
              title={"Prescription"}
              buttonText={"Create Prescription"}
              listData={prescriptions}
              onClick={() => setShowCreatePrescriptionModal(true)}
            />
          </CardUnit>
          <Modal
            open={showCreatePrescriptionModal}
            onClose={() => setShowCreatePrescriptionModal(false)}
          >
            <Box
              sx={{
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "50%",
                minHeight: "50%",
                pt: 2,
                px: 4,
                pb: 3,
                overflowY: "auto",
              }}
            >
              <Card>
                <CardContent>
                  <PriscriptionForm
                    patientId={patientId}
                    doctorId={doctorId}
                    patientData={patientData}
                    closeModal={() => setShowCreatePrescriptionModal(false)}
                  />
                </CardContent>
              </Card>
            </Box>
          </Modal>
        </Grid>
        <Grid item xs={12}>
          <CardUnit position={"bottom"}>
            <OperationBox
              title={"Medical Records"}
              buttonText={"Request Medical Test"}
              listData={medicalRecords}
              onClick={() => setShowRequestMedicalTestModal(true)}
            />
          </CardUnit>

          <Modal
            open={showRequestMedicalTestModal}
            onClose={() => setShowRequestMedicalTestModal(false)}
          >
            <Box>
              <Card>
                <Typography variant="outlined">
                  {" "}
                  (Scroll down and up to navigate through our web form)
                </Typography>
              </Card>
              <Card sx={styleRequestMedical}>
                <MedicalTestRequisitionForm
                  patientId={patientId}
                  doctorId={doctorId}
                  patientData={patientData}
                  doctorDetails={doctorDetails}
                />
              </Card>
              <Card sx={{ position: "relative" }}>
                <DownloadUploadForm />
                <Button
                    style={{width:'200px',
                      color:'white',
                      backgroundColor:'#1A4FBA',
                      border:'none',
                      fontFamily:'Poppins'}}
                  onClick={() => setShowRequestMedicalTestModal(false)}
                >
                  Exit
                </Button>
              </Card>
            </Box>
          </Modal>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DoctorOperations;
