import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../constants";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import "./osteoporosis.css"

function Osteoporosis() {
    const location = useLocation();
    const state = location.state;
    const patientInfo = state;
    const [osteoporosisData, setosteoporosisData] = useState(null);
    const [prediction, setPrediction] = useState("");
    const [predictionLoader, setPredictionLoader] = useState(false);
    console.log("state", state);
    const order = ["id", "ldl_c", "Ankylosing_spondylitis", "Calcitonin", "copd", "cad", "tlt", "fnt", "Calcitriol", "tl", "fn"];

    useEffect(() => {
        async function getosteoporosisData() {
            try {
                const id = patientInfo.id;
                const { data } = await axios.post(`${BASE_URL}/getOsteoporosisData`, { patientId: id });
                const features = order.map(key => data[0][key]);
                console.log("features", features);
                setosteoporosisData(features);
            } catch (err) {
                console.error(err);
            }
        }
        if (patientInfo.id) {
            getosteoporosisData();
        }
    }, [patientInfo]);

    async function predict(rowData) {
        setPredictionLoader(true);
        const requestBody = {
            "features": rowData.slice(1)
        };

        console.log("requestBody", requestBody);
        try {
            console.log('Before axios.post');
            const { data } = await axios.post(
                "https://osteoporosis2024-e0742f4746f8.herokuapp.com/predict",
                requestBody
            );
            console.log('After axios.post', data);
            setPrediction(data);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setPredictionLoader(false);
        }
    }

    function renderPredictionCell() {
        if (predictionLoader) {
            return <div style={{ color: "#59748A", fontFamily: "helvetica" }}>Loading...</div>;
        }

        if (!prediction && osteoporosisData) {
            return (
                <button
                    className="predictButton"
                    onClick={() => predict(osteoporosisData)}
                >
                    Predict
                </button>
            );
        }
        console.log("prediction", prediction)
        if (prediction !== undefined && prediction !== null) {
            return <div style={{ fontSize: "1.3rem", fontWeight: "700", color: "#59748A", fontFamily: "helvetica" }
            }>{prediction[0] === 1 ? "TRUE" : "FALSE"}</div>;
        }

        return null;
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100vh", background: "#F8FAFC" }}>
            <div style={{ fontSize: "2rem", marginBottom: "3rem", fontFamily: "helvetica", fontWeight: "700", color: "#555555", marginTop: "5rem" }}>Osteoporosis Prediction</div>
            <TableContainer sx={{ width: "70%", borderRadius: "10px", border: "1px solid rgba(224, 224, 224, 1)", overflow: "hidden" }}>
                <Table sx={{
                    minWidth: 650
                }} aria-label="customized table">
                    <TableHead>
                        <TableRow sx={{
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}>
                            <TableCell align="center" sx={{ fontSize: "1.2rem", fontWeight: "700", background: "#C6DFFF80", color: "#59748A" }}>Patient Information</TableCell>
                            <TableCell align="center" sx={{ fontSize: "1.2rem", fontWeight: "700", background: "#C6DFFF80", color: "#59748A" }}>Data</TableCell>
                            <TableCell align="center" sx={{ fontSize: "1.2rem", fontWeight: "700", background: "#C6DFFF80", color: "#59748A" }}>Prediction</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow sx={{
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}>
                            <TableCell align="center" sx={{ fontSize: "1.2rem", color: "#59748A", background: "#F3F8FC" }}>
                                {patientInfo.FName} {patientInfo.MName} {patientInfo.LName}
                            </TableCell>
                            <TableCell align="center" sx={{ background: "#F3F8FC" }} >
                                {osteoporosisData && (
                                    <div style={{ fontSize: "1.2rem", color: "#59748A", fontFamily: "helvetica", whiteSpace: "pre-wrap", display: "flex", flexWrap: "wrap", alignItems: "center", marginLeft: "2rem" }}>
                                        {osteoporosisData.map((value, index) => (
                                            <div key={index} style={{ flex: "0 0 calc(30% - 20px)", marginRight: "20px", minWidth: "10rem", marginBottom: "10px", display: "flex", alignItems: "flex-start", color: "#59748A", fontFamily: "helvetica" }}>
                                                <span style={{ fontWeight: 700, color: "#59748A", fontFamily: "helvetica" }}>{order[index]}:&nbsp;</span> {value}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </TableCell>
                            <TableCell align="center" sx={{ background: "#F3F8FC" }}>{renderPredictionCell()}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <a style={{ fontSize: "1.5rem", color: "#59748A", fontFamily: "helvetica", marginTop: "3rem", border: "2px solid rgba(224, 224, 224, 1)", padding: "10px", borderRadius: "12px", background: "" }} href="https://osteoporosis2024-e0742f4746f8.herokuapp.com/">website</a>
        </div>
    );
}

export default Osteoporosis;