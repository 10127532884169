import { Button } from "@mui/material";
import "./PatientInfoTable.css";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

const getTableColumns = (handleOpenViewModal, data) => [
    {
        name: "id",
        label: "ID",
        options: {
            filter: true,
            sort: false,
            setCellHeaderProps: () => {
                return {
                    style: {
                        textAlign: "left",
                        marginLeft: "1rem !important",
                        padding: "0 0 0 1.3rem",
                    },
                };
            },
            customBodyRenderLite: (dataIndex) => (
                <>
                    <div style={{ padding: "0 0 0 1rem" }}>{data[dataIndex].id}</div>
                </>
            ),
        },
    },
    {
        name: "Name",
        label: "Name",
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        name: "Age",
        label: "Age",
    },
    {
        name: "Gender",
        Label: "Gender",
    },
    {
        name: "status",
        label: "Status",
        options: {
            filter: true,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
                let style = {};
                let text = "";

                // mock, should be replaced when status is ready in db
                const lastDigit = data[dataIndex].id % 10;

                switch (true) {
                    case lastDigit >= 0 && lastDigit <= 3:
                        style = { backgroundColor: "#BCD2F6", color: "black" };
                        text = "Awaiting surgery";
                        break;
                    case lastDigit >= 4 && lastDigit <= 5:
                        style = { backgroundColor: "#BCE0C9", color: "black" };
                        text = "Recovered";
                        break;
                    case lastDigit >= 6 && lastDigit <= 7:
                        style = { backgroundColor: "#F0C6C5", color: "black" };
                        text = "On treatment";
                        break;
                    case lastDigit >= 8 && lastDigit <= 9:
                        style = { backgroundColor: "#D7D7D7", color: "black" };
                        text = "Inactive";
                        break;
                    default:
                        style = { backgroundColor: "#D7D7D7", color: "black" };
                        text = "Error";
                }

                return (
                    <div
                        className="doctor-patients-info-table-status-text"
                        style={{ ...style }}
                    >
                        {text}
                    </div>
                );
            },
        },
    },
    {
        name: "Last_appointment",
        label: "Last Appointment",
        options: {
            customBodyRenderLite: (dataIndex) => {
                const value = data[dataIndex].Last_appointment;
                return (
                    <div>{value ? value : "/"}</div>
                );
            },
        },
    },
    {
        name: "start",
        label: "Next Appointment",
        options: {
            customBodyRenderLite: (dataIndex) => {
                const value = data[dataIndex].start;
                return (
                    <div>{value ? value : "/"}</div>
                );
            },
        },
    },
    {
        name: "options",
        label: "Options",
        options: {
            customBodyRenderLite: (dataIndex) => {
                const rowData = data[dataIndex];
                return (
                    <Button
                        sx={{
                            color: "#A3AED0",
                        }}
                        startIcon={<MoreHorizOutlinedIcon />}
                        onClick={() => handleOpenViewModal(rowData)}
                    ></Button>
                );
            },
        },
    },
];

export default getTableColumns;