import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";

const ReferralHistory = ({ referrals }) => {
  return (
    <>
      <Typography variant="h6" sx={{ marginY: 2 }}>
        Referral History
      </Typography>
      <List>
        {referrals.length > 0 ? (
          referrals.map((referral, index) => (
            <ListItem key={index} divider>
              <ListItemText
                primary={`${referral.referredDoctorFName} ${referral.referredDoctorLName} - ${referral.referredDoctorSpecialization}`}
                secondary={`Referred on ${referral.referralDate}`}
              />
            </ListItem>
          ))
        ) : (
          <Typography variant="body1">No referrals found.</Typography>
        )}
      </List>
    </>
  );
};

export default ReferralHistory;
