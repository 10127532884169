import { Link, useLocation } from "react-router-dom";
import { Breadcrumb, Layout, Input, Space, ConfigProvider } from "antd";
import { SearchOutlined, AudioOutlined } from "@ant-design/icons";

import "./Header.css";
const breadcrumbNameMap = {
  "/Admin/dashboard": "Dashboard",
  "/Admin/profile": "Profile",
  "/Admin/messages": "Messages",
  "/Admin/management": "Management",
  "/Admin/help": "Help",

  "/doctor/dashboard": "Dashboard",
  "/doctor/profile": "Profile",
  "/doctor/patientpage": "Patients",
  "/doctor/patient-details-zhiyu": "Patient Details",
  "/doctor/messages": "Messages",
  "/doctor/calendar": "Calendar",
  "/doctor/services": "Planning",
  "/TasksList": "Task List",
  "/calendar/list": "Appointment List",

  "/patient/planning": "Planning",
  "/patient/dashboard": "Dashboard",
  "/patient/calendar": "Calendar",
  "/patient/report": "Report",

  "/ClinicalStaff/dashboard": "Dashboard",
  "/ClinicalStaff/calendar": "Calendar",
  "/patient/profile": "Profile",
  "/patient/help": "Help",
  "/patient/messages": "Messages",
};

const HeaderBreadcrumb = (props) => {
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    if (breadcrumbNameMap[url]) {
      return (
        <Breadcrumb.Item key={url}>
          <Link style={{ fontFamily: "Poppins", color: "#6E7191" }} to={url}>
            {breadcrumbNameMap[url]}
          </Link>
        </Breadcrumb.Item>
      );
    } else {
      return <></>;
    }
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link style={{ fontFamily: "Poppins", color: "#B1B5C5" }} to="/">
        Pages
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <>
      <Breadcrumb
        className="doctor-header-breadcrumb"
        style={{ display: "flex", alignItems: "center" }}
      >
        {breadcrumbItems}
      </Breadcrumb>
    </>
  );
};

const Header = (props) => {
  return (
    <Layout.Header
      style={{ backgroundColor: "inherit", padding: "0", marginBottom: "2rem" }}
    >
      <Space
        style={{
          marginTop: "1rem",
          marginLeft: "1rem",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <HeaderBreadcrumb />
      </Space>
    </Layout.Header>
  );
};

export default Header;
