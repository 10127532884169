import React from 'react';
import Home from "./home.png"
import './register.css';
import Logo from "./logo.svg";
import {Link as RouterLink} from "react-router-dom";
const register = () => {
    return (
        <div className="register-page">
            <nav className="navbar">
                <div className="navbar-brand">
                    <a href="/"><img src={Logo} alt="Your Logo"/></a> {/* Use imported logo */}
                </div>


                <div className="sign-button">
                    <RouterLink to="/">
                        <button className="btn-signin_up">Home
                        </button>
                    </RouterLink>

                </div>


            </nav>
            <section id="register" className="section register-section">
                <div className="register-content">
                    <div className="left">
                        <div className="register-title">
                            <h1>e-Hospital</h1>
                            <h4>Smart Digital Medicine Solutions</h4>
                        </div>
                        <div className="register-frame">
                            <h1>Register</h1>
                            <h4>Register as: </h4>
                            <div className="register-info">
                                <div className="row">
                                    <RouterLink to="/PatientRegistration">
                                    <button className="option-btn">Patient</button>
                                    </RouterLink>

                                    <RouterLink to="/DoctorRegistration">
                                        <button className="option-btn">Doctor</button>
                                    </RouterLink>

                                    <RouterLink to="/HospitalAdminRegistration">
                                        <button className="option-btn">Hospital</button>
                                    </RouterLink>

                                </div>
                                <div className="row">
                                    <RouterLink to="/LabAdminRegistration">
                                        <button className="option-btn">Lab</button>
                                    </RouterLink>

                                    <RouterLink to="/LabApp">
                                        <button className="option-btn">Lab Test Appointment</button>
                                    </RouterLink>
                                    </div>
                            </div>


                            <p>Already have an account?
                                <a href="/login"> Sign in</a>
                            </p>
                        </div>

                    </div>
                    <div className="right">
                        <img src={Home} alt="Home Image"/>
                    </div>
                </div>

            </section>
        </div>
    );
};

export default register;
