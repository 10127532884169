import React from "react";
import { Component } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import Homepage from "./screens/HomePage"
import LandingPage from "./screens/LandingPage";
import LandingPageHome from "./screens/LandingPageHome";
import DBConnection from "./screens/DBConnection";
import Contact from "./screens/Contact";
import JoinUs from "./screens/JoinUs";
import AdminLayout from "./layout/AdminLayout";
import AdminDashboard from "./screens/AdminPanel/AdminDashboard";
import ContactAdmin from "./screens/AdminPanel/ContactAdmin";
import DocHelpAdmin from "./screens/AdminPanel/DocHelpAdmin";
import JoinUsAdmin from "./screens/AdminPanel/JoinUsAdmin";
import MessageAdmin from "./screens/AdminPanel/MessageAdmin";
import NewUserAdmin from "./screens/AdminPanel/NewUserAdmin";
import DataAdmin from "./screens/AdminPanel/DataAdmin.jsx";
import UserManagementAdmin from "./screens/AdminPanel/UserManagementAdmin.jsx";
import HelpInquiryAdmin from "./screens/AdminPanel/HelpInquiryAdmin.jsx";
import ClinicHelpAdmin from "./screens/AdminPanel/ClinicHelpAdmin";
import PatientStaff from "./screens/ClinicalStaffPanel/PatientStaff.jsx";
import DoctorStaff from "./screens/ClinicalStaffPanel/DoctorStaff.jsx";
import ReviewAdmin from "./screens/AdminPanel/ReviewAdmin";
import ClinicalStaffLayout from "./layout/ClinicalStaffLayout";
import ClinicalStaffDashboard from "./screens/ClinicalStaffPanel/ClinicalStaffDashboard";
import StaffHelp from "./screens/ClinicalStaffPanel/StaffHelp";
import DoctorTaskStaff from "./screens/ClinicalStaffPanel/DocTaskStaff";
import PatientMessageStaff from "./screens/ClinicalStaffPanel/PatientMessageStaff";
import Searchpatient from "./screens/searchpatient";
import Searchresult from "./screens/searchresult";
import DoctorVideo from "./screens/DoctorPanel/DoctorVideo";
import Skincancerml from "./screens/skincancerml";
import PCOS from "./screens/Models/PCOS";
import SecondPCOS from "./screens/Models/SecondPCOS";
import HeartFail5 from "./screens/Models/chronicKidney";

import PneumoniaPrediction from "./screens/Models/PneumoniaPrediction";
import BoneFracture from "./screens/Models/BoneFracture";
import BrainStrokePred from "./screens/Models/BrainStrokePred";
import CoronaryArteryPrediction from "./screens/Models/CoronaryArteryPrediction";
import GlaucomaDetection from "./screens/Models/GlaucomaDetection";
import Tumor from "./screens/Models/Tumor";

import Header from "./components/Header-new";
import Footer from "./components/footer-new";
import KidneyStoneML from "./screens/eir_kidney_stone_checker";
import Ckdml from "./screens/eir_kidney_cdk_checker";
import Heartdiseaseml from "./screens/heartdiseaseml";
import LogIn from "./screens/SignInUp/login/login";
import SignUp from "./screens/SignInUp/register/register";
import PatientRegistration from "./screens/SignUp/PatientRegistration";
import DoctorRegistration from "./screens/SignUp/DoctorRegistration";
import HospitalAdminRegistration from "./screens/SignUp/HospitalAdminRegistration";
import LabAdminRegistration from "./screens/SignUp/LabAdminRegistration";
import LabApp from "./screens/SignUp/LabApp";
import Specialities from "./screens/Specialities";
import Services from "./screens/Services";
import ProfilePage from "./screens/profile.js";
import UsefulLocations from "./screens/UsefulLocations";
import "tachyons";
import SkinCancerMlPage from "./screens/skinCancerMlPage";
import BreastDisease from "./screens/Prediction/BreastDisease/BreastDisease";
import BreastCancerML from "./screens/eir_breast_cancer_checker";
import KidneyFailureML from "./screens/kidney_failure_checker.jsx";
import BreastCancerPredictionML from "./screens/breast_cancer_prediction";
import ThyroidDiseaseML from "./screens/eir_thyroid_disease_checker";
import PulmonaryDiseaseDetection from "./screens/PulmonaryDiseaseML/Components/Landing";
import ThyroidML from "./screens/eir_thyroid_disease_checker";
import Liver_disease_ML from "./screens/liver_prediction_model";
import Pneumoniaml from "./screens/Pneumoniaml";
import Bonecancerml from "./screens/Bonecancerml";
import HepatitisML from "./screens/hepatitis.jsx";
import AdminDashboardPage from "./screens/AdminNew/AdminDashboardPage.jsx";
import AdminProfilePage from "./screens/AdminNew/AdminProfilePage.jsx";
import AdminMessagesPage from "./screens/AdminNew/AdminMessagesPage.jsx";
import AdminManagementPage from "./screens/AdminNew/AdminManagementPage.jsx";
import AdminHelpPage from "./screens/AdminNew/AdminHelpPage.jsx";
import DoctorLayout from "./layout/DoctorLayout";
import Dashboard from "./screens/DoctorPanel/DoctorDashboard";
import { DoctorPatients } from "./screens/DoctorPanel/DoctorPatients";
import { DocProfile } from "./screens/DoctorPanel/DoctorProfile";
import { DoctorMessages } from "./screens/DoctorPanel/DoctorMessages";
import { DoctorServices } from "./screens/DoctorPanel/DoctorServices";
import DoctorCalendarPage from "./screens/DoctorNew/DoctorCalendarPage";
import DoctorPlanningPage from "./screens/DoctorNew/DoctorPlanningPage";
import { DoctorReferrals } from "./screens/DoctorPanel/DoctorReferrals.jsx";
import DoctorHelp from "./screens/DoctorPanel/DoctorHelp";
import HeartStroke from "./screens/HeartStroke";
import PsychologyPrediction from "./screens/PsychologyPrediction";
import EditTask from "./screens/DoctorTasks/EditTask";
import TasksListPage from "./screens/DoctorTasks/TasksList";
import TestimonialsPage from "./screens/TestimonialsPage"; // Import TestimonialsPage
import Terms from "./screens/terms";
import Rights from "./screens/rights";
import Webform from "./screens/webform";
import HealthcareModels from "./screens/ModelsHub";
import ThyroidModel from "./screens/ThyroidModel";
import DoctorCalendar from "./screens/Calendar/DoctorCalendar";
import PatientCalendar from "./screens/Calendar/PatientCalendar";
import PatientBookTime from "./screens/Calendar/PatientBookTime";
import TimeSegmentDetail from "./screens/Calendar/TimeSegmentDetail";
import DoctorAppointmentListPage from "./screens/Calendar/DoctorAppointmentList";
import ServicesHomePage from "./components/services/ServicesHomePage";
import HealthServices from "./components/services/HealthServices";
import DoctorService from "./components/services/DoctorService";
import PatientService from "./components/services/PatientService";
import HeartFail from "./screens/HeartFail";
import BrainStroke from "./screens/BrainStroke";
import VoiceRecoginition from "./screens/VoiceRecoginition.js";
import VideoBackground from "./styles/screens/VideoBackground";
import Chatbot from "./screens/Chatbot/Chatbot";
import Sidebar from "./components/SideBar";
import PatientLayout from "./layout/PatientLayout.jsx";
import LandingIntro from "./screens/LandingIntro.js";
import { PatientPortal } from "./screens/PatientPanel/PatientPortal.jsx";
import {
  readLoginData,
  clearLoginData,
  isTempLogin,
  writeLoginData,
} from "./loginData.js";
import "./App.css";
import SkinDiseasesMlPage from "./screens/SkinDiseasesMlPage.js";
import ViewRating from "./screens/ViewRating";
import DiabeticML from "./screens/DiabeticML.js";
import { PatientReport } from "./screens/PatientReport/PatientReport.jsx";
import { PatientPrescriptionRefill } from "./screens/PatientPrescriptionRefill/PatientPrescriptionRefill.jsx";
import ClinicalStaffDashboardNew from "./screens/ClinicalStaffPanelNew/ClinicalStaffDashboard.jsx";
import ClinicalStaffCalendarPage from "./screens/ClinicalStaffPanelNew/ClinicalStaffCalendar.jsx";
import Analytic from "./screens/analytics.js";
import { PatientPlanning } from "./screens/PatientPanel/PatientPlanning/PatientPlanning.jsx";
import { PatientReferrals } from "./screens/PatientPanel/PatientReferrals.jsx";
import PatientAppointmentList from "./screens/Calendar/PatientAppointmentList.jsx";
import DoctorDashboard from "./screens/DoctorNew/DoctorDashboard.jsx";
import DoctorProfilePage from "./screens/DoctorNew/DoctorProfilePage.jsx";
import PatientPage from "./screens/DoctorNew/PatientPage.jsx";
import PatientCalendarPage from "./screens/PatientPanel/PatientCalendarPage";
import SclerosisDiseaseChecker from "./screens/sclerosis_disease_checker";
import Dementia from "./screens/Models/Dementia.js";
import Osteoporosis from "./screens/Models/Osteoporosis.js";
import DieasePrognosis from "./screens/DiseasePrognosis.jsx";
import HeartDisease from "./screens/HeartDisease.jsx";
import Insomnia from "./screens/Insomnia.jsx";
import LungCancer from "./screens/Models/LungCancer.js";
import AtrialFibrillation from "./screens/Models/AtrialFibrillation.js";
import StrokePrediction from "./screens/Models/StrokePrediction.js";
import HeartAnomalies from "./screens/Models/HeartAnomalies.js";
import BrainAnomalies from "./screens/Models/BrainAnomalies.js";
import ThyroidPrediction from "./screens/Models/ThyroidPrediction.js";

import HeartFail2 from "./screens/Models/coronaryArteryDisease";
import HeartFail3 from "./screens/Models/dementiaDetection";

import HeartFail4 from "./screens/Models/rheumatoidArthritis";
import FundunsEye from "./screens/Models/FundunsEye.js";
import PageLayout from "./components/PageLayout/PageLayout.jsx";
import PatientProfilePage from "./screens/PatientPanel/PatientProfilePage.jsx";
import PharmaceuticalsDashboard from "./screens/Pharmaceuticals/Dashboard/PharmaceuticalsDashboard.jsx";
import PharmaceuticalsClinicalTrail  from "./screens/Pharmaceuticals/ClinicalTrail/PharmaceuticalsClinicalTrail.jsx";
import PharmaceuticalsClinicalTrailAdd  from "./screens/Pharmaceuticals/ClinicalTrail/PharmaceuticalsClinicalTrailAdd.jsx";
import PharmaceuticalsSpecificClinicalTrail from './screens/Pharmaceuticals/ClinicalTrail/PharmaceuticalsSpecificClinicalTrail.jsx';
import PharmaceuticalsManagement  from "./screens/Pharmaceuticals/Management/PharmaceuticalsManagement.jsx";
import PharmaceuticalsProfileView  from "./screens/Pharmaceuticals/View/PharmaceuticalsProfileView.jsx";
import  PharmaceuticalsHelp  from "./screens/Pharmaceuticals/Help/PharmaceuticalsHelp.jsx";
import { PatientMessages } from "./screens/PatientPanel/PatientMessages.jsx";
import PatientHelp from "./screens/PatientPanel/PatientHelp.jsx";
import HeartDiseaseTwo from "./screens/HeartDiseaseTwo.jsx";
import DiabetesPrognosis from "./screens/DiabetesPrognosis.jsx";
import CoronaryDisease from "./screens/CoronaryDisease.jsx";
import EpilepticSeizure from "./screens/Epileptic_seizure.jsx";
import RheumatoidArthritisTwo from "./screens/rheumatoid.jsx";
import CovidPred from "./screens/Models/CovidPred";
import AlzheimerAnalysis from "./screens/AlzheimerAnalysis";
const ClassicLayout = (props) => {
  return (
    <>
      <button className="menu-button" onClick={props.toggleSidebar}>
        &#9776;
      </button>
      {props.isSidebarOpen && (
        <Sidebar isOpen={props.isSidebarOpen} onClose={props.toggleSidebar} />
      )}

      <Outlet />

    </>
  );
};

class App extends Component {
  constructor() {
    super();
    this.state = {
      isSidebarOpen: false,
      user: (() => readLoginData())(),
    };
  }

  loadUser = (data) => {
    const userInfo = {
      type: data.type,
      id: data.id,
      name: data.name,
      email: data.email,
      startInPage: data.startInPage,
    };
    writeLoginData(userInfo, true);
    this.setState({
      user: userInfo,
    });
  };

  loadTempUser = (data) => {
    const userInfo = {
      type: data.type,
      id: data.id,
      name: data.name,
      email: data.email,
      startInPage: data.startInPage,
    };
    writeLoginData(userInfo, false);
    this.setState({
      user: userInfo,
    });
  };

  toggleSidebar = () => {
    this.setState((prevState) => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));
  };
  clearUser = () => {
    const loginData = readLoginData();

    //https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/users/inactiveUser
    if (loginData.type === "Patient") {
      fetch(
        "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/users/inactiveUser",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: loginData.email,
          }),
        }
      )
        .then((res) => {
          if (res.status === 200) {
            clearLoginData();
          } else {
            throw new Error("Error in processing request");
          }
        })
        .catch((error) => {
          console.error("There was an error during the fetch:", error);
        });
      this.setState({
        user: {
          type: "NotLoggedIn",
          id: -1,
          name: "",
          email: "",
          startInPage: "",
        },
      });
    } else {
      clearLoginData();
      this.setState({
        user: {
          type: "NotLoggedIn",
          id: -1,
          name: "",
          email: "",
          startInPage: "",
        },
      });
    }
  };
  componentDidMount() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  }
  handleBeforeUnload = (e) => {
    const loginData = readLoginData();
    if (isTempLogin() && loginData.type === "Patient") {
      try {
        fetch(
          "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/users/inactiveUser",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: loginData.email,
            }),
          }
        );
      } catch (err) {
        console.error("Error with signing out user", err);
      }
      e.preventDefault();
      e.returnValue = "";
      return "Are you sure you want to leave? Your changes may not be saved.";
    }
  };
  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  }

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route
            path="/Admin"
            element={<PageLayout clearUser={this.clearUser} user="Admin" />}
          >
            <Route index element={<Navigate to="/Admin/dashboard" />} />
            <Route path="/Admin/dashboard" element={<AdminDashboardPage />} />
            <Route path="/Admin/profile" element={<AdminProfilePage />} />
            <Route path="/Admin/messages" element={<AdminMessagesPage />} />
            <Route path="/Admin/management" element={<AdminManagementPage />} />
            <Route path="/Admin/help" element={<AdminHelpPage />} />
          </Route>
          <Route
            path="/ClinicalStaff"
            element={<ClinicalStaffDashboardNew clearUser={this.clearUser} />}
          />
          <Route
            path="/ClinicalStaff/dashboard"
            element={<ClinicalStaffDashboardNew clearUser={this.clearUser} />}
          />
          <Route
            path="/ClinicalStaff/calendar"
            element={<ClinicalStaffCalendarPage clearUser={this.clearUser} />}
          />

          
          <Route
            path="/pharmaceuticals"
            element={
              <PageLayout user="Pharmaceuticals" clearUser={this.clearUser} />
            }
          >
            <Route index element={<Navigate to="/pharmaceuticals/dashboard" />} />
            <Route path="/pharmaceuticals/dashboard" element={<PharmaceuticalsDashboard />} />
            <Route path="/pharmaceuticals/clinicaltrial" element={<PharmaceuticalsClinicalTrail />} />
            <Route path="/pharmaceuticals/clinicaltrialadd" element={<PharmaceuticalsClinicalTrailAdd />} />
            <Route path="/pharmaceuticals/clinicaltrial/specificclinicaltrial/:trial_id" element={<PharmaceuticalsSpecificClinicalTrail />} />
            <Route path="/pharmaceuticals/management" element={<PharmaceuticalsManagement />} />
            <Route path="/pharmaceuticals/view/:viewCategory/:viewId" element={<PharmaceuticalsProfileView />} />
            <Route path="/pharmaceuticals/help" element={<PharmaceuticalsHelp />} />
          </Route>

          <Route
            path="/doctor"
            element={<PageLayout clearUser={this.clearUser} />}
          >
            <Route path="/doctor/dashboard" element={<DoctorDashboard />} />
            <Route
              path="/doctor/patientpage"
              element={<PatientPage clearUser={this.clearUser} />}
            />
            <Route
              path="/doctor/profile"
              element={<DoctorProfilePage clearUser={this.clearUser} />}
            />
            <Route path="/doctor/calendar" element={<DoctorCalendarPage />} />
            <Route path="/doctor/services" element={<DoctorPlanningPage />} />
            <Route path="/doctor/messages" element={<DoctorMessages />} />
            <Route path="/doctor/help" element={<DoctorHelp />} />
          </Route>
          {this.state.user.type === "Doctor" ? (
            <>
              <Route
                path="/calendar/list"
                element={
                  <DoctorAppointmentListPage clearUser={this.clearUser} />
                }
              />
            </>
          ) : null}
          <Route
            path="/TasksList"
            element={<TasksListPage clearUser={this.clearUser} />}
          />
          <Route
            path="/patient/calendar"
            element={<PatientCalendarPage clearUser={this.clearUser} />}
          />
          <Route path="/dementia" element={<Dementia />} />
          <Route path="/osteoporosis" element={<Osteoporosis />} />
          <Route path="/lungcancer" element={<LungCancer />} />
          <Route path="/fundunsEye" element={<FundunsEye />} />
          <Route path="/atrialFibrillation" element={<AtrialFibrillation />} />
          <Route path="/strokePrediction" element={<StrokePrediction />} />
          <Route path="/heartAnomalies" element={<HeartAnomalies />} />
          <Route path="/brainAnomalies" element={<BrainAnomalies />} />
          <Route path="/thyroidPrediction" element={<ThyroidPrediction />} />
          <Route
            path="/"
            element={
              <ClassicLayout
                isSidebarOpen={this.state.isSidebarOpen}
                toggleSidebar={this.toggleSidebar}
                user={this.state.user}
                clearUser={this.clearUser}
              />
            }
          >
            <Route path="/" element={<Homepage />} />
            <Route path="/LandingIntro" element={<LandingIntro />} />
            <Route path="/LandingPage" element={<LandingPage />} />
            <Route path="/ViewRating" element={<ViewRating />} />
            <Route
              path="/LogIn"
              element={
                this.state.user.type === "NotLoggedIn" ? (
                  <LogIn
                    loadUser={this.loadUser}
                    loadTempUser={this.loadTempUser}
                  />
                ) : (
                  <Navigate to={`${this.state.user.startInPage}`} />
                )
              }
            />
            <Route
              path="/SignUp"
              element={
                this.state.user.type === "NotLoggedIn" ? (
                  <SignUp loadUser={this.loadUser} />
                ) : (
                  <Navigate to={`${this.state.user.startInPage}`} />
                )
              }
            />
            <Route path="/searchpatient" element={<Searchpatient />} />
            <Route path="/skincancerml" element={<Skincancerml />} />
            <Route path="/pcos" element={<PCOS />} />
            <Route path="/secondpcos" element={<SecondPCOS />} />
            <Route path="/alzheimer" element={<AlzheimerAnalysis />} />
            <Route path="/heartDisease" element={<HeartDiseaseTwo />} />
            <Route
              path="/pneumoniaPrediction"
              element={<PneumoniaPrediction />}
            />

            <Route path="/CovidPred" element={<CovidPred />} />

            <Route path="/BoneFracture" element={<BoneFracture />} />
            <Route path="/BrainStrokePred" element={<BrainStrokePred />} />
            <Route
              path="/CoronaryArteryPrediction"
              element={<CoronaryArteryPrediction />}
            />
            <Route path="/GlaucomaDetection" element={<GlaucomaDetection />} />
            <Route path="/Tumor" element={<Tumor />} />
            <Route path="/chronicKidney" element={<HeartFail5 />} />
            <Route path="/skinCancerMLPage" element={<SkinCancerMlPage />} />
            <Route
              path="/sclerosisDiagnosis"
              element={<SclerosisDiseaseChecker />}
            />
            <Route
              path="/skinCancerDiseasesPage"
              element={<SkinDiseasesMlPage />}
            />
            <Route path="/insomnia" element={<Insomnia />} />
            <Route path="/Searchresult" element={<Searchresult />} />
            <Route path="/DoctorVideo" element={<DoctorVideo />} />
            <Route path="/DBConnection" element={<DBConnection />} />
            <Route path="/testimonial" element={<TestimonialsPage />} />{" "}
            {/* Use TestimonialsPage */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/JoinUs" element={<JoinUs />} />
            <Route
              path="/Admin"
              element={<AdminLayout adminInfo={this.state.user} />}
            >
              <Route index element={<AdminDashboard />} />
              <Route path="/Admin/message" element={<MessageAdmin />} />
              <Route path="/Admin/newuser" element={<NewUserAdmin />} />
              <Route
                path="/Admin/usermanagement"
                element={<UserManagementAdmin />}
              />
              <Route path="/Admin/helpinquiry" element={<HelpInquiryAdmin />} />
              <Route path="/Admin/data" element={<DataAdmin />} />
              <Route path="/Admin/dashboard" element={<AdminDashboard />} />
              <Route path="/Admin/dochelp" element={<DocHelpAdmin />} />
              <Route path="/Admin/clinichelp" element={<ClinicHelpAdmin />} />
              <Route path="/Admin/contact" element={<ContactAdmin />} />
              <Route path="/Admin/review" element={<ReviewAdmin />} />
              <Route path="/Admin/joinus" element={<JoinUsAdmin />} />
            </Route>
            <Route
              path="/ClinicalStaff"
              element={<ClinicalStaffLayout adminInfo={this.state.user} />}
            >
              {/*<Route index element={<ClinicalStaffDashboard />} />*/}
              <Route
                path="/ClinicalStaff/dashboard-old"
                element={<ClinicalStaffDashboard />}
              />
              <Route
                path="/ClinicalStaff/patientmanagement"
                element={<PatientStaff />}
              />
              <Route
                path="/ClinicalStaff/doctormanagement"
                element={<DoctorStaff />}
              />
              <Route
                path="/ClinicalStaff/NewPatient"
                element={<PatientRegistration />}
              />
              <Route
                path="/ClinicalStaff/patientmessage"
                element={<PatientMessageStaff />}
              />
              <Route
                path="/ClinicalStaff/DoctorTask"
                element={<DoctorTaskStaff />}
              />
              <Route
                path="/ClinicalStaff/techsupport"
                element={<StaffHelp />}
              />
            </Route>

            <Route path="/terms" element={<Terms />} />
            <Route path="/rights" element={<Rights />} />
            <Route path="/webform" element={<Webform />} />
            <Route path="/Tasks/:id" element={<EditTask />} />
            {/*<Route path="/TasksList" element={<TasksList />} />*/}
            <Route path="/kidneyfailure" element={<KidneyFailureML />} />
            <Route path="/diease-prognosis" element={<DieasePrognosis />} />
            <Route path="/diabetes-prognosis" element={<DiabetesPrognosis />} />
            <Route path="/heart-disease" element={<HeartDisease />} />
            <Route path="/kidneystoneml" element={<KidneyStoneML />} />
            <Route path="/chronickidneyml" element={<Ckdml />} />
            <Route path="/BreastDisease" element={<BreastDisease />} />
            <Route path="/breastcancerml" element={<BreastCancerML />} />
            <Route
              path="/breastcancerpredictionml"
              element={<BreastCancerPredictionML />}
            />
            <Route path="/coronaryDisease" element={<CoronaryDisease />} />
            <Route path="/epilepticSeizure" element={<EpilepticSeizure />} />
            <Route
              path="/rheumatoidArthritisTwo"
              element={<RheumatoidArthritisTwo />}
            />
            <Route path="/thyroidDiseaseml" element={<ThyroidML />} />
            <Route path="/Pneumoniaml" element={<Pneumoniaml />} />
            <Route path="/heartfailure" element={<HeartFail />} />
            <Route path="/coronaryArteryDisease" element={<HeartFail2 />} />
            <Route path="/dementiaDetection" element={<HeartFail3 />} />
            <Route path="/rheumatoidArthritis" element={<HeartFail4 />} />
            <Route path="/brainstroke" element={<BrainStroke />} />
            <Route path="/Bonecancerml" element={<Bonecancerml />} />
            <Route path="/hepatitis" element={<HepatitisML />} />
            <Route path="/heartdiseaseml" element={<Heartdiseaseml />} />
            <Route path="/heartstroke" element={<HeartStroke />} />
            <Route
              path="/PsychologyPrediction"
              element={<PsychologyPrediction />}
            />
            <Route
              path="/PatientRegistration"
              element={<PatientRegistration loadUser={this.loadUser} />}
            />
            <Route
              path="/DoctorRegistration"
              element={<DoctorRegistration loadUser={this.loadUser} />}
            />
            <Route
              path="/HospitalAdminRegistration"
              element={<HospitalAdminRegistration loadUser={this.loadUser} />}
            />
            <Route
              path="/LabAdminRegistration"
              element={<LabAdminRegistration loadUser={this.loadUser} />}
            />
            <Route path="/LabApp" element={<LabApp />} />
            <Route path="/specialities" element={<Specialities />} />
            <Route path="/serviceHome" element={<ServicesHomePage />} />
            <Route path="/services" element={<HealthServices />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/bookAppServices" element={<Services />} />
            <Route path="/doctorServices" element={<DoctorService />} />
            <Route path="/patientServices" element={<PatientService />} />
            <Route path="/services" element={<Services />} />
            <Route path="/serviceshomepage" element={<ServicesHomePage />} />
            <Route path="/usefulLocations" element={<UsefulLocations />} />
            <Route path="/liverdiseaseML" element={<Liver_disease_ML />} />
            <Route path="/pulmonaryDiseaseML" element={<PulmonaryDiseaseDetection/>} />
            <Route path="/VoiceRecoginition" element={<VoiceRecoginition />} />
            <Route
              path="/Chatbot"
              element={<Chatbot userInfo={this.state.user} />}
            />
            <Route
              path="/doctor"
              element={<DoctorLayout doctorInfo={this.state.user} />}
            >
              {/* <Route index element={<Dashboard />} /> */}
              {/* <Route path="/doctor/dashboard" element={<Dashboard />} /> */}
              <Route path="/doctor/patients" element={<DoctorPatients />} />
              {/*<Route path="/doctor/profile" element={<DocProfile />} />*/}
              {/*<Route path="/doctor/messages" element={<DoctorMessages />} />*/}
              <Route path="/doctor/services" element={<DoctorServices />} />
              {/*<Route path="/doctor/help" element={<DoctorHelp />} />*/}
              <Route path="/doctor/referrals" element={<DoctorReferrals />} />
            </Route>
            <Route path="/HealthcareModels" element={<HealthcareModels />} />
            <Route path="/ThyroidModel" element={<ThyroidModel />} />
            <Route
              path="/calendar"
              element={
                this.state.user.type === "Doctor" ? (
                  <DoctorCalendar />
                ) : (
                  <PatientCalendar />
                )
              }
            />
            <Route
              path="/calendar/timesegment/:id"
              element={<TimeSegmentDetail />}
            />
            <Route path="/calendar/booktime" element={<PatientBookTime />} />
            <Route
              path="/calendar/list"
              element={
                this.state.user.type ===
                "Doctor" ? /*<DoctorAppointmentListPage />*/ null : (
                  <PatientAppointmentList />
                )
              }
            />
            <Route path="/Analytic" element={<Analytic />} />
            <Route path="/DiabeticML" element={<DiabeticML />} />
          </Route>
          <Route
            path="/patient"
            element={
              <PageLayout
                clearUser={this.clearUser}
                user="Patient"
                patientID={this.state.user.id}
              />
            }
          >
            <Route index element={<PatientPortal />} />
            <Route path="/patient/dashboard" element={<PatientPortal />} />
            <Route path="/patient/report" element={<PatientReport />} />
            <Route path="/patient/planning" element={<PatientPlanning />} />
            <Route path="/patient/referrals" element={<PatientReferrals />} />
            <Route
              path="/patient/prescriptionRefills"
              element={<PatientPrescriptionRefill />}
            />
            <Route
              path="/patient/profile"
              element={<PatientProfilePage clearUser={this.clearUser} />}
            />
            <Route path="/patient/messages" element={<PatientMessages />} />
            <Route path="/patient/help" element={<PatientHelp />} />
          </Route>
          <Route path="/HealthcareModels" element={<HealthcareModels />} />
          <Route path="/ThyroidModel" element={<ThyroidModel />} />
          <Route
            path="/calendar"
            element={
              this.state.user.type === "Doctor" ? (
                <DoctorCalendar />
              ) : (
                <PatientCalendar />
              )
            }
          />
          <Route
            path="/calendar/timesegment/:id"
            element={<TimeSegmentDetail />}
          />
          <Route path="/calendar/booktime" element={<PatientBookTime />} />
          {/*<Route
            path="/calendar/list"
            element={
              this.state.user.type === "Doctor" ? (
                <DoctorAppointmentList />
              ) : (
                <PatientAppointmentList />
              )
            }
          />*/}
          <Route path="/Analytic" element={<Analytic />} />
          <Route path="/DiabeticML" element={<DiabeticML />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
export default App;
