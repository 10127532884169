import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

const PressureChart = ({ start, data }) => {
  const [gaugeData, setGaugeData] = useState([
    {
      value: 0,
      name: "EDA",
      title: {
        offsetCenter: ["0%", "-15%"],
      },
      detail: {
        valueAnimation: true,
        offsetCenter: ["0%", "15%"],
      },
    },
  ]);

  useEffect(() => {
    // console.log("spo2", data);
    // const value = data?.spo2?.[0];
    const value = data?.spo2;
    console.log("spo2", value);
    if (value !== undefined) {
      setGaugeData([
        {
          ...gaugeData[0],
          value: value,
          name: 95 < value && value < 98 ? "Normal" : "Unnormal",
        },
      ]);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      clearChartData();
      console.log("SPO2 Chart cleared");
    };
  }, []);

  function clearChartData() {
    setGaugeData([{ ...gaugeData[0], value: 0, name: "NULL" }]);
  }

  const getOption = () => {
    return {
      series: [
        {
          type: "gauge",
          startAngle: 90,
          endAngle: -270,
          min: 0,
          max: 100,
          pointer: {
            show: false,
          },
          progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: false,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#464646",
            },
          },
          axisLine: {
            lineStyle: {
              width: 20,
            },
          },
          splitLine: {
            show: false,
            distance: 0,
            length: 10,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
            distance: 50,
          },
          data: gaugeData,
          title: {
            fontSize: 16,
          },
          detail: {
            width: 80,
            height: 25,
            fontSize: 20,
            color: "inherit",
            borderColor: "inherit",
            borderRadius: 20,
            borderWidth: 1,
            formatter: "{value}",
          },
        },
      ],
    };
  };

  return (
    <ReactECharts
      option={getOption()}
      style={{ height: "100%", width: "100%" }}
    />
  );
};

export default PressureChart;
