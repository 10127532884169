// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-modal-close {
  display: none !important;
};

.ant-modal-mask {
  background-color: transparent !important;
}`, "",{"version":3,"sources":["webpack://./src/screens/DoctorNew/PatientPage.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B,CAAA;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".ant-modal-close {\n  display: none !important;\n};\n\n.ant-modal-mask {\n  background-color: transparent !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
