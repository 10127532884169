import React from "react";
import { Button, Col, Flex, Row } from "antd";
import { Box, Card, CardContent, Typography, Grid, CardActionArea } from "@mui/material";
import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import VaccinesOutlinedIcon from '@mui/icons-material/VaccinesOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ChecklistIcon from '@mui/icons-material/Checklist';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';

import { readLoginData } from "../../loginData";
import { SurgeryPlanning } from "../../components/DoctorComponents/SurgeryPlanningCreate";
import { ViewSurgeryPlans } from "../../components/DoctorComponents/SurgeryPlanningGrid";
import { Widget, addResponseMessage } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import "./DoctorPlanningPage.css";

const PageContent = () => {
  const doctorId = readLoginData().id;
  const navigate = useNavigate();
  const [lastTimeoutId, setLastTimeoutId] = useState(null);

  const services = [
    { icon: VaccinesOutlinedIcon, title: "Create a New Surgery Plan", description: "Expert surgical planning and post-operative care." },
    { icon: ListAltOutlinedIcon, title: "View Surgery Plans", description: "Expert surgical planning and post-operative care." },
    { icon: CalendarTodayOutlinedIcon, title: "Calendar", description: "Doctor Calendar" },
    { icon: ChecklistIcon, title: "Task Management", description: "All Tasks of the Doctor" },
    { icon: PendingActionsOutlinedIcon, title: "Appointment List", description: "List of All Appointments of the Doctor" },
    // Add more services as needed
    { icon:  GridViewRoundedIcon, title: "Other Planning", description: "The Description of other planning" },
  ];

  const [openSurgeryModal, setOpenSurgeryModal] = useState(false);
  const [viewSurgeryPlansModal, setViewSurgeryPlansModal] = useState(false);

  const handleCardClick = (title) => {
    // Placeholder for click handler logic
    if (title === "Create a New Surgery Plan") {
        setOpenSurgeryModal(true);
    } else if (title === "View Surgery Plans"){
        setViewSurgeryPlansModal(true);
    } else if (title === "Calendar") {
        navigate('/doctor/calendar');
    } else if (title === "Task Management") {
        navigate('/TasksList');
    } else if (title === "Appointment List") {
        navigate('/calendar/list');
    }
    console.log(`Clicked on ${title}`);
  };


  const callDeleteDoctorConversationAPI = async () => {
    try {
      const url = `https://chatbot-2024-90539106da8b.herokuapp.com/delete_doctor_app_conversation/${doctorId}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(), // Assuming requestData is defined globally
      });
      if (!response.ok) {
        console.error('Error:', response.status);
      }
    } catch (error) {
      console.error('Error calling delete_doctor_app_conversation API:', error);
    }
  };
  
  const handleNewUserMessage = async (newMessage) => {
    clearTimeout(lastTimeoutId);
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
      const requestData = {
        userObject: {
          userInput: {
            message: newMessage,
            timezone: userTimezone,
          },
        },
      };
      const url = `https://chatbot-2024-90539106da8b.herokuapp.com/appointment_doctor/${doctorId}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
      if (response.ok) {
        const responseData = await response.json();
        addResponseMessage(responseData);
        const timeoutId = setTimeout(() => {
          console.log("Chat inactive for 5 minutes. Closing chat or showing message...");
          callDeleteDoctorConversationAPI(); // Call the common function here
          addResponseMessage("Chat reset due to inactivity");
        }, 5* 60 * 1000); // 5 minutes in milliseconds
        setLastTimeoutId(timeoutId);
      } else {
        console.error('Error:', response.status);
      }
    } catch (error) {
      console.error('Error fetching response from API:', error);
      addResponseMessage("Sorry, something went wrong. Please try again later.");
    }
  };
  
  window.addEventListener('beforeunload', function (event) {
    callDeleteDoctorConversationAPI(); // Call the common function here
    event.preventDefault();
  });
  
  return <>
    <Row wrap={true} gutter={[77, 63]} className="doctor-planning-page">
      {services.map((service, index) => (
        <Col span={8}>
          <Button
              className='planning-button'
              onClick={() => handleCardClick(service.title)}>
            <div className="planning-button-icon">{React.createElement(service.icon, { fontSize: "large" })}</div>
            {service.title}
          </Button>
        </Col>
      ))}
    </Row>
    {/* SurgeryPlanning Modal */}
    <SurgeryPlanning open={openSurgeryModal} onClose={() => setOpenSurgeryModal(false)} doctorId={doctorId} />
    <ViewSurgeryPlans open={viewSurgeryPlansModal} onClose={() => setViewSurgeryPlansModal(false)} doctorId={doctorId} />
    <Widget
  handleNewUserMessage={handleNewUserMessage}
/>
  </>;
};

export const DoctorPlanningPage = () => {
  return <PageContent />;
};

export default DoctorPlanningPage;
