import React, { useCallback } from "react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import ForumIcon from "@mui/icons-material/Forum";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import Sidebar from "./SideBar";
import TopNavMobile from "./TopNavMobile";

const DoctorSideBar = (props) => {
  const navigate = useNavigate();

  const getItem = (label, key, icon, action, children, type) => ({
    key,
    icon,
    children,
    label,
    type,
    action,
  });

  const menuItems = useMemo(
    () => [
      getItem("Dashboard", "Dashboard", <HomeIcon />, "/doctor/dashboard"),
      getItem("Profile", "Profile", <AccountCircleIcon />, "/doctor/profile"),
      getItem(
        "Patient",
        "Patient",
        <PersonSearchIcon />,
        "/doctor/patientpage"
      ),
      getItem("Messages", "Messages", <ForumIcon />, "/doctor/messages"),
      getItem(
        "Planning",
        "Planning",
        <MedicalServicesIcon />,
        "/doctor/services"
      ),
      getItem(
        "Calendar",
        "Calendar",
        <CalendarMonthIcon />,
        "/doctor/calendar"
      ),
      getItem("Help", "Help", <FavoriteIcon />, "/doctor/help"),
      getItem("Log out", "Logout", <LogoutIcon />, () => {
        props.clearUser();
        navigate("/LogIn");
      }),
    ],
    [navigate, props]
  );

  const menuItemsNoLogout =
     [
      getItem("Dashboard", "Dashboard", <HomeIcon />, "/doctor/dashboard"),
      getItem("Profile", "Profile", <AccountCircleIcon />, "/doctor/profile"),
      getItem(
        "Patient",
        "Patient",
        <PersonSearchIcon />,
        "/doctor/patientpage"
      ),
      getItem("Messages", "Messages", <ForumIcon />, "/doctor/messages"),
      getItem(
        "Planning",
        "Planning",
        <MedicalServicesIcon />,
        "/doctor/services"
      ),
      getItem(
        "Calendar",
        "Calendar",
        <CalendarMonthIcon />,
        "/doctor/calendar"
      ),
      getItem("Help", "Help", <FavoriteIcon />, "/doctor/help"),
    ]

  const onLogOut = useCallback(() => {
    props.clearUser();
    navigate("/LogIn");
  }, [navigate, props])

  //the mobile layout is not designed to be responsive; the initial loading has to be in a small screen
  let width = window.innerWidth;
  if (width <= 605) {
    return <>
      <TopNavMobile
        menuItems={menuItemsNoLogout}
        onLogOut={onLogOut} />

    </>;
  } else {
    return <>
      <Sidebar
        menuItems={menuItems}
      />
    </>;
  }
};

export default DoctorSideBar;
