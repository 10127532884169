import React, { useState, useEffect } from "react";
import { Box, Card, CardContent, TextField, Grid, Button, Typography, MenuItem, Select, InputLabel, FormControl, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import { readLoginData } from '../../../loginData';
import 'react-phone-input-2/lib/style.css';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import 'react-country-flag';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "../PharmaceuticalsContent.css";
import { checkExistingClinicalTrialsId, createNewClinicalTrials } from '../../../api/user';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';

const ClinicalTrialListTableTitle = ({ title }) => (
  <div className="pharmaceuticals-dashboard-table-title">
    <span>{title}</span>
  </div>
);

const PharmaceuticalsClinicalTrialAdd = () => {
  const navigate = useNavigate();
  const companyInfo = readLoginData();
  const [formData, setFormData] = useState({
    phone: '',
    country: '',
    region: '',
    startDate: null,
    endDate: null,
    primaryPurpose: '',
    trialPhase: '',
    studyType: '',
    allocation: '',
    interventionModel: '',
    masking: '',
    maskingDetails: {
      participant: false,
      investigator: false
    },
    sponsor: '',
    principalInvestigator: '',
    pathology: '',
    ageRange: { min: '', max: '' },
    gender: '',
    bmi: { min: '', max: '' },
    diseases: [],
    surgeries: [],
    priorMedications: '',
    pregnancy: '',
    firstName: '',
    middleName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    trialName: '',
    trialId: '',
    officialTitle: '',
    briefSummary: '',
    detailedDescription: '',
    relatedConditions: '',
    ethicsApproval: '' // 新增字段
  });

  const [trialIdError, setTrialIdError] = useState(false);
  const [ageRangeError, setAgeRangeError] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    const savedData = localStorage.getItem('clinicalTrialData');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('clinicalTrialData', JSON.stringify(formData));
  }, [formData]);

  const textFieldStyles = {
    inputProps: { style: { fontSize: 16, borderRadius: 0 } },
    InputLabelProps: { style: { fontWeight: 'bold', fontSize: 16 } },
    variant: 'outlined',
    margin: 'dense',
    fullWidth: true,
  };

  const labelStyle = {
    color: '#000',
    fontSize: 15,
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      maskingDetails: {
        ...prevState.maskingDetails,
        [name]: checked
      }
    }));
  };

  const handleSelectChange = (name, value) => {
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCountryChange = (val) => {
    setFormData(prevState => ({
      ...prevState,
      country: val,
      region: ''
    }));
  };

  const handleRegionChange = (val) => {
    setFormData(prevState => ({
      ...prevState,
      region: val
    }));
  };

  const handleTrialIdChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setFormData(prevState => ({
        ...prevState,
        trialId: value
      }));
      setTrialIdError(false);
    } else {
      setTrialIdError(true);
      setSnackbarMessage('Trial ID must be a number.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleTrialIdBlur = async () => {
    if (formData.trialId) {
      try {
        const exists = await checkExistingClinicalTrialsId(formData.trialId);
        if (exists) {
          setTrialIdError(true);
          setFormData(prevState => ({
            ...prevState,
            trialId: ''
          }));
          setSnackbarMessage('Trial ID already exists.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        } else {
          setTrialIdError(false);
        }
      } catch (error) {
        console.error('Error checking trial ID:', error);
      }
    }
  };

  const handleAgeBlur = () => {
    const minAge = parseInt(formData.ageRange.min, 10) || 0;
    const maxAge = parseInt(formData.ageRange.max, 10) || 99;

    if (minAge > maxAge) {
      setAgeRangeError(true);
      setFormData(prevState => ({
        ...prevState,
        ageRange: { min: '', max: '' }
      }));
      setSnackbarMessage('Max age cannot be less than min age.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else {
      setAgeRangeError(false);
      setFormData(prevState => ({
        ...prevState,
        ageRange: { min: minAge, max: maxAge }
      }));
    }
  };

  const validateFormData = () => {
    let isValid = true;
    let missingFields = [];

    Object.keys(formData).forEach(key => {
      if (key === 'ageRange' || key === 'bmi' || key === 'endDate' || key === 'middleName') return;
      if (!formData[key]) {
        isValid = false;
        missingFields.push(key);
      }
    });

    if (!formData.ethicsApproval) {
      isValid = false;
      missingFields.push('ethicsApproval');
    }

    if (!isValid) {
      setSnackbarMessage(`Missing fields: ${missingFields.join(', ')}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }

    return isValid;
  };

  const handleBMIChange = (field, value) => {
    setFormData(prevState => ({
      ...prevState,
      bmi: {
        ...prevState.bmi,
        [field]: value
      }
    }));
  };

  const getBMICondition = () => {
    const { min, max } = formData.bmi;
    if (!min && !max) return '> 1 and < 99';
    if (min && max) {
      return parseFloat(min) > parseFloat(max) ? `> ${min} or < ${max}` : `> ${min} and < ${max}`;
    }
    if (min) return `> ${min}`;
    if (max) return `< ${max}`;
    return '';
  };

  const handleSubmit = async () => {
    if (validateFormData()) {
      const formDataToSubmit = {
        ...formData,
        ageRange: `${formData.ageRange.min || '0'}-${formData.ageRange.max || '99'}`,
        bmi: getBMICondition()
      };

      console.log(formDataToSubmit);

      try {
        await createNewClinicalTrials({ formDataToSubmit, companyInfo });
        setSnackbarMessage('Clinical trial created successfully.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        localStorage.removeItem('clinicalTrialData');
        setFormData({
          phone: '',
          country: '',
          region: '',
          startDate: null,
          endDate: null,
          primaryPurpose: '',
          trialPhase: '',
          studyType: '',
          allocation: '',
          interventionModel: '',
          masking: '',
          maskingDetails: {
            participant: false,
            investigator: false
          },
          sponsor: '',
          principalInvestigator: '',
          pathology: '',
          ageRange: { min: '', max: '' },
          gender: '',
          bmi: { min: '', max: '' },
          diseases: [],
          surgeries: [],
          priorMedications: '',
          pregnancy: '',
          firstName: '',
          middleName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          trialName: '',
          trialId: '',
          officialTitle: '',
          briefSummary: '',
          detailedDescription: '',
          relatedConditions: ''
        });
        alert("Clinical trial created successfully! Click to back to the trials list.")
        navigate(`/pharmaceuticals/clinicaltrial`);
      } catch (error) {
        setSnackbarMessage('Failed to create clinical trial.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 3, backgroundColor: '#f7f9fc' }}>
      <Grid container spacing={3}>
        {/* Contact Information Card */}
        <Grid item xs={12} md={6}>
          <Card sx={{ padding: 2, boxShadow: 3, backgroundColor: 'white', height: '100%' }}>
            <CardContent>
              <ClinicalTrialListTableTitle title="Contact Information" />
              <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography style={labelStyle}>First Name</Typography>
                    <TextField
                      {...textFieldStyles}
                      placeholder="Enter First Name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={labelStyle}>Middle Name</Typography>
                    <TextField
                      {...textFieldStyles}
                      placeholder="Enter Middle Name"
                      name="middleName"
                      value={formData.middleName}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={labelStyle}>Last Name</Typography>
                    <TextField
                      {...textFieldStyles}
                      placeholder="Enter Last Name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={labelStyle}>Phone Number</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <PhoneInput
                          country={'us'}
                          value={formData.phone}
                          onChange={phone => setFormData(prevState => ({ ...prevState, phone }))}
                          inputStyle={{ width: '100%', fontSize: 14, borderRadius: 0 }}
                          containerStyle={{ marginBottom: '16px' }}
                          buttonStyle={{ borderRadius: 0 }}
                          dropdownStyle={{ borderRadius: 0 }}
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          {...textFieldStyles}
                          placeholder="Enter Phone Number"
                          name="phoneNumber"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={labelStyle}>Email</Typography>
                    <TextField
                      {...textFieldStyles}
                      placeholder="Enter Email Address"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Trial Basic Information */}
        <Grid item xs={12} md={6}>
          <Card sx={{ padding: 2, boxShadow: 3, backgroundColor: 'white', height: '100%' }}>
            <CardContent>
              <ClinicalTrialListTableTitle title="Trial Basic Information" />
              <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography style={labelStyle}>Trial Name</Typography>
                    <TextField
                      {...textFieldStyles}
                      placeholder="Enter Trial Name"
                      name="trialName"
                      value={formData.trialName}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={labelStyle}>Trial ID</Typography>
                    <TextField
                      {...textFieldStyles}
                      placeholder="Enter Trial ID"
                      name="trialId"
                      value={formData.trialId}
                      onChange={handleTrialIdChange}
                      onBlur={handleTrialIdBlur}
                      error={trialIdError}
                      helperText={trialIdError ? 'Invalid or existing Trial ID' : ''}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={labelStyle}>Locations</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <CountryDropdown
                          value={formData.country}
                          onChange={handleCountryChange}
                          style={{ width: '100%', height: '40px', borderRadius: 0 }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <RegionDropdown
                          country={formData.country}
                          value={formData.region}
                          onChange={handleRegionChange}
                          style={{ width: '100%', height: '40px', borderRadius: 0 }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={labelStyle}>Official Title</Typography>
                    <TextField
                      {...textFieldStyles}
                      placeholder="Enter Official Title"
                      name="officialTitle"
                      value={formData.officialTitle}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Brief Summary Card */}
        <Grid item xs={12} md={4}>
          <Card sx={{ padding: 2, boxShadow: 3, backgroundColor: 'white', height: '100%' }}>
            <CardContent>
              <ClinicalTrialListTableTitle title="Brief Summary" />
              <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      {...textFieldStyles}
                      multiline
                      rows={4}
                      placeholder="Enter Brief Summary"
                      name="briefSummary"
                      value={formData.briefSummary}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Detailed Description Card */}
        <Grid item xs={12} md={8}>
          <Card sx={{ padding: 2, boxShadow: 3, backgroundColor: 'white', height: '100%' }}>
            <CardContent>
              <ClinicalTrialListTableTitle title="Detailed Description" />
              <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      {...textFieldStyles}
                      multiline
                      rows={4}
                      placeholder="Enter Detailed Description"
                      name="detailedDescription"
                      value={formData.detailedDescription}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Trial Details Card */}
        <Grid item xs={12}>
          <Card sx={{ padding: 2, boxShadow: 3, backgroundColor: 'white', height: 'auto' }}>
            <CardContent>
              <ClinicalTrialListTableTitle title="Trial Details" />
              <Grid container spacing={3}>
                {/* Nested Trial Details Card */}
                <Grid item xs={12} md={6}>
                  <Card sx={{ padding: 2, boxShadow: 0, backgroundColor: 'transparent', height: 'auto' }}>
                    <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                      <Grid container spacing={2}>
                        {/* Section 1 */}
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <Typography style={labelStyle}>Primary Purpose</Typography>
                              <FormControl fullWidth>
                                <InputLabel>Purpose</InputLabel>
                                <Select
                                  value={formData.primaryPurpose}
                                  onChange={(e) => handleSelectChange('primaryPurpose', e.target.value)}
                                  variant="outlined"
                                >
                                  <MenuItem value="Treatment">Treatment</MenuItem>
                                  <MenuItem value="Prevention">Prevention</MenuItem>
                                  <MenuItem value="Diagnostic">Diagnostic</MenuItem>
                                  <MenuItem value="Supportive Care">Supportive Care</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography style={labelStyle}>Trial Phase</Typography>
                              <FormControl fullWidth>
                                <InputLabel>Phase</InputLabel>
                                <Select
                                  value={formData.trialPhase}
                                  onChange={(e) => handleSelectChange('trialPhase', e.target.value)}
                                  variant="outlined"
                                >
                                  <MenuItem value="Phase I">Phase I</MenuItem>
                                  <MenuItem value="Phase II">Phase II</MenuItem>
                                  <MenuItem value="Phase III">Phase III</MenuItem>
                                  <MenuItem value="Phase IV">Phase IV</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography style={labelStyle}>Study Type</Typography>
                              <FormControl fullWidth>
                                <InputLabel>Type</InputLabel>
                                <Select
                                  value={formData.studyType}
                                  onChange={(e) => handleSelectChange('studyType', e.target.value)}
                                  variant="outlined"
                                >
                                  <MenuItem value="Interventional">Interventional</MenuItem>
                                  <MenuItem value="Observational">Observational</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* Section 2 */}
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <Typography style={labelStyle}>Allocation</Typography>
                              <FormControl fullWidth>
                                <InputLabel>Allocation</InputLabel>
                                <Select
                                  value={formData.allocation}
                                  onChange={(e) => handleSelectChange('allocation', e.target.value)}
                                  variant="outlined"
                                >
                                  <MenuItem value="Randomized">Randomized</MenuItem>
                                  <MenuItem value="Non-randomized">Non-randomized</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography style={labelStyle}>Intervention Model</Typography>
                              <FormControl fullWidth>
                                <InputLabel>Model</InputLabel>
                                <Select
                                  value={formData.interventionModel}
                                  onChange={(e) => handleSelectChange('interventionModel', e.target.value)}
                                  variant="outlined"
                                >
                                  <MenuItem value="Single Group">Single Group</MenuItem>
                                  <MenuItem value="Parallel">Parallel</MenuItem>
                                  <MenuItem value="Crossover">Crossover</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography style={labelStyle}>Masking</Typography>
                              <FormControl fullWidth>
                                <InputLabel>Masking</InputLabel>
                                <Select
                                  value={formData.masking}
                                  onChange={(e) => handleSelectChange('masking', e.target.value)}
                                  variant="outlined"
                                >
                                  <MenuItem value="None (Open Label)">None (Open Label)</MenuItem>
                                  <MenuItem value="Single">Single</MenuItem>
                                  <MenuItem value="Double">Double</MenuItem>
                                </Select>
                              </FormControl>
                              {(formData.masking === 'Single' || formData.masking === 'Double') && (
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={formData.maskingDetails.participant}
                                        onChange={handleCheckboxChange}
                                        name="participant"
                                      />
                                    }
                                    label="Participant"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={formData.maskingDetails.investigator}
                                        onChange={handleCheckboxChange}
                                        name="investigator"
                                      />
                                    }
                                    label="Investigator"
                                  />
                                </FormGroup>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                </Grid>

                {/* Nested Trial Dates and Ethics Card */}
                <Grid item xs={12} md={6}>
                  <Card sx={{ padding: 2, boxShadow: 0, backgroundColor: 'transparent', height: 'auto' }}>
                    <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography style={labelStyle}>Start Date</Typography>
                          <DatePicker
                            selected={formData.startDate}
                            onChange={(date) => setFormData(prevState => ({ ...prevState, startDate: date }))}
                            dateFormat="yyyy/MM/dd"
                            placeholderText="YYYY/MM/DD"
                            customInput={<TextField {...textFieldStyles} />}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography style={labelStyle}>End Date</Typography>
                          <DatePicker
                            selected={formData.endDate}
                            onChange={(date) => setFormData(prevState => ({ ...prevState, endDate: date }))}
                            dateFormat="yyyy/MM/dd"
                            placeholderText="YYYY/MM/DD"
                            customInput={<TextField {...textFieldStyles} />}
                          />
                        </Grid>
                      </Grid>
                      <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography style={labelStyle}>Sponsor</Typography>
                            <TextField
                              {...textFieldStyles}
                              placeholder="Enter Sponsor Details"
                              name="sponsor"
                              value={formData.sponsor}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography style={labelStyle}>Principal Investigator</Typography>
                            <TextField
                              {...textFieldStyles}
                              placeholder="Enter Principal Investigator"
                              name="principalInvestigator"
                              value={formData.principalInvestigator}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography style={labelStyle}>Ethics Approval</Typography>
                            <TextField
                              {...textFieldStyles}
                              placeholder="Enter Ethics Approval Details"
                              name="ethicsApproval"
                              value={formData.ethicsApproval}
                              onChange={handleInputChange}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Inclusion Criteria Card */}
        <Grid item xs={12} md={6}>
          <Card sx={{ padding: 2, boxShadow: 3, backgroundColor: 'white', height: 'auto' }}>
            <CardContent>
              <ClinicalTrialListTableTitle title="Inclusion Criteria" />
              <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography style={labelStyle}>Related Conditions</Typography>
                    <TextField
                      {...textFieldStyles}
                      placeholder="Enter Related Conditions"
                      name="relatedConditions"
                      value={formData.relatedConditions}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={labelStyle}>Pathology</Typography>
                    <FormControl fullWidth>
                      <InputLabel>Pathology</InputLabel>
                      <Select
                        value={formData.pathology}
                        onChange={(e) => handleSelectChange('pathology', e.target.value)}
                        variant="outlined"
                      >
                        <MenuItem value="Hypertension">Hypertension</MenuItem>
                        <MenuItem value="Type 2 Diabetes">Type 2 Diabetes</MenuItem>
                        <MenuItem value="Asthma">Asthma</MenuItem>
                        {/* Add more options as needed */}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={labelStyle}>Gender</Typography>
                    <FormControl fullWidth>
                      <InputLabel>Gender</InputLabel>
                      <Select
                        value={formData.gender}
                        onChange={(e) => handleSelectChange('gender', e.target.value)}
                        variant="outlined"
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Both">Both</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={labelStyle}>Age Range (Min)</Typography>
                    <TextField
                      {...textFieldStyles}
                      type="number"
                      placeholder="Min Age"
                      name="ageRangeMin"
                      value={formData.ageRange.min}
                      onChange={(e) => setFormData(prevState => ({ ...prevState, ageRange: { ...prevState.ageRange, min: e.target.value } }))}
                      onBlur={handleAgeBlur}
                      error={ageRangeError}
                      helperText={ageRangeError ? 'Invalid age range' : ''}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={labelStyle}>Age Range (Max)</Typography>
                    <TextField
                      {...textFieldStyles}
                      type="number"
                      placeholder="Max Age"
                      name="ageRangeMax"
                      value={formData.ageRange.max}
                      onChange={(e) => setFormData(prevState => ({ ...prevState, ageRange: { ...prevState.ageRange, max: e.target.value } }))}
                      onBlur={handleAgeBlur}
                      error={ageRangeError}
                      helperText={ageRangeError ? 'Invalid age range' : ''}
                    />
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Exclusion Criteria Card */}
        <Grid item xs={12} md={6}>
          <Card sx={{ padding: 2, boxShadow: 3, backgroundColor: 'white', height: 'auto' }}>
            <CardContent>
              <ClinicalTrialListTableTitle title="Exclusion Criteria" />
              <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography style={labelStyle}>BMI Range</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          {...textFieldStyles}
                          type="number"
                          placeholder="Min BMI"
                          name="bmiMin"
                          value={formData.bmi.min}
                          onChange={(e) => handleBMIChange('min', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          {...textFieldStyles}
                          type="number"
                          placeholder="Max BMI"
                          name="bmiMax"
                          value={formData.bmi.max}
                          onChange={(e) => handleBMIChange('max', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    <Typography style={labelStyle}>
                      {getBMICondition()}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={labelStyle}>Diseases</Typography>
                    <FormControl fullWidth>
                      <InputLabel>Diseases</InputLabel>
                      <Select
                        value={formData.diseases}
                        onChange={(e) => handleSelectChange('diseases', e.target.value)}
                        variant="outlined"
                      >
                        <MenuItem value="Cardiovascular Diseases">Cardiovascular Diseases</MenuItem>
                        <MenuItem value="Endocrine Diseases">Endocrine Diseases</MenuItem>
                        <MenuItem value="Respiratory Diseases">Respiratory Diseases</MenuItem>
                        <MenuItem value="Digestive Diseases">Digestive Diseases</MenuItem>
                        <MenuItem value="Renal Disease">Renal Disease</MenuItem>
                        <MenuItem value="Neurological Diseases">Neurological Diseases</MenuItem>
                        <MenuItem value="Immunological Diseases">Immunological Diseases</MenuItem>
                        <MenuItem value="Infectious Diseases">Infectious Diseases</MenuItem>
                        <MenuItem value="Cancer">Cancer</MenuItem>
                        <MenuItem value="Liver Disease">Liver Disease</MenuItem>
                        <MenuItem value="Dermatological Diseases">Dermatological Diseases</MenuItem>
                        <MenuItem value="Musculoskeletal Diseases">Musculoskeletal Diseases</MenuItem>
                        <MenuItem value="Mental Health Disorders">Mental Health Disorders</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={labelStyle}>Surgeries</Typography>
                    <FormControl fullWidth>
                      <InputLabel>Surgeries</InputLabel>
                      <Select
                        value={formData.surgeries}
                        onChange={(e) => handleSelectChange('surgeries', e.target.value)}
                        variant="outlined"
                      >
                        <MenuItem value="Recent surgeries">Recent surgeries</MenuItem>
                        <MenuItem value="Recent abdominal surgery">Recent abdominal surgery</MenuItem>
                        <MenuItem value="Recent brain surgery">Recent brain surgery</MenuItem>
                        <MenuItem value="Thoracic surgery">Thoracic surgery</MenuItem>
                        <MenuItem value="Recent lung surgery">Recent lung surgery</MenuItem>
                        <MenuItem value="Joint replacement surgery">Joint replacement surgery</MenuItem>
                        <MenuItem value="Recent joint surgery">Recent joint surgery</MenuItem>
                        <MenuItem value="Recent breast surgery">Recent breast surgery</MenuItem>
                        <MenuItem value="Recent thoracic surgery">Recent thoracic surgery</MenuItem>
                        <MenuItem value="Recent bowel surgery">Recent bowel surgery</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={labelStyle}>Prior Medications</Typography>
                    <TextField
                      {...textFieldStyles}
                      placeholder="Enter Prior Medications"
                      name="priorMedications"
                      value={formData.priorMedications}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={labelStyle}>Pregnancy</Typography>
                    <FormControl fullWidth>
                      <InputLabel>Pregnancy</InputLabel>
                      <Select
                        value={formData.pregnancy}
                        onChange={(e) => handleSelectChange('pregnancy', e.target.value)}
                        variant="outlined"
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Unrestricted">Unrestricted</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Create Trials
          </Button>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PharmaceuticalsClinicalTrialAdd;