import React, { useState } from 'react'
import { Flex, Tabs, Card } from "antd";
import PatientMiniCalendar from "./PatientCalendar/PatientMiniCalendar";
import PatientUpcomingTasks from "./PatientCalendar/PatientUpcomingTasks";
import { HealthOverview } from "./DataVisualization/HealthOverview";
import { PatientReminder } from "../../components/PatientComponents/patient-reminder/PatientReminder";
import { RecentMedicalRecords } from "./RecentMedicalRecords";

const PatientPortalMobile = props => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [dataRefresh, setDataRefresh] = useState(false);
    const handleDataChange = () => {
        setDataRefresh(true);
        setTimeout(() => {
            setDataRefresh(false);
        });
    };

    const tabItems = [
        {
            key: '1',
            label: 'Current Medication',
            children: <PatientReminder />
        },
        {
            key: '2',
            label: 'Health Overview',
            children:
                <Card
                    elevation={0}
                    style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "1em",
                        height: "100%",
                    }}
                >
                    <HealthOverview />
                </Card>,
        },
        {
            key: '3',
            label: 'Medical Records',
            children:
                <Card
                    elevation={0}
                    style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "1em",
                        height: "100%",
                    }}
                >
                    <RecentMedicalRecords />
                </Card>,
        },
    ]
    return (
        <Flex
            vertical
            gap="middle">
            <Tabs defaultActiveKey='1' items={tabItems} />
            <Card>
                {dataRefresh ? null : (
                    <>
                        <PatientMiniCalendar
                            date={selectedDate}
                            onSelectDate={(date) => setSelectedDate(date)}
                        />
                        <PatientUpcomingTasks
                            date={selectedDate}
                            onDataChange={handleDataChange}
                        />
                    </>
                )}
            </Card>
        </Flex>
    )
}

export default PatientPortalMobile