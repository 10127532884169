import { Spin } from "antd";
import moment from "moment";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";

import "./PlanningCalendarView.css";

const getStyleFromEvent = (event) => {
  const style = {};

  style.color = 'black';
  style.border = "1px solid black";

  const { category, status, appointmentStatus } = event;
  if(appointmentStatus === undefined || appointmentStatus === 1 || (appointmentStatus === null && status === 0)){
    if(category === 1){ style.backgroundColor = '#EB00FF4D' }
    else if(category === 3){ style.backgroundColor = '#D9D9D9FF' }
    else if(category === 4){ style.backgroundColor = '#FFA9004D' }
    else if(category === 5){ style.backgroundColor = '#3377DD4D' }
    else{ style.backgroundColor = '#40DC8263' }
  }else if(appointmentStatus === 0){
    style.backgroundColor = '#ADEDCD80';
    style.border = "1px dotted black";
  }else if(appointmentStatus === null){
    style.backgroundColor = '#DD52334D';
  }

  return style;
}

const WeekPlanningEvent = ({ event }) => {
  console.log("PlanningEvent", event);

  return <>
    <div className='planning-event'>
      <div className='planning-event-content'>
        <div className='planning-event-text'>
          <div style={{ color: "#1A4FBA" }}>{ `${moment(event.start).format('h:mm')}-${moment(event.end).format('h:mm')}` }</div>
          <br/>
          { event.description }
        </div>
      </div>
    </div>
  </>;
};

const MonthPlanningEvent = ({ event }) => {
  console.log("PlanningEvent", event);

  return <>
    <div className='planning-event'>
      <div className='planning-event-content'>
        <div className='planning-event-text'>
          { event.description ? event.description : <>&nbsp;</> }
        </div>
      </div>
    </div>
  </>;
};

const TimeSegmentsView = (props) => {
  const localizer = momentLocalizer(moment);
  console.log("Data", props.data);
  const eventsList = props.data.map(e => ({
    ...e,
    start: moment(e.start).toDate(),
    end: moment(e.end).toDate(),
  }));
  const eventStyleGetter = (event, start, end, isSelected) => {
    return {
      style: {
        padding: "0",
        borderRadius: '8px',
        opacity: "0.8",
        display: 'block',
        ...getStyleFromEvent(event),
      }
    };
  }

  return (
    <>
      <Calendar
        className="planning-calendar"
        toolbar={false}
        selectable={props.selectable}
        defaultView={Views.WEEK}
        localizer={localizer}
        events={eventsList}
        date={props.date}
        view={props.view}
        onRangeChange={props.onRangeChange}
        onSelectSlot={props.onSelectSlot}
        onSelectEvent={props.onSelectEvent}
        eventPropGetter={eventStyleGetter}
        startAccessor="start"
        endAccessor="end"
        components={{
          week: {
            event: WeekPlanningEvent,
          },
          month: {
            event: MonthPlanningEvent,
          }
        }}
      />
    </>
  );
}

export const calcRange = (date, view) => {
  if(view === 'week') {
    return [moment(date).startOf('week').toDate(), moment(date).endOf('week').toDate()];
  }else if(view === 'month') {
    return [moment(date).startOf('month').toDate(), moment(date).endOf('month').toDate()];
  }else if(view === 'day') {
    return [moment(date).startOf('day').toDate(), moment(date).endOf('day').toDate()];
  }else if(view === 'agenda') {
    return [moment(date).startOf('day').toDate(), moment(date).endOf('day').add(1, 'month').toDate()];
  }
};

const PlanningCalendarView = (props) => {
  console.log("PlanningCalendarView", props);
  return <>
    <Spin spinning={props.loading}>
      <TimeSegmentsView
        {...props}
        data={props.data}
        date={props.date}
        view={props.view}

        onSelectSlot={(r) => props.onSelectSlot?.(r)}
        onSelectEvent={(e) => props.onSelectEvent?.(e)}
      />
    </Spin>
  </>;
};

export default PlanningCalendarView;
