// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bone-container {
    width: 50vw;
    height: 50vh;
  }
  
  td {
    text-align: center;
  }
  
  .predictButton {
    background-color: #ef5350 !important;
  }
  
  .saveButton {
    background-color: #42a5f5 !important;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .skin-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    row-gap: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/screens/boneMl.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;EACd;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,oCAAoC;EACtC;;EAEA;IACE,oCAAoC;IACpC,uBAAkB;IAAlB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;IACd,aAAa;EACf","sourcesContent":[".bone-container {\n    width: 50vw;\n    height: 50vh;\n  }\n  \n  td {\n    text-align: center;\n  }\n  \n  .predictButton {\n    background-color: #ef5350 !important;\n  }\n  \n  .saveButton {\n    background-color: #42a5f5 !important;\n    width: fit-content;\n  }\n  \n  .skin-page {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin: 20px 0;\n    row-gap: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
