import React, { useState } from "react";
import moment from "moment";
import { Button, Card, Col, Divider, Flex, Row, Tag } from "antd";
import { useNavigate } from "react-router-dom";

import { readLoginData } from "../../loginData";
import { doctorGetCalendar } from "../../api/calendar";

import PlanningCalendarToolbar from "../../components/CalendarComponents/PlanningCalendarToolbar";
import PlanningCalendarView, {
  calcRange,
} from "../../components/CalendarComponents/PlanningCalendarView";
import CreateDoctorTask from "./CreateDoctorTask";
import TimeSegmentDialog from "./TimeSegmentDialog";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./DoctorCalendarPage.css";

const DoctorCalendar = (props) => {
  const navigate = useNavigate();

  const loginData = readLoginData();
  let [needLoad, setNeedLoad] = useState(true);
  let [loading, setLoading] = useState(true);
  let [data, setData] = useState([]);

  let [date, setDate] = useState(moment().toDate());
  let [view, setView] = useState("week");

  if (needLoad) {
    (async () => {
      setLoading(true);
      setNeedLoad(false);
      const range = calcRange(date, view);
      const response = await doctorGetCalendar(loginData, range[0], range[1]);
      setData(response);
      console.log("response", response);
      setLoading(false);
    })();
  }

  // states for the modal
  const [createOpen, setCreateOpen] = useState(false);
  const [start, setStart] = useState(moment().toDate());
  const [end, setEnd] = useState(moment().add(1, "hour").toDate());

  const [detailId, setDetailId] = useState(null);
  const handleSelectEvent = (event) => {
    console.log("open detail", event);
    setDetailId(event.id);
  };

  const handleCreateEvent = () => {
    const start = moment(date).startOf("day").add("12", "hour");
    const end = moment(date).startOf("day").add("12.5", "hour");
    setStart(start.toDate());
    setEnd(end.toDate());
    setCreateOpen(true);
  };

  const handleSelectSlot = (range) => {
    if (range.action === "select") {
      const start = moment(range.start);
      const end = moment(range.end);
      if (start.clone().add(24, "hour").isAfter(end)) {
        setStart(start.toDate());
        setEnd(end.toDate());
        setCreateOpen(true);
      }
    }
  };

  const handleOk = () => {
    setCreateOpen(false);
    setNeedLoad(true);
  };

  const handleCancel = () => {
    setCreateOpen(false);
  };

  return (
    <>
      <PlanningCalendarToolbar
        date={date}
        view={view}
        hasCreateButton={true}
        onNavigate={(d) => {
          setDate(d);
          setNeedLoad(true);
        }}
        onView={(v) => {
          setView(v);
          setNeedLoad(true);
        }}
        onCreateEvent={handleCreateEvent}
      />
      <Row justify="space-between" className="calendar-categories" gutter={10}>
        <Col span={4}>
          <Tag color="#D9D9D9FF">Surgery</Tag>
        </Col>
        <Col span={4}>
          <Tag color="#FFA9004D">General Consultation</Tag>
        </Col>
        <Col span={4}>
          <Tag color="#3377DD4D">Lab Testing</Tag>
        </Col>
        <Col span={4}>
          <Tag color="#EB00FF4D">Task</Tag>
        </Col>
        <Col span={4}>
          <Tag color="#40DC8263">W/O State</Tag>
        </Col>
        <Col span={4}>
          <Tag color="#ADEDCD80" style={{ border: "1px dotted" }}>
            Pending Approval
          </Tag>
        </Col>
      </Row>
      <Card
        bordered={false}
        style={{
          height: "95.8%",
          marginBottom: "4.2%",
          backgroundColor: "white",
          borderRadius: "1.5rem",
        }}
      >
        <PlanningCalendarView
          selectable={true}
          loading={loading}
          data={data}
          date={date}
          view={view}
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleSelectEvent}
        />
        {createOpen ? (
          <CreateDoctorTask
            category={2}
            start={start}
            end={end}
            onOk={handleOk}
            onCancel={handleCancel}
          />
        ) : null}
        {detailId ? (
          <TimeSegmentDialog
            id={detailId}
            onClose={() => setDetailId(null)}
            onApprove={() => setNeedLoad(true)}
          />
        ) : null}
      </Card>
    </>
  );
};

const DoctorCalendarPage = () => {
  return (
    <>
      <Flex
        justify="space-evenly"
        style={{ height: "100%", marginTop: "2rem" }}
      >
        <Flex vertical gap="1rem" style={{ display: "flex", width: "100%" }}>
          <DoctorCalendar />
        </Flex>
      </Flex>
    </>
  );
};

export default DoctorCalendarPage;
