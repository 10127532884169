import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import remindersDrugsIcon from "../../../assets/images/reminders-drugs.svg";
import remindersPillIcon from "../../../assets/images/reminders-pill.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Switch from "@mui/material/Switch";
import Alert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./style.css";

export function PatientReminder() {
  const patientId = useOutletContext();
  const [patientReminders, setPatientReminders] = useState([]);
  const [alert, setAlert] = useState("");

  useEffect(() => {
    //https://e-react-node-backend-22ed6864d5f3.herokuapp.com
    //https://e-react-node-backend-22ed6864d5f3.herokuapp.com
    axios
      .post(
        "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/getPrescriptionsByPatientId",
        { patientId }
      )
      .then((response) => {
        const modifiedData = response.data.map((item) => ({
          ...item,
        }));
        console.log(modifiedData);
        setPatientReminders(modifiedData);
      })
      .catch((error) =>
        console.error("Error fetching created referrals:", error)
      );
  }, [patientId]);

  const [checked, setChecked] = React.useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const currentTime = `${now.getHours().toString().padStart(2, "0")}:${now
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;

      patientReminders.forEach((prescription) => {
        if (prescription.frequency === "Daily" && currentTime === "12:00") {
          setAlert(
            `Time to take ${prescription.quantity} ${prescription.quantity_unit}s ${prescription.medicine_name}  ${prescription.dose}${prescription.dose_unit} `
          );
          setChecked(true);
        }
      });
    }, 5000);

    return () => clearInterval(timer);
  }, [patientReminders]);

  useEffect(() => {
    let timer = null;
    if (checked) {
      timer = setTimeout(() => {
        setChecked(false);
      }, 8000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [checked]);

  const MedicationItem = ({ prescription }) => {
    return (
      <>
        <div className="patient-reminders-item">
          <div className="patient-reminders-icon-container">
            <img src={remindersPillIcon} alt="pill" style={{ width: "50%" }} />
          </div>
          <div
            className="patient-reminders-text-container"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div
              className="patient-reminders-item-title"
              style={{ fontFamily: "Poppins" }}
            >
              {prescription.medicine_name}&nbsp;
              {prescription.dose}
              {prescription.dose_unit}
            </div>
            <div
              className="patient-reminders-subtitle"
              style={{ color: "#00000099", fontFamily: "Poppins" }}
            >
              {prescription.quantity}&nbsp;
              {prescription.quantity_unit}(S),&nbsp;{prescription.frequency}
            </div>
          </div>
          <div
            className="patient-reminders-arrow-container"
            style={{ position: "absolute", right: "1em", top: "1em" }}
          >
            <ArrowForwardIosIcon sx={{ color: "#00000099", width: "70%" }} />
          </div>
        </div>
      </>
    );
  };

  const MedicationList = ({ listLength }) => {
    return (
      <div style={{ marginLeft: "1em" }}>
        {patientReminders
          .filter((reminder) => reminder.medicine_id !== null)
          .map((reminder, index, array) => (
            <React.Fragment key={index}>
              <MedicationItem prescription={reminder} />
              {index !== array.length - 1 && (
                <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }} />
              )}
            </React.Fragment>
          ))}
      </div>
    );
  };

  return (
    <>
      <Box className="patient-reminders-container">
        <Box className="patient-reminders-title">
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "1.4rem",
              fontFamily: "Poppins",
              color: "#1A4EBA",
              textAlign: "left",
            }}
            gutterBottom
            mb={0}
          >
            Current Medication
          </Typography>
        </Box>
        <Stack
          className="patient-reminders-list-container"
          direction="column"
          justifyContent="center"
          spacing={4}
          sx={{ mt: 0 }}
        >
          <MedicationList />
        </Stack>
      </Box>
      <Box className="patient-reminders-alert-container">
        <Slide direction="down" in={checked} mountOnEnter unmountOnExit>
          <Alert
            className="patient-reminders-alert"
            severity="info"
            sx={{
              border: "1.5px solid #0288D1",
              borderRadius: "10px",
              fontFamily: "Poppins, Arial, sans-serif",
              "& .MuiAlert-message": {
                fontFamily: "inherit",
              },
              fontSize: "1.1rem",
              "& .MuiAlert-icon": {
                fontSize: "1.6rem",
              },
              display: "flex",
              alignItems: "center",
              "& .MuiAlert-action": {
                padding: "0 0 0 0.5em ",
              },
            }}
            onClose={(checked) => {
              setChecked(!checked);
            }}
          >
            {alert}
          </Alert>
        </Slide>
      </Box>
    </>
  );
}
