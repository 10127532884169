import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import MessageIcon from "@mui/icons-material/Message";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HelpIcon from "@mui/icons-material/Help";
import PagesIcon from "@mui/icons-material/Pages";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import TopNavMobile from "./TopNavMobile";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import LogoutIcon from "@mui/icons-material/Logout";
import Sidebar from "./SideBar";
import { useMemo } from "react";

const SideBar = (props) => {
  const navigate = useNavigate();

  const getItem = (label, key, icon, action, children, type) => ({
    key,
    icon,
    children,
    label,
    type,
    action,
  });
  const menuItems = useMemo(
    () => [
      getItem("Dashboard", "Dashboard", <PagesIcon />, "/patient/dashboard"),
      getItem("Profile", "Profile", <AccountBoxIcon />, "/patient/profile"),
      getItem(
        "HealthReport",
        "HealthReport",
        <TrackChangesIcon />,
        "/patient/report"
      ),
      getItem("Messages", "Messages", <MessageIcon />, "/patient/messages"),
      getItem(
        "Planning",
        "Planning",
        <MedicalServicesIcon />,
        "/patient/planning"
      ),
      getItem(
        "Calendar",
        "Calendar",
        <CalendarMonthIcon />,
        "/patient/calendar"
      ),
      getItem("Help", "Help", <HelpIcon />, "/patient/help"),
      getItem("Log out", "Logout", <LogoutIcon />, () => {
        props.clearUser();
        navigate("/LogIn");
      }),
    ],
    [navigate, props]
  );

  const menuItemsNoLogout = [
    getItem("Dashboard", "Dashboard", <PagesIcon />, "/patient/dashboard"),
    getItem("Profile", "Profile", <AccountBoxIcon />, "/patient/profile"),
    getItem(
      "HealthReport",
      "HealthReport",
      <TrackChangesIcon />,
      "/patient/report"
    ),
    getItem("Messages", "Messages", <MessageIcon />, "/patient/messages"),
    getItem(
      "Planning",
      "Planning",
      <MedicalServicesIcon />,
      "/patient/planning"
    ),
    getItem("Calendar", "Calendar", <CalendarMonthIcon />, "/patient/calendar"),
    getItem("Help", "Help", <HelpIcon />, "/patient/help"),
    getItem("Log out", "Logout", <LogoutIcon />, () => {
      props.clearUser();
      navigate("/LogIn");
    }),
  ];

  const onLogOut = useCallback(() => {
    props.clearUser();
    navigate("/LogIn");
  }, [navigate, props]);

  let width = window.innerWidth;
  if (width <= 605) {
    return (
      <>
        <TopNavMobile menuItems={menuItemsNoLogout} onLogOut={onLogOut} />
      </>
    );
  } else {
    return (
      <>
        <Sidebar menuItems={menuItems} />
      </>
    );
  }
};

export default SideBar;
