import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    responsiveFontSizes,
} from "@mui/material";
const tableColumn = {
    age: "age",
    sex: "sex",
    chest_pain_type: "chest_pain_type",
    resting_bps: "resting_bps",
    cholesterol: "cholesterol",
    fasting_blood_sugar: "fasting_blood_sugar",
    rest_ecg: "rest_ecg",
    max_heart_rate: "max_heart_rate",
    exercise_angina: "exercise_angina",
    oldpeak: "oldpeak",
    ST_slope: "ST_slope",
};

//const API_ROOT = `https://e-react-node-backend-22ed6864d5f3.herokuapp.com`;  //TODO: Need to replace with the backend server address
const API_ROOT = `https://e-react-node-backend-22ed6864d5f3.herokuapp.com`;  //TODO: Need to replace with the backend server address

const HEART_FAIL_API_ENDPOINT = `https://dementia-detection-36eb5d2d1e9e.herokuapp.com/predict`;

const HeartFail3 = () => {
    const location = useLocation();
    const patientId = location.state.id;

    const [predictionData, setPredictionData] = useState([]);
    const [heartFail, setHeartFail] = useState();

    const predictHeartFail = async () => {
        var p;
        if (predictionData[0] == null) {
            return;
        }
        if (predictionData[0].Gender == "Male") {
            p = 0;
        }
        else { p = 1; }
        console.log(predictionData[0]);
        const predictionResponse = await axios.post(HEART_FAIL_API_ENDPOINT, {


            "M/F": p,

            "Age": predictionData[0].Age,

            "EDUC": predictionData[0].Educ,

            "SES": predictionData[0].Ses,

            "MMSE": predictionData[0].mmse,

            "eTIV": predictionData[0].etiv,

            "nWBV": predictionData[0].nwbv,

            "ASF": predictionData[0].asf



        });
        setHeartFail(predictionResponse.data);
    }

    useEffect(() => {
        (async () => {
            //const strokeDataResponse = await axios.get(`${API_ROOT}/getCoronaryArteryDisease/${patientId}`);
            const strokeDataResponse = await axios.post("https://e-react-node-backend-22ed6864d5f3.herokuapp.com/getDementiaDetection", {
                patientId
            });
            console.log(strokeDataResponse)
            setPredictionData(strokeDataResponse.data);
        })();
    }, []);

    const displayTableHead = () => {
        return Object.keys(tableColumn).map((columnKey) => {
            const columnValue = tableColumn?.[columnKey];
            return <th>{columnValue}</th>
        });
    };

    const renderTableData = () => {
        return Object.keys(tableColumn).map((columnKey) => {
            if (predictionData?.[columnKey] === undefined) {
                return;
            }
            return <td>{predictionData?.[columnKey]}</td>
        });
    };

    return (
        <React.Fragment>
            <br /><h2>Dementia Detection</h2><br />
            <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
                <h4 style={{ clear: "both" }}><br />Prediction Result: {heartFail?.prediction}</h4>
                <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>    <button onClick={predictHeartFail}>Diagnose</button></div>
            </div>
            <TableContainer
                component={Paper}
                sx={{ minWidth: 550, minHeight: 400 }}
            >
                <Table
                    sx={{ minWidth: 1250, minHeight: 400 }}
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="right">
                                patient_id
                                </TableCell>
                            <TableCell align="right">
                                group
                            </TableCell>
                            <TableCell align="right">
                                gender
                                </TableCell>
                            <TableCell align="right">
                                age
                                </TableCell>
                            <TableCell align="right">
                                Educ
                                </TableCell>
                            <TableCell align="right">
                                Ses
                                </TableCell>
                            <TableCell align="right">
                                mmse
                                </TableCell>
                            <TableCell align="right">cdr</TableCell>
                            <TableCell align="right">
                                etiv
                                </TableCell>
                            <TableCell align="right">nwbv</TableCell>
                            <TableCell align="right">asf</TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {predictionData.map((prescription) => (
                            <TableRow
                                key={prescription.id}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {prescription.id}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.patient_id}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.group}
                                </TableCell>

                                <TableCell align="right">
                                    {prescription.Gender}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.Age}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.Educ}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.Ses}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.mmse}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.cdr}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.etiv}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.nwbv}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.asf}
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
};

export default HeartFail3;
