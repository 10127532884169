import { Button, DatePicker, Radio } from "antd";
import dayjs from "dayjs";
import AddCircleIcon from '@mui/icons-material/AddCircle';

import "./PlanningCalendarToolbar.css";

const PlanningCalendarToolbar = (props) => {
  const handleNavigate = (date) => {
    props.onNavigate(date.toDate());
  };
  const handleView = (e) => {
    props.onView(e.target.value);
  }

  return <>
    <div className='planning-toolbar-container'>
      <div className="planning-toolbar-left-space" />
      <Radio.Group
        onChange={handleView}
        value={props.view}
        className="planning-toolbar-view">
        <Radio.Button value="week" style={{ color: "#042586", "border-color": "#042586" }}> Week</Radio.Button>
        <Radio.Button value="month" style={{ color: "#042586", "border-color": "#042586" }}>Month</Radio.Button>
      </Radio.Group>
      <div className="planning-toolbar-middle-space" />
      <DatePicker
        onChange={handleNavigate}
        picker={props.view}
        value={dayjs(props.date)}
        className="planning-toolbar-date" style={{ backgroundColor: "white", "border-color": "#042586", color: "#042586" }} />
      <div className="planning-toolbar-right-space" />
      {props.hasCreateButton ? (
        <Button type="link" className="planning-toolbar-create-button" onClick={props.onCreateEvent}>
          <AddCircleIcon style={{ color: "#042586", "border-color": "#042586" }} /><span style={{ width: "0.5rem", color: "#042586" }} /><a style={{ color: "#042586", "border-color": "#042586" }}>Add new event</a>
        </Button>
      ) : null}
    </div>
  </>;
}

export default PlanningCalendarToolbar;
