// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breastDiseaseContainer {
    padding: 10px 10%;
    margin-bottom: 250px;
}

.breastDiseaseContainer .tableContainer {
    border: none;
}

.breastDiseaseContainer .tableContainer thead th {
    padding: 10px 20px;
    font-weight: bold;
    background-color: #ccc;
    font-size: 18px;
}

.breastDiseaseContainer .tableContainer th {
    padding: 10px 20px;
    font-weight: 500;
    background-color: #eee;
    font-size: 12px;
}

.breastDiseaseContainer .tableContainer td {
    padding: 10px 20px;
    font-weight: 400;
    background-color: #fff;
    font-size: 12px;
}

.breastDiseaseContainer .tableContainer tr input {
    border: none
}

.breastDiseaseContainer .tableContainer tfoot {
    padding: 10px 20px;
    background-color: #ccc;
}

.breastDiseaseContainer .tableContainer tfoot .tableFooterButton {
    width: 200px;
    display: flex;
    justify-self: flex-end;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/screens/Prediction/BreastDisease/style.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI;AACJ;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC","sourcesContent":[".breastDiseaseContainer {\n    padding: 10px 10%;\n    margin-bottom: 250px;\n}\n\n.breastDiseaseContainer .tableContainer {\n    border: none;\n}\n\n.breastDiseaseContainer .tableContainer thead th {\n    padding: 10px 20px;\n    font-weight: bold;\n    background-color: #ccc;\n    font-size: 18px;\n}\n\n.breastDiseaseContainer .tableContainer th {\n    padding: 10px 20px;\n    font-weight: 500;\n    background-color: #eee;\n    font-size: 12px;\n}\n\n.breastDiseaseContainer .tableContainer td {\n    padding: 10px 20px;\n    font-weight: 400;\n    background-color: #fff;\n    font-size: 12px;\n}\n\n.breastDiseaseContainer .tableContainer tr input {\n    border: none\n}\n\n.breastDiseaseContainer .tableContainer tfoot {\n    padding: 10px 20px;\n    background-color: #ccc;\n}\n\n.breastDiseaseContainer .tableContainer tfoot .tableFooterButton {\n    width: 200px;\n    display: flex;\n    justify-self: flex-end;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
