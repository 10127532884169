// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doctor-planning-page {
  margin-top: 10%;
}

.doctor-planning-page .planning-button {
  display: block;
  margin: 0 auto;
  width: 280px;
  height: 144px;
  border-radius: 0.625rem;
  border: none;

  background: #C6DFFF;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 17px;
  line-height: 1.875rem;
  text-align: center;
  color: black;
}

.doctor-planning-page .planning-button .planning-button-icon {
  color: #1A4FBA;
}
`, "",{"version":3,"sources":["webpack://./src/screens/DoctorNew/DoctorPlanningPage.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,YAAY;;EAEZ,mBAAmB;EACnB,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;EACf,qBAAqB;EACrB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".doctor-planning-page {\n  margin-top: 10%;\n}\n\n.doctor-planning-page .planning-button {\n  display: block;\n  margin: 0 auto;\n  width: 280px;\n  height: 144px;\n  border-radius: 0.625rem;\n  border: none;\n\n  background: #C6DFFF;\n  font-family: 'Poppins';\n  font-weight: 700;\n  font-size: 17px;\n  line-height: 1.875rem;\n  text-align: center;\n  color: black;\n}\n\n.doctor-planning-page .planning-button .planning-button-icon {\n  color: #1A4FBA;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
