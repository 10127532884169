import moment from "moment";
import { readLoginData } from "../../../loginData";
import { doctorGetCalendar } from "../../../api/calendar";
import React, { useEffect, useState, useMemo } from "react";
import { Select } from "antd";
import MUIDataTable from "mui-datatables";
import { MenuItem, FormControl } from "@mui/material";
import ViewModal from "../patient-details/ViewModal";
import calendarIcon from "../../../assets/images/empty-calendar.svg";
import getTableColumns from "./tableColumns";
import "./RecentPatientList.css";

const TableTitle = () => (
  <div className="doctor-patients-appointments-table-title">
    <span>Patients Appointments</span>
  </div>
);

const getTimePeriod = (value, selectedDate) => {
  let date = moment(selectedDate);
  switch (value) {
    case "today":
      return [moment().startOf("day"), moment().endOf("day")];
    case "week":
      return [date.startOf("week"), date.clone().endOf("week")];
    case "month":
      return [date.startOf("month"), date.clone().endOf("month")];
    default:
      return [moment(value).startOf("day"), moment(value).endOf("day")];
  }
};

const RecentPatientList = (props) => {
  const [timeScope, setTimeScope] = useState("today");
  const [users, setUsers] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [viewModal, setViewModal] = useState(false);

  const handleTimeScopeChange = (value) => {
    setTimeScope(value);
  };

  const loginData = useMemo(() => readLoginData(), []);

  useEffect(() => {
    if (
      moment(props.selectedDate).isBetween(
        moment().startOf("day"),
        moment().endOf("day"),
        undefined,
        "[]"
      )
    ) {
      setTimeScope("today");
    } else {
      setTimeScope(moment(props.selectedDate).format("YYYY-MM-DD"));
    }
  }, [props.selectedDate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (loginData.type !== "NotLoggedIn") {
          const [start, end] = getTimePeriod(timeScope, props.selectedDate);
          const fetchedData = await doctorGetCalendar(
            loginData,
            start.toDate(),
            end.toDate()
          );
          const processedData = fetchedData
            .filter((r) => r.category !== 1 && r.status !== 0)
            .map((r) => {
              return {
                ...r,
                scheduledTime: {
                  start: r.start,
                  end: r.end,
                },
              };
            });
          console.log(processedData);
          setUsers(processedData);
        } else {
          console.log("User is not logged in.");
          setUsers([]);
        }
      } catch (error) {
        console.error(`Error fetching patient data: ${error.message}`);
      }
    };

    fetchData();
  }, [loginData, props.selectedDate, timeScope]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const patientIdFromUrl = urlParams.get("patientId");
    console.log("patientIdFromUrl", patientIdFromUrl);

    if (patientIdFromUrl) {
      setSelectedRowData({
        ...selectedRowData,
        patient: { ...selectedRowData.patient, id: patientIdFromUrl },
      });
      setViewModal(true);
    }
    const handlePopState = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const patientIdFromUrl = urlParams.get("patientId");

      if (patientIdFromUrl) {
        setViewModal(true);
        setSelectedRowData({
          ...selectedRowData,
          patient: { ...selectedRowData.patient, id: patientIdFromUrl },
        });
      } else {
        setViewModal(false);
        setSelectedRowData({
          ...selectedRowData,
          patient: { ...selectedRowData.patient, id: null },
        });
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const HeaderElements = ({ currentScope, onTimeScopeChange }) => {
    return (
      <div style={{ width: "130px" }}>
        <FormControl size="small" sx={{ width: "auto", border: "none" }}>
          <Select
            labelId="time-scope-select-label"
            id="time-scope-select"
            value={currentScope}
            onChange={onTimeScopeChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="week">Week</MenuItem>
            <MenuItem value="month">Month</MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  };
  const handleOpenViewModal = (value) => {
    console.log("row", value);
    const patientId = value.patient.id;
    const newUrl = new URL(window.location);
    newUrl.searchParams.set("patientId", patientId);
    window.history.pushState({}, "", newUrl);
    setViewModal(true);
    setSelectedRowData(value);
    setViewModal(true);
  };

  const handleCloseViewModal = () => {
    setViewModal(false);
    const newUrl = new URL(window.location);
    newUrl.searchParams.delete("patientId");
    window.history.pushState({}, "", newUrl);
  };

  const columns = getTableColumns(handleOpenViewModal, users, calendarIcon);

  const options = {
    filterType: "dropdown",
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    viewColumns: false,
    print: false,
    elevation: 0,
    customToolbar: () => (
      <HeaderElements
        onTimeScopeChange={handleTimeScopeChange}
        currentScope={timeScope}
      />
    ),
    // onRowClick: (rowData, rowMeta) => {
    //   const dataIndex = rowMeta.dataIndex;
    //   const user = users[dataIndex];
    //   handleOpenViewModal(user);
    // },
  };

  return (
    <div className="doctor-patients-appointments-table">
      <MUIDataTable
        title={<TableTitle />}
        data={users}
        columns={columns}
        options={options}
      />
      {viewModal ? (
        <ViewModal
          visible={viewModal}
          onClose={handleCloseViewModal}
          patientId={selectedRowData.patient.id}
          doctorId={loginData.id}
        />
      ) : null}
    </div>
  );
};

export default RecentPatientList;
