import React, { useState } from 'react';
import moment from 'moment';
import dayjs from 'dayjs';
import { DatePicker, Form, Input, TimePicker } from 'antd';

const AddNewTask = (props) => {
  const onDate = (date) => {
    props.onChange({ date: moment(date.toDate()).startOf('day').add(moment(props.date).subtract(moment(props.date).startOf('day'))) });
  };
  const onTime = (time) => {
    const t = time.toDate();
    props.onChange({ date: moment(props.date).startOf('day').add(moment(t).subtract(moment(t).startOf('day'))) });
  };
  const onDescription = (e) => {
    console.log(e);
    props.onChange({ description: e.target.value });
  };
  
  return <>
    <Form
      name="newTask"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      autoComplete="off"
    >
      <Form.Item
        label="Date"
        name="date"
        rules={[
          {
            required: true,
            message: 'Please input the date!',
          },
        ]}
      >
        <DatePicker value={dayjs(props.date)} onChange={onDate} style={{ width: '100%' }}/><br/>
      </Form.Item>
      <Form.Item
        label="Time"
        name="time"
        rules={[
          {
            required: true,
            message: 'Please input the time!',
          },
        ]}
      >
        <TimePicker value={dayjs(props.date)} onChange={onTime} style={{ width: '100%' }}/><br/>
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: 'Please input the description!',
          },
        ]}
      >
        <Input value={props.description} onChange={onDescription} placeholder="Type description here" style={{ width: '100%' }} /><br/>
      </Form.Item>
    </Form>
  </>;
};

export default AddNewTask;
