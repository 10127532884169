// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.planning-calendar.rbc-calendar { 
  height: 700px; 
}

.planning-calendar .rbc-timeslot-group {
  min-height: 200px;
}

.planning-calendar .rbc-current-time-indicator {
  background-color: #FA6C39;
}

.planning-calendar .rbc-event-label {
  display: none;
}

.planning-event {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.planning-event-content {
  flex-grow: 1;
}

.planning-event-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ant-select-item {
  text-align: initial;
}
`, "",{"version":3,"sources":["webpack://./src/components/CalendarComponents/PlanningCalendarView.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".planning-calendar.rbc-calendar { \n  height: 700px; \n}\n\n.planning-calendar .rbc-timeslot-group {\n  min-height: 200px;\n}\n\n.planning-calendar .rbc-current-time-indicator {\n  background-color: #FA6C39;\n}\n\n.planning-calendar .rbc-event-label {\n  display: none;\n}\n\n.planning-event {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.planning-event-content {\n  flex-grow: 1;\n}\n\n.planning-event-text {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n}\n\n.ant-select-item {\n  text-align: initial;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
