// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bc-container {
    font-family: 'Open Sans', sans-serif;
    width: 80%;
    margin: 2rem auto;
    padding: 1.5rem;
    border-radius: 12px;
    background-color: #fafafa;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.bc-container:hover {
    transform: scale(1.02);
}

.title {
    margin-bottom: 1.5rem;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.diagnosis-result {
    margin: 1.5rem 0;
    font-size: 1.2rem;
    color: #555;
    transition: color 0.3s;
}

.results-tablea {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #e0e0e0;
}

.results-tablea th, .results-tablea td {
    border: 1px solid #e0e0e0;
    padding: 0.7rem 0.5rem;
    text-align: left;
}

.results-tablea th {
    background-color: #f5f5f5;
    color: #555;
}

.results-tablea tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.results-tablea tr:hover {
    background-color: #fcfcfc;
}
`, "",{"version":3,"sources":["webpack://./src/styles/screens/BreastCancer.css"],"names":[],"mappings":"AAEA;IACI,oCAAoC;IACpC,UAAU;IACV,iBAAiB;IACjB,eAAe;IACf,mBAAmB;IACnB,yBAAyB;IACzB,2CAA2C;IAC3C,0BAA0B;AAC9B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');\n\n.bc-container {\n    font-family: 'Open Sans', sans-serif;\n    width: 80%;\n    margin: 2rem auto;\n    padding: 1.5rem;\n    border-radius: 12px;\n    background-color: #fafafa;\n    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);\n    transition: transform 0.3s;\n}\n\n.bc-container:hover {\n    transform: scale(1.02);\n}\n\n.title {\n    margin-bottom: 1.5rem;\n    color: #333;\n    text-transform: uppercase;\n    letter-spacing: 1.5px;\n}\n\n.diagnosis-result {\n    margin: 1.5rem 0;\n    font-size: 1.2rem;\n    color: #555;\n    transition: color 0.3s;\n}\n\n.results-tablea {\n    width: 100%;\n    border-collapse: collapse;\n    border: 1px solid #e0e0e0;\n}\n\n.results-tablea th, .results-tablea td {\n    border: 1px solid #e0e0e0;\n    padding: 0.7rem 0.5rem;\n    text-align: left;\n}\n\n.results-tablea th {\n    background-color: #f5f5f5;\n    color: #555;\n}\n\n.results-tablea tr:nth-child(odd) {\n    background-color: #f9f9f9;\n}\n\n.results-tablea tr:hover {\n    background-color: #fcfcfc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
