import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Popover, Typography } from "@mui/material";
import React from "react";

import bell from "../../../assets/images/bell.svg";
import greyBell from "../../../assets/images/grey-bell.svg";
import {Link} from "react-router-dom";

const typoFontFamily = {
  fontFamily: "Poppins, Roboto ,Helvetica,Arial, sans-serif",
};

function formatDateString(dateString, clockOnly) {
  const date = new Date(dateString);

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  if (clockOnly) {
    return `${hours}:${minutes}`;
  }
  return `${hours}:${minutes} ${year}/${month}/${day}`;
}

const timeSegment = (start, end) => {
  return formatDateString(start, true) + " - " + formatDateString(end, false);
};

export const PatientName = ({ profileData }) => {
  return (
    <Typography
      variant="h5"
      gutterBottom
      style={{
        color: "#1A4FBA",
        fontWeight: "600",
        marginBottom: "1em",
        ...typoFontFamily,
        textAlign: "center",
      }}
    >
      {profileData.FName} {profileData.LName}
    </Typography>
  );
};

export const ProfileProperties = ({ profileData }) => {
  return (
    <>
      <div
        style={{ color: "#A3AED0", ...typoFontFamily, marginBottom: "0.5em" }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
          <span style={{ ...typoFontFamily }}>{profileData.Age} years old</span>
          <span style={{ ...typoFontFamily }}>{profileData.Gender}</span>
          <span style={{ ...typoFontFamily }}>
            {profileData.City}, {profileData.Province}
          </span>
        </Box>
      </div>
      <div style={{ color: "#A3AED0", ...typoFontFamily }}>
        <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
          <span style={{ ...typoFontFamily }}>
            Phone: {profileData.MobileNumber}
          </span>
          <span style={{ ...typoFontFamily }}>
            Email: {profileData.EmailId}
          </span>
        </Box>
      </div>
    </>
  );
};

const PatientBodyProperty = ({
  propertyName,
  propertyValue,
  propertyUnit,
  borderRight,
  bodyStyle,
}) => {
  return (
    <Grid
      item
      xs={3}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        borderRight: borderRight ? "1px solid #D4E3F0" : "",
      }}
      pt={1}
    >
      <Typography variant="body2">{propertyName}</Typography>
      <Typography variant="body1" sx={{ fontWeight: "bold", ...bodyStyle }}>
        {propertyValue}
        {propertyUnit}
      </Typography>
    </Grid>
  );
};

export const BodyProperties = ({ profileData }) => {
  return (
    <Grid container mt={1} style={{ color: "#85A5C2" }}>
      <PatientBodyProperty
        propertyName={"Blood"}
        propertyValue={profileData.BloodGroup}
        propertyUnit={""}
        borderRight={true}
      />
      <PatientBodyProperty
        propertyName={"Height"}
        propertyValue={profileData.height}
        propertyUnit={"cm"}
        borderRight={true}
      />
      <PatientBodyProperty
        propertyName={"Weight"}
        propertyValue={profileData.weight}
        propertyUnit={"kg"}
        borderRight={true}
      />
      <PatientBodyProperty
        propertyName={"BMI"}
        propertyValue={(
          profileData.weight / Math.pow(profileData.height / 100, 2)
        ).toFixed(1)}
        propertyUnit={""}
        borderRight={false}
        bodyStyle={{ color: "rgba(64, 220, 130, 0.7)" }}
      />
    </Grid>
  );
};

export const BlueButton = ({
  buttonTitle,
  width,
  height,
  fontSize,
  onClick,
}) => {
  return (
    <Button
      variant="contained"
      sx={{
        textTransform: "none",
        borderRadius: "0.8em",
          margin:"3px",
        padding: "0.9em 1.3em 0.9em 1.3em",
        height: height ? height : "auto",
        fontSize: fontSize ? fontSize : "auto",
        backgroundColor: "#1A4EBA",
      }}
      onClick={onClick}
    >
      {buttonTitle}
    </Button>
  );
};

export const ProfileButtons = ({
  toMessagePatient,
  toMedicalChatbot,
  toVideoCall,
  toEncounter,
}) => {
  return (
    <Grid mt={"2em"} sx={{ display: "flex", justifyContent: "space-evenly" }}>
      <BlueButton
        buttonTitle={"Message Patient"}
        width={"20px"}
        onClick={toMessagePatient}
      ></BlueButton>
      <BlueButton
        buttonTitle={"Medical Chatbot"}
        width={"20px"}
        onClick={toMedicalChatbot}
      ></BlueButton>
      <BlueButton
        buttonTitle={"Video Call"}
        width={"20px"}
        onClick={toVideoCall}
      ></BlueButton>
      <BlueButton
        buttonTitle={"Encounter"}
        width={"20px"}
        onClick={toEncounter}
      ></BlueButton>
    </Grid>
  );
};

const AppointmentListItem = ({ appointment, titleName, date, past }) => {
  return (
    <ListItem sx={{ pl: "2em" }}>
      <ListItemAvatar>
        <img
          style={{ width: "80%" }}
          src={past ? greyBell : bell}
          alt="notification icon"
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            sx={{
              fontSize: "1em",
              color: "#858585",
              fontWeight: "500",
              ...typoFontFamily,
              mb: "0.4em",
            }}
          >
            {appointment
              ? appointment.patientDescription
                ? appointment.patientDescription
                : "Default Appointment"
              : titleName}
          </Typography>
        }
        secondary={
          <Typography
            sx={{
              fontSize: "0.8em",
              color: "#9E9E9E",
              fontWeight: "300",
              ...typoFontFamily,
            }}
          >
            {appointment
              ? timeSegment(appointment.start, appointment.end)
              : date}
          </Typography>
        }
      />
    </ListItem>
  );
};

const AppointmentContentList = ({ appointments, past, fold }) => {
  const visibleAppointments = fold ? appointments.slice(0, 2) : appointments;
  return (
    <>
      {visibleAppointments.length > 1 ? (
        visibleAppointments.map((appointment, index) => (
          <React.Fragment key={index}>
            <AppointmentListItem
              appointment={appointment}
              past={past ? past : false}
            />
            {index < visibleAppointments.length - 1 && (
              <Divider
                sx={{
                  ml: "2em",
                  borderColor: "#EEF5FB",
                  width: `calc(100% - 2em)`,
                }}
              />
            )}
          </React.Fragment>
        ))
      ) : visibleAppointments.length === 1 ? (
        <AppointmentListItem
          appointment={visibleAppointments[0]}
          past={past ? past : false}
        />
      ) : (
        <Typography
          variant="subtitle1"
          sx={{ textAlign: "center", color: "#CCCCCC" }}
        >
          No {past ? "past" : "upcoming"} appointments
        </Typography>
      )}
    </>
  );
};

const AppointmentsPopover = ({
  open,
  anchorEl,
  handleClose,
  futureAppointments,
  pastAppointments,
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      slotProps={{
        paper: {
          style: {
            padding: "1em 1em 1em 0",
            width: "24em",
            maxHeight: "30em",
            position: "relative",
          },
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "1.3em",
          right: "1.2em",
          zIndex: 1100,
        }}
      >
        <IconButton onClick={handleClose}>
          <CloseIcon sx={{ fontSize: "1em" }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7em 0 0 2em",
        }}
      >
        <Typography
          sx={{ color: "#59748A", fontSize: "1.2em", fontWeight: "600" }}
        >
          Upcoming Appointments
        </Typography>
      </Box>
      <Box sx={{}}>
        <AppointmentContentList appointments={futureAppointments} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 1em 0 2em",
        }}
      >
        <Typography
          sx={{
            color: "#59748A",
            fontSize: "1.2em",
            fontWeight: "600",
            mt: "1em",
          }}
        >
          Past Appointments
        </Typography>
      </Box>
      <Box>
        <AppointmentContentList appointments={pastAppointments} past={true} />
      </Box>
    </Popover>
  );
};

export const AppointmentList = ({
  handleClick,
  upcomingAppointmentData,
  open,
  anchorEl,
  handleClose,
}) => {
  const now = new Date();
  const pastAppointments =
    upcomingAppointmentData && upcomingAppointmentData.result
      ? upcomingAppointmentData.result.filter((appointment) => {
          return new Date(appointment.start) < now;
        })
      : [];

  const futureAppointments =
    upcomingAppointmentData && upcomingAppointmentData.result
      ? upcomingAppointmentData.result.filter((appointment) => {
          return new Date(appointment.start) >= now;
        })
      : [];

  return (
    <>

      <AppointmentsPopover
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        futureAppointments={futureAppointments}
        pastAppointments={pastAppointments}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 5em 0 2em",
        }}
      >
        <Typography
          sx={{ color: "#59748A", fontSize: "1.2em", fontWeight: "600" }}
        >
          Upcoming Appointments
        </Typography>
        <IconButton onClick={handleClick}>
          <KeyboardArrowDownIcon sx={{ fontSize: "1.2em" }} />
        </IconButton>
      </Box>

      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <AppointmentContentList appointments={futureAppointments} fold={true} />
      </List>
    </>
  );
};
