import React from 'react';
import './footer.css';
import Logo from "./Logo-white.svg"
const footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-block">
                    <img src={Logo} alt="Your Logo"/>
                    <h4>Address</h4>
                    <p  style={{ fontStyle: 'italic' }}>75 Laurier Ave E,
                        Ottawa, ON K1N 6N5</p>
                </div>
                <div className="footer-block">
                    <h4>Company Info</h4>
                    <p style={{ margin: '50px' }}>Our e-Hospital platform offers convenient online consultations, medical records access, and appointment scheduling.</p>
                </div>
                <div className="footer-block">
                    <h4>Contact</h4>
                    <p>Phone: +1 555 555 555
                    </p>
                    <p>
                        uottawabiomedicalsystems@gmail.com

                    </p>
                </div>
            </div>
        </footer>
    );
};

export default footer;