import React from "react";
import { Box, Typography } from "@mui/material";
import Weight from "./Weight";
import TrendingUpTwoToneIcon from "@mui/icons-material/TrendingUpTwoTone";
import TrendingDownTwoToneIcon from "@mui/icons-material/TrendingDownTwoTone";
import PressureChart from "./PressureChart";
import { Grid } from "@mui/material";
import ECG from "./BloodPressureChart";
import Glucose from "./Glucose";
import CircleIcon from "@mui/icons-material/Circle";
import WarningIcon from "@mui/icons-material/Warning";
import Divider from "@mui/material/Divider";
import TCHChart from "./TCHChart";

export const WeightConmponent = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#C6DFFF",
        borderRadius: "10px",
        padding: "1vh",
        height: "25vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography variant="h5">90 Kg</Typography>
          <Box display="flex" alignItems="center">
            <TrendingUpTwoToneIcon style={{ color: "red" }} />
            <Typography>+12 Kg</Typography>
          </Box>
        </Box>
        <Box>
          <Typography>Weight</Typography>
        </Box>
      </Box>
      <Weight />
    </Box>
  );
};

export const TCHComponent = () => {
  return (
    <Grid
      container
      style={{
        backgroundColor: "#C6DFFF",
        borderRadius: "10px",
        padding: "1vh",
        height: "25vh",
      }}
    >
      <Grid item xs={4}>
        <Box
          sx={{
            display: "flex",
            paddingTop: "1vh",
            paddingLeft: "1vh",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography variant="h5">7.8</Typography>
            <Box display="flex" alignItems="center">
              <TrendingUpTwoToneIcon style={{ color: "red" }} />
              <Typography>+2%</Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            marginTop: "3vh",
            // paddingLeft: "2px",
          }}
        >
          <Typography variant="body2" style={{ marginTop: "1vh" }}>
            6.3 mmol/L
          </Typography>
          <Typography variant="body2" style={{ marginTop: "1vh" }}>
            4.1 mmol/L
          </Typography>
          <Typography variant="body2" style={{ marginTop: "1vh" }}>
            4.9 mmol/L
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Box>
          <Typography>TCH</Typography>
          <Typography>CHOD-PAP1</Typography>
        </Box>
        {/* <PressureChart /> */}
        <TCHChart />
      </Grid>
    </Grid>
  );
};

export const BloodPressureComponent = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "start",
          px: "10%",
          marginTop: "2vh",
        }}
      >
        <Typography variant="h5">139-89 mm</Typography>
        <Typography variant="h6">Blood Pressure</Typography>
      </Box>
      <Box sx={{ textAlign: "left", display: "flex", px: "10%" }}>
        <TrendingUpTwoToneIcon style={{ color: "red" }} />
        <Typography variant="subtitle1">30 mm Hg. Art.</Typography>
        <TrendingDownTwoToneIcon style={{ color: "green" }} />
        <Typography variant="subtitle1">15 mm Hg. Art.</Typography>
      </Box>
      <ECG />
    </>
  );
};

export const GlucoseComponent = () => {
  return (
    <Box
      style={{
        backgroundColor: "#C6DFFF",
        borderRadius: "10px",
        height: "100%",
      }}
    >
      <Typography
        variant="h6"
        sx={{ textAlign: "right", paddingTop: "2vh", px: "10%" }}
      >
        Glucose
      </Typography>
      <Box>
        <Glucose />
      </Box>
      <Box sx={{ mx: "1vw" }}>
        <StatusIndicator Time="Jan 27, 2022" Value="4.1" fontSize="body2" />
        <Divider />
        <StatusIndicator Time="Feb 5, 2022" Value="6.3" fontSize="body2" />
        <Divider />
        <StatusIndicator Time="Feb 15, 2022" Value="7.8" fontSize="body2" />
        <Box sx={{ marginTop: "4vh" }}>
          <GlucoseLegend
            status="Normal"
            rangeText="4.1-6.1"
            Icon={CircleIcon}
          />
          <GlucoseLegend
            status="Abnormalities"
            rangeText=">7.0"
            Icon={WarningIcon}
          />
        </Box>
      </Box>
    </Box>
  );
};

export const GlucoseLegend = ({ status, rangeText, Icon }) => {
  const iconColor = status === "Abnormalities" ? "#FFC107" : "#3FC979";
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        margin: "0.5vh 0",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "start" }}>
        <Icon style={{ color: iconColor }} fontSize="tiny" />
        <Typography component="span" variant="body2">
          {status}
        </Typography>
      </Box>
      <Typography component="span" variant="body2">
        {rangeText} mmol/L
      </Typography>
    </Box>
  );
};

export const StatusIndicator = ({ Time, Value, fontSize }) => {
  const rangeValue = parseFloat(Value);
  const Icon = rangeValue > 7 ? WarningIcon : CircleIcon;
  const iconColor = rangeValue > 7 ? "#FFC107" : "#3FC979";
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        margin: "0.5vh 0",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "start" }}>
        <Icon style={{ color: iconColor }} fontSize="tiny" />
        <Typography variant={fontSize}>{Time}</Typography>
      </Box>
      <Typography variant={fontSize}>{Value}mmol/L</Typography>
    </Box>
  );
};

// export default WeightConmponent;
