import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../constants";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import "./brainAnomalies.css"

function BrainAnomalies() {
    const location = useLocation();
    const state = location.state;
    const patientInfo = state;
    const [brainAnomaliesData, setBrainAnomaliesData] = useState(null);
    const [prediction, setPrediction] = useState("");
    const [predictionLoader, setPredictionLoader] = useState(false);
    console.log("state", state);

    useEffect(() => {
        async function getBrainAnomaliesData() {
            try {
                const id = patientInfo.id;
                const { data } = await axios.post(`${BASE_URL}/imageRetrieveByPatientId`, { patientId: id, recordType: "MRI_Brain" });
                console.log("data", data);
                setBrainAnomaliesData(data.success[0].file.buffer);
            } catch (err) {
                console.error(err);
            }
        }
        if (patientInfo.id) {
            getBrainAnomaliesData();
        }
    }, [patientInfo]);

    async function predict(base64Image) {
        setPredictionLoader(true);
        try {
            console.log('Before FormData creation');
            const formData = new FormData();
            const blob = await (async () => {
                return new Promise((resolve) => {
                    const binaryData = atob(base64Image);
                    const arrayBuffer = new ArrayBuffer(binaryData.length);
                    const uint8Array = new Uint8Array(arrayBuffer);

                    for (let i = 0; i < binaryData.length; i++) {
                        uint8Array[i] = binaryData.charCodeAt(i);
                    }

                    const blob = new Blob([uint8Array], { type: "image/jpeg" });
                    resolve(blob);
                });
            })();
            console.log('After FormData creation', formData);

            formData.append("file", blob, "image.jpg");
            console.log('Before axios.post');
            const { data } = await axios.post(
                "https://ishaasamyuktha-2024-1fdd779e434f.herokuapp.com/predict",
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            console.log('After axios.post', data);
            setPrediction({
                brainAnomalies: data.predicted_Disease
            });
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setPredictionLoader(false);
        }
    }

    function renderPredictionCell() {
        if (predictionLoader) {
            return <div>Loading...</div>;
        }

        if (!prediction && brainAnomaliesData) {
            return (
                <button
                    className="predictButton"
                    onClick={() => predict(brainAnomaliesData)}
                >
                    Predict
                </button>
            );
        }

        if (prediction !== undefined && prediction !== null) {
            return <div style={{ fontSize: "1.3rem", fontWeight: "700", color: "#59748A", fontFamily: "helvetica" }
            } >{prediction && prediction.brainAnomalies}</div>;

        }

        return null;
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100vh", background: "#F8FAFC" }}>
            <div style={{ fontSize: "2rem", marginBottom: "3rem", fontFamily: "helvetica", fontWeight: "700", color: "#555555", marginTop: "5rem" }}>brainAnomalies Prediction</div>
            <TableContainer sx={{ width: "60%", borderRadius: "10px", border: "1px solid rgba(224, 224, 224, 1)", overflow: "hidden" }}>
                <Table sx={{
                    minWidth: 650
                }} aria-label="customized table">
                    <TableHead>
                        <TableRow sx={{
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}>
                            <TableCell align="center" sx={{ fontSize: "1.2rem", fontWeight: "700", background: "#C6DFFF80", color: "#59748A" }}>Patient Information</TableCell>
                            <TableCell align="center" sx={{ fontSize: "1.2rem", fontWeight: "700", background: "#C6DFFF80", color: "#59748A" }}>X-Ray Image</TableCell>
                            <TableCell align="center" sx={{ fontSize: "1.2rem", fontWeight: "700", background: "#C6DFFF80", color: "#59748A" }}>Prediction</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow sx={{
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}>
                            <TableCell align="center" sx={{ fontSize: "1.2rem", color: "#59748A", background: "#F3F8FC" }}>
                                {patientInfo.FName} {patientInfo.MName} {patientInfo.LName}
                            </TableCell>
                            <TableCell align="center" sx={{ background: "#F3F8FC" }} >
                                {brainAnomaliesData && (
                                    <img
                                        src={`data:image/jpeg;base64,${brainAnomaliesData}`}
                                        alt="Patient X-Ray"
                                        style={{ width: '200px', height: 'auto' }}
                                    />
                                )}
                            </TableCell>
                            <TableCell align="center" sx={{ background: "#F3F8FC" }}>{renderPredictionCell()}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default BrainAnomalies;