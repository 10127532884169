import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

const Temperature = ({ start, data }) => {
  const [temperatureData, setTemperatureData] = useState({
    labels: [],
    datasets: [
      {
        label: "Temperature Data",
        data: [],
        borderColor: "#5AD8A6",
        lineTension: 0,
        pointRadius: 0,
        borderWidth: 0.8,
      },
    ],
  });
  const [isLoading, setIsLoading] = useState(true);
  const options = {
    animation: false,
    scales: {
      x: {
        type: "linear",
        beginAtZero: true,
      },
      y: {
        type: "linear",
        min: 20,
        max: 40,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  // https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/users/getRaspberryData
  useEffect(() => {
    if (data && data.temperature && data.temperature.length > 0) {
      console.log("Temperature Data", data.temperature);
      const ecgDataArray = data?.temperature;
      setTemperatureData((currentData) => {
        // 新接收的数据添加到当前数据集
        let newData = [...currentData.datasets[0].data, ...ecgDataArray];

        // 保证newData长度不超过50个数据点
        if (newData.length > 50) {
          newData = newData.slice(-50);
        }

        // 更新标签，使其在0到50之间均匀分布
        let newLabels = Array.from(
          { length: newData.length },
          (_, index) => index
        );

        // 返回更新后的状态
        return {
          ...currentData,
          labels: newLabels,
          datasets: [
            {
              ...currentData.datasets[0],
              data: newData,
            },
          ],
        };
      });
    }
  }, [data]);

  useEffect(() => {
    return () => {
      clearChartData();
      console.log("Temperature Chart cleared");
    };
  }, []);

  function clearChartData() {
    setTemperatureData({
      labels: [],
      datasets: [{ data: [] }],
    });
  }

  return (
    <Line
      data={temperatureData}
      options={options}
      style={{ height: "100%", width: "100%" }}
    />
  );
};

export default Temperature;
