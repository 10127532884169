import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";

function EpilepticSeizure() {
  const [showDiagnose, setShowDiagnose] = useState(false);
  const [dataDisplay, setDataDisplay] = useState([]);
  const location = useLocation();
  const [prediction, setPrediction] = useState("");
  const patient_id = location.state?.id;
  const BASE_URL = "https://e-react-node-backend-22ed6864d5f3.herokuapp.com"; // Update with your node backend app URL
  //   const BASE_URL = "https://e-react-node-backend-22ed6864d5f3.herokuapp.com"; // Update with your node backend app URL

  useEffect(() => {
    console.log("useEffect", dataDisplay);
  }, [dataDisplay]);

  useEffect(() => {
    const getHeartDiseaseData = async () => {
      try {
        console.log("patient found ", patient_id);
        const response = await axios.post(
          `${BASE_URL}/getEpilepticSeizureData`,
          {
            patient_id,
            tableName: "heart_disease_win2024",
          }
        );
        console.log("response", response.data[0]);
        if (response.data && response.data.length == 1) {
          setDataDisplay(response.data[0]?.X);
        } else {
          alert("Test Data Not Found");
        }
      } catch (error) {
        alert(`Error: ${error.message}`);
      }
    };

    getHeartDiseaseData();
  }, [patient_id]);

  const predict = async () => {
    let floatArray = dataDisplay.split(",").map(function (item) {
      return parseFloat(item);
    });
    console.log("floatArray", floatArray);
    let request = { features: floatArray };
    console.log("request", request);
    try {
      const response = await axios.post(
        "https://epilecptic-seizure-model-2024-83c32feb67c7.herokuapp.com/predict",
        request,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log("predicate", response.data);
      setPrediction(response.data);
      setShowDiagnose(true);
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <>
      <div className="bc-container">
        <h2 className="title"> Heart Disease(Arrhythmia) Predication Result</h2>
        <div className="diagnosis-result">
          <strong>Diagnosis:</strong>
          {showDiagnose ? `${prediction}` : ""}
        </div>

        {/* <table className="results-tablea">
          <thead>
            <tr>
              <th>Parameters</th>
              <th>Values</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(dataDisplay).map(([key, value], index) => (
              <tr key={index}>
                <td>{key}</td>
                <td>{value}</td>
              </tr>
            ))}
          </tbody>
        </table> */}
        <br />
        <center>
          <button onClick={() => predict()}>Diagnose</button>
          <br />
          <br />
          <br />
        </center>
        <br />
      </div>
    </>
  );
}

export default EpilepticSeizure;
