import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    responsiveFontSizes,
} from "@mui/material";
const tableColumn = {
    age: "age",
    sex: "sex",
    chest_pain_type: "chest_pain_type",
    resting_bps: "resting_bps",
    cholesterol: "cholesterol",
    fasting_blood_sugar: "fasting_blood_sugar",
    rest_ecg: "rest_ecg",
    max_heart_rate: "max_heart_rate",
    exercise_angina: "exercise_angina",
    oldpeak: "oldpeak",
    ST_slope: "ST_slope",
};

//const API_ROOT = `https://e-react-node-backend-22ed6864d5f3.herokuapp.com`;  //TODO: Need to replace with the backend server address
const API_ROOT = `http://127.0.0.1:8080`;  //TODO: Need to replace with the backend server address

const HEART_FAIL_API_ENDPOINT = `https://rheumatoid2024-c6d66e131ad6.herokuapp.com/mymodel`;

const HeartFail4 = () => {
    const location = useLocation();
    const patientId = location.state.id;

    const [predictionData, setPredictionData] = useState([]);
    const [heartFail, setHeartFail] = useState();

    const predictHeartFail = async () => {
        const predictionResponse = await axios.post(HEART_FAIL_API_ENDPOINT, {

            "Age": [predictionData.age],
            "Sex": [predictionData.gender == "Male" ? 0 : 1],
            "Smoking_Status": [predictionData.smoking_status]


        });
        setHeartFail(predictionResponse.data);
    }

    useEffect(() => {
        (async () => {
            //const strokeDataResponse = await axios.get(`${API_ROOT}/getCoronaryArteryDisease/${patientId}`);
            const strokeDataResponse = await axios.post("http://127.0.0.1:8080/getRheumatoidArthritis", {
                patientId
            });
            console.log(strokeDataResponse)
            setPredictionData(strokeDataResponse.data);
        })();
    }, []);

    const displayTableHead = () => {
        return Object.keys(tableColumn).map((columnKey) => {
            const columnValue = tableColumn?.[columnKey];
            return <th>{columnValue}</th>
        });
    };

    const renderTableData = () => {
        return Object.keys(tableColumn).map((columnKey) => {
            if (predictionData?.[columnKey] === undefined) {
                return;
            }
            return <td>{predictionData?.[columnKey]}</td>
        });
    };

    return (
        <React.Fragment>
            <br /><h2>Dementia Detection</h2><br />
            <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
                <h4 style={{ clear: "both" }}><br />Dementia Detection for the patient is {heartFail?.prediction}</h4>
                <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>    <button onClick={predictHeartFail}>Diagnose</button></div>
            </div>
            <TableContainer
                component={Paper}
                sx={{ minWidth: 550, minHeight: 400 }}
            >
                <Table
                    sx={{ minWidth: 1250, minHeight: 400 }}
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="right">
                                patient_id
                                </TableCell>
                            <TableCell align="right">
                                age
                            </TableCell>
                            <TableCell align="right">
                                gender
                                </TableCell>
                            <TableCell align="right">
                                smoking_status
                                </TableCell>
                            <TableCell align="right">
                                ra_status
                                </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {predictionData.map((prescription) => (
                            <TableRow
                                key={prescription.id}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {prescription.id}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.patient_id}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.age}
                                </TableCell>

                                <TableCell align="right">
                                    {prescription.gender}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.smoking_status}
                                </TableCell>
                                <TableCell align="right">
                                    {prescription.ra_status}
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
};

export default HeartFail4;
