import { Button } from "antd";
import moment from "moment";
import "./RecentPatientList.css";

const getTableColumns = (handleOpenViewModal, users, calendarIcon) => [
    {
        name: "id",
        label: "Patient ID",
        options: {
            filter: true,
            sort: false,
            setCellHeaderProps: (value) => {
                return {
                    style: {
                        textAlign: "left",
                        marginLeft: "1rem !important",
                        padding: "0 0 0 1rem",
                    },
                };
            },
            customBodyRenderLite: (dataIndex) => (
                <>
                    <div style={{ padding: "0 0 0 1rem" }}>{users[dataIndex].patient.id}</div>
                </>
            ),
        },
    },
    {
        name: "patientName",
        label: "Name",
        options: {
            filter: true,
            sort: false,
            setCellHeaderProps: (value) => {
                return {
                    style: {
                        textAlign: "left",
                    },
                };
            },
            customBodyRenderLite: (dataIndex) => (
                <Button
                    type="link"
                    onClick={() => handleOpenViewModal(users[dataIndex])}
                    className="doctor-patients-appointments-table-name-button"
                >
                    <div> {users[dataIndex].patientName}</div>
                </Button>
            ),
        },
    },
    {
        name: "patientAge",
        label: "Age",
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        name: "category",
        label: "Visit Type",
        options: {
            filter: true,
            sort: false,
            setCellHeaderProps: (value) => {
                return {
                    style: {
                        "&. span": { justifyContent: "center" },
                    },
                };
            },
            customBodyRender: (value) => {
                switch (value) {
                    case 1:
                        return "Task";
                    case 2:
                        return "Available Slot";
                    case 3:
                        return "Surgery";
                    case 4:
                        return "General Consultation";
                    case 5:
                        return "Lab Test";
                    default:
                        return "Unknown";
                }
            },
        },
    },
    {
        name: "scheduledTime",
        label: "Scheduled Time",
        options: {
            filter: true,
            sort: true,
            sortCompare: (order) => {
                return (obj1, obj2) => {
                    const time1 = moment(obj1.data.start).unix();
                    const time2 = moment(obj2.data.start).unix();

                    if (order === 'asc') {
                        return time1 - time2;
                    } else {
                        return time2 - time1;
                    }
                };
            },

            customBodyRender: (value, tableMeta) => {
                const rowData = value;
                const startDate = moment(rowData.start);
                const endDate = moment(rowData.end);

                const dateDisplay = startDate.isSame(moment(), "day")
                    ? "Today"
                    : startDate.format("D MMM, YYYY");
                const startTime = startDate.format("HH:mm");
                const endTime = endDate.format("HH:mm");

                return (
                    <div className="doctor-patients-appointments-table-schedule-time-container">
                        <img
                            className="doctor-patients-appointments-table-schedule-time-icon"
                            src={calendarIcon}
                            alt="calendar"
                        />
                        <div className="doctor-patients-appointments-table-schedule-time-text-container">
                            <div className="doctor-patients-appointments-table-schedule-time-text">{`${dateDisplay}`}</div>
                            <div className="doctor-patients-appointments-table-schedule-time-text">{`${startTime}-${endTime}`}</div>
                        </div>
                    </div>
                );
            },
        },
    },
    {
        name: "status",
        label: "Status",
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value, meta) => {
                let style = {};
                let text = "";

                switch (value) {
                    case -1:
                        if (moment().isBefore(moment(meta.rowData.end))) {
                            style = { backgroundColor: "#E4F3E9", color: "black" };
                            text = "Scheduled";
                        } else {
                            style = { backgroundColor: "#BCE1C9", color: "black" };
                            text = "Completed";
                        }
                        break;
                    case 0:
                        style = { backgroundColor: "gray", color: "black" };
                        text = "Available";
                        break;
                    case 1:
                        style = { backgroundColor: "#F0C6C5", color: "black" };
                        text = "Waiting Approval";
                        break;
                    default:
                        style = { backgroundColor: "gray", color: "black" };
                        text = "Unknown";
                }

                return (
                    <div
                        className="doctor-patients-appointments-table-status-text"
                        style={{ ...style }}
                    >
                        {text}
                    </div>
                );
            },
        },
    },
];

export default getTableColumns;