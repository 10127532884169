import * as React from "react";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { readLoginData } from "../../loginData";
import { patientMainPageGetCalendar } from "../../api/calendar";
import { styled } from "@mui/material/styles";

const loginData = readLoginData();

/**
 * Mimic fetch with abort controller https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
 * ⚠️ No IE11 support
 */

function fakeFetch(date, { signal }) {
  return new Promise(async (resolve, reject) => {
    try {
      // Convert the date format or any other required format
      const startDate = initialValue.startOf("month");
      const endDate = initialValue.endOf("month");

      // Send the actual HTTP request
      const response = await patientMainPageGetCalendar(
        loginData,
        startDate,
        endDate,
        {
          signal: signal,
        }
      );

      // Resolve the Promise with the response data
      resolve(response);
    } catch (error) {
      if (error.name === "AbortError") {
        // If the request is aborted, reject the Promise
        reject(new DOMException("aborted", "AbortError"));
      } else {
        // Handle other types of errors
        reject(error);
      }
    }
  });
}

// const initialValue = dayjs("2024-01-25");
const initialValue = dayjs();

function ServerDay(props) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.indexOf(props.day.date()) >= 0;

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular" // Set overlap to "circular"
      badgeContent={
        isSelected ? (
          <span
            style={{
              fontSize: "0.6em",
              backgroundColor: "#44b700",
              color: "#44b700",
              borderRadius: "50%", // Set borderRadius to "50%" for circular shape
              marginLeft: "-3px", // Move the badge slightly to the left
              marginBottom: "-3px", // Move the badge slightly downwards
            }}
          >
            &#9679;
          </span>
        ) : undefined
      }
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

export default function PatientPortalCalendar({ setEvent }) {
  const requestAbortController = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState([1, 2, 15]);
  const [data, setData] = React.useState([]);

  const fetchHighlightedDays = (date) => {
    const controller = new AbortController();
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then((response) => {
        setData(response);
        extractAndSetEventData(response);
        // console.log("response", response);

        // Extract the date part from the 'start' field and set it to highlightedDays
        const extractedDays = response.map((item) => {
          const date = new Date(item.start); // Convert ISO date string to Date object
          return date.getDate(); // Get the day part from the date
        });
        setHighlightedDays(extractedDays);
        setIsLoading(false);
        // console.log("highlightedDays", highlightedDays);
      })
      .catch((error) => {
        // ignore the error if it's caused by `controller.abort`
        if (error.name !== "AbortError") {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  React.useEffect(() => {
    fetchHighlightedDays(initialValue);
    // abort request on unmount
    return () => requestAbortController.current?.abort();
  }, []);

  /**
   * Extracts and sets event data based on the response.
   * It filters the response to get events that match the selected date,
   * formats the start time, and maps it to an object with doctor name and start time.
   * Then, it updates the event state using setEvent.
   * @param {Array} response - The response containing event data
   */
  function extractAndSetEventData(response) {
    // Format initialValue to compare only the date part using dayjs
    const formattedInitialValue = initialValue
      .startOf("day")
      .format("YYYY-MM-DD");

    // Filter events that match the selected date, format start time, and map to an object
    const eventsOfTheDay = response
      ?.filter((item) => {
        // Convert item.start to 'YYYY-MM-DD' format as well
        const startDate = dayjs(item.start).format("YYYY-MM-DD");
        return startDate === formattedInitialValue;
      })
      .map((item) => ({
        doctorName: item.doctor.name,
        startTime: dayjs(item.start).format("YYYY-MM-DD | hh:mm A"),
      }));
    // console.log("eventsOfTheDay", eventsOfTheDay);

    // Update the event state using setEvent
    setEvent(eventsOfTheDay);
  }

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }

    setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        defaultValue={initialValue}
        loading={isLoading}
        onMonthChange={handleMonthChange}
        renderLoading={() => <DayCalendarSkeleton />}
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            highlightedDays,
          },
        }}
      />
    </LocalizationProvider>
  );
}
