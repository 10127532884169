import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import axios from "axios";
import { useLocation } from "react-router-dom";

import { readLoginData } from "../../loginData";

export function DoctorMessages() {
  const doctorId = readLoginData().id;
  const [selectedPatient, setSelectedPatient] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [patients, setPatients] = useState([]);

  const [searchText, setSearchText] = useState("");
  const location = useLocation();
  const passedPatientId = location.state?.selectedPatientId;
  useEffect(() => {
    if (passedPatientId) {
      setSelectedPatient(passedPatientId);
      // You may also want to fetch messages for this patient here
      fetchMessages();
    }
  }, [passedPatientId]);

  // Filter patients based on search text
  // Simplify filter logic for debugging purposes
  const filteredPatients =
    searchText === ""
      ? patients
      : patients.filter((patient) => {
          const patientName = `${patient.FName} ${patient.LName}`.toLowerCase();
          return patientName.includes(searchText.toLowerCase());
        });

  // Debugging: Log the patients array and the searchText
  console.log("Patients:", patients);
  console.log("Search Text:", searchText);
  console.log("Filtered patients:", filteredPatients);
  useEffect(() => {
    let newMessage = messages.map((msg, index) => {
      let original = msg.time_stamp;
      let converted = new Date(msg.time_stamp).toLocaleString();
      console.log(`Original: ${original}, Converted: ${converted}`);
      return converted;
    });
    // console.log("messages:", newMessage);
  }, [messages]);

  const handleSearchChange = (event, value) => {
    setSearchText(value || "");
  };

  const handleAutocompleteChange = (event, newValue) => {
    if (newValue) {
      setSelectedPatient(newValue.id);
    } else {
      setSelectedPatient("");
    }
  };

  // Inside DoctorMessages component
  useEffect(() => {
    fetchDoctorInfo();
    fetchPatients();
  }, []);

  const fetchDoctorInfo = async () => {
    try {
      const response = await axios.post(
        "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/DoctorProfileInfo",
        { doctorId }
      );
      // Assuming the response structure as { FName, LName, ... }
      setDoctorInfo(response.data); // Store doctor's info in state
    } catch (error) {
      console.error("Error fetching doctor info:", error);
    }
  };

  const [doctorInfo, setDoctorInfo] = useState(null); // Add this state

  const fetchPatients = async () => {
    try {
      const response = await axios.post(
        "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/DoctorPatientsAuthorized",
        { doctorId }
      );
      setPatients(response.data);
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };

  const fetchMessages = async () => {
    try {
      const response = await axios.post(
        "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/getDoctorPatientMessages",
        { doctorId, patientId: selectedPatient }
      );
      setMessages(response.data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  useEffect(() => {
    if (selectedPatient) {
      fetchMessages();
    }
  }, [selectedPatient]);

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const sendMessage = async () => {
    if (message.trim() !== "" && selectedPatient && doctorInfo) {
      const selectedPatientData = patients.find(
        (p) => p.id === selectedPatient
      );

      if (selectedPatientData) {
        const patientFName = selectedPatientData.FName;
        const patientLName = selectedPatientData.LName;

        try {
          //https://e-react-node-backend-22ed6864d5f3.herokuapp.com
          await axios.post(
            "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/sendDoctorPatientMessage",
            {
              doctorId,
              patientId: selectedPatient,
              doctorFName: doctorInfo.Fname,
              doctorLName: doctorInfo.Lname,
              patientFName,
              patientLName,
              message: "Dr. " + doctorInfo.Lname + " : " + message,
              time: new Date().toISOString(),
            }
          );
          setMessage("");
          fetchMessages(); // Refresh the conversation
        } catch (error) {
          console.error("Error sending message:", error);
        }
      }
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom style={{fontFamily:'Poppins'}}>
        Doctor Messages
      </Typography>
      <Autocomplete
        value={
          filteredPatients.find((patient) => patient.id === selectedPatient) ||
          null
        }
        onChange={handleAutocompleteChange}
        inputValue={searchText}
        onInputChange={handleSearchChange}
        options={filteredPatients}
        getOptionLabel={(option) => `${option.FName} ${option.LName}`}
        sx={{ mb: 2, width: "100%" }}
        renderInput={(params) => (
          <TextField {...params} label="Search Patient" multiline />
        )}
      />

      <TextField
        label="Write a message"
        multiline
        rows={4}
        value={message}
        onChange={handleMessageChange}
        fullWidth
        variant="outlined"
        sx={{ mb: 2 }}
      />

      <Button
          style={{width:'max-content',
            color:'white',
            backgroundColor:'#1A4FBA',
            border:'none',
          fontFamily:'Poppins'}}
        variant="contained"
          onClick={sendMessage}>
        Send Message
      </Button>

      <Box sx={{ mt: 3 }}>
        <h2 >Previous Messages:</h2>
        <Card
          variant="outlined"
          sx={{ mt: 1, overflowY: "auto", maxHeight: 200 }}
        >
          <CardContent>
            {messages.map((msg, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Typography variant="subtitle2">
                  {new Date(msg.time_stamp).toLocaleString()}
                  {/* Converts the 'timestamp' field to a readable format */}
                </Typography>
                <Typography>{msg.message}</Typography>
              </Box>
            ))}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
