import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../constants";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import "./thyroidPrediction.css"

function ThyroidPrediction() {
    const location = useLocation();
    const state = location.state;
    const patientInfo = state;
    const [thyroidPredictionData, setThyroidPredictionData] = useState(null);
    const [prediction, setPrediction] = useState("");
    const [predictionLoader, setPredictionLoader] = useState(false);
    console.log("state", state);



    useEffect(() => {

        const formDataEx = patientInfo.id === 161 ? {
            'age': '57',
            'sex': '1',
            'TSH': '100',
            'T3': '3.6',
            'T4U': '2',
            'FTI': '3',
            'onthyroxine': '0',
            'queryonthyroxine': '0',
            'onantithyroidmedication': '0',
            'sick': '1',
            'pregnant': '0',
            'thyroidsurgery': '0',
            'I131treatment': '0',
            'queryhypothyroid': '0',
            'queryhyperthyroid': '0',
            'lithium': '0',
            'goitre': '0',
            'tumor': '0',
            'hypopituitary': '0',
            'psych': '1'
        } : {
            'age': '71',
            'sex': '0',
            'TSH': '0.02',
            'T3': '2.2',
            'T4U': '165',
            'FTI': '186',
            'onthyroxine': '0',
            'queryonthyroxine': '0',
            'onantithyroidmedication': '0',
            'sick': '1',
            'pregnant': '0',
            'thyroidsurgery': '0',
            'I131treatment': '0',
            'queryhypothyroid': '0',
            'queryhyperthyroid': '0',
            'lithium': '0',
            'goitre': '0',
            'tumor': '0',
            'hypopituitary': '0',
            'psych': '1'
        }

        setThyroidPredictionData(formDataEx);
    }, [patientInfo]); 

    async function predict(thyroidPredictionData) {
        setPredictionLoader(true);
        const formData = new FormData();

        console.log("thyroidPredictionData", thyroidPredictionData);

        Object.keys(thyroidPredictionData).forEach(key => {
            formData.append(key, thyroidPredictionData[key]);
        });

        for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
        }


        // console.log("requestBody", requestBody);
        try {
            console.log('Before axios.post');
            // const { data } = await axios.post(
            //     "https://thyroidprediction-2024-221db23dd9a1.herokuapp.com/predict_thyroid",
            //     requestBody
            // );
            const { data } = await axios.post(
                "https://thyroid-detection-2024-e2a1392e1f29.herokuapp.com/predict",
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            console.log('After axios.post', data);
            setPrediction(data);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setPredictionLoader(false);
        }
    }

    function renderPredictionCell() {
        if (predictionLoader) {
            return <div style={{ color: "#59748A", fontFamily: "helvetica" }}>Loading...</div>;
        }

        // if (!prediction && thyroidPredictionData) {
        if (!prediction) {
            return (
                <button
                    className="predictButton"
                    onClick={() => predict(thyroidPredictionData)}
                >
                    Predict
                </button>
            );
        }
        console.log("prediction", prediction)
        if (prediction !== undefined && prediction !== null) {
            return <div style={{ fontSize: "1.3rem", fontWeight: "700", color: "#59748A", fontFamily: "helvetica" }
            }>{prediction}</div>;
        }

        return null;
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100vh", background: "#F8FAFC" }}>
            <div style={{ fontSize: "2rem", marginBottom: "3rem", fontFamily: "helvetica", fontWeight: "700", color: "#555555", marginTop: "5rem" }}>thyroid Prediction</div>
            <TableContainer sx={{ width: "80%", borderRadius: "10px", border: "1px solid rgba(224, 224, 224, 1)", overflow: "hidden" }}>
                <Table sx={{
                    minWidth: 650
                }} aria-label="customized table">
                    <TableHead>
                        <TableRow sx={{
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}>
                            <TableCell align="center" sx={{ fontSize: "1.2rem", fontWeight: "700", background: "#C6DFFF80", color: "#59748A" }}>Patient Information</TableCell>
                            <TableCell align="center" sx={{ fontSize: "1.2rem", fontWeight: "700", background: "#C6DFFF80", color: "#59748A" }}>Data</TableCell>
                            <TableCell align="center" sx={{ fontSize: "1.2rem", fontWeight: "700", background: "#C6DFFF80", color: "#59748A" }}>Prediction</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow sx={{
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}>
                            <TableCell align="center" sx={{ fontSize: "1.2rem", color: "#59748A", background: "#F3F8FC" }}>
                                {patientInfo.FName} {patientInfo.MName} {patientInfo.LName}
                            </TableCell>
                            <TableCell align="center" sx={{ background: "#F3F8FC" }} >
                                {thyroidPredictionData && (
                                    <div style={{ fontSize: "1.2rem", color: "#59748A", fontFamily: "helvetica", whiteSpace: "pre-wrap", display: "flex", flexWrap: "wrap", alignItems: "center", marginLeft: "2rem" }}>
                                        {Object.entries(thyroidPredictionData).map(([key, value], index) => (
                                            <div key={index} style={{ flex: "0 0 calc(30% - 20px)", marginRight: "20px", minWidth: "12rem", marginBottom: "10px", display: "flex", alignItems: "flex-start", color: "#59748A", fontFamily: "helvetica" }}>
                                                <span style={{ fontWeight: 700, color: "#59748A", fontFamily: "helvetica" }}>
                                                    {key} :&nbsp;</span>
                                                {value}
                                            </div>
                                        ))}

                                    </div>
                                )}
                            </TableCell>
                            <TableCell align="center" sx={{ background: "#F3F8FC" }}>{renderPredictionCell()}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default ThyroidPrediction;