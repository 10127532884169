import { Modal, Select } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import SquareIcon from '@mui/icons-material/Square';

import { clinicalStaffCreateAvailableTimeSegment, doctorCreateAvailableTimeSegment } from '../../api/calendar';
import { readLoginData } from '../../loginData';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const AllCategories = [
  {
    value: 1,
    label: <>Task</>,
  },
  {
    value: 2,
    label: <>Available Slot</>,
  },
];

const CreateAvailableTimeSegments = (props) => {
  const categories = AllCategories.filter(c => props.categories.includes(c.value));

  const onStart = (e) => {
    props.onChange({ start: e.target.value });
  };
  const onEnd = (e) => {
    props.onChange({ end: e.target.value });
  };
  const onDescription = (e) => {
    props.onChange({ description: e.target.value });
  };
  const handleCategory = (e) => {
    props.onChange({ category: e });
  };

  return <>
    <form>
      <label for="category">Category:</label><br/>
      <Select
        id="category"
        value={props.category}
        style={{ width: 200, textAlign: "left" }}
        onChange={handleCategory}
        options={categories}
      /><br/>

      <label for="doctor">Doctor:</label><br/>
      <input type="text" id="doctor" name="doctor" value={props.doctorInfo.name} disabled/><br/>
      <label for="start">Start:</label><br/>
      <input type="text" id="start" name="start" value={props.start} onChange={onStart}/><br/>
      <label for="end">End:</label><br/>
      <input type="text" id="end" name="end" value={props.end} onChange={onEnd}/><br/>
      <label for="description">Descrption:</label><br/>
      <input type="text" id="description" name="description" value={props.description} onChange={onDescription}/><br/>
    </form>
  </>;
};

const CreateDoctorTask = (props) => {
  console.log("CreateDoctorTask props", props);
  const loginData = readLoginData();
  const categories = (props.categories===undefined) ? (AllCategories.map(c => c.value)) : props.categories;

  const [ formContent, setFormContent ] = useState({
    category: ((props.category!==undefined) ? props.category : categories[0]),
    patient: undefined,
    start: moment(props.start).format(dateFormat),
    end: moment(props.end).format(dateFormat),
    description: "",
  });

  const [valid, setValid] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleFormChange = (change) => {
    const newState = {...formContent, ...change};
    setFormContent(newState);
    updateValid(newState);
  }

  const updateValid = (newState) => {
    setValid((moment(newState.start).isValid()) && (moment(newState.end).isValid()));
  }

  const handleOk = () => {
    setConfirmLoading(true);
    (async () => {
      if(props.fromClinicalStaff){
        await clinicalStaffCreateAvailableTimeSegment(
          loginData,
          props.doctorInfo.id,
          formContent.category,
          moment(formContent.start).toDate(),
          moment(formContent.end).toDate(),
          formContent.description
        );
      }else{
        await doctorCreateAvailableTimeSegment(
          loginData,
          formContent.category,
          moment(formContent.start).toDate(),
          moment(formContent.end).toDate(),
          formContent.description
        );
      }
      props.onOk();
    })();
  };

  return (
    <Modal
      title={ "Add An Event" }
      open={true}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={props.onCancel}
      okButtonProps={{ disabled: !valid }}
      style={{ textAlign: "initial" }}
    >
      <CreateAvailableTimeSegments
        doctorInfo={ props.doctorInfo ? props.doctorInfo : { id: loginData.id, name: loginData.name } }
        categories={categories}
        {...formContent}
        onChange={handleFormChange}
      />
    </Modal>
  );
}

export default CreateDoctorTask;
