import React, { useEffect, useState, useRef } from "react";
import { Modal, Divider, Grid, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import PatientDetails from "./PatientDetails.jsx";
import PageLayout from "../../../components/PageLayout/PageLayout.jsx";
import DoctorOperations from "./DoctorOperations.jsx";

const ViewModal = ({ visible, onClose, patientId, doctorId }) => {
  useEffect(() => {
    (async () => {
      try {
        const response = await axios.post(
          "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/users/getPatientPortalInfoById",
          { patientId }
        );
        console.log(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, [patientId]);

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      sx={{
        overflowY: "auto",
      }}
    >
      <PageLayout>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            minHeight: "500px",
            bgcolor: "#F3F8FC",
            overflowY: "auto",
            minWidth: "800px",
          }}
        >
          <Grid
            container
            spacing={0}
            direction="column"
            sx={{ background: "#FFF", borderRadius: "1.5rem", padding: "2rem" }}
          >
            <IconButton
              sx={{
                position: "absolute",
                right: "1.5rem",
                top: "1.5rem",
                zIndex: "10",
              }}
              aria-label="close"
              color="inherit"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
            <PatientDetails patientId={patientId} doctorId={doctorId} />
            <Divider sx={{ borderBottomWidth: 2, borderColor: "#D4E3F0" }} />
            <DoctorOperations patientId={patientId} doctorId={doctorId} />
          </Grid>
        </Box>
      </PageLayout>
    </Modal>
  );
};

export default ViewModal;
