import React from 'react';
import Modal from '../LandingPageModal/LandingPageModal';
import { motion } from "framer-motion";
import '../../../styles/components/LandingPageStyles/LandingPageExtra.css';
import '../../../styles/components/LandingPageStyles/LandingPageHome.css';
import { useNavigate } from 'react-router-dom';
import SolutionsBGN from '../../../styles/screens/LandingPageSolutionsVideo';

const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };
  const ModalButton = ({ onClick, label }) => (
    <motion.button
      className="modal-button"
      type="button"
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
    >
      {label}
    </motion.button>
  );
  
const LandingPageModalThree = ({ closeFn = () => null, open = false }) => {
    const navigate = useNavigate();
    return (
    
    <Modal open={open}>
      
      <div className="modal--mask">
        <motion.div 
        drag
        dragConstraints={{
          top: 0,
          left: 10,
          right: 10,
          bottom: 10,}}
        className="modal2 gray-gradient"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        >
          <SolutionsBGN /> 
          <div style={{
          display: "flex", justifyContent: "center"}} >
          <button
          type="button"
          
          style={{
          width: "25%", marginTop: 180,
          fontWeight: 'normal',textShadow: "1px 2px 1px rgb(100, 100, 100)" }} 
          onClick={() => {window.location.href="LandingPage"}}
          >
          E-Hospital
          </button>
          </div>
          <div className="modal--header">
          <p style={{display: "flex", justifyContent: "center", fontSize: 17, textAlign: "center", color: "white", fontWeight: 'normal',textShadow: "1px 2px 1px rgb(100, 100, 100)"}}>Our e-Hospital platform offers comprehensive<br/>Medical 
          Health Records, a Centric Medical <br/>Chatbot, and Medical Tasks Planning.</p>
         <div style={{
          display: "flex", justifyContent: "center"}} >
          <button
          type="button"
          
          style={{
          width: "25%", marginTop: 30,
          fontWeight: 'normal',textShadow: "1px 2px 1px rgb(100, 100, 100)" }} 
          onClick={() => {window.location.href="http://www.e-hospital.ca/under-developement"}}
          >
          Healthcare Provider Digital Twin
          </button>
          </div>
          <p style={{display: "flex", justifyContent: "center", fontSize: 17, textAlign: "center", color: "white", fontWeight: 'normal',textShadow: "1px 2px 1px rgb(100, 100, 100)"}}>With our Healthcare Provider Digital Twin, <br/>you can monitor and manage
          your health <br/>from the comfort of your home.</p>

          <div style={{
          display: "flex", justifyContent: "center"}} >
          <button
          type="button"
          
          style={{
          width: "25%", marginTop: 30,
          fontWeight: 'normal',textShadow: "1px 2px 1px rgb(100, 100, 100)" }} 
          onClick={() => {window.location.href="http://www.e-hospital.ca/under-developement"}}
          >
          Robotic Surgery
          </button>
          </div>
          <p style={{display: "flex", justifyContent: "center", fontSize: 17,textAlign: "center",color: "white", fontWeight: 'normal',textShadow: "1px 2px 1px rgb(100, 100, 100)"}}>Experience the precision<br/> and effectiveness of Robotic Surgery for <br/>
          complex medical procedures.</p>

          <div style={{
          display: "flex", justifyContent: "center"}} >
          <button
          type="button"
          
          style={{
          width: "25%", marginTop: 30,
          fontWeight: 'normal',textShadow: "1px 2px 1px rgb(100, 100, 100)" }} 
          onClick={() => {window.location.href="http://www.e-hospital.ca/under-developement"}}
          >
          Advanced Medical Informatics
          </button>
          </div>

          <p style={{display: "flex", justifyContent: "center",fontSize: 17, textAlign: "center",color: "white", fontWeight: 'normal',textShadow: "1px 2px 1px rgb(100, 100, 100)"}}>Leverage our Intelligent Health Informatics <br/>to analyze patient data and make informed<br/> 
           decisions for better healthcare outcomes.</p>

          <div style={{
          display: "flex", justifyContent: "center"}} >
          <button
          type="button"
          
          style={{
          width: "25%", marginTop: 30,
          fontWeight: 'normal',textShadow: "1px 2px 1px rgb(100, 100, 100)" }} 
          onClick={() => {window.location.href="http://www.e-hospital.ca/under-developement"}}
          >
          Medical Digital Assistance
          </button>
          </div>
          
          <p style={{display: "flex", justifyContent: "center", fontSize: 17,textAlign: "center",color: "white", fontWeight: 'normal',textShadow: "1px 2px 1px rgb(100, 100, 100)"}}>Our Medical Digital Assistance<br/>provides real-time medical guidance <br/> 
          and support through AI-powered chatbots.</p>
          <div style={{
          display: "flex",paddingTop:105, justifyContent: "center"}}><ModalButton onClick={closeFn} label="Close" /></div>
          <h4  className="modal--header h4" style={{
          fontWeight: 'normal', textShadow: "1px 2px 1px rgb(45, 45, 45)"}} 
          >       
          </h4>        
          </div>        
        </motion.div>
        
      </div>   
    </Modal>
  );
};
export default LandingPageModalThree;
