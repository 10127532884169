import {} from './profileUtils';
import React, { useState, useEffect } from "react";
import { readLoginData } from '../../../loginData';
import { getSpecificClinicalTrialsInfo } from '../../../api/user';
import * as profileUtils from './profileUtils'; // 导入 profileUtils
import "../PharmaceuticalsContent.css";
import SpecificClinicalTrialsPatients from "../ClinicalTrail/SpecificClinicalTrialsPatients";
import SpecificClinicalTrialsMatchedPatients from "../ClinicalTrail/SpecificClinicalTrialsMatchedPatients";
import {Grid,Table,TableBody,TableContainer,} from '@mui/material';

// 临床试验名称显示
const SpecificTrialTitle = ({ trial_name }) => (
  <div className="pharmaceuticals-dashboard-table-title">
    <span>{trial_name}</span>
  </div>
);

const SpecificTrialInfo = ({ trial_id }) => {
  const currentUserInfo = readLoginData();
  const [Info, setSpecificTrialsInfo] = useState(null); // 初始化为 null

  // 获取当前临床试验的详细信息
  useEffect(() => {
    const fetchSpecificTrialsInfo = async () => {
      try {
        const info = await getSpecificClinicalTrialsInfo(trial_id);
        setSpecificTrialsInfo(info[0]);
        console.log("Fetched clinical trial info");
      } catch (error) {
        console.error('Error fetching clinical trials:', error);
      }
    };
    fetchSpecificTrialsInfo();
  }, [trial_id]);

  if (!Info) {
    return <div>Loading...</div>; // 可以显示加载指示器
  }

  return (
    <>
      <SpecificTrialTitle trial_name={Info.trial_name} />

      <Grid container marginTop={2} marginLeft={3}>
        <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
          <profileUtils.SpecificTrialBasicSectionTitle sectionTitle="Official Title" />
          <profileUtils.SpecificTrialBasicSectionContent sectionContent={Info.official_title} />
        </Grid>

        <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
          <profileUtils.SpecificTrialBasicSectionTitle sectionTitle="Brief Summary" />
          <profileUtils.SpecificTrialBasicSectionContent sectionContent={Info.brief_summary} />
        </Grid>

        <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
          <profileUtils.SpecificTrialBasicSectionTitle sectionTitle="Detailed Description" />
          <profileUtils.SpecificTrialBasicSectionContent sectionContent={Info.detailed_description} />
        </Grid>
        <Grid item xs={12} sm={3} sx={{ mb: 5 }}>
          <profileUtils.SpecificTrialBasicSectionTitle sectionTitle="Start Date" />
          <profileUtils.SpecificTrialBasicSectionContent sectionContent={Info.start_date} />
        </Grid>
        {Info.end_date && (
          <Grid item xs={12} sm={3} sx={{ mb: 5 }}>
            <profileUtils.SpecificTrialBasicSectionTitle sectionTitle="End Date" />
            <profileUtils.SpecificTrialBasicSectionContent sectionContent={Info.end_date} />
          </Grid>
        )}
      </Grid>

      <Grid container marginTop={2} marginLeft={3}>
        <Grid item xs={12} sm={5}sx={{ mb: 5 }}>
          <profileUtils.SpecificTrialBasicSectionTitle sectionTitle="Detailed Information" />
          <profileUtils.PofileScrollbar>
            <TableContainer>
              <Table>
                <profileUtils.ProfileScrollbarTableHead
                  headers={[{ label: 'Criteria', align: 'left' }, { label: 'Details', align: 'right' }]}
                />
                <TableBody>
                  <profileUtils.ProfileScrollbarTableBody title="Company Name" description={Info.company_name} />
                  <profileUtils.ProfileScrollbarTableBody title="Trial ID" description={Info.trial_id} />
                  <profileUtils.ProfileScrollbarTableBody title="Related Conditions" description={Info.related_conditions} />
                  <profileUtils.ProfileScrollbarTableBody title="Trial Status" description={Info.trial_status} />
                  <profileUtils.ProfileScrollbarTableBody title="Trial Phase" description={Info.trial_phase} />
                  <profileUtils.ProfileScrollbarTableBody title="Study Type" description={Info.study_type} />
                  <profileUtils.ProfileScrollbarTableBody title="Allocation" description={Info.allocation} />
                  <profileUtils.ProfileScrollbarTableBody title="Intervention Model" description={Info.intervention_model} />
                  <profileUtils.ProfileScrollbarTableBody title="Masking" description={Info.masking} />
                  <profileUtils.ProfileScrollbarTableBody title="Primary Purpose" description={Info.primary_purpose} />
                  <profileUtils.ProfileScrollbarTableBody title="Locations" description={Info.locations} />
                  <profileUtils.ProfileScrollbarTableBody title="Principal Investigator" description={Info.principal_investigator} />
                  <profileUtils.ProfileScrollbarTableBody title="Sponsor" description={Info.sponsor} />
                  <profileUtils.ProfileScrollbarTableBody title="Ethics Approval" description={Info.ethics_approval} />
                  <profileUtils.ProfileScrollbarTableBody title="Pathology" description={Info.pathology} />
                  <profileUtils.ProfileScrollbarTableBody title="Age Range" description={Info.age_range} />
                  <profileUtils.ProfileScrollbarTableBody title="Gender" description={Info.gender} />

                  {/* Exclusion Criteria */}
                  {Info.exclusion_criteria && (
                    <>
                      <profileUtils.ProfileScrollbarTableBody title="Exclusion Criteria - BMI" description={Info.exclusion_criteria.BMI} />
                      <profileUtils.ProfileScrollbarTableBody title="Exclusion Criteria - Diseases" description={Info.exclusion_criteria.Diseases} />
                      <profileUtils.ProfileScrollbarTableBody title="Exclusion Criteria - Pregnancy" description={Info.exclusion_criteria.Pregnancy} />
                      <profileUtils.ProfileScrollbarTableBody title="Exclusion Criteria - Surgeries" description={Info.exclusion_criteria.Surgeries} />
                      <profileUtils.ProfileScrollbarTableBody title="Exclusion Criteria - Prior Medications" description={Info.exclusion_criteria['Prior Medications']} />
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </profileUtils.PofileScrollbar>
        </Grid>
        <Grid item xs={12} sm={1} sx={{ mb: 5 }}></Grid>
        {currentUserInfo && currentUserInfo.type === 'Pharma' && (
          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <profileUtils.SpecificTrialBasicSectionTitle sectionTitle="Management" />
            <SpecificClinicalTrialsPatients trial_id={Info.trial_id} />
            <SpecificClinicalTrialsMatchedPatients trial_info={Info} />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default SpecificTrialInfo;
