import moment from 'moment';
import dayjs from 'dayjs';
import React, { useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, List, Space, Spin, DatePicker, Radio } from 'antd';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { patientGetCalendar } from '../../api/calendar';
import { readLoginData } from '../../loginData';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const { RangePicker } = DatePicker;

const getTimeSegmentState = (status) => {
  if(status < 0){
    return 'Approved';
  }else if(status > 0){
    return 'PendingApproval';
  }else{
    return 'NotBooked';
  }
};

const getColorFromStatus = (type, status) => {
  if(type === 1){ return 'MediumSlateBlue' }
  if(status > 0){
    return 'DarkOrange';
  }else if(status < 0){
    return 'DodgerBlue';
  }else{
    return 'DarkGreen';
  }
}

const TimeSegmentsView = (props) => {
  
  const navigate = useNavigate();

  const [ status, setStatus ] = useState('All');

  console.log("Data", props.data);

  const eventStatusFilter = ((status) => {
    switch(status){
      default:
      case 'All':
        return (e) => true;
      case 'Approved':
        return (e) => e.status < 0;
      case 'PendingApproval':
        return (e) => e.status > 0;
      case 'NotBooked':
        return (e) => e.status === 0;
    }
  })(status);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleRangeChange = (dates) => {
    if(dates){
      props.onRangeChange(dates[0].toDate(), dates[1].toDate());
    }else{
      props.onRangeChange(moment().startOf('week'), moment().endOf('week'));
    }
  }

  const eventsList = props.data.filter(e => e.type === 2 && eventStatusFilter(e))
  .sort((a, b) => moment(a.start).isAfter(moment(b.start)))
  .map(e => ({
    ...e,
    start: moment(e.start).toDate(),
    end: moment(e.end).toDate(),
    color: getColorFromStatus(e.type, e.status),
  }));
  console.log(eventsList);

  return (
    <>
      <Space
        direction="vertical"
        style={{ marginBottom: "20px" }}
        size="middle"
      >
        <Space
          direction="horizontal"
          style={{ justifyContent: "flex-end", marginRight: "20px" }}
        >
          <RangePicker
            value={[dayjs(props.range[0]), dayjs(props.range[1])]}
            onChange={handleRangeChange}
          />
          <Radio.Group value={status} onChange={handleStatusChange}>
            <Radio.Button value="All">All</Radio.Button>
            <Radio.Button value="Approved">Approved</Radio.Button>
            <Radio.Button value="PendingApproval">PendingApproval</Radio.Button>
            <Radio.Button value="NotBooked">NotBooked</Radio.Button>
          </Radio.Group>
          <Button type="primary" onClick={() => navigate("/calendar")}>
            Edit Appointments
          </Button>
          <Link to="/doctor/services">
            <Button
                style={{width:'50px',
                  color:'white',
                  backgroundColor:'#1A4FBA',
                  border:'none'}}>
              Back
            </Button>
          </Link>
        </Space>
      </Space>
      <List
        itemLayout="horizontal"
        pagination={{ position: "bottom", align: "center", pageSize: 10 }}
        dataSource={eventsList}
        renderItem={(item, index) => (
          <List.Item style={{ padding: "20px", fontSize: "20px" }}>
            <List.Item.Meta
              title={
                <Link
                  to={`/calendar/timesegment/${item.id}`}
                  style={{ color: item.color }}
                >
                  {moment(item.start).format(dateFormat)}{" "}
                  {moment
                    .duration(moment(item.end).diff(item.start))
                    .humanize()}
                  {item.patient?.id ? ` Patient: ${item.patient.name}` : null}
                </Link>
              }
              description={
                <>
                  {getTimeSegmentState(item.status)}
                  <br />
                  Description: {item.description}
                </>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
}

const PatientAppointmentList = (props) => {
  
  const navigate = useNavigate();

  const loginData = readLoginData();
  let [ needLoad, setNeedLoad ] = useState(true);
  let [ loading, setLoading] = useState(true);
  let [ data, setData ] = useState([]);
  let [ currentStart, setCurrentStart ] = useState(moment().startOf('week'));
  let [ currentEnd, setCurrentEnd ] = useState(moment().endOf('week'));

  const setCurrentRange = (start, end) => {
    setCurrentStart(start);
    setCurrentEnd(end);
    setNeedLoad(true);
  }

  const fetchData = async () => {
    setLoading(true);
    setData([]);
    const response = await patientGetCalendar(loginData, currentStart.toDate(), currentEnd.toDate());
    setData(response);
    setLoading(false);
  };

  const handleRangeChange = useCallback((start, end) => {
    setCurrentRange(moment(start), moment(end));
  }, [])

  if(needLoad){
    setNeedLoad(false);
    fetchData();
  }

  const handleSelect = (event) => {
    console.log(event);
    if(event.type === 1){
      navigate(`/Tasks/${event.id}`)
    }else if(event.type === 2){
      navigate(`/calendar/timesegment/${event.id}`)
    }
  };

  return <>
      <Spin spinning={loading}>
        <TimeSegmentsView
          range={[currentStart, currentEnd]}
          onRangeChange={handleRangeChange}
          data={data}
          onSelect={handleSelect}/>
      </Spin>
    </>;
}

export default PatientAppointmentList;
