// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clinicalstaff-recent-patient-component {
  background-color: white;
  border-radius: 1.5rem;
}

.clinicalstaff-recent-patient-component-title {
  text-align: left;
  font-size: 1.8rem;
  margin-top: 1rem;
  margin-left: 3.3rem;
}

.clinicalstaff-recent-patient-component-title, .clinicalstaff-recent-patient-component-select {
  display: inline-block;
}

.clinicalstaff-recent-patient-component-select {
  margin-left: 45%;
}

.clinicalstaff-recent-patient-list {
  margin-bottom: 2.6rem;
}

.clinicalstaff-recent-patient-list-item {
  margin-top: 1rem;
  margin-left: 5%;
  padding: 0 !important;
}

.clinicalstaff-recent-patient-list-avatar {
  width: 4.7rem;
  height: 4.7rem;
  border-width: 1px;
  border-color: #fa79b8;
  padding: 6px;
  background-clip: content-box;
  background-color: #fedeed;
  color: black;
}

.clinicalstaff-recent-patient-list-avatar > span {
  line-height: calc(4.7rem - 14px) !important;
  font-size: 1.3rem;
}

.clinicalstaff-recent-patient-name {
  font-size: 1.4rem;
  font-weight: bold;
}

.clinicalstaff-recent-patient-time-tag {
  margin-right: 14.6%;
  min-width: 12%;

  border-radius: 0.5em;
  padding: 0.3em 1em 0.3em 1em;
  font-size: 1.1rem;
}`, "",{"version":3,"sources":["webpack://./src/screens/ClinicalStaffPanelNew/RecentPatientList.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,iBAAiB;EACjB,qBAAqB;EACrB,YAAY;EACZ,4BAA4B;EAC5B,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,2CAA2C;EAC3C,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,cAAc;;EAEd,oBAAoB;EACpB,4BAA4B;EAC5B,iBAAiB;AACnB","sourcesContent":[".clinicalstaff-recent-patient-component {\n  background-color: white;\n  border-radius: 1.5rem;\n}\n\n.clinicalstaff-recent-patient-component-title {\n  text-align: left;\n  font-size: 1.8rem;\n  margin-top: 1rem;\n  margin-left: 3.3rem;\n}\n\n.clinicalstaff-recent-patient-component-title, .clinicalstaff-recent-patient-component-select {\n  display: inline-block;\n}\n\n.clinicalstaff-recent-patient-component-select {\n  margin-left: 45%;\n}\n\n.clinicalstaff-recent-patient-list {\n  margin-bottom: 2.6rem;\n}\n\n.clinicalstaff-recent-patient-list-item {\n  margin-top: 1rem;\n  margin-left: 5%;\n  padding: 0 !important;\n}\n\n.clinicalstaff-recent-patient-list-avatar {\n  width: 4.7rem;\n  height: 4.7rem;\n  border-width: 1px;\n  border-color: #fa79b8;\n  padding: 6px;\n  background-clip: content-box;\n  background-color: #fedeed;\n  color: black;\n}\n\n.clinicalstaff-recent-patient-list-avatar > span {\n  line-height: calc(4.7rem - 14px) !important;\n  font-size: 1.3rem;\n}\n\n.clinicalstaff-recent-patient-name {\n  font-size: 1.4rem;\n  font-weight: bold;\n}\n\n.clinicalstaff-recent-patient-time-tag {\n  margin-right: 14.6%;\n  min-width: 12%;\n\n  border-radius: 0.5em;\n  padding: 0.3em 1em 0.3em 1em;\n  font-size: 1.1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
