import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { saveCurrentPath } from '../LocalNavigate';
import { getSpecificClinicalTrialsPatients, getSpecificClinicalTrialsInvitingPatients, getSpecificClinicalTrialsApplyingPatients } from '../../../api/user';
import MUIDataTable from "mui-datatables";
import SettingsIcon from '@mui/icons-material/Settings';
import { Typography, IconButton } from '@mui/material';
import { ClinicalTrialsStatus } from '../Dashboard/DashUtils';

// 临床试验和患者关联表的标题
const ClinicalTrialsPatientsTableTitle = () => (
  <Typography variant="h5" component="div" sx={{ fontSize: 'medium', fontWeight: 'bold' }}>Partnership Patients</Typography>
);

// 获取状态颜色
const getColorForStatus = (status) => {
  switch (status) {
    case 'Inviting':
      return 'warning';
    case 'Applying':
      return 'info';
    case 'Enrolled':
      return 'success';
    default:
      return 'default';
  }
};

// 点击 patient_full_name 时的操作
const PatientNameClicked = async (patient_id, navigate) => {
  try {
    navigate(`/pharmaceuticals/view/${2}/${patient_id}`);
  } catch (error) {
    console.error('Error opening patient profile!:', error);
  }
};

// 点击 doctor_full_name 时的操作
const DoctorNameClicked = async (doctor_id, navigate) => {
  try {
    navigate(`/pharmaceuticals/view/${1}/${doctor_id}`);
  } catch (error) {
    console.error('Error opening doctor profile!:', error);
  }
};

// 渲染关联医生信息显示
const renderDoctorsInfo = (doctors, navigate) => {
  return (
    <div>
      {doctors.map((doctor, index) => (
        <React.Fragment key={doctor.doctor_id}>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => DoctorNameClicked(doctor.doctor_id, navigate)}
          >
            {doctor.name}
          </span>
          {index < doctors.length - 1 && ', '}
        </React.Fragment>
      ))}
    </div>
  );
};

const tableClinicalTrialsPatientsRender = (value, tableMeta, navigate) => {
  const columnName = tableMeta.columnData.name;
  let onClick = undefined;
  let cursorStyle = 'default';
  saveCurrentPath();

  if (columnName === 'patient_full_name') {
    onClick = () => PatientNameClicked(tableMeta.rowData[1], navigate);
    cursorStyle = 'pointer';
  } else if (columnName === 'doctor_info') {
    const doctors = tableMeta.rowData[5];
    return renderDoctorsInfo(doctors, navigate);
  }

  return (
    <div style={{ cursor: cursorStyle }} onClick={onClick}>{value}</div>
  );
};

const tableClinicalTrialsStatusRender = (value) => {
  const color = getColorForStatus(value);
  return <ClinicalTrialsStatus color={color} status={value} />;
};

// 表头定义
const createColumns = (navigate) => [
  { name: 'trial_id', options: { display: false, filter: false } },
  { name: 'patient_id', options: { display: false, filter: false } },
  { name: 'patient_full_name', label: 'Patient Name', options: { customBodyRender: (value, tableMeta) => tableClinicalTrialsPatientsRender(value, tableMeta, navigate), filter: false } },
  { name: 'enrollment_status', label: 'Status', options: { customBodyRender: (value) => tableClinicalTrialsStatusRender(value), filter: false } },
  { name: 'enrollment_date', label: 'Date', options: { customBodyRender: (value, tableMeta) => tableClinicalTrialsPatientsRender(value, tableMeta, navigate), filter: false } },
  { name: 'doctor_info', label: 'Doctor Name', options: { customBodyRender: (value, tableMeta) => tableClinicalTrialsPatientsRender(value, tableMeta, navigate), filter: false } },
];

// 点击设置按钮
const onEditClick = async (navigate) => {
  try {
    alert("Add edit page navigate here!");
  } catch (error) {
    console.error('Error opening the edit page!:', error);
  }
};

// 自定义工具栏
const CustomToolbar = ({ navigate }) => {
  return (
    <IconButton onClick={() => onEditClick(navigate)} aria-label="more">
      <SettingsIcon />
    </IconButton>
  );
};

// 生成 options 对象
const createOptions = (navigate) => {
  return {
    selectableRows: 'none',
    download: false,
    viewColumns: false,
    print: false,
    elevation: 0,
    filter: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10],
    setRowProps: () => ({
      style: { backgroundColor: '#FFFFFF' },
    }),
    setCellProps: () => ({
      style: { backgroundColor: '#FFFFFF' },
    }),
    customToolbar: () => <CustomToolbar navigate={navigate} />
  };
};

// 主体部分
const SpecificClinicalTrialsPatients = ({ trial_id }) => {
  const [ClinicalTrialsPatients, setClinicalTrialsPatients] = useState([]);
  const navigate = useNavigate();

  // 调用 createOptions和createColumns 函数，确保点击时能正确导航到其他部分
  const options = createOptions(navigate);
  const columns = createColumns(navigate);

  // 获取对应患者信息
  useEffect(() => {
    const fetchClinicalTrialsPatients = async () => {
      try {
        const EnrolledPatients = await getSpecificClinicalTrialsPatients(Number(trial_id));
        const InvitingPatients = await getSpecificClinicalTrialsInvitingPatients(Number(trial_id));
        const ApplyingPatients = await getSpecificClinicalTrialsApplyingPatients(Number(trial_id));
        // 合并查询结果
        const ClinicalTrialsPatients = [...EnrolledPatients, ...InvitingPatients, ...ApplyingPatients];
        setClinicalTrialsPatients(ClinicalTrialsPatients);
      } catch (error) {
        console.error('Error fetching clinical trials:', error);
      }
    };
    fetchClinicalTrialsPatients();
  }, [trial_id]);

  return (
    <MUIDataTable
      title={<ClinicalTrialsPatientsTableTitle />}
      data={ClinicalTrialsPatients}
      columns={columns}
      options={options}
    />
  );
};

export default SpecificClinicalTrialsPatients;
