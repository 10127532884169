import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Divider, Row, Select } from "antd";
import ReactECharts from "echarts-for-react";

import "./AdminDashboardPage.css";
import { getSystemStatus } from "../../api/admin";
import ModelEvaluationModal from "./ModelEvaluation";

const UserBase = ({ data }) => {
  const option = {
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        name: 'User Base',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: {
          formatter: '{d}%'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: data.patient, name: 'Patient', itemStyle: { color: 'rgb(25,53,166)' } },
          { value: data.doctor, name: 'Doctor', itemStyle: { color: 'rgb(155,183,245)' } },
          { value: data.clinicalStaff, name: 'Clinical Staff', itemStyle: { color: '#e5ba73' } },
        ]
      }
    ]
  };

  return <>
    <Card>
      <div className="admin-dashboard-chart-title">User Base</div>
      <Row gutter={24}>
        <Col span={14}>
          <ReactECharts option={option} />
        </Col>
        <Col span={10}>

          <div style={{ borderRadius:"10px", backgroundColor: "rgb(25,53,166)" }} className="admin-dashboard-chart-legend-item">PATIENT</div>
          <div style={{ borderRadius:"10px", backgroundColor: "rgb(155,183,245)" }} className="admin-dashboard-chart-legend-item">DOCTOR</div>
          <div style={{ borderRadius:"10px", backgroundColor: "#e5ba73" }} className="admin-dashboard-chart-legend-item">CLINICAL STAFF</div>
        </Col>
      </Row>
    </Card>
  </>;
};

const Visits = ({ data }) => {
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '20%',
      containLabel: true,
    },
    legend: {
      orient: 'vertical',
      x: 'left',
      top: 'center',
    },
    xAxis: [
      {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: 'DOCTOR',
        type: 'bar',
        stack: 'User',
        emphasis: {
          focus: 'series'
        },
        data: [320, 332, 301, 334, 390, 330, 320],
        itemStyle: { color: 'rgb(25,53,166)' },
      },
      {
        name: 'PATIENT',
        type: 'bar',
        stack: 'User',
        emphasis: {
          focus: 'series'
        },
        data: [120, 132, 101, 134, 90, 230, 210],
        itemStyle: { color: 'rgb(155,183,245)' },
      },
      {
        name: 'CLINICALSTAFF',
        type: 'bar',
        stack: 'User',
        emphasis: {
          focus: 'series'
        },
        data: [220, 182, 191, 234, 290, 330, 310],
        itemStyle: { color: '#e5ba73' },
      },
    ]
  };
  
  return <>
    <Card>
      <div>
        <div className="admin-dashboard-chart-title" style={{ display: "inline" }}>Visits</div>
        <Select
          defaultValue="month"
          variant="borderless"
          style={{
            fontFamily:'Poppins',
            width: 120,
            float: "right",
          }}
          options={[
            {
              value: 'month',
              label: 'This month',
            },
          ]}
        />
      </div>
      <Row gutter={24}>
        <Col span={0}>
          <div style={{borderRadius: "10px", backgroundColor: "rgb(25,53,166)"}}
               className="admin-dashboard-chart-legend-item">PATIENT
          </div>
          <div style={{borderRadius: "10px", backgroundColor: "rgb(155,183,245)"}}
               className="admin-dashboard-chart-legend-item">DOCTOR
          </div>
          <div style={{borderRadius: "10px", backgroundColor: "#e5ba73"}}
               className="admin-dashboard-chart-legend-item">CLINICAL STAFF
          </div>
        </Col>
        <Col span={24}>
          <ReactECharts option={option}/>
        </Col>
      </Row>
    </Card>
  </>;
};

const FeatureLinks = (props) => {
  const navigate = useNavigate();
  const services = [
    {title: "Doctor Verification", to: '/Admin/management#DoctorVerification'},
    {title: "Service Requests", to: '/Admin/management#ServiceRequests'},
    {title: "Consent Form Monitoring", to: '/Admin/management#ConsentFormMonitoring' },
    { title: "Potential Issues", to: '/Admin/management#PotentialIssues' },

  ];

  return <>
    <Row gutter={78} style={{ marginTop: "2.813rem" }}>
      {services.map((service, index) => (
        <Col span={6}>
          <Button
              className='admin-dashboard-feature-button'
              onClick={() => navigate(service.to)}>
            {service.title}
          </Button>

        </Col>
      ))}
    </Row>

  </>;
};

export const AdminDashboardPage = (props) => {
  const [ data, setData ] = useState({});
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  useEffect(() => {
    (async () => {
      const result = await getSystemStatus();
      setData({
        patient: result.patient_count,
        doctor: result.doctor_count,
        clinicalStaff: result.clinical_staff_count,
        hospital: result.hospital_count,
      });
    })();
  }, []);

  return <>
    <Card
      bordered={false}
      className="admin-dashboard-welcome-card"
      style={{
        width: "55.2%",
        backgroundColor: "#1a4fba",
      }}
    >
      <div
        style={{
          color: "#FFFFFF",
          fontSize: "1.65rem",
          textAlign: "left",
        }}
      >
        Hello <span style={{ color: "white" }}>Mr.Black,</span>
      </div>
      <p
        style={{
          color: "#d4e3ef",
          marginTop: "1rem",
          fontSize: "1rem",
          textAlign: "left",
        }}
      >
        Have a good day at work.
      </p>
    </Card>
    <Card
      bordered={false}
      className="admin-dashboard-data-bar"
      style={{
        width: "100%",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row gutter={16}>
        <Col span={5}>
          <div className="admin-dashboard-data-bar-value">{data.patient + data.doctor + data.clinicalStaff}</div>
          <div className="admin-dashboard-data-bar-description">Users</div>
        </Col>
        <Col span={1}><Divider type="vertical" /></Col>
        <Col span={5}>
          <div className="admin-dashboard-data-bar-value">{Math.floor(data.patient/10)}</div>
          <div className="admin-dashboard-data-bar-description">New Users</div>
        </Col>
        <Col span={1}><Divider type="vertical" /></Col>
        <Col span={5}>
          <div className="admin-dashboard-data-bar-value">{data.patient}</div>
          <div className="admin-dashboard-data-bar-description">Patients</div>
        </Col>
        <Col span={1}><Divider type="vertical" /></Col>
        <Col span={5}>
          <div className="admin-dashboard-data-bar-value">{data.hospital}</div>
          <div className="admin-dashboard-data-bar-description">Providers</div>
        </Col>
      </Row>
    </Card>
    <Row gutter={40} style={{ marginTop: "2.813rem" }}>
      <Col span={12}>
        <UserBase data={data}/>
      </Col>
      <Col span={12}>
        <Visits/>
      </Col>
    </Row>
    <FeatureLinks/>
    <Row gutter={78} style={{ marginTop: "2.813rem" }}>
          <Col span={6}>
            <Button className='admin-dashboard-feature-button'  onClick={handleShow}>
              ML Models Evaluation
            </Button>
          </Col>
    </Row>
    <ModelEvaluationModal show={showModal} handleClose={handleClose} />

  </>;
};

export default AdminDashboardPage;
