import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import FolderIcon from "@mui/icons-material/Folder";
import ListItemButton from "@mui/material/ListItemButton";
import Box from "@mui/material/Box";
import { ReactComponent as MedicalRecordIcon1 } from "../../assets/images/medical-record-1.svg";
import { ReactComponent as MedicalRecordIcon } from "../../assets/images/medical-record.svg";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { getPatientRecordsById } from "../../utilities/apis";
import { readLoginData } from "../../loginData";
import { PatientRecordViewImages } from "../../components/DoctorComponents/PatientRecordViewImages";

export const RecentMedicalRecords = () => {
  const loginData = readLoginData();
  const [openModal, setOpenModal] = useState(false);
  const [recordList, setRecordList] = useState([]);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    const fetchRecords = async () => {
      const collections = [
        "CT-Scan_Abdomen",
        "CT-Scan_Brain",
        "CT-Scan_Chest",
        "MRI_Brain",
        "MRI_Spine",
        "X-Ray_Chest",
        "X-Ray_Feet",
        "X-Ray_Lung",
        "X-Ray_Skin",
      ];
      let allRecords = [];

      for (let collection of collections) {
        const result = await getPatientRecordsById(loginData.id, collection);
        console.log("Records", result);
        if (result.length > 0) {
          result.forEach((record) => {
            allRecords.push({
              RecordDate: record.RecordDate,
              collection: collection,
              result: record,
            });
          });
        }
      }

      allRecords.sort(
        (a, b) => new Date(b.RecordDate) - new Date(a.RecordDate)
      );

      const recentRecords = allRecords.slice(0, 3);

      setRecords(recentRecords);
    };

    fetchRecords();
  }, []);

  const fetchPatientRecords = async (recordType) => {
    const filteredRecords = records.filter(
      (record) => record.collection === recordType
    );
    const results = filteredRecords.map((record) => record.result);
    setRecordList(results);
  };
  const handleOpenModal = (recordTypeSelect) => {
    fetchPatientRecords(recordTypeSelect);
    setOpenModal(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };
  return (
    <>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "1.4rem",
          fontFamily: "Poppins",
          color: "#1A4EBA",
          textAlign: "left",
          mb: "1rem",
        }}
      >
        Most Recent Medical Records
      </Typography>
      <List>
        {records.map((record, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              component="a"
              href="#simple-list"
              onClick={() => handleOpenModal(record.collection)}
            >
              <Box
                sx={{
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  backgroundColor: "rgba(217, 217, 217, 0.6)", // #D9D9D9 with 60% opacity
                  p: 0.5,
                  mr: 2,
                }}
              >
                <MedicalRecordIcon1 style={{ width: "20px", height: "20px" }} />
              </Box>
              <ListItemText primary={record.collection.replace("_", " ")} />

              <Typography sx={{ mr: 5 }}>
                {formatDate(record.RecordDate)}
              </Typography>
              <MedicalRecordIcon style={{ width: "20px", height: "20px" }} />
            </ListItemButton>
          </ListItem>
        ))}
        <RecordModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          recordList={recordList}
        />
      </List>
    </>
  );
};

const RecordModal = ({ open, onClose, recordList }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <center>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            sx={{ width: "50%" }}
          >
            Exit
          </Button>
        </center>
        <Card sx={{ minHeight: 800, overflow: "auto" }}>
          <PatientRecordViewImages recordData={recordList} />
        </Card>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Responsive width
  maxHeight: "90vh",
  overflowY: "auto",
  bgcolor: "#f5f5f5", // Paper-like background color
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Softer shadow
  p: 4,
  borderRadius: 2, // Slight rounding of corners
};
