import { ConfigProvider, Layout } from "antd";
import AdminSideBar from "../SideBar/AdminSideBar";
import DoctorSideBar from "../SideBar/DoctorSideBar";
import PatientSideBar from "../SideBar/PatientSideBar";
import PharmaceuticalsSidebar from "../SideBar/PharmaceuticalsSidebar";
import Header from "../Header/Header";
import "./PageLayout.css";
import { Outlet } from "react-router-dom";

const layoutStyle = {
  textAlign: "initial",
  backgroundColor: "#f2f8fd",
  width: "100vw",
  minHeight: "100vh",
  overflowX: "scroll",
};

const PageLayout = ({
  children,
  clearUser,
  className,
  user = "Doctor",
  patientID,
}) => {
  let SidebarComponent;

  switch (user) {
    case "Admin":
      SidebarComponent = <AdminSideBar clearUser={clearUser} />;
      break;
    case "Doctor":
      SidebarComponent = <DoctorSideBar clearUser={clearUser} />;
      break;
    case "Pharmaceuticals":
      SidebarComponent = <PharmaceuticalsSidebar clearUser={clearUser} />;
      break;
    case "Patient":
      SidebarComponent = <PatientSideBar clearUser={clearUser} />;
      break;
    default:
      SidebarComponent = <PatientSideBar clearUser={clearUser} />;
      break;
  }
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Layout: {
              colorBgLayout: "#f2f8fd",
            },
          },
        }}
      >
        <Layout style={layoutStyle} className={className}>
          {/* {user === "Doctor" ? (
            <DoctorSideBar clearUser={clearUser} />
          ) : (
            <PatientSideBar clearUser={clearUser} />
          )} */}
          {SidebarComponent}
          <Layout className="page-layout-main">
            <Header />
            {children}
            <Outlet context={patientID} />
          </Layout>
        </Layout>
      </ConfigProvider>
    </>
  );
};

export default PageLayout;
