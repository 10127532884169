import { ConfigProvider, Layout } from "antd";

import ClinicalStaffSideBar from "../../components/SideBar/ClinicalStaffSideBar";
import Header from "../../components/Header/Header";

import "./PageLayout.css";

const layoutStyle = {
  backgroundColor: '#f2f8fd',
  width: '100vw',
  minHeight: '100vh',
};

const PageLayout = ({ children, clearUser }) => {
  return <>
    {/* <BackgroundSetter backgroundColor='#f2f8fd' /> */}
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            colorBgLayout: '#f2f8fd',
          },
        },
      }}
    >
      <Layout style={layoutStyle} className="page-layout-root">
        <ClinicalStaffSideBar clearUser={clearUser}/>
        <Layout className="page-layout-main">
          <Header/>
          {children}
        </Layout>
      </Layout>
    </ConfigProvider>
  </>;
};

export default PageLayout;
