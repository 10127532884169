import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { Typography, Box, Button } from "@mui/material";
import overviewIcon from "../../../assets/images/health-overview.svg";
import AITreatmentPopup from "../../../../src/components/DoctorComponents/DoctorAITreatmentPlan.jsx"; // Correct import path
import { BlueButton } from "./PatientDetailsComponents.jsx";

const HealthOverview = ({ patientId, doctorId, profileData }) => {
  const [showAITreatmentPopup, setShowAITreatmentPopup] = useState(false);
  const [gradient, setGradient] = useState(null);

  useEffect(() => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "rgba(255, 169, 0, 1)");
    gradient.addColorStop(0.3, "rgba(255, 169, 0, 1)");
    gradient.addColorStop(1, "rgba(255, 255, 255, 1)");
    setGradient(gradient);
  }, []);

  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "April",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "# of weight",
        data: [72, 69, 73, 75, 78, 81, 79, 74, 73, 72, 70, 68, 66, 65],
        backgroundColor: gradient,
        borderColor: "rgba(255, 255, 255, 1)",
        borderRadius: 10,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: false,
        grid: {
          display: true,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 4,
        },
        title: {
          display: true,
          text: "kg",
          align: "end",
          color: "#C4C4C4",
          font: {
            size: 14,
            weight: "bold",
          },
          padding: { top: 10, left: 0, right: 0, bottom: 0 },
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: true,
      },
    },
  };

  const popupClickHandler = (e) => {
    e.stopPropagation();
    setShowAITreatmentPopup(true);
  };

  if (!gradient) return null;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: "2em",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "3em",
              height: "3em",
              borderRadius: "0.5em",
              background: "#FFF",
              boxShadow: "10px 10px 30px 10px #9EA2AC4D",
              mr: "1.5em",
            }}
          >
            <img
              style={{ width: "2em" }}
              src={overviewIcon}
              alt="notifoverview icon"
            />
          </Box>
          <Typography
            gutterBottom
            sx={{ color: "#4F4E4E", fontWeight: "500", fontSize: "1.4em" }}
            mb={0}
          >
            Health Overview
          </Typography>
        </Box>
        <Box>
        <BlueButton onClick={popupClickHandler} fullWidth variant="outlined" buttonTitle={"AI Treatment Plan"} />
        <AITreatmentPopup
          open={showAITreatmentPopup}
          onClose={() => setShowAITreatmentPopup(false)}
          patientId={patientId}
          doctorId={doctorId}
          patientName={`${profileData.FName} ${profileData.LName}`} // Pass patient name as a prop
        />
        </Box>
      </Box>
      <Box>
        <Bar data={data} options={options} style={{ height: "22rem" }} />
      </Box>
    </>
  );
};

export default HealthOverview;
