import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MessageIcon from "@mui/icons-material/Message";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { Link } from "react-router-dom";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HelpIcon from "@mui/icons-material/Help";
import PagesIcon from "@mui/icons-material/Pages";

import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import CalendarMonthOutlined from "@mui/icons-material/CalendarMonthOutlined";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import LogoutIcon from "@mui/icons-material/Logout";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

function MainListItems({ clearUser, user }) {
  const [activeItem, setActiveItem] = React.useState(null);
  const handleListItemClick = (item) => {
    setActiveItem(item);
  };
  const listItems = [
    { id: "1", to: "/patient/portal", icon: <PagesIcon />, text: "Portal" },
    { id: "2", to: "/profile", icon: <AccountBoxIcon />, text: "Profile" },

    {
      id: "6",
      to: "/patient/report",
      icon: <TrackChangesIcon />,
      text: "HealthReport",
    },
    { id: "3", to: "", icon: <MessageIcon />, text: "Messages" },
    {
      id: "7",
      to: "/patient/planning",
      icon: <CalendarMonthOutlined />,
      text: "Planning",
    },
    { id: "4", to: "/patient/calendar", icon: <CalendarMonthIcon />, text: "Calendar" },

    { id: "5", to: "", icon: <HelpIcon />, text: "Help" },
  ];
  return (
    <Stack sx={{ justifyContent: "space-between" }}>
      <Box
        sx={{
          height: "40vh",
          mb: "45vh",
        }}
      >
        {listItems.map((item) => (
          <ListItemButton
            key={item.id}
            component={Link}
            to={item.to}
            onClick={() => handleListItemClick(item.to)}
            sx={{
              backgroundColor:
                activeItem === item.to ? "#0E45B7" : "transparent",
              color: activeItem === item.to ? "#FFFFFF" : "",
              width: "80%",
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: "#0E45B7",
                color: "#FFFFFF",
                width: "80%",
                borderRadius: "5px",
              },
              mx: "auto",
            }}
          >
            <ListItemIcon
              sx={{
                color: activeItem === item.to ? "#FFFFFF" : "",
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        ))}
      </Box>
      <Box>
        <ListItemButton
          component={Link}
          to="/LogIn"
          onClick={clearUser}
          sx={{
            backgroundColor: "transparent",
            color: "text.primary",
            width: "80%",
            borderRadius: "5px",
            "&:hover": {
              backgroundColor: "#0E45B7",
              color: "#FFFFFF",
              width: "80%",
              borderRadius: "5px",
            },
            mx: "auto",
          }}
        >
          <ListItemIcon>
            <LogoutIcon sx={{ color: "text.primary" }} />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </ListItemButton>
      </Box>
    </Stack>
  );
}
export default MainListItems;
