// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: Roboto, sans-serif;
  margin: 0;
  padding: 0;
}

.app--shell {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 7rem 1fr auto;
  height: 100%;
  position: absolute;
  z-index: 0;
}

.app--header,
.app--footer {
  padding: 1rem;
}

.app--header {
  background: rgb(225, 225, 225);
  border-bottom: 1px solid rgb(127, 127, 127);
  padding: 1rem;
}

.app--header h1 {
  margin: 0 0 1rem 0;
  padding: 0;
}

.app--nav {
  display: flex;
  column-gap: 1rem;
}

.app--screen {
  padding: 2rem 1rem;
}

.app--screen h2 {
  margin: 0 0 1rem 0;
  padding: 0;
}

.app--footer {
  background: rgb(31, 31, 31);
  color: rgb(255, 255, 255);
  padding: 1rem;
}

.copyright {
  margin: 0;
  padding: 0;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/LandingPageStyles/LandingPageApp-Shell.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,0BAA0B;EAC1B,iCAAiC;EACjC,YAAY;EACZ,kBAAkB;EAClB,UAAU;AACZ;;AAEA;;EAEE,aAAa;AACf;;AAEA;EACE,8BAA8B;EAC9B,2CAA2C;EAC3C,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,2BAA2B;EAC3B,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,SAAS;EACT,UAAU;EACV,kBAAkB;AACpB","sourcesContent":["body {\n  font-family: Roboto, sans-serif;\n  margin: 0;\n  padding: 0;\n}\n\n.app--shell {\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-template-rows: 7rem 1fr auto;\n  height: 100%;\n  position: absolute;\n  z-index: 0;\n}\n\n.app--header,\n.app--footer {\n  padding: 1rem;\n}\n\n.app--header {\n  background: rgb(225, 225, 225);\n  border-bottom: 1px solid rgb(127, 127, 127);\n  padding: 1rem;\n}\n\n.app--header h1 {\n  margin: 0 0 1rem 0;\n  padding: 0;\n}\n\n.app--nav {\n  display: flex;\n  column-gap: 1rem;\n}\n\n.app--screen {\n  padding: 2rem 1rem;\n}\n\n.app--screen h2 {\n  margin: 0 0 1rem 0;\n  padding: 0;\n}\n\n.app--footer {\n  background: rgb(31, 31, 31);\n  color: rgb(255, 255, 255);\n  padding: 1rem;\n}\n\n.copyright {\n  margin: 0;\n  padding: 0;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
