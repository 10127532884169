import React, {useRef, useState} from 'react';
import './content.css'; // Import CSS file
import Home from './home.png';
import Doctor from './doctor.png'
import Services from './services.png'
import AISolution from './ai-solution.png'
import { Link as RouterLink } from 'react-router-dom';
import ScreenReader from "../../Accessibility/SreenReader";

const Content = () => {
    const mainContentRef = useRef();


    return (
        <div className="content" ref={mainContentRef}>
            <section id="home" className="section home-section">
                <div className="home-content">
                    <div className="left">
                        <h1>e-Hospital</h1>
                        <h4>Smart Digital Medicine Solutions</h4>
                        <RouterLink to="/JoinUs" >
                        <button className="btn-join">Join Us Now</button>
                            </RouterLink>
                    </div>
                    <div className="right">
                        <img src={Home} alt="Home Image"/>
                    </div>
                </div>
                <div className="home-blocks">
                    <div className="home-block">
                        <h3>Platform</h3>
                        <p>Provide a full-featured online communication platform for doctors and patients.
                        </p>
                    </div>
                    <div className="home-block">
                        <h3>Machine Learning</h3>
                        <p>Utilizing the latest machine learning algorithms to assist in diagnosing diseases with high accuracy.</p>
                    </div>
                    <div className="home-block">
                        <h3>User-oriented</h3>
                        <p>Customized personalization for users, offering user-friendly functions and interfaces.
                        </p>
                    </div>
                </div>
            </section>
            <section id="about" className="section about-section">
                <div className="about-content">

                    <div className="left">
                        <img src={Doctor} alt="Doctor Image"/>
                    </div>
                    <div className="right">
                        <h3>About us</h3>
                        <h2>Electrical platform for every role</h2>
                        <h4>Enjoy Your Electronic Health System</h4>
                        <p>Simplify patient care through a digital platform with virtual consultations, electronic
                            record management, and secure communication, enhancing medical service efficiency and
                            convenience, supporting personalized healthcare at home.</p>
                    </div>
                </div>
                <div className="mission-content" >
                    <h2>Our Mission</h2>

                    <p>
                        <b>
                        Empower Patients:
                    </b>
                        We believe that healthcare should be patient-centric. Our eHealth system puts individuals in the
                        driver's seat of their health journey, enabling them to make informed decisions, access their
                        health records, and interact with healthcare professionals seamlessly.
                    </p>
                    <p><b>
                        Support Health Care Providers:
                        </b>
                        We understand the challenges healthcare professionals face daily. Our eHealth platform is designed to streamline their work, enhance communication, and provide valuable insights that aid in delivering superior patient care. </p>
                    <p> <b>
                        Ensure Data Security:
                    </b>
                        Your health data is precious. We prioritize the highest standards of data security and privacy to ensure that your sensitive information remains confidential and secure..</p>
                </div>
            </section>
            <section id="services" className="section services-section">
                <div className="services-content">
                    <div className="left">
                        <h3>Services</h3>
                        <h2>Electrical platform for every role</h2>

                    </div>
                    <div className="right">
                        <img src={Services} alt="Home Image"/>
                    </div>
                </div>
                <div className="services-blocks">
                    <div className="services-block" >
                        <h3>For Doctors</h3>
                        <p>Streamline patient care with virtual consultations, record management, and secure communication</p>
                        <RouterLink to="/services" >
                        <button className="btn-join">More Info</button>
                        </RouterLink>
                    </div>
                    <div className="services-block" >
                        <h3>For Patients</h3>
                        <p>Access medical records, schedule virtual appointments, and communicate with healthcare providers.</p>
                        <RouterLink to="/services" >
                            <button className="btn-join">More Info</button>
                        </RouterLink>
                    </div>
                    <div className="services-block" >
                        <h3>For Clinics</h3>
                        <p>Collaborate with doctors to schedule work, communicate with medical users, and create forms online.</p>
                        <RouterLink to="/services" >
                            <button className="btn-join">More Info</button>
                        </RouterLink>
                    </div>
                </div>
            </section>
                <section id="ai-solution" className="section ai-solution-section">
                    <div className="ai-solution-content">
                        <div className="left">
                            <img src={AISolution} alt="AI Solution"/>
                        </div>
                        <div className="right">
                            <h3>AI Solution</h3>
                            <h2>Experienced in multiple medical practices</h2>
                            <p>Artificial intelligence technology combined with diagnostics.</p>
                            <RouterLink to="/Specialities">
                                <button className="btn-question">Specialities</button>
                            </RouterLink>
                        </div>
                    </div>
                    <div className="ai-solution-blocks">
                        <div className="ai-solution-block">
                            <p>We utilize cutting-edge technology, including Machine Learning (ML) models, to offer advanced healthcare services, focusing on precise and swift diagnostics for early ailment detection.</p>
                        </div>
                        <div className="ai-solution-block">
                            <p>We enable seamless integration with diagnostic labs for efficient test ordering, results retrieval, and collaborative patient diagnostics.</p>
                        </div>
                        <div className="ai-solution-block">
                            <p>Our platform facilitates AI-driven analysis, enhancing the accuracy and speed of medical assessments.</p>
                        </div>
                    </div>
                </section>
            <section id="contact" className="section contact-section">
                <div className="contact-content">
                    <h3>A Widely Used Platform</h3>
                </div>
                <div className="contact-blocks">
                    <div className="contact-block">
                        <h3>3+ Countries</h3>
                    </div>
                    <div className="contact-block">
                        <h3>150+ Doctors</h3>
                    </div>
                    <div className="contact-block">
                        <h3>300+ Patients</h3>

                    </div>

                </div>
                <div className="questions">
                    <div className="info">
                        <p>Our application process ensures the privacy of each client's information. At the same time,
                            each application will be checked by professionals to ensure efficient.</p>
                    </div>
                    <div className="contact-buttons">
                        <RouterLink to="/JoinUs" >
                            <button className="btn-join">Join Us Now</button>
                        </RouterLink>
                        <RouterLink to="/Contact">
                            <button className="btn-question">Have Questions</button>
                        </RouterLink>

                    </div>

                </div>
            </section>
            <ScreenReader contentRef={mainContentRef} />


        </div>


    );
};

export default Content;
