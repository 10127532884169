import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Card, Col, Divider, Flex, Row, Select, Tag } from 'antd';
import { useNavigate } from "react-router-dom";

import { readLoginData } from '../../loginData';
import { getDoctors } from '../../api/calendar';
import { clinicalStaffGetDoctorCalendar, clinicalStaffGetTasks } from '../../api/clinicalStaff';

import PlanningCalendarToolbar from '../../components/CalendarComponents/PlanningCalendarToolbar';
import PlanningCalendarView, { calcRange } from '../../components/CalendarComponents/PlanningCalendarView';
import CreateDoctorTask from '../DoctorNew/CreateDoctorTask';
import TimeSegmentDialog from '../DoctorNew/TimeSegmentDialog';
import PageLayout from "./PageLayout";
import CreateClinicalStaffTask from './CreateClinicalStaffTask';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import "./ClinicalStaffCalendar.css";

const DoctorCalendar = (props) => {
  const navigate = useNavigate();

  const loginData = readLoginData();
  let [ needLoad, setNeedLoad ] = useState(false);
  let [ loading, setLoading] = useState(false);
  let [ data, setData ] = useState([]);

  let [ doctorList, setDoctorList ] = useState([]);
  let [ selectedDoctor, setSelectedDoctor ] = useState(null);
  let [ date, setDate ] = useState(moment().toDate());
  let [ view, setView ] = useState('week');

  let [ clinicalStaffTaskOpen, setClinicalStaffTaskOpen ] = useState(false);

  useEffect(() => {
    (async() => {
      const response = await getDoctors(loginData);
      setDoctorList([{ label: "My Calendar", value: null }].concat(response.map(r => ({
        label: r.name,
        value: r.id,
      }))));
    })();
  }, []);

  useEffect(() => {
    setNeedLoad(true);
  }, [ doctorList, selectedDoctor, date, view ])

  if(needLoad){
    (async () => {
      setLoading(true);
      setNeedLoad(false);
      const range = calcRange(date, view);
      let response;
      if(selectedDoctor){
        response = await clinicalStaffGetDoctorCalendar(loginData, selectedDoctor, range[0], range[1]);
      }else{
        response = await clinicalStaffGetTasks(loginData, range[0], range[1]);
        response = response.map(r => ({ ...r, category: 1 }));
      }
      setData(response);
      console.log("response", response);
      setLoading(false);
    })();
  }

  const doctorSelectSearch = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  // states for the modal
  const [ createOpen, setCreateOpen ] = useState(false);
  const [ start, setStart] = useState(moment().toDate());
  const [ end, setEnd ] = useState(moment().add(1, 'hour').toDate());

  const [ detailId, setDetailId ] = useState(null);
  const handleSelectEvent = (event) => {
    if(selectedDoctor===null)return;
    console.log("open detail", event);
    setDetailId(event.id);
  };

  const handleCreateEvent = () => {
    const start = moment(date).startOf('day').add('12', 'hour');
    const end = moment(date).startOf('day').add('12.5', 'hour');
    setStart(start.toDate());
    setEnd(end.toDate());

    if(selectedDoctor===null){
      setClinicalStaffTaskOpen(true);
    }else{
      setCreateOpen(true);
    }
  }
  
  const handleSelectSlot = (range) => {
    if(range.action==='select'){
      const start = moment(range.start);
      const end = moment(range.end);
      if(!start.clone().add(24, 'hour').isAfter(end))return;
      setStart(start.toDate());
      setEnd(end.toDate());

      if(selectedDoctor===null){
        setClinicalStaffTaskOpen(true);
      }else{
        setCreateOpen(true);
      }
    }
  };

  const handleOk = () => {
    setCreateOpen(false);
    setNeedLoad(true);
  };

  const handleCancel = () => {
    setCreateOpen(false);
  };

  return <>
    <div className='clinicalstaff-calendar-toolbar'>
      <div>
        <span style={{ fontSize: "0.875rem", color: "#00000099" }}>Select Calendar:&nbsp;</span>
        <Select
          showSearch
          options={doctorList}
          value={selectedDoctor}
          onChange={(v) => setSelectedDoctor(v)}
          filterOption={doctorSelectSearch}
          placeholder="Please select a user"
          style={{ minWidth: "4rem" }}
          className="clinicalstaff-calendar-toolbar-select"
        />
      </div>
      <PlanningCalendarToolbar
        date={date}
        view={view}
        hasCreateButton={true}

        onNavigate={(d) => { setDate(d); setNeedLoad(true); }}
        onView={(v) => { setView(v); setNeedLoad(true); }}
        onCreateEvent={ handleCreateEvent }
      />
    </div>
    <Row justify="space-between" className='clinicalstaff-calendar-categories' gutter={10}>
      <Col span={4}><Tag color="#D9D9D9FF">Surgery</Tag></Col>
      <Col span={4}><Tag color="#FFA9004D">General Consultation</Tag></Col>
      <Col span={4}><Tag color="#3377DD4D">Lab Testing</Tag></Col>
      <Col span={4}><Tag color="#EB00FF4D">Task</Tag></Col>
      <Col span={4}><Tag color="#40DC8263">W/O State</Tag></Col>
      <Col span={4}><Tag color="#ADEDCD80" style={{ border: "1px dotted" }}>Pending Approval</Tag></Col>
    </Row>
    <Card
        bordered={false}
        style={{
          height: "95.8%",
          marginBottom: "4.2%",
          backgroundColor: "white",
          borderRadius: "1.5rem",
        }}
      >
      <PlanningCalendarView
        selectable={true}
        loading={loading}
        data={data}
        date={date}
        view={view}

        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleSelectEvent}
      />
      { createOpen ?
        <CreateDoctorTask
          fromClinicalStaff={true}
          doctorInfo={{ id: selectedDoctor, name: doctorList.find(r => r.value===selectedDoctor).label }}
          category={2}
          start={start}
          end={end}
          onOk={handleOk}
          onCancel={handleCancel}/>
        : null }
      { detailId ? <TimeSegmentDialog id={detailId} onClose={() => setDetailId(null)} onApprove={() => setNeedLoad(true)}/> : null }
      { clinicalStaffTaskOpen ? (
        <CreateClinicalStaffTask
          start={start}
          end={end}
          onOk={() => { setNeedLoad(true); setClinicalStaffTaskOpen(false); }}
          onCancel={() => { setClinicalStaffTaskOpen(false); }}
        />
      ) : null }
    </Card>
  </>;
};

const ClinicalStaffCalendarPage = (props) => {
  return (
    <>
      <PageLayout clearUser={props.clearUser} className="clinicalstaff-calendar-page">
        <Flex
          justify="space-evenly"
          style={{ height: "100%", marginTop: "4rem" }}
        >
          <Flex vertical gap="1rem" style={{ display: "flex", width: "90%" }}>
            <DoctorCalendar />
          </Flex>
        </Flex>
      </PageLayout>
    </>
  );
};

export default ClinicalStaffCalendarPage;
