import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import axios from "axios";
import moment from "moment";
import "moment-timezone";
import { useLocation } from "react-router-dom";

import { readLoginData } from "../../loginData";

export function PatientMessages() {
  const patientId = readLoginData().id;
  const [selectedDoctor, setSelectedPatient] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [doctors, setDoctors] = useState([]);

  const [searchText, setSearchText] = useState("");
  // const location = useLocation();
  // const passedPatientId = location.state?.selectedPatientId;
  //   useEffect(() => {
  // if (passedPatientId) {
  //   setSelectedPatient(passedPatientId);
  // You may also want to fetch messages for this patient here
  // fetchMessages();
  // }
  //   }, [passedPatientId]);

  // Filter doctors based on search text
  // Simplify filter logic for debugging purposes
  const filteredDoctors =
    searchText === ""
      ? doctors
      : doctors.filter((doctor) => {
          const doctorName = `${doctor.Fname} ${doctor.Lname}`.toLowerCase();
          return doctorName.includes(searchText.toLowerCase());
        });

  // Debugging: Log the doctors array and the searchText
  useEffect(() => {
    console.log("Doctors updated:", doctors);
  }, [doctors]);
  useEffect(() => {
    console.log("Search Text:", searchText);
  }, [searchText]);
  useEffect(() => {
    console.log("Filtered doctors:", filteredDoctors);
  }, [filteredDoctors]);
  useEffect(() => {
    let newMessage = messages.map((msg, index) => {
      let original = msg.time_stamp;
      let localTime = moment
        .tz(msg.time_stamp, "America/Toronto")
        .format("YYYY/MM/DD HH:mm:ss");
      console.log(`Original: ${original}, Converted: ${localTime}`);
      return localTime;
    });
    // console.log("messages:", newMessage);
  }, [messages]);

  const handleSearchChange = (event, value) => {
    setSearchText(value || "");
  };

  const handleAutocompleteChange = (event, newValue) => {
    if (newValue) {
      setSelectedPatient(newValue.id);
    } else {
      setSelectedPatient("");
    }
  };

  // Inside DoctorMessages component
  useEffect(() => {
    fetchPatientInfo();
    fetchDoctors();
  }, []);

  const fetchPatientInfo = async () => {
    try {
      const response = await axios.post(
        "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/PatientProfileInfo",
        { patientId }
      );
      // Assuming the response structure as { FName, LName, ... }
      setPatientInfo(response.data); // Store doctor's info in state
    } catch (error) {
      console.error("Error fetching patient info:", error);
    }
  };

  const [patientInfo, setPatientInfo] = useState(null); // Add this state

  const fetchDoctors = async () => {
    try {
      const response = await axios.post(
        "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/findDoctorsByPatientId",
        { patientId }
      );
      setDoctors(response.data);
    } catch (error) {
      console.error("Error fetching doctors:", error);
    }
  };

  const fetchMessages = async () => {
    try {
      const response = await axios.post(
        "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/getPatientDoctorMessage",
        { patientId, doctorId: selectedDoctor }
      );
      setMessages(response.data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  useEffect(() => {
    if (selectedDoctor) {
      fetchMessages();
    }
  }, [selectedDoctor]);

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const sendMessage = async () => {
    if (message.trim() !== "" && selectedDoctor && patientInfo) {
      const selectedDoctorData = doctors.find((p) => p.id === selectedDoctor);

      if (selectedDoctorData) {
        const doctorFName = selectedDoctorData.Fname;
        const doctorLName = selectedDoctorData.Lname;

        try {
          //https://e-react-node-backend-22ed6864d5f3.herokuapp.com
          await axios.post(
            "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/sendPatientDoctorMessage",
            {
              patientId,
              doctorId: selectedDoctor,
              patientFName: patientInfo.FName,
              patientLName: patientInfo.LName,
              doctorFName,
              doctorLName,
              message: patientInfo.FName + " : " + message,
              time: new Date().toISOString(),
            }
          );
          setMessage("");
          fetchMessages(); // Refresh the conversation
        } catch (error) {
          console.error("Error sending message:", error);
        }
      }
    }
  };

  return (
    <Box sx={{ p: 3 }} >
      <Typography variant="h4" gutterBottom style={{fontFamily:'Poppins'}}>
        Doctor Messages
      </Typography>
      <Autocomplete
        value={
          filteredDoctors.find((doctor) => doctor.id === selectedDoctor) || null
        }
        onChange={handleAutocompleteChange}
        inputValue={searchText}
        onInputChange={handleSearchChange}
        options={filteredDoctors}
        getOptionLabel={(option) => `${option.Fname} ${option.Lname}`}
        sx={{ mb: 2, width: "100%" }}
        renderInput={(params) => (
          <TextField {...params} label="Search Doctor" multiline />
        )}
      />

      <TextField
        label="Write a message"
        multiline
        rows={4}
        value={message}
        onChange={handleMessageChange}
        fullWidth
        variant="outlined"
        sx={{ mb: 2 }}
      />

      <Button
          style={{width:'max-content',
            color:'white',
            backgroundColor:'#1A4FBA',
            border:'none',
            fontFamily:'Poppins'}}
          variant="contained"
          onClick={sendMessage}>
        Send Message
      </Button>

      <Box sx={{ mt: 3 }}>
        <h2 >Previous Messages:</h2>
        <Card
            variant="outlined"
            sx={{ mt: 1, overflowY: "auto", maxHeight: 200 }}
        >
          <CardContent>
            {messages.map((msg, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Typography variant="subtitle2">
                    {moment
                        .utc(msg.time_stamp)
                        .utcOffset(-8 * 60)
                        .format("YYYY/MM/DD HH:mm:ss")}
                    {/* Converts the 'timestamp' field to a readable format */}
                  </Typography>
                  <Typography>{msg.message}</Typography>
                </Box>
            ))}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
