import React, { useState } from 'react';
import './ModelEvaluation.css';
import axios from 'axios';

const ModelEvaluation = ({ show, handleClose }) => {
    const [selectedModel, setSelectedModel] = useState(null);
    const [evaluationReport, setEvaluationReport] = useState('');

    const [rocCurveUrl, setRocCurveUrl] = useState('');
    const [shapSummaryPlotUrl, setShapSummaryPlotUrl] = useState('');

    const [modelFile, setModelFile] = useState(null);
    const [csvFile, setCsvFile] = useState(null);


    const models = [
        'Rheumatoid Arthritis', 'Skin Cancer Dignostic', 'Brain Dignostic',
        'Kidney Stone Diagnosis', 'Chronic Kidney Disease Diagnosis',
        'PCOS detection', 'Second Brain Stroke Prediction',
        'Covid Prediction', 'Coronary Artery Prediction', 'PCOS prediction',
        'Pneumonia Prediction', 'Bone Fracture', 'Glaucoma Detection', 'Tumor Detection',
        'Chronic Kidney', 'Breast Disease Diagnosis', 'Breast Cancer Diagnosis',
        'Breast Cancer Diagnosis', 'Liver Disease Diagnosis', 'Thyroid Disease Detection',
        'Skin Cancer Diagnosis', 'Skin Diseases Diagnosis', 'Heart Failure Prediction',
        'Brain Stroke Prediction', 'Pneumonia Disease Detection', 'Heart Stroke',
        'Heart Diagnostic', 'Hepatitis Prediction Model', 'Bone Cancer Detection',
        'Psychology Detection', 'Diabetics Detection', 'Dementia Detection',
        'Second Dementia Detection', 'Osteoporosis Detection', 'Sclerosis Diagnosis',
        'Kidney Failure'
    ];

    const handleSelect = (model) => {
        setSelectedModel(model);
    };

    const handleFileChange = (e, setFile) => {
        setFile(e.target.files[0]);
    };

    const evaluateModel = async () => {
        if (selectedModel && modelFile && csvFile) {
            // Replace this with actual evaluation logic
            try {
                const formData = new FormData();
                formData.append('model_name', selectedModel);
                formData.append('model_file', modelFile);
                formData.append('csv_file', csvFile);

                // Log FormData entries
                formData.forEach((value, key) => {
                    console.log(`${key}: ${value}`);
                });

                const response = await axios.post('https://whispering-wave-29439-54ce99f78584.herokuapp.com/evaluate', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                const { accuracy, cross_val_scores, f1_score, roc_curve_url, shap_summary_plot_url } = response.data;

                setEvaluationReport(
                    {
                        accuracy,
                        cross_val_scores,
                        f1_score
                    }
                );
                setRocCurveUrl(roc_curve_url);
                setShapSummaryPlotUrl(shap_summary_plot_url);
            } catch (error) {
                console.error('Error evaluating model:', error);
                setEvaluationReport('Failed to evaluate model.');
            }
        } else {
            setEvaluationReport('Please select a model, upload the model file and the CSV file.');
        }
    };
    const renderReport = () => {
        if (!evaluationReport || evaluationReport.error) return <div>{evaluationReport.error}</div>;

        const { accuracy, cross_val_scores, f1_score } = evaluationReport;

        return (
            <div className="evaluation-report">
                <p><strong>Accuracy:</strong> {accuracy !== undefined ? accuracy.toFixed(2) : 'N/A'}</p>
                <p><strong>Cross-Validation Scores:</strong> {cross_val_scores ? cross_val_scores.join(', ') : 'N/A'}</p>
                <p><strong>F1 Score:</strong> {f1_score !== undefined ? f1_score.toFixed(2) : 'N/A'}</p>
            </div>
        );
    };
    return (
        <div className={`modal ${show ? 'show' : ''}`}>
            <div className="modal-content">
                <span className="close" onClick={handleClose}>&times;</span>
                <h2>ML Models Evaluation</h2>
                <div className="modal-body">
                    <div className="left-side">
                        <h3>ML Models</h3>
                        <ul className="model-list">
                            {models.map((model, index) => (
                                <li
                                    key={index}
                                    className={selectedModel === model ? 'selected' : ''}
                                    onClick={() => handleSelect(model)}
                                >
                                    {model}
                                </li>
                            ))}
                        </ul>
                        <h3>Upload Model and Data</h3>
                        <input type="file" accept=".sav,.pkl,.joblib" onChange={(e) => handleFileChange(e, setModelFile)} />
                        <input type="file" accept=".csv" onChange={(e) => handleFileChange(e, setCsvFile)} />
                        <button className="evaluate-button" onClick={evaluateModel}>
                            Evaluate
                        </button>
                    </div>
                    <div className="right-side">
                        <h3>Evaluation Report</h3>
                        {renderReport()}
                        {rocCurveUrl && (
                            <div>
                                <h4>ROC Curve:</h4>
                                <img src={`https://whispering-wave-29439-54ce99f78584.herokuapp.com${rocCurveUrl}`} alt="ROC Curve" />
                            </div>
                        )}
                        {shapSummaryPlotUrl && (
                            <div>
                                <h4>SHAP Summary Plot:</h4>
                                <img src={`https://whispering-wave-29439-54ce99f78584.herokuapp.com${shapSummaryPlotUrl}`} alt="SHAP Summary Plot" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModelEvaluation;