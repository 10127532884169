import React, { useEffect, useState } from "react";
import moment from "moment";
import { Avatar, Button, List } from "antd";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { readLoginData } from "../../loginData";
import { doctorGetTasks } from "../../api/calendar";
import CreateDoctorTask from "./CreateDoctorTask";

import "./DoctorUpcomingTasks.css";

const DoctorUpcomingTasks = (props) => {
  const loginData = readLoginData();
  const [needLoad, setNeedLoad] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const date = props.date;

  useEffect(() => {
    setNeedLoad(true);
  }, [date]);

  if (needLoad) {
    setLoading(true);
    setNeedLoad(false);
    (async () => {
      const response = await doctorGetTasks(
        loginData,
        moment(date).startOf("day").toDate(),
        moment(date).endOf("day").toDate()
      );
      setData(response);
      setLoading(false);
    })();
  }

  const handleOk = () => {
    setOpen(false);
    setNeedLoad(true);
    props.onDataChange?.();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const start = moment(date).startOf("day").add("12", "hour");
  const end = moment(date).startOf("day").add("12.5", "hour");

  return (
    <>
      <h2 className="doctor-subtitle" style={{ textAlign: "left" }}>
        Upcoming Tasks
      </h2>
      <div className="upcoming-task-container">
        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item className="upcoming-task-item">
              <List.Item.Meta
                avatar={
                  <Avatar>
                    {item.description[0]?.[0]?.toUpperCase() || "T"}
                  </Avatar>
                }
                title={item.description}
                description={moment(item.start).format("D MMMM, YYYY | H:mm A")}
              />
            </List.Item>
          )}
        />
      </div>
      <div>
        <Button
          type="link"
          className="upcoming-task-create-button"
          onClick={() => setOpen(true)}
        >
          <AddCircleIcon />
          <span style={{ width: "0.5rem" }} />
          Create a new task
        </Button>
      </div>
      {open ? (
        <CreateDoctorTask
          categories={[1]}
          start={start}
          end={end}
          onOk={handleOk}
          onCancel={handleCancel}
        />
      ) : null}
    </>
  );
};

export default DoctorUpcomingTasks;
