import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { GlucoseLegend } from "../PatientPanel/DataVisualization/HealthOverviewComponent";
import CircleIcon from "@mui/icons-material/Circle";
import WarningIcon from "@mui/icons-material/Warning";
import Glucose from "../PatientPanel/DataVisualization/Glucose";
import { StatusIndicator } from "../PatientPanel/DataVisualization/HealthOverviewComponent";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Weight from "../PatientPanel/DataVisualization/Weight";
import BloodPressure from "../PatientPanel/DataVisualization/BloodPressure";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabPage() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          width: "90%",
          margin: "auto",
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="Tabs Page">
            <Tab label="Glucose" {...a11yProps(0)} />
            <Tab label="Weight" {...a11yProps(1)} />
            <Tab label="Blood Pressure" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <GlucoseInReportPage />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Weight />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <BloodPressure />
        </CustomTabPanel>
      </Box>
    </>
  );
}

const GlucoseInReportPage = () => {
  return (
    <>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "start", gap: "2vw" }}>
          <GlucoseLegend
            status="Normal"
            rangeText="4.1-6.1"
            Icon={CircleIcon}
          />
          <GlucoseLegend
            status="Abnormalities"
            rangeText=">7.0"
            Icon={WarningIcon}
            style={{ marginLeft: "2vw" }}
          />
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "space-between", mb: "2vh" }}
        >
          <Typography sx={{ color: "#C4C4C4" }}>Activity Graph</Typography>
          <Typography sx={{ color: "#C4C4C4" }}>
            Between Jan 27 - Feb 26
          </Typography>
        </Box>
        <Glucose />
        <Box sx={{ width: "90%", margin: "auto" }}>
          <StatusIndicator Time="Jan 27, 2022" Value="4.1" />
          <Divider />
          <StatusIndicator Time="Feb 5, 2022" Value="6.3" />
          <Divider />
          <StatusIndicator Time="Feb 15, 2022" Value="7.8" />
          <Box sx={{ display: "flex", justifyContent: "center", gap: "1vw" }}>
            <Typography variant="subtitle1">
              Molecular and pathogenicity diagnoses is ready
            </Typography>
            <Button variant="contained" size="small">
              See
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
// export default TabPage;
