// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.planning-toolbar-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.planning-toolbar-left-space {
  flex: 0 2 60%;
}

.planning-toolbar-view {
  flex: 0 0 fit-content;
  height: 1.5rem;
  color: "#042586";
  border-color: "#042586" !important;
}

.planning-toolbar-view label {
  height: 1.5rem;
  line-height: 1.5rem;
  background-color: transparent;
  color: "#042586";
  border-color: "#042586" !important;
}
.planning-toolbar-view label.ant-radio-button-wrapper {
  color: "#042586";
  border-color: "#042586" !important;
  background-color: transparent;
}
.planning-toolbar-view label.ant-radio-button-wrapper-checked {
  color: "#042586";
  border-color: "#042586" !important;
  background-color: #D9D9D9;
}


.planning-toolbar-middle-space {
  flex: 0 1 3.6%;
}

.planning-toolbar-date {
  width: 9.264rem;
  height: 1.5rem;
  line-height: 1.5rem;
  background-color: #D9D9D9;
}

.planning-toolbar-right-space {
  flex: 1 1 auto;
}

.planning-toolbar-create-button {
  flex: 0 0 fit-content;
}

.planning-toolbar-create-button, .planning-toolbar-create-button .MuiSvgIcon-root {
  font-family: 'Poppins';
  font-size: 0.875rem ;
  font-weight: 600;
  line-height: 1.313rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/CalendarComponents/PlanningCalendarToolbar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,6BAA6B;EAC7B,gBAAgB;EAChB,kCAAkC;AACpC;AACA;EACE,gBAAgB;EAChB,kCAAkC;EAClC,6BAA6B;AAC/B;AACA;EACE,gBAAgB;EAChB,kCAAkC;EAClC,yBAAyB;AAC3B;;;AAGA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;EACtB,oBAAoB;EACpB,gBAAgB;EAChB,qBAAqB;AACvB","sourcesContent":[".planning-toolbar-container {\n  display: flex;\n  flex-direction: row;\n  align-items: stretch;\n}\n\n.planning-toolbar-left-space {\n  flex: 0 2 60%;\n}\n\n.planning-toolbar-view {\n  flex: 0 0 fit-content;\n  height: 1.5rem;\n  color: \"#042586\";\n  border-color: \"#042586\" !important;\n}\n\n.planning-toolbar-view label {\n  height: 1.5rem;\n  line-height: 1.5rem;\n  background-color: transparent;\n  color: \"#042586\";\n  border-color: \"#042586\" !important;\n}\n.planning-toolbar-view label.ant-radio-button-wrapper {\n  color: \"#042586\";\n  border-color: \"#042586\" !important;\n  background-color: transparent;\n}\n.planning-toolbar-view label.ant-radio-button-wrapper-checked {\n  color: \"#042586\";\n  border-color: \"#042586\" !important;\n  background-color: #D9D9D9;\n}\n\n\n.planning-toolbar-middle-space {\n  flex: 0 1 3.6%;\n}\n\n.planning-toolbar-date {\n  width: 9.264rem;\n  height: 1.5rem;\n  line-height: 1.5rem;\n  background-color: #D9D9D9;\n}\n\n.planning-toolbar-right-space {\n  flex: 1 1 auto;\n}\n\n.planning-toolbar-create-button {\n  flex: 0 0 fit-content;\n}\n\n.planning-toolbar-create-button, .planning-toolbar-create-button .MuiSvgIcon-root {\n  font-family: 'Poppins';\n  font-size: 0.875rem ;\n  font-weight: 600;\n  line-height: 1.313rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
