import React, { useState , useEffect } from "react";
import { Card } from "antd";
import "../PharmaceuticalsContent.css";
import { ClinicalTrialsStatus } from '../Dashboard/DashUtils';
import { saveCurrentPath } from '../LocalNavigate';
import { getDetailedClinicalTrialsList } from '../../../api/user';
import { readLoginData } from '../../../loginData';
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
//临床试验模块标题
const ClinicalTrialListTableTitle = () => (
  <div className="pharmaceuticals-dashboard-table-title">
  <span>Clinical Trial List</span>
  </div>
);

// 根据状态获取颜色
const getColorForStatus = (status) => {
  switch (status) {
    case 'Under Review':
      return 'warning';
    case 'Ongoing':
      return 'info';
    case 'Completed':
      return 'success';
    case 'Rejected':
      return 'error';
    default:
      return 'default';
  }
};

//点击特定试验时的操作
const SpecificTrialClicked = async (trial_id, navigate) => {
  try {
    navigate(`/pharmaceuticals/clinicaltrial/specificclinicaltrial/${trial_id}`);
  } catch (error) {
    console.error('Error opening specific trial!:', error);
  }
};

// 自定义列渲染
const tableTrialListRender = (value, tableMeta, navigate) => {
  const styles = {
    default: { color: 'black', cursor: 'default' },
    clickable: { color: 'black', cursor: 'pointer' },
    centered: { textAlign: 'center', minWidth: '100px' },
  };

  const columnIndex = tableMeta.columnIndex;

  return columnIndex === 9 ? (
    <div style={styles.centered}>
      <ClinicalTrialsStatus color={getColorForStatus(value)} status={value} />
    </div>
  ) : columnIndex === 1 ? (
    <div style={styles.centered}>
      {Number(value)}
    </div>
  ) : (
    <div
      style={columnIndex === 0 ? styles.clickable : styles.default}
      onClick={columnIndex === 0 ? () => SpecificTrialClicked(tableMeta.rowData[1], navigate) : undefined}
    >
      {value}
    </div>
  );
};
// 表头定义
const createColumns = (navigate) => [
  { name: 'trial_name', label: 'Name', options: { customBodyRender: (value, tableMeta) => tableTrialListRender(value, tableMeta, navigate), filter: false } },
  { name: 'trial_id', label: 'Id', options: { customBodyRender: (value, tableMeta) => tableTrialListRender(value, tableMeta), filter: false } },
  { name: 'related_conditions', label: 'Conditions', options: { customBodyRender: (value, tableMeta) => tableTrialListRender(value, tableMeta) } },
  { name: 'trial_phase', label: 'Phase', options: { customBodyRender: (value, tableMeta) => tableTrialListRender(value, tableMeta)} },
  { name: 'study_type', label: 'Type', options: { customBodyRender: (value, tableMeta) => tableTrialListRender(value, tableMeta)} },
  { name: 'locations', label: 'Location', options: { customBodyRender: (value, tableMeta) => tableTrialListRender(value, tableMeta) } },
  { name: 'principal_investigator', label: 'Investigator', options: { customBodyRender: (value, tableMeta) => tableTrialListRender(value, tableMeta) } },
  { name: 'sponsor', label: 'Sponsor', options: { customBodyRender: (value, tableMeta) => tableTrialListRender(value, tableMeta) } },
  { name: 'ethics_approval', label: 'Ethics', options: { customBodyRender: (value, tableMeta) => tableTrialListRender(value, tableMeta), filter: false } },
  { name: 'trial_status', label: 'Status', options: { customBodyRender: (value, tableMeta) => tableTrialListRender(value, tableMeta) } }
];


//点击more按钮
const onAddClick = async(navigate) => {
  try {
    navigate('/pharmaceuticals/clinicaltrialadd');
  } catch (error) {
    console.error('Error opening the clinical trial list!:', error);
  }
};
// 自定义工具栏
const CustomToolbar = ({ navigate }) => {
  return (
    <IconButton onClick={() => onAddClick(navigate) } aria-label="more">
      <AddIcon />
    </IconButton>
  );
};

// 生成 options 对象
const createOptions = (navigate) => {
  return {
    selectableRows: 'none',
    download: false,
    viewColumns: false,
    print: false,
    elevation: 0,
    rowsPerPage: 5, // 每页显示10条数据
    rowsPerPageOptions: [5,10], // 设置分页选项，只显示每页10条
    setRowProps: () => ({
      style: { backgroundColor: '#FFFFFF' },
    }),
    setCellProps: () => ({
      style: { backgroundColor: '#FFFFFF' },
    }),
    customToolbar: () => <CustomToolbar navigate={navigate} />
  };
};


const PharmaceuticalsClinicalTrail = () => {
  const companyInfo = readLoginData();
  const navigate = useNavigate();
  const [trials, setTrials] = useState([]);
  saveCurrentPath();

 // 调用 createOptions和createColumns 函数，确保点击时能正确导航到其他部分
 const options = createOptions(navigate);
 const columns = createColumns(navigate);

 //获取临床试验详细信息列表
 useEffect(() => {
  const fetchTrials = async () => {
    try {
      const trialsList = await getDetailedClinicalTrialsList(companyInfo.id);
      setTrials(trialsList);
    } catch (error) {
      console.error('Error fetching clinical trials:', error);
    }
  };
  fetchTrials();
}, [companyInfo]);

return (
  <Card bordered={false} style={{ width: "100%", backgroundColor: "#FFFFFF",}}>
  <MUIDataTable
    title={<ClinicalTrialListTableTitle />}
    data={trials}
    columns={columns}
    options={options}
  />
  </Card>
);
};

export default PharmaceuticalsClinicalTrail;