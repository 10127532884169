import { DatePicker, Form, Input, Modal, Select, TimePicker } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import React, { useState } from 'react';

import { clinicalStaffAddTask } from '../../api/clinicalStaff';
import { readLoginData } from '../../loginData';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const rangeCheck = (start, end) => [ moment.min([ start, end ]), moment.max([ start, end ]) ];

const DialogContent = (props) => {
  const handleDate = (e) => {
    const newDate = moment(e.toDate());
    const [ start, end ] = [
      moment(newDate).startOf('day').add(moment(props.start).subtract(moment(props.start).startOf('day'))),
      moment(newDate).startOf('day').add(moment(props.end).subtract(moment(props.end).startOf('day'))),
    ];
    props.onChange({ start, end });
  };
  const handleTime = (e) => {
    console.log("time", e);
    const [ start, end ] = [
      moment(e[0].toDate()),
      moment(e[1].toDate()),
    ];
    props.onChange({ start, end });
  };
  const handleDescription = (e) => {
    props.onChange({ description: e.target.value });
  };
  const date = dayjs(props.start.toDate());
  const time = [ dayjs(props.start), dayjs(props.end) ];

  return <>
    <Form
      name="newTask"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      autoComplete="off"
    >
      <Form.Item
        label="Date"
        name="date"
        rules={[
          {
            required: true,
            message: 'Please input the date!',
          },
        ]}
      >
        <DatePicker format="YYYY-MM-DD" value={date} onChange={handleDate} style={{ width: '100%' }}/><br/>
      </Form.Item>
      <Form.Item
        label="Time"
        name="time"
        rules={[
          {
            required: true,
            message: 'Please input the time!',
          },
        ]}
      >
        <TimePicker.RangePicker format="HH:mm:ss" value={time} onChange={handleTime} style={{ width: '100%' }}/><br/>
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: 'Please input the description!',
          },
        ]}
      >
        <Input value={props.description} onChange={handleDescription} placeholder="Type description here" style={{ width: '100%' }} /><br/>
      </Form.Item>
    </Form>
  </>;
};

const CreateClinicalStaffTask = (props) => {
  const loginData = readLoginData();

  const [ formContent, setFormContent ] = useState(() => {
    return {
      start: moment(props.start),
      end: moment(props.end),
      description: "",
    };
  });

  const [valid, setValid] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleFormChange = (change) => {
    const newState = {...formContent, ...change};
    setFormContent(newState);
    updateValid(newState);
  }

  const updateValid = (newState) => {
    setValid((moment(newState.start).isValid()) && (moment(newState.end).isValid()) && newState.description);
  }

  const handleOk = () => {
    setConfirmLoading(true);
    (async () => {
      const [ start, end ] = rangeCheck(
        moment(formContent.start),
        moment(formContent.end),
      );
      await clinicalStaffAddTask(
        loginData,
        start.toDate(),
        end.toDate(),
        formContent.description);
      props.onOk();
    })();
  };

  return (
    <Modal
      title={ "Create Task" }
      open={true}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={props.onCancel}
      okButtonProps={{ disabled: !valid }}
      style={{ textAlign: "initial" }}
    >
      <DialogContent
        staff={loginData.id}
        {...formContent}
        onChange={handleFormChange}
      />
    </Modal>
  );
}

export default CreateClinicalStaffTask;
