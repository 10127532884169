import moment from "moment";
import { readLoginData } from "../../loginData";
import { doctorGetCalendar } from "../../api/calendar";
import { Card, Empty, Spin } from "antd";
import React, { useEffect, useState, useMemo } from "react";
import "./DoctorAppointmentListMobile.css";
const DoctorAppointmentListMobile = (props) => {
  // State for selected time scope
  const [timeScope, setTimeScope] = useState("today");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const loginData = useMemo(() => readLoginData(), []);

  const getTimePeriod = (value) => {
    switch (value) {
      case "today":
        return [moment().startOf("day"), moment().endOf("day")];
      case "week":
        return [moment().startOf("week"), moment().endOf("week")];
      case "month":
        return [moment().startOf("month"), moment().endOf("month")];
      default:
        return [moment(value).startOf("day"), moment(value).endOf("day")];
    }
  };

  useEffect(() => {
    if (
      moment(props.selectedDate).isBetween(
        moment().startOf("day"),
        moment().endOf("day"),
        undefined,
        "[]"
      )
    ) {
      setTimeScope("today");
    } else {
      setTimeScope(moment(props.selectedDate).format("YYYY-MM-DD"));
    }
  }, [props.selectedDate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (loginData.type !== "NotLoggedIn") {
          setLoading(true);
          const [start, end] = getTimePeriod(timeScope);
          const fetchedData = await doctorGetCalendar(
            loginData,
            start.toDate(),
            end.toDate()
          );
          setLoading(false);
          const processedData = fetchedData
            .filter((r) => r.category !== 1 && r.status !== 0)
            .map((r) => {
              return {
                ...r,
                scheduledTime: {
                  start: r.start,
                  end: r.end,
                },
              };
            });
          setUsers(processedData);
        } else {
          console.log("User is not logged in.");
          setUsers([]);
        }
      } catch (error) {
        console.error(`Error fetching patient data: ${error.message}`);
      }
    };

    fetchData();
  }, [loginData, timeScope]);

  const appointmentType = (value) => {
    switch (value) {
      case 1:
        return "Task";
      case 2:
        return "Available Slot";
      case 3:
        return "Surgery";
      case 4:
        return "General Consultation";
      case 5:
        return "Lab Test";
      default:
        return "Unknown";
    }
  }

  const appointmentList = users.map(row => {
    const startDate = moment(row.start);
    const endDate = moment(row.end);
    const dateDisplay = startDate.isSame(moment(), "day")
      ? "Today"
      : startDate.format("D MMM, YYYY");
    const startTime = startDate.format("HH:mm");
    const endTime = endDate.format("HH:mm");
    return <Card
      style={{
        width: "100%",
        backgroundColor: "#B3E3C7",
        textAlign: "center",
        borderRadius: "1.5rem"
      }}
    >
      <Card.Meta
        style={{ marginBottom: "20px" }}
        title={row.patientName} />
      <p
        className="doctor-appointment-list-mobile-card-text"
        style={{ fontWeight: "500", marginBottom: "20px" }}>
        {appointmentType(row.category)}
      </p>
      <p>{dateDisplay}</p>
      <p>{startTime}-{endTime}</p>
    </Card>
  })

  if (!users.length) {
    return <div
      style={{
        width: "100%",
        justifyContent: "center"
      }}>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={"No Upcoming Appointments"}
      />
    </div>

  }

  return (
      <Spin spinning={loading}>
        <div className="doctor-dashboard-mobile-appointment-list">
          {appointmentList}
        </div>
      </Spin>
  );
};

export default DoctorAppointmentListMobile;
