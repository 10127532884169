import { ConfigProvider, Layout } from "antd";

import SideBar from "../../components/SideBar/PatientSideBar";
import Header from "../../components/Header/Header";

const layoutStyle = {
  textAlign: "initial",
  backgroundColor: "#f2f8fd",
  width: "100vw",
  minHeight: "100vh",
};

const PageLayout = ({ children, clearUser }) => {
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Layout: {
              colorBgLayout: "#f2f8fd",
            },
          },
        }}
      >
        <Layout style={layoutStyle}>
          <SideBar clearUser={clearUser} />
          <Layout className="page-layout-main">
            <Header />
            {children}
          </Layout>
        </Layout>
      </ConfigProvider>
    </>
  );
};

export default PageLayout;
