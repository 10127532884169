import {
  BloodPressureComponent,
  TCHComponent,
  WeightConmponent,
} from "../PatientPanel/DataVisualization/HealthOverviewComponent";
import TabPage from "./TabPage";
import "./PatientReport.css";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  responsiveFontSizes,
} from "@mui/material";
import {
  Modal,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import axios from "axios";
import { readLoginData } from "../../loginData";
import {
  getPatientRecords,
  getPatientRecordsById,
  getPatientBloodTestById,
} from "../../utilities/apis";
import { PatientRecordViewImages } from "../../components/DoctorComponents/PatientRecordViewImages";
import { RealtimeSignalModule } from "./RealtimeSignal/RealtimeSignalModule";

export function PatientReport() {
  const [bloodTest, setbloodTest] = useState([]);

  useEffect(() => {
    // 创建一个函数来加载数据
    const fetchData = async () => {
      try {
        const response = await getPatientBloodTestById(loginData.id); // 使用你的API endpoint
        console.log(response);
        setbloodTest(response); // 假设后台返回的数据是直接可用的
      } catch (error) {
        console.error("Error fetching data: ", error);
        // 处理错误情况
      }
    };

    fetchData(); // 调用函数来加载数据
  }, []);
  const buttons = [
    { title: "MRI" },
    { title: "CT Scan" },
    { title: "X-Rays" },
    { title: "Blood Test" },
    { title: "Signal Analysis" },
  ];

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%", // Responsive width
    maxHeight: "90vh",
    overflowY: "auto",
    bgcolor: "#f5f5f5", // Paper-like background color
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Softer shadow
    p: 4,
    borderRadius: 2, // Slight rounding of corners
  };
  const dialogStyle = (modalTitle) => {
    switch (modalTitle) {
      case "Signal Analysis":
        return { maxWidth: "xl", width: "80%", height: "80%" };
      case "Blood Test":
        return { maxWidth: "lg", width: "80%" };
      default:
        return { maxWidth: "sm", width: "20%" };
    }
  };
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("MIR");
  const [recordList, setRecordList] = useState([]);
  const [openImageRecordModal, setOpenImageRecordModal] = useState(false);
  const loginData = readLoginData();
  const handleOpen = (newTitle) => {
    setModalTitle(newTitle);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const fetchPatientRecords = async (recordType) => {
    //Syed's API imageRetrieveByPhoneNumber
    const records = await getPatientRecordsById(loginData.id, recordType);
    //const records = "";
    setRecordList(records);
  };
  const viewDetailsImages = (recordTypeSelect) => {
    fetchPatientRecords(recordTypeSelect);
    setOpenImageRecordModal(true);
  };
  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F0F9FD",
          height: "100%",
        }}
      >
        <Grid item>
          <TabPage />
        </Grid>
        <Grid item sx={{ display: "flex", justifyContent: "center" }}>
          {/* Data visualization : Medical Records = 1:1 */}
          {/* <Grid container>
            <Grid item xs={8}> */}
          {/* Put the medical records here */}

          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: "space-between",
              // mt: 2,
              // ml: 10,
              width: "90%",
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h5" align="left" sx={{ mt: "1rem" }}>
                View Medical Records
              </Typography>
            </Grid>
            {buttons.map((button) => (
              <Grid item xs={12} sm={6} md={2.4} key={button.title}>
                <Button
                  variant="contained"
                  disableElevation
                  fullWidth
                  sx={{
                    backgroundColor: "#0E45B6",
                    height: "100px",
                    borderRadius: "10px",
                    color: "#000",
                    fontSize: "1.5rem",
                    fontWeight: "600",
                    "&:hover": {
                      backgroundColor: "#0E45B6", // Example of changing color on hover
                    },
                    color: "#FFFFFF",
                  }}
                  onClick={() => handleOpen(button.title)}
                >
                  {button.title}
                </Button>
              </Grid>
            ))}
          </Grid>
          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              sx: dialogStyle(modalTitle),
            }}
          >
            <DialogContent>
              <Grid item xs={12} md={12} sx={{ height: "100%" }}>
                {modalTitle == "Signal Analysis" && <RealtimeSignalModule />}
                {modalTitle == "CT Scan" && (
                  <Card>
                    {/* CT Scans */}
                    {/* X-Rays */}
                    <CardContent>
                      <Typography variant="h5">CT Scans</Typography>
                      <Button
                        fullWidth
                        onClick={() => viewDetailsImages("CT-Scan_Abdomen")}
                      >
                        Abdomen
                      </Button>
                      <Button
                        fullWidth
                        onClick={() => viewDetailsImages("CT-Scan_Brain")}
                      >
                        Brain{" "}
                      </Button>
                      <Button
                        fullWidth
                        onClick={() => viewDetailsImages("CT-Scan_Breast")}
                      >
                        Breast{" "}
                      </Button>
                    </CardContent>
                  </Card>
                )}
                {modalTitle == "MRI" && (
                  <Card>
                    {/* CT Scans */}
                    {/* X-Rays */}
                    <CardContent>
                      <Typography variant="h5">MRI</Typography>
                      <Button
                        fullWidth
                        onClick={() => viewDetailsImages("MRI_Spine")}
                      >
                        Spine
                      </Button>
                      <Button
                        fullWidth
                        onClick={() => viewDetailsImages("MRI_Brain")}
                      >
                        Brain{" "}
                      </Button>
                    </CardContent>
                  </Card>
                )}
                {modalTitle == "X-Rays" && (
                  <Card>
                    {/* CT Scans */}
                    {/* X-Rays */}
                    <CardContent>
                      <Typography variant="h5">X-Rays</Typography>
                      <Button
                        fullWidth
                        onClick={() => viewDetailsImages("X-Ray_Chest")}
                      >
                        Chest
                      </Button>
                      <Button
                        fullWidth
                        onClick={() => viewDetailsImages("X-Ray_Feet")}
                      >
                        Feet
                      </Button>
                      <Button
                        fullWidth
                        onClick={() => viewDetailsImages("X-Ray_Lung")}
                      >
                        Lung
                      </Button>
                      <Button
                        fullWidth
                        onClick={() => viewDetailsImages("X-ray-Skin")}
                      >
                        Skin
                      </Button>
                    </CardContent>
                  </Card>
                )}
                {modalTitle == "Blood Test" && (
                  <TableContainer
                    component={Paper}
                    sx={{ minWidth: 550, minHeight: 400 }}
                  >
                    <Table
                      sx={{ minWidth: 1250, minHeight: 400 }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Blood Test ID</TableCell>
                          <TableCell align="right">TotalBilirubin</TableCell>
                          <TableCell align="right">DirectBilirubin</TableCell>
                          <TableCell align="right">
                            AlkalinePhosphotase
                          </TableCell>
                          <TableCell align="right">
                            AlamineAminotransferase
                          </TableCell>
                          <TableCell align="right">
                            AspartateAminotransferase
                          </TableCell>
                          <TableCell align="right">TotalProtiens</TableCell>
                          <TableCell align="right">Albumin</TableCell>
                          <TableCell align="right">
                            AlbuminAndGlobulinRatio
                          </TableCell>
                          <TableCell align="right">RecordDate</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bloodTest.map((prescription) => (
                          <TableRow
                            key={prescription.id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {prescription.id}
                            </TableCell>
                            <TableCell align="right">
                              {prescription.TotalBilirubin}
                            </TableCell>
                            <TableCell align="right">
                              {prescription.DirectBilirubin}
                            </TableCell>

                            <TableCell align="right">
                              {prescription.AlkalinePhosphotase}
                            </TableCell>
                            <TableCell align="right">
                              {prescription.AlamineAminotransferase}
                            </TableCell>
                            <TableCell align="right">
                              {prescription.AspartateAminotransferase}
                            </TableCell>
                            <TableCell align="right">
                              {prescription.TotalProtiens}
                            </TableCell>
                            <TableCell align="right">
                              {prescription.Albumin}
                            </TableCell>
                            <TableCell align="right">
                              {prescription.AlbuminAndGlobulinRatio}
                            </TableCell>
                            <TableCell align="right">
                              {prescription.RecordDate}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              {/* 关闭弹窗的按钮 */}

              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
          <Modal
            open={openImageRecordModal}
            onClose={() => setOpenImageRecordModal(false)}
          >
            <Box sx={modalStyle}>
              <center>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setOpenImageRecordModal(false)}
                  sx={{ width: "50%" }}
                >
                  Exit
                </Button>
              </center>
              <Card sx={{ minHeight: 800, overflow: "auto" }}>
                <PatientRecordViewImages recordData={recordList} />
              </Card>
            </Box>
          </Modal>
          {/*</Grid></div>*/}
          {/* </Grid>
          </Grid> */}
        </Grid>
      </Grid>
    </>
  );
}

const PatientReportButton = ({ title, onClick }) => {
  return (
    <Button
      variant="contained"
      disableElevation
      sx={{
        backgroundColor: "#c6dfff",
        width: "190px",
        height: "144px",
        borderRadius: "10px",
        color: "#000",
        fontSize: "17px",
        fontWeight: "700",
      }}
      onClick={onClick}
    >
      {title}
    </Button>
  );
};
