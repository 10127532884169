import { Button, Card, Flex, List, Avatar, Tag, Select } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Typography,
  Box,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { readLoginData } from "../../loginData";
import { clinicalStaffGetRecentPatients, getDoctors } from "../../api/calendar";

import "./RecentPatientList.css";
import { useNavigate } from "react-router-dom";

const getTimePeriod = (value) => {
  switch (value) {
    default:
    case "today":
      return [moment().startOf("day"), moment().endOf("day")];
    case "week":
      return [moment().startOf("week"), moment().endOf("week")];
    case "month":
      return [moment().startOf("month"), moment().endOf("month")];
  }
};

const RecentPatientList = () => {
  const navigate = useNavigate();
  const loginData = readLoginData();
  if (loginData.type === "NotLoggedIn") {
    // Handle not logged in scenario
    console.log("User is not logged in.");
    navigate("/LogIn");
  }

  const [ loading, setLoading ] = useState(true);
  const [ doctorList, setDoctorList ] = useState([]);
  const [ selectedDoctor, setSelectedDoctor ] = useState(undefined);

  // State for selected time scope
  const [timeScope, setTimeScope] = useState("today");
  const [users, setUsers] = useState([]);
  const handleTimeScopeChange = (value) => {
    setTimeScope(value);
  };

  useEffect(() => {
    (async() => {
      const result = await getDoctors(loginData);
      setDoctorList(result.map(r => ({
        label: r.name,
        value: r.id,
      })));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if(!selectedDoctor)return;
        const [start, end] = getTimePeriod(timeScope);
        console.log("Time scope changed to:", timeScope);
        const fetchedData = await clinicalStaffGetRecentPatients(
          loginData,
          selectedDoctor,
          start.toDate(),
          end.toDate()
        );
        setUsers(fetchedData);
        setLoading(false);
      } catch (error) {
        console.error(`Error fetching patient data: ${error.message}`);
      }
    })();
  }, [ doctorList, selectedDoctor, timeScope ]);

  const doctorSelectSearch = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const HeaderElements = ({ onTimeScopeChange, currentScope }) => {
    return (
      <FormControl size="small" sx={{ m: 1, minWidth: 120 }}>
        <Select
          labelId="time-scope-select-label"
          id="time-scope-select"
          value={currentScope}
          onChange={handleTimeScopeChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value="today">Today</MenuItem>
          <MenuItem value="week">Week</MenuItem>
          <MenuItem value="month">Month</MenuItem>
        </Select>
      </FormControl>
    );
  };

  // Your existing setup for columns, options, and theme
  const columns = [
    {
      name: "id",
      label: "Patient ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "patientName",
      label: "Name",
    },
    {
      name: "category",
      label: "Visit Type",
      options: {
        customBodyRender: (value) => {
          switch (value) {
            case 1:
              return "Task";
            case 2:
              return "Available Slot";
            case 3:
              return "Surgery";
            case 4:
              return "General Consultation";
            case 5:
              return "Lab Test";
            default:
              return "Unknown"; // Handle unexpected values
          }
        },
      },
    },
    {
      name: "start",
      label: "scheduled Time",
      options: {
        customBodyRender: (value, tableMeta) => {
          // `value` is the start date/time
          // Using tableMeta.rowIndex to get the corresponding row data from your users state
          const rowData = users[tableMeta.rowIndex];

          // Parsing the start and end times using moment
          const startDate = moment(rowData.start);
          const endDate = moment(rowData.end);

          // Format the date and time display
          const dateDisplay = startDate.isSame(moment(), "day")
            ? "Today"
            : startDate.format("D MMM, YYYY");
          const startTime = startDate.format("HH:mm");
          const endTime = endDate.format("HH:mm");

          // Combine the date and time strings
          return `${dateDisplay}, ${startTime}-${endTime}`;
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          let style = {};
          let text = "";

          switch (value) {
            case 1:
              style = { backgroundColor: "green", color: "white" }; // Green shape
              text = "Completed";
              break;
            case -1:
              style = {
                backgroundColor: "gray",
                color: "white",
              }; // Gray shape
              text = "Scheduled";
              break;
            case 0:
              style = { backgroundColor: "red", color: "white" }; // Red shape
              text = "Canceled";
              break;
            case 2:
              style = { backgroundColor: "blue", color: "white" }; // Blue shape
              text = "Waiting Room";
              break;
            default:
              style = { backgroundColor: "gray", color: "white" }; // Default color for unknown status
              text = "Unknown";
          }

          return (
            <div
              style={{
                ...style,
                padding: "5px 10px",
                borderRadius: "20px",
                display: "inline-block",
              }}
            >
              {text}
            </div>
          );
        },
      },
    },
  ];
  const options = {
    selectableRows: false,
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [10, 13, 20],
  };
  const getMuiTheme = () =>
    createTheme({
      typography: {
        fontFamily: "Poppins",
        color: "black",
      },
      palette: {
        background: {
          paper: "#ffff",
        },
      },
      components: {
        MuiTableCell: {
          styleOverrides: {
            head: {
              // padding: "10px 4px",
              borderLeft: "none", // Removes the left border from the header cell
              borderRight: "none",
            },
            body: {
              // padding: "7px 15px",
              borderLeft: "none", // Removes the left border from the body cell
              borderRight: "none",
              borderBottom: "none",
              borderTop: "none",
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              "&:nth-of-type(odd)": {
                backgroundColor: "#fff", // setting odd rows to white
              },
              "&:nth-of-type(even)": {
                backgroundColor: "#fff", // setting even rows to white
              },
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              borderRadius: "20px",
              overflow: "hidden",
            },
          },
        },
      },
    });
  return (
    <div className="py-10  grid place-items-center">
      <div className="w-full max-w-7xl">
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                <span>
                  <Select
                    showSearch
                    options={doctorList}
                    value={selectedDoctor}
                    onChange={(v) => setSelectedDoctor(v)}
                    filterOption={doctorSelectSearch}
                    placeholder="Please select a doctor"
                    style={{ minWidth: "10rem" }}
                    />
                </span>
                <span style={{ marginLeft: "1rem" }}>Total Patients</span>
                <span style={{ marginLeft: "8px" }}>{users?.length}</span>
              </div>
            }
            data={users}
            columns={columns}
            options={{
              selectableRowsHeader: false,
              selectableRowsHideCheckboxes: true,
              customToolbar: () => (
                <HeaderElements
                  onTimeScopeChange={handleTimeScopeChange}
                  currentScope={timeScope}
                />
              ),
            }}
          />
        </ThemeProvider>
      </div>
    </div>
  );
};

export default RecentPatientList;
