import axios from "axios";
import { BASE_URL } from "../constants";

export const getSystemStatus = async () => {
  const response = await axios.post(`${BASE_URL}/api/admin/getSystemStatus`);
  return response.data.result;
};

export const getPatientList = async () => {
  const response = await axios.post(`${BASE_URL}/api/users/getPatientList`);
  return response.data.result;
};

export const getUnverifiedDoctors = async () => {
  const response = await axios.post(`${BASE_URL}/api/users/getUnverifiedDoctors`);
  return response.data.result;
};

export const verifyDoctor = async (doctorId, newState) => {
  const response = await axios.post(`${BASE_URL}/api/users/verifyDoctor`, { doctorId, newState });
  return response.data.result;
};

export const getTickets = async () => {
  const response = await axios.post(`${BASE_URL}/api/users/getTickets`);
  return response.data.result;
};

export const adminGetTasks = async (loginData, start, end) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/admin/getTasks`,
      {
        loginData,
        start,
        end,
      }
    );
    console.log("adminGetTasks", response.data);
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const adminAddTask = async (
  loginData,
  start,
  end,
  description,
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/admin/addTask`,
      {
        loginData,
        start: start.toISOString(),
        end: end.toISOString(),
        description,
      }
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const adminChangeTask = async (
  loginData,
  id,
  status,
) => {
  try {
    await axios.post(
      `${BASE_URL}/api/admin/changeTask`,
      {
        loginData,
        id,
        status,
      }
    );
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const adminDeleteTask = async (
  loginData,
  id,
) => {
  try {
    await axios.post(
      `${BASE_URL}/api/admin/deleteTask`,
      {
        loginData,
        id,
      }
    );
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
