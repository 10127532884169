import React from "react";
import ReactECharts from "echarts-for-react";

const typeFontFamily = { fontFamily: "Mulish, sans-serif" };
const BloodPressure = () => {
  const getOption = () => {
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {
        data: ["SBP", "DBP"],
        right: "5%",
        top: "0%",
      },

      grid: {
        top: "15%",
        bottom: "8%",
        right: "5%",
        left: "5%",
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
      ],
      yAxis: [
        {
          name: "mmHg",
          nameTextStyle: {
            color: "#C4C4C4",
            fontSize: "1rem",
            fontWeight: 500,
            ...typeFontFamily,
            align: "right",
            lineHeight: 20,
            padding: [0, 0, 10, 0],
          },
          type: "value",
          interval: 25,
          min: 50,
          max: 150,
        },
      ],
      series: [
        {
          name: "SBP",
          type: "line",
          showSymbol: false,
          itemStyle: {
            color: "#5B8FF9", // 设置线的颜色为 #5B8FF9
          },
          areaStyle: { color: "rgba(91, 143, 249, 0.8)" },
          emphasis: {
            focus: "series",
          },
          data: [120, 125, 133, 127, 120, 128, 135],
          z: 0,
        },
        {
          name: "DBP",
          type: "line",
          showSymbol: false,
          itemStyle: {
            color: "#5AD8A6", // 设置线的颜色为 #5AD8A6
          },
          areaStyle: {
            color: "rgba(90, 240, 120, 0.7)", // 设置填充区域颜色，同样使用rgba以调整透明度
          },
          emphasis: {
            focus: "series",
          },
          data: [65, 68, 82, 85, 77, 75, 70],
          z: 10,
        },
      ],
    };
  };
  return (
    <ReactECharts
      option={getOption()}
      style={{ height: "35vh", width: "100%" }}
    />
  );
};
export default BloodPressure;
