import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Typography,
  Box,
  CardContent,
  TextField,
  Grid,
  ListItem,
  ListItemIcon,
  Paper,
  Snackbar,
  Button,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./PrescriptionForm.css";

export const PriscriptionForm = ({ patientId, doctorId, closeModal }) => {
  console.log("Patient ID inside priscription form is:", patientId);
  console.log("Doctor ID inside priscription form is:", doctorId);
  const [patientData, setPatientData] = React.useState({});
  useEffect(() => {
    (async () => {
      try {
        const response = await axios.post(
          "https://e-react-node-backend-22ed6864d5f3.herokuapp.com/api/users/getPatientPortalInfoById",
          { patientId }
        );
        console.log(response);
        setPatientData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, [patientId]);

  console.log("priscription form  data", patientData);
  const medicationOptions = [
    "Naproxen (250 mg) [1 Tablet(s) TID - Three time daily 30 days]",
    "Naproxen (500 mg) [1 Tablet(s) TID - Twice daily 30 days]",
    "Naproxen (375 mg) [1 Tablet(s) TID - Three times daily 30 days]",
    "Ibuprofen (200 mg) [1 Tablet(s) TID - Three times daily as needed]",
    "Amoxicillin (500 mg) [1 Capsule(s) TID - Three times daily for 14 days]",
    "Lisinopril (10 mg) [1 Tablet(s) QD - Once daily]",
    "Metformin (500 mg) [1 Tablet(s) BID - Twice daily with meals]",
    "Simvastatin (20 mg) [1 Tablet(s) QHS - Every bedtime]",
    "Losartan (50 mg) [1 Tablet(s) QD - Once daily]",
    "Sertraline (50 mg) [1 Tablet(s) QD - Once daily]",
    // ... other medication options
  ];
  const [prescription, setPrescription] = useState({
    doctorId: "",
    patientId: "",
    doctorFName: "",
    doctorLName: "",
    doctorPhone: "",
    doctorOfficeAddress: "",
    patientFName: "",
    patientLName: "",
    patientPhone: "",
    patientAddress: "",
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isContinuous, setIsContinuous] = useState("notSet");
  const [substitutionNotAllowed, setSubstitutionNotAllowed] = useState(false);
  const [autoFill, setAutoFill] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setMedicationName(value);
    if (value.length > 0) {
      const filteredSuggestions = medicationOptions.filter((option) =>
        option.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleTypeChange = (event) => {
    const { name, value } = event.target;
  };

  const [historyOpen, setHistoryOpen] = useState(false);

  const handleHistoryToggle = () => {
    setHistoryOpen(!historyOpen);
  };
  const handleSuggestionClick = (suggestion) => {
    setMedicationName(suggestion);
    setSuggestions([]);
  };
  const [isEditable, setIsEditable] = useState(false);

  const handleIconClick = () => {
    setIsEditable((current) => !current);
    setIsEditable(true);
  };
  const [medicationName, setMedicationName] = useState("");

  const [inputValue, setInputValue] = useState("Type-2 Diagnosis");
  const [suggestions, setSuggestions] = useState([]);

  const prepareForPrinting = () => {
    const elements = document.querySelectorAll("input, select");
    console.log(`Found ${elements.length} elements for printing.`);
    elements.forEach((element) => {
      const printValue = document.createElement("span");
      printValue.className = "print-value";
      printValue.innerText = element.value;
      printValue.style.display = "block";
      element.style.display = "none";
      element.parentNode.insertBefore(printValue, element);
      console.log(
        `Processed element with id: ${element.id} and value: ${element.value}`
      );
    });
  };

  // Add a cleanup function to restore the form after printing
  const cleanupAfterPrint = () => {
    window.removeEventListener("afterprint", cleanupAfterPrint);
    document.querySelectorAll(".print-value").forEach((printValue) => {
      printValue.parentNode.removeChild(printValue);
    });
    document.querySelectorAll("input, select").forEach((element) => {
      element.style.display = "block";
    });
  };

  const handlePrintPrescription = () => {
    window.print();
  };
  // Function to format the date
  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const [currentDate, setCurrentDate] = useState(formatDate(new Date()));

  useEffect(() => {
    // Function to calculate milliseconds until midnight
    const msUntilMidnight = () => {
      const now = new Date();
      const midnight = new Date(now);
      midnight.setDate(now.getDate() + 1);
      midnight.setHours(0, 0, 0, 0);
      return midnight - now;
    };

    // Function to update the date
    const updateDate = () => {
      setCurrentDate(formatDate(new Date())); // Update state to current date
      // Set another timeout for the next day
      setTimeout(updateDate, msUntilMidnight());
    };

    // Set initial timeout until next midnight
    const timeoutId = setTimeout(updateDate, msUntilMidnight());

    // Clear timeout on cleanup
    return () => clearTimeout(timeoutId);
  }, []);

  // how to handel input form
  const handleSubmit = async (event) => {
    event.preventDefault();
    closeModal();
  };

  const formSectionStyle = {
    backgroundColor: "#f2f2f2",
    padding: "20px",
    borderRadius: "8px",
    marginBottom: "20px",
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <h2 style={{ color: "black", marginBottom: "25px" }}>
              Prescription
            </h2>
            <div
              style={{
                textAlign: "right",
                color: "black",
                fontFamily: "Poppins",
                marginTop: "-50px",
                fontSize: "11px",
              }}
            >
              Writen Date:{currentDate}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="infoleft print-only">
              <h4
                style={{
                  fontfamily: "Poppins",
                  marginBottom: "5px",
                  lineheight: "24.02px",
                  letterspacing: "-0.02em",
                  textAlign: "left",
                  color: "#1A4FBA",
                  marginTop: "33px",
                }}
              >
                {patientData.FName}

                {patientData.LName}
              </h4>
              <p
                style={{
                  color: "#6E7191",
                  fontSize: "10px",
                  textAlign: "left",
                  fontFamily: "Poppins",
                }}
              >
                Age:
                <span
                  style={{
                    color: "black",
                    fontSize: "10px",
                    textAlign: "left",
                    fontFamily: "Poppins",
                    fontWeight: "500px",
                  }}
                >
                  {patientData.Age} years old
                </span>
              </p>
              <p
                style={{
                  color: "#6E7191",
                  fontSize: "10px",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  marginBottom: "10px",
                }}
              >
                Address:
                <span
                  style={{
                    color: "black",
                    fontSize: "10px",
                    textAlign: "left",
                    fontFamily: "Poppins",
                    fontWeight: "500px",
                  }}
                >
                  {patientData.City}
                </span>
              </p>
            </div>
          </Grid>
          <Grid item xs={4}>
            <p
              style={{
                color: "#6E7191",
                fontSize: "10px",
                textAlign: "center",
                fontFamily: "Poppins",
                marginTop: "50px",
              }}
            >
              ID Name:{" "}
              <span
                style={{
                  color: "black",
                  fontSize: "10px",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontWeight: "500px",
                }}
              >
                {patientData.id}
              </span>
            </p>
          </Grid>
          <Grid item xs={4}>
            <p
              style={{
                color: "#6E7191",
                fontSize: "10px",
                textAlign: "right",
                fontFamily: "Poppins",
                marginTop: "50px",
              }}
            >
              Phone Cell:
              <span
                style={{
                  color: "black",
                  fontSize: "10px",
                  textAlign: "right",
                  fontFamily: "Poppins",
                  fontWeight: "500px",
                }}
              >
                {patientData.MobileNumber}
              </span>
            </p>
            <p
              style={{
                color: "#6E7191",
                fontSize: "10px",
                textAlign: "right",
                fontFamily: "Poppins",
              }}
            >
              Home:
              <span
                style={{
                  color: "black",
                  fontSize: "10px",
                  textAlign: "right",
                  fontFamily: "Poppins",
                  fontWeight: "500px",
                }}
              >
                {patientData.phoneHome}
              </span>
            </p>
          </Grid>
          <Grid item xs={6}>
            <form onSubmit={handleSubmit}>
              <div className="forms" style={{ position: "relative" }}>
                <label htmlFor="medicationName" className="flabel">
                  Medication Name:
                </label>
                <input
                  className="fcontrol"
                  id="medicationName"
                  name="medicationName"
                  type="text"
                  value={medicationName}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
                {suggestions.length > 0 && (
                  <Paper className="autocomplete-dropdown-container" square>
                    {suggestions.map((suggestion, index) => {
                      // Find the part of the suggestion that matches the input
                      const matchStart = suggestion
                        .toLowerCase()
                        .indexOf(medicationName.toLowerCase());
                      const matchEnd = matchStart + medicationName.length;
                      const beforeMatch = suggestion.slice(0, matchStart);
                      const matchText = suggestion.slice(matchStart, matchEnd);
                      const afterMatch = suggestion.slice(matchEnd);

                      return (
                        <div
                          key={index}
                          className="autocomplete-item"
                          onMouseDown={() => handleSuggestionClick(suggestion)}
                        >
                          {beforeMatch}
                          <span style={{ color: "#1A4FBA" }}>{matchText}</span>
                          {afterMatch}
                        </div>
                      );
                    })}
                  </Paper>
                )}
              </div>

              <div className="inline-group-leftside">
                <div className="form-group-p">
                  <label
                    htmlFor="dose"
                    style={{
                      marginTop: "20px",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: 600,
                      marginRight: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Dose
                  </label>
                  <div className="input-group-dose">
                    <input
                      type="number"
                      id="doseAmount"
                      name="doseAmount"
                      className="dose-amount"
                      value={prescription.doseAmount}
                    />
                    <select
                      id="doseUnit"
                      name="doseUnit"
                      className="dose-unit"
                      value={prescription.doseUnit}
                      style={{ backgroundColor: "#D9D9D9" }}
                    >
                      <option value="tablets">Tablets</option>
                    </select>
                  </div>
                  <label
                    htmlFor="dose"
                    style={{
                      marginTop: "15px",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: 600,
                      marginRight: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Quantity
                  </label>
                  <div className="input-group-dose">
                    <input
                      type="number"
                      id="doseAmount"
                      name="doseAmount"
                      className="dose-amount"
                      value={prescription.doseAmount}
                    />
                    <select
                      id="doseUnit"
                      name="doseUnit"
                      className="dose-unit"
                      value={prescription.doseUnit}
                      style={{ backgroundColor: "#D9D9D9" }}
                    >
                      <option value="tablets" style={{ fontFamily: "Poppins" }}>
                        Tablets
                      </option>
                    </select>
                  </div>
                  <label
                    htmlFor="route"
                    style={{
                      marginTop: "15px",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: 600,
                      marginRight: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Route
                  </label>
                  <select
                    className="form-control-route"
                    id="oral"
                    name="oral"
                    onChange={handleInputChange}
                  >
                    <option
                      value="Oral"
                      style={{ fontFamily: "Poppins", fontSize: "10px" }}
                    >
                      Oral
                    </option>
                  </select>
                </div>

                <div className="form-group-p">
                  <label
                    htmlFor="frequency"
                    style={{
                      marginTop: "20px",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: 600,
                      marginRight: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Frequency
                  </label>
                  <select
                    className="form-control-frequency"
                    id="frequency"
                    name="frequency"
                    onChange={handleInputChange}
                  >
                    <option
                      value="BID - Twice daily"
                      style={{ fontFamily: "Poppins", fontSize: "10px" }}
                    >
                      BID - Twice daily
                    </option>
                  </select>
                  <label
                    htmlFor="Duration"
                    style={{
                      marginTop: "15px",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: 600,
                      marginRight: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Duration
                  </label>
                  <input
                    id="duration"
                    name="duration"
                    style={{
                      width: "95%",
                      borderRadius: "5px",
                      border: " 1px solid #cccccc",
                    }}
                  />
                  <label
                    htmlFor="refill"
                    style={{
                      marginTop: "15px",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: 600,
                      marginRight: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Refill
                  </label>
                  <input
                    id="refill"
                    name="refill"
                    style={{
                      width: "95%",
                      borderRadius: "5px",
                      border: " 1px solid #cccccc",
                    }}
                  />
                </div>
              </div>
            </form>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                backgroundColor: "#F6F6F6",
                padding: "20px",
                borderRadius: "5px",
                marginLeft: "8px",
                fontFamily: "Poppins",
              }}
            >
              <h5
                style={{
                  color: "black",
                  textAlign: "left",
                  marginBottom: "10px",
                  fontFamily: "Poppins",
                }}
              >
                Diagnosis
              </h5>
              <div
                className="form-grou-text"
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  readOnly={!isEditable}
                  style={{
                    flex: 1,
                    paddingRight: "30px",
                  }}
                />

                <EditIcon
                  onClick={handleIconClick}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: 10,
                  }}
                />
              </div>
              <div>
                <select
                  style={{
                    backgroundColor: "#E4E4E4",
                    width: "98%",
                    borderColor: "#E4E4E4",
                    padding: "5px",
                    marginTop: "15px",
                    marginBottom: "10px",
                  }}
                  id="diagnosisHistory"
                  name="DiagnosisHistory"
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Diagnosis History
                  </option>
                  <option
                    value="Type - 2 Diabetes"
                    style={{ backgroundColor: "white" }}
                  >
                    Type - 2 Diabetes
                  </option>
                </select>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "16px",
                backgroundColor: "#f5f5f5",
                marginTop: "25px",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "8px",
                }}
              >
                <label
                  htmlFor="start-date"
                  style={{
                    marginRight: "4px",
                    fontFamily: "Poppins",
                    fontSize: "13px",
                    marginRight: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Start on:
                </label>
                <input
                  type="date"
                  id="start-date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  style={{ fontFamily: "Poppins", fontSize: "13px" }}
                />
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <label
                  htmlFor="end-date"
                  style={{
                    marginRight: "4px",
                    fontFamily: "Poppins",
                    fontSize: "13px",
                    marginRight: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  End on:
                </label>
                <input
                  type="date"
                  id="end-date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  style={{ fontFamily: "Poppins", fontSize: "13px" }}
                />
              </div>
            </div>

            <fieldset
              style={{
                border: "none",
                padding: 0,
                margin: 0,
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  marginRight: "10px",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Continuous
              </span>
              <label
                style={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: "400",
                  marginRight: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  name="continuous"
                  value="yes"
                  style={{
                    marginRight: "6px",
                    Color: "blue",
                  }}
                  checked={isContinuous === "yes"}
                  onChange={() => setIsContinuous("yes")}
                />
                Yes
              </label>
              <label
                style={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: "400",
                  marginRight: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  name="continuous"
                  value="no"
                  style={{
                    marginRight: "6px",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                  checked={isContinuous === "no"}
                  onChange={() => setIsContinuous("no")}
                />
                No
              </label>
              <label
                style={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: "400",
                  marginRight: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  name="continuous"
                  value="notSet"
                  style={{
                    marginRight: "6px",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                  checked={isContinuous === "notSet"}
                  onChange={() => setIsContinuous("notSet")}
                />
                Not set
              </label>
            </fieldset>

            <fieldset>
              <span
                style={{
                  marginRight: "10px",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                substitution Not Allowed
              </span>
              <label
                style={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: "400",
                  marginRight: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  checked={substitutionNotAllowed}
                  style={{ marginRight: "6px" }}
                  onChange={(e) => setSubstitutionNotAllowed(e.target.checked)}
                />
                Yes
              </label>
              <label
                style={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: "400",
                  marginRight: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  checked={!substitutionNotAllowed}
                  style={{ marginRight: "6px" }}
                  onChange={(e) => setSubstitutionNotAllowed(!e.target.checked)}
                />
                No
              </label>
            </fieldset>

            <label
              style={{
                fontFamily: "Poppins",
                fontSize: "12px",
                fontWeight: "500",
                marginRight: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Autofill
              <input
                type="checkbox"
                style={{ marginLeft: "6px" }}
                checked={autoFill}
                onChange={(e) => setAutoFill(e.target.checked)}
              />
            </label>
          </Grid>
          <Grid container style={{ width: "100%", margin: 0 }}>
            <Grid
              item
              xs={6}
              style={{
                padding: 0,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                type="submit"
                style={{
                  width: "150px",
                  marginTop: "25px",
                  marginRight: "30px",
                  backgroundColor: "#0047ab",
                }}
              >
                <span
                  style={{
                    fontFamily: "Mulish",
                    fontSize: "14px",
                    fontWeight: "700PX",
                  }}
                ></span>
                save and Exit
              </button>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                padding: 0,
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <button
                style={{
                  width: "150px",
                  marginTop: "25px",
                  marginRight: "30px",
                  backgroundColor: "#0047ab",
                }}
                onClick={handlePrintPrescription}
              >
                Print
              </button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default PriscriptionForm;
