import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import LandingPageVideo from "../styles/screens/LandingPageVideo";
import AppShell from "./LandingPageAppShell";
import "../styles/components/LandingPageStyles/LandingPageHome.css";
import "../styles/components/LandingPageStyles/LandingPageExtra.css";
import { motion } from "framer-motion";
import img from "../styles/components/LandingPageStyles/platform.png";
import img1 from "../styles/components/LandingPageStyles/bg.jpeg";
import img2 from "../styles/components/LandingPageStyles/button.jpg";
import img3 from "../styles/components/LandingPageStyles/end_bg.jpg";
import { style } from "@mui/system";
import { positions } from "@mui/system";
import intro_img1 from "../styles/components/LandingPageStyles/intro_img1.jpg";
import intro_img2 from "../styles/components/LandingPageStyles/intro_img2.jpg";
import intro_img3 from "../styles/components/LandingPageStyles/intro_img3.jpg";
import Footer from "../components/footer-new.js";

const LandingPageHome = () => {
  const text = "Begin Your e-Healthy Journey";
  const text2 = "Through our Smart Digital Medicine Solutions";
  const letters = Array.from(text);
  const letters2 = Array.from(text2);

  const container = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
      opacity: 1,
      transition: { staggerChildren: 0.03, delayChildren: 0.04 * i },
    }),
  };
  const container2 = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
      opacity: 1,
      transition: { staggerChildren: 0.04, delayChildren: 0.04 * i },
    }),
  };

  const child2 = {
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100,
      },
    },
    hidden: {
      opacity: 0,
      x: -20,
      y: 0,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100,
      },
    },
  };

  const child = {
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100,
      },
    },
    hidden: {
      opacity: 0,
      x: 20,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100,
      },
    },
  };

  const cards = [
    {
      title: "I am Doctor",
      description:
        "Streamline patient care with virtual consultations, record management, and secure communication. ",
      imageUrl: intro_img1,
      section: "/LandingIntro#section1",
    },
    {
      title: "I am Patient",
      description:
        "Access medical records, schedule virtual appointments, and communicate with healthcare providers.",
      imageUrl: intro_img2,
      section: "/LandingIntro#section2",
    },
    {
      title: "I am Clinic",
      description:
        "Collaborate with doctors to schedule work, communicate with medical users, and create forms online.",
      imageUrl: intro_img3,
      section: "/LandingIntro#section3",
    },
  ];

  ReactDOM.render(
    <StrictMode>
      <AppShell />
      <div
        className="landing-body"
        style={{ position: "relative", width: "100%", height: "100vh" }}
      >
        <LandingPageVideo
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: "1",
          }}
        />
        <motion.div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: "5",
            width: "100%",
            overflow: "hidden",
            display: "flex",
            fontSize: "4rem",
            justifyContent: "center",
            color: "white",
            alignItems: "flex-end",
            height: "45vh",
            textShadow: "1px 1.1px 1px rgb(45, 45, 45)",
          }}
          variants={container}
          initial="hidden"
          animate="visible"
        >
          {letters.map((letter, index) => (
            <motion.span variants={child2} key={index}>
              {letter === " " ? "\u00A0" : letter}
            </motion.span>
          ))}
        </motion.div>
        <motion.div
          style={{
            position: "absolute",
            top: "45%",
            zIndex: "5",
            width: "100%",
            overflow: "hidden",
            display: "flex",
            fontSize: "1.9rem",
            alignItems: "flex-start",
            justifyContent: "center",
            color: "white",
            textShadow: "1px 1.1px 1px rgb(45, 45, 45)",
          }}
          variants={container2}
          initial="hidden"
          animate="visible"
        >
          {letters2.map((letter, index) => (
            <motion.span variants={child2} key={index}>
              {letter === " " ? "\u00A0" : letter}
            </motion.span>
          ))}
        </motion.div>
      </div>

      <div
        style={{
          position: "absolute",
          zIndex: "5",
          top: "60%",
          width: "100%",
          display: "flex",
          fontSize: "1.8rem",
          justifyContent: "center",
          color: "white",
          textShadow: "1px 1.1px 1px rgb(45, 45, 45)",
          paddingTop: "80px",
        }}
      >
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            window.location.href = "LandingPage";
          }}
          style={{
            padding: "12px 24px",
            borderRadius: "36px",
            backgroundColor: "#004AB3",
          }}
          // className="save-buttonZ"
        >
          Join Us Now
        </motion.button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "30%",
            padding: "30px",
            textAlign: "center",
          }}
        >
          <div style={{ fontSize: "2rem", justifyContent: "center" }}>
            Platform
          </div>
          <div style={{ height: "1vh" }}></div>
          <div
            style={{
              width: "60%",
              fontSize: "1.2rem",
              margin: "0 auto",
              textAlign: "center",
              fontFamily: " sans-serif",
            }}
          >
            Provide a full-featured online communication platform for doctors
            and patients.
          </div>
        </div>
        <div
          style={{
            width: "30%",
            padding: "30px",
            textAlign: "center",
          }}
        >
          <div style={{ fontSize: "2rem", justifyContent: "center" }}>
            Machine Learning
          </div>
          <div style={{ height: "1vh" }}></div>
          <div
            style={{
              width: "60%",
              fontSize: "1.2rem",
              margin: "0 auto",
              textAlign: "center",
              fontFamily: " sans-serif",
            }}
          >
            Utilizing the latest machine learning algorithms to assist in
            diagnosing diseases with high accuracy.
          </div>
        </div>
        <div
          style={{
            width: "30%",
            padding: "30px",
            textAlign: "center",
          }}
        >
          <div style={{ fontSize: "2rem", justifyContent: "center" }}>
            User-oriented
          </div>
          <div style={{ height: "1vh" }}></div>
          <div
            style={{
              width: "60%",
              fontSize: "1.2rem",
              margin: "0 auto",
              textAlign: "center",
              fontFamily: " sans-serif",
            }}
          >
            Customized personalization for users, offering user-friendly
            functions and interfaces.
          </div>
        </div>
      </div>

      <div
        style={{
          padding: "0px",
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        <hr
          style={{
            left: "15%",
            width: "70%",
            margin: "0 auto",
          }}
        ></hr>
      </div>

      <div
        style={{
          padding: "0px",
          textAlign: "center",
          marginTop: "50px",
        }}
      >
        <div
          style={{
            fontSize: "2rem",
            justifyContent: "center",
          }}
        >
          Electrical platform for every role
        </div>
      </div>

      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            padding: "0 10%",
            boxSizing: "border-box",
            width: "100%",
            marginTop: "80px",
            marginBottom: "80px",
            position: "relative",
            zIndex: 1,
          }}
        >
          {cards.map((card, index) => (
            <div
              key={index}
              style={{
                textAlign: "center",
                width: "30%",
                backgroundColor: "white",
                borderRadius: "10px",
                margin: "0 3%",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "20px",
              }}
            >
              <img src={card.imageUrl}></img>
              <p
                style={{
                  width: "90%",
                  fontSize: "1.3rem",
                  margin: "50px auto 50px",
                  textAlign: "left",
                  fontFamily: "sans-serif",
                  lineHeight: "1.3",
                }}
              >
                {card.description}
              </p>
              <button
                onClick={() => {
                  window.location.href = card.section;
                }}
                style={{
                  alignSelf: "center",
                  marginTop: "auto",
                  padding: "10px 20px",
                  borderRadius: "20px",
                  border: "none",
                  backgroundColor: "#004AB3",
                  color: "white",
                  cursor: "pointer",
                  fontSize: "1.3rem",
                }}
              >
                {card.title}
              </button>
            </div>
          ))}
        </div>
        <div
          style={{
            height: "80px",
            backgroundColor: "#004AB3",
            width: "100%",
            position: "relative",
            zIndex: "0",
          }}
        ></div>
      </div>

      <div
        style={{
          position: "relative",
          width: "100vw",
          height: "100vh",
          backgroundColor: "#F7F7F8",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            fontSize: "2.5vw",
            marginTop: "10vh",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          Enjoy Your Electronic Health System
        </div>
        <p style={{ fontSize: "1.5vw" }}>
          <div
            style={{
              width: "50%",
              height: "30%",
              position: "absolute",
              top: "35%",
            }}
          >
            <p
              style={{
                width: "75%",
                fontSize: "5vm",
                margin: "0 auto",
                textAlign: "left",
                fontFamily: " sans-serif",
                lineHeight: "1.3",
              }}
            >
              Simplify patient care through a digital platform with virtual
              consultations, electronic record management, and secure
              communication, enhancing medical service efficiency and
              convenience, supporting personalized healthcare at home.
            </p>
          </div>
        </p>
        <img
          src={img}
          style={{
            width: "50%",
            height: "70%",
            position: "absolute",
            justifyContent: "right",
            top: "20%",
          }}
        ></img>
      </div>

      <div
        style={{
          position: "relative",
          width: "100vw",
          height: "100vh",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        <img
          src={img1}
          style={{
            width: "40%",
            height: "90%",
            position: "absolute",
            left: "5%",
            top: "5%",
            borderRadius: "10px",
            zIndex: "-1",
          }}
        ></img>
        <div
          style={{
            width: "30%",
            position: "absolute",
            textAlign: "right",
            zIndex: "0",
            left: "12%",
            top: "70%",
            fontSize: "1.5vw",
          }}
        >
          <p style={{ fontSize: "2vw" }}>Diagnosis accuracy</p>{" "}
          <div style={{ height: "2vh" }}></div>
          <p style={{ lineHeight: "1.3" }}>
            For all models, we have an accuracy rate of more than 90%, providing
            instant and accurate diagnosis of your health
          </p>
        </div>
        <img
          src={img2}
          style={{
            width: "18%",
            height: "40%",
            position: "absolute",
            right: "5%",
            top: "5%",
            zIndex: "-1",
          }}
        ></img>
        <button
          onClick={() => {
            window.location.href = "Specialities";
          }}
          style={{
            borderRadius: "3vw",
            padding: "0.5vw 0.5vw",
            fontSize: "1.2vw",
            border: "none",
            backgroundColor: "white",
            color: "#004AB3",
            cursor: "pointer",
            position: "absolute",
            zIndex: "0",
            top: "38%",
            right: "7%",
          }}
        >
          {">>"}
        </button>
        <div
          style={{
            width: "25%",
            position: "absolute",
            textAlign: "left",
            zIndex: "0",
            left: "50%",
            top: "20%",
            fontSize: "1.5vw",
          }}
        >
          <p style={{ fontSize: "3vw" }}>AI Solution +</p>
          <div style={{ height: "2vh" }}></div>
          <p style={{ lineHeight: "1.2" }}>
            Artificial intelligence technology combined with diagnostics.
          </p>
        </div>

        <div
          style={{
            width: "45%",
            position: "absolute",
            textAlign: "left",
            zIndex: "0",
            left: "50%",
            top: "55%",
            fontSize: "1.5vw",
            lineHeight: "1.2",
          }}
        >
          1. We utilize cutting-edge technology, including Machine Learning (ML)
          models, to offer advanced healthcare services, focusing on precise and
          swift diagnostics for early ailment detection.
          <div style={{ height: "4vh" }}></div>
          2. We enable seamless integration with diagnostic labs for efficient
          test ordering, results retrieval, and collaborative patient
          diagnostics.
          <div style={{ height: "4vh" }}></div>
          3. Our platform facilitates AI-driven analysis, enhancing the accuracy
          and speed of medical assessments.
        </div>
      </div>

      <div
        style={{
          position: "relative",
          backgroundImage: `url(${img3})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "1500px",
          width: "100%",
        }}
      >
        <div
          style={{
            position: "absolute",
            zIndex: "1",
            top: "10%",
            width: "30%",
            left: "15%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "3vw",
          }}
        >
          <div>A Widely Used Platform</div>
        </div>

        <div
          style={{
            position: "absolute",
            zIndex: "1",
            top: "40%",
            left: "15%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.5vw",
            flexDirection: "column",
          }}
        >
          <div style={{ fontSize: "5vw" }}>3+</div>
          <div>countries</div>
        </div>

        <div
          style={{
            position: "absolute",
            zIndex: "1",
            top: "35%",
            left: "45%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.5vw",
            flexDirection: "column",
          }}
        >
          <div style={{ fontSize: "5vw" }}>150+</div>
          <div>doctors</div>
        </div>

        <div
          style={{
            position: "absolute",
            zIndex: "1",
            top: "15%",
            left: "70%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.5vw",
            flexDirection: "column",
          }}
        >
          <div style={{ fontSize: "5vw" }}>300+</div>
          <div>patients</div>
        </div>

        <div
          style={{
            position: "absolute",
            zIndex: "1",
            top: "60%",
            width: "50%",
            left: "5%",
            display: "flex",
            justifyContent: "left",
            textAlign: "left",
            fontSize: "1.5vw",
            lineHeight: "1.2",
          }}
        >
          <div>
            Our application process ensures the privacy of each client's
            information. At the same time, each application will be checked by
            professionals to ensure efficient.
          </div>
        </div>
        <button
          onClick={() => {
            window.location.href = "JoinUs";
          }}
          style={{
            position: "absolute",
            zIndex: "5",
            bottom: "37%",
            left: "58%",
            borderRadius: "36px",
            fontSize: "1.8vw",
            backgroundColor: "white",
            color: "black",
            cursor: "pointer",
          }}
        >
          Join us now
        </button>
        <button
          onClick={() => {
            window.location.href = "Contact";
          }}
          style={{
            position: "absolute",
            zIndex: "5",
            bottom: "37%",
            left: "75%",
            borderRadius: "36px",
            fontSize: "1.8vw",
            backgroundColor: "#D9D9D9",
            color: "black",
            cursor: "pointer",
          }}
        >
          Have questions
        </button>
      </div>
      <Footer />
    </StrictMode>,

    document.getElementById("root")
  );
};
export default LandingPageHome;
