import {
  Typography,
  Button,
  Grid,
  Paper,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar,
  Box,
  Modal,
  buttonBaseClasses,
  ListItemButton,
} from "@mui/material";
import React from "react";
import PressureChart from "./PressureChart";
import ECG from "./BloodPressureChart";
import TrendingUpTwoToneIcon from "@mui/icons-material/TrendingUpTwoTone";
import TrendingDownTwoToneIcon from "@mui/icons-material/TrendingDownTwoTone";
import Glucose from "./Glucose";
import CircleIcon from "@mui/icons-material/Circle";
import WarningIcon from "@mui/icons-material/Warning";
import {
  WeightConmponent,
  TCHComponent,
  BloodPressureComponent,
  GlucoseComponent,
} from "./HealthOverviewComponent";
import Weight from "./Weight";
const GridLayout = () => {
  return (
    <Box sx={{ marginTop: "2vh" }}>
      {/* left:right=2:1 */}
      <Grid container>
        <Grid item xs={8}>
          {/* up:down=1:1 */}
          <Grid container direction="column">
            <Grid item xs={6} sx={{ marginBottom: "2vh" }}>
              {/* the first floor left:right=1:1 */}
              <Grid container>
                <Grid item xs={6} sx={{ paddingRight: "2vh" }}>
                  <WeightConmponent />
                </Grid>
                <Grid item xs={6}>
                  <TCHComponent />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ backgroundColor: "#C6DFFF", borderRadius: "10px" }}
            >
              <BloodPressureComponent />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} sx={{ px: "1vw" }}>
          {/* right component */}
          <GlucoseComponent />
        </Grid>
      </Grid>
    </Box>
  );
};
export default GridLayout;
