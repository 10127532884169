import React, { useEffect, useState } from "react";
import SimpleBarReact from 'simplebar-react';
import { alpha, styled } from '@mui/material/styles';
import { Box,IconButton,Typography,Autocomplete, TextField, Grid,Button } from "@mui/material";
import { Table, TableBody, TableRow, TableCell,TableHead } from '@mui/material';
import { Dialog,DialogContent, DialogTitle,Snackbar } from "@mui/material";
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';


// 页面标题显示格式
export const PageTitleDisplay = ({ pagetitle }) => (
  <div style={{ display: 'flex', alignItems: 'center', fontWeight: 500, fontSize: '1.5rem', color: '#1A4FBA' }}>
    <span>{pagetitle}</span>
  </div>
);

// 区域标题显示格式
export const SectionTitleDisplay = ({ title }) => (
  <Box sx={{ mb: 1 }}>
    <Typography variant="h5" component="div" style={{ fontSize: "medium", fontWeight: 'bold' }}>
      {title}
    </Typography>
  </Box>
);

// 基础显示格式
export const BasicInfoDisplay = ({ InfoTitle, InfoContent, marginTop }) => (
  <Box sx={{ mt: marginTop, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <Typography variant="h5" component="div" sx={{ fontSize: 'small', fontWeight: 'bold' }}>{InfoTitle}:</Typography>
    <Box sx={{ ml: 1 }} /> {/* 添加空格 */}
    <Typography variant="body1" component="span" sx={{ fontSize: 'small' }}>{InfoContent}</Typography>
  </Box>
);

// 带灰色分割线的显示格式
export const InfoDisplayWithBorder = ({ title, content, marginTop }) => (
  <>
    <Typography variant="h5" component="div" sx={{ mt: marginTop, fontSize: 'medium', fontWeight: 'bold' }}>{title}</Typography>
    <Box sx={{ borderBottom: 1, borderColor: 'grey.300' }} />
    <Typography variant="body1" component="span" sx={{ fontSize: 'small' }}>{content}</Typography>
  </>
);


// 对话框标题显示格式
export const DialogTitleDisplay = ({ open, onClose, dialogTitle, children }) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleClose = (_, reason) => {
    if (reason !== "backdropClick") {
      onClose();
    } else {
      setAlertMessage('Cannot close the dialog by clicking outside.');
      setAlertOpen(true);
    }
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
    setAlertMessage('Please complete or cancel the application!');
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        BackdropProps={{ onClick: handleBackdropClick }}
      >
        <DialogTitle>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span style={{ fontStyle: 'italic', fontSize: '1.5rem', color: '#1A4FBA', fontFamily: 'Arial, sans-serif' }}>
              {dialogTitle}
            </span>
            <Typography variant="subtitle2" color="textSecondary">
              Fields marked with <span style={{ color: 'red' }}>*</span> are required.
            </Typography>
            <IconButton onClick={onClose} sx={{ position: 'absolute', right: 0, top: 0, color: 'red' }}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
      </Dialog>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleAlertClose} severity="warning" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

// 对话框单选列表显示格式
export const DialogListSingleSelect = ({ dataList, setSelectedItem, config }) => {
  if (!dataList || !config || !dataList.length || !config.displayKey || !config.label ) {
    console.error("Invalid dataList or config");
    return null;
  }

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={3}>
        <Typography>
          {config.label}
          {config.required && <span style={{ color: 'red' }}> *</span>} {/* 添加必填项标记 */}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Autocomplete
  options={dataList}
  getOptionLabel={(option) => option[config.displayKey] + (option.disabled ? ' (pending)' : '')}
  onChange={(event, value) => {
    setSelectedItem(value);
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      variant="outlined"
      fullWidth
      placeholder={config.placeholder} // 设置默认填充内容
    />
  )}
  getOptionDisabled={(option) => option.disabled} // 根据 disabled 属性设置选项是否可选
/>
      </Grid>
    </Grid>
  );
};

// 对话框多选列表显示格式
export const DialogListMultipleSelect = ({ dataList, setSelectedItems, config }) => {
  if (!dataList || !config || !dataList.length || !config.displayKey || !config.label) {
    console.error("Invalid dataList or config");
    return null;
  }

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={3}>
        <Typography>
          {config.label}
          {config.required && <span style={{ color: 'red' }}> *</span>} {/* 添加必填项标记 */}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Autocomplete
          multiple
          options={dataList}
          getOptionLabel={(option) => option[config.displayKey]}
          onChange={(event, value) => {
            setSelectedItems(value);
            if (value.length > 0) {
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              placeholder={config.placeholder} // 设置默认填充内容
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

// 对话框的信息输入框
export const DialogMessageInput = ({ message, setMessage, config }) => {
  if (!config || !config.label || !config.placeholder) {
    console.error("Invalid config");
    return null;
  }

  return (
    <Grid container alignItems="flex-start" spacing={2}>
      <Grid item xs={3}>
        <Typography>
          {config.label}
          {config.required && <span style={{ color: 'red' }}> *</span>} {/* 添加必填项标记 */}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          rows={config.rows || 4}  // 使用config中的rows，默认为3
          placeholder={config.placeholder} // 设置默认填充内容
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

// 对话框按钮
export const DialogInviteSubmit = ({ handleInvite, requiredFields }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');

  const areRequiredFieldsFilled = requiredFields.every(field => field);

  const handleSubmit = () => {
    if (areRequiredFieldsFilled) {
      handleInvite();
    } else {
      setSnackbarMessage('Please fill in all required fields.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mb: 2 }}>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={!areRequiredFieldsFilled}
        >
          Submit invitation
        </Button>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};


// 特定临床试验基础信息标题显示
export const SpecificTrialBasicSectionTitle = ({ sectionTitle }) => (
  <>
  <Typography paragraph variant="overline" sx={{color: '#1a4eba',fontSize: '1rem', fontWeight: 'bold', }}>{sectionTitle}</Typography>
  </>
);
// 特定临床试验基础信息内容显示
export const SpecificTrialBasicSectionContent = ({ sectionContent }) => (
  <>
       <Typography variant="body2" sx={{fontSize: '0.9rem' }}>{sectionContent}</Typography>
    </>
);

//资料显示页内表格风格
const PofileScrollbarRootStyle = styled('div')(() => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
}));


export const PofileScrollbar=({ children, sx, ...other }) =>{
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  const PofileScrollbarStyle = styled(SimpleBarReact)(({ theme }) => ({
    maxHeight: '100%',
    '& .simplebar-scrollbar': {
      '&:before': {
        backgroundColor: alpha(theme.palette.grey[600], 0.48),
      },
      '&.simplebar-visible:before': {
        opacity: 1,
      },
    },
    '& .simplebar-track.simplebar-vertical': {
      width: 10,
    },
    '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
      height: 6,
    },
    '& .simplebar-mask': {
      zIndex: 'inherit',
    },
  }));

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <PofileScrollbarRootStyle>
      <PofileScrollbarStyle timeout={500} clickOnTrack={false} sx={sx} {...other}>
        {children}
      </PofileScrollbarStyle>
    </PofileScrollbarRootStyle>
  );
};
//显示医院详细资料的表头
export const ProfileScrollbarTableHead = ({ headers }) => {
  return (
    <TableHead sx={{ borderBottom: (theme) => `solid 1px ${theme.palette.divider}`, '& th': { backgroundColor: '#f5f5f5' } }}>
      <TableRow>
        <TableCell width={40} sx={{ backgroundColor: '#f5f5f5' }}>#</TableCell>
        {headers.map((header, index) => (
          <TableCell key={index} align={header.align || 'left'} sx={{ backgroundColor: '#f5f5f5' }}>
            {header.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
//显示医院详细资料的内容
export const ProfileScrollbarTableBody = ({ title, description }) => {
  return (
    <TableRow
      sx={{
        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
      }}
    >
      <TableCell sx={{ backgroundColor: '#ffffff' }} /> {/* 空的TableCell用于对齐表头的#列 */}
      <TableCell align="left" sx={{ backgroundColor: '#ffffff' }}>
        <Box sx={{ maxWidth: 560 }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="left" sx={{ backgroundColor: '#ffffff' }}>
        <Box sx={{ maxWidth: 560 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {description}
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};