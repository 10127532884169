import React from "react";
import { 
  Box, 
  Card, 
  Typography, 
  Grid, 
  CardContent, 
} from "@mui/material";
import { Modal, Button, TextField} from '@mui/material';
import { Modal as AntdModal } from 'antd';
import axios from "axios";
import { useEffect, useState, useRef } from "react";

import Autocomplete from '@mui/material/Autocomplete';
// SurgeryPlanning.jsx
export function SurgeryPlanning({ open, onClose, doctorId })  {
   const [authorizedPatients, setAuthorizedPatients] = useState([]);
   const [selectedPatient, setSelectedPatient] = useState(''); // Initialize to an empty string


      useEffect(() => {
        if (open) {
            fetchAuthorizedPatients();
        }
    }, [open, doctorId]); 
    


    const handleAutocompleteChange = (event, newValue) => {
      if (newValue) {
        setSelectedPatient(newValue.id);
      } else {
        setSelectedPatient('');
      }
    };
  

    const fetchAuthorizedPatients = async () => {
        try {
          const response = await axios.post('https://e-react-node-backend-22ed6864d5f3.herokuapp.com/DoctorPatientsAuthorized', { doctorId });
          setAuthorizedPatients(response.data);
          if (response.data.length > 0) {
            setSelectedPatient(response.data[0].id); // Set to the first patient's id
          }
        } catch (error) {
          console.error('Error fetching authorized patients:', error);
        }
      };
      
   
    const surgeryTypeRef = useRef(null);
    const surgeryDateRef = useRef(null);
    const consultationDetailsRef = useRef(null);
    const riskAssessmentDetailsRef = useRef(null);
    const postOperativeCarePlanRef = useRef(null);
    
      
      const resetFormRefs = () => {
        surgeryTypeRef.current.value = '';
        surgeryDateRef.current.value = '';
        consultationDetailsRef.current.value = '';
        riskAssessmentDetailsRef.current.value = '';
        postOperativeCarePlanRef.current.value = '';
    };

      const handleSubmit = async (e) => {
        e.preventDefault();
        const surgeryType = surgeryTypeRef.current.value;
        const surgeryDate = surgeryDateRef.current.value;
        const preSurgeryConsultationDetails = consultationDetailsRef.current.value;
        const riskAssessmentDetails = riskAssessmentDetailsRef.current.value;
        const postOperativeCarePlan = postOperativeCarePlanRef.current.value;
        resetFormRefs()
        console.log(selectedPatient)
        let patientId=selectedPatient;
        if (patientId) {
          try {
            const response = await axios.post('https://e-react-node-backend-22ed6864d5f3.herokuapp.com/saveSurgeryPlan', {
              doctorId,
              patientId,
              surgeryType, 
              surgeryDate, 
              preSurgeryConsultationDetails,
              riskAssessmentDetails, 
              postOperativeCarePlan
            });
            console.log(response.data);
            AntdModal.success({
              content: 'Created a record successfully!',
              onOk: () => onClose(),
            });
          } catch (error) {
            console.error('Error submitting form:', error);
            AntdModal.error({
              content: `Error submitting form: ${error}`,
            });
          }
        } else {
          // Handle error if patientId is not selected
        }
      };
      
      
   
      useEffect(() => {
      fetchAuthorizedPatients();
      }, []);

      const style1 = {
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        minHeight: '100%',
        boxShadow: 24,
        pt: 2,
        pb: 3,
        overflowY: 'auto',
          fontFamily:'Poppins'
      };
   
      const SurgeryPlanModalContent = () => (
       
          <Box sx={style1}>
            <Card >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                {/* Form for adding surgery plan */}
                <Card><CardContent>
                <Typography style={{fontFamily:'Poppins'}}
                    variant="h4" sx={{mt:2, mx:2, textAlign:'center'}}>
                     Create A surgery Plan
                </Typography>
                <form style={{fontFamily:'Poppins'}} onSubmit={handleSubmit}>

                <Autocomplete
                  value={authorizedPatients.find(patient => patient.id === selectedPatient) || null}
                  onChange={handleAutocompleteChange}
                  options={authorizedPatients}
                  getOptionLabel={(option) => `${option.FName} ${option.LName}`}
                  renderInput={(params) => <TextField {...params} label="Search and select a patient"  multiline/>}
                  sx={{ mt: 2, mb: 2 }}
                />

                <TextField 
                    fullWidth
                    label="Surgery Type" 
                    name="surgeryType"
                    inputRef={surgeryTypeRef}
                    defaultValue={surgeryTypeRef.current ? surgeryTypeRef.current.value : ''}
                    sx={{mt:2, mb:2}}
                    multiline
                />
                {/* Additional input fields */}
                <TextField 
                    fullWidth
                    label="Surgery Date" 
                    name="surgeryDate" 
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    inputRef={surgeryDateRef}
                    defaultValue={surgeryTypeRef.current ? surgeryTypeRef.current.value : ''}
                    sx={{mt:2}}
                />
                  <TextField 
                    fullWidth
                    label="pre Surgery ConsultationDetails" 
                    name="preSurgeryConsultationDetails"
                    inputRef={consultationDetailsRef}
                    defaultValue={consultationDetailsRef.current ? consultationDetailsRef.current.value : ''}
                    sx={{mt:2}}
                    multiline
                    rows={3}
                />
                <TextField 
                    fullWidth
                    label="riskAssessmentDetails" 
                    name="riskAssessmentDetails"
                    inputRef={riskAssessmentDetailsRef}
                    defaultValue={riskAssessmentDetailsRef.current ? riskAssessmentDetailsRef.current.value : ''}
                    sx={{mt:2}}
                    multiline
                    rows={3}
                />
                   <TextField 
                    fullWidth
                    label="postOperativeCarePlan" 
                    name="postOperativeCarePlan"
                    inputRef={postOperativeCarePlanRef}
                    defaultValue={postOperativeCarePlanRef.current ? postOperativeCarePlanRef.current.value : ''}
                    sx={{mt:2, mb:2}}
                    multiline
                    rows={3}
                />
                {/* TextFields for preSurgeryConsultationDetails, riskAssessmentDetails, postOperativeCarePlan */}
                <Button
                    style={{width:'200px',
                        color:'white',
                        backgroundColor:'#1A4FBA',
                        border:'none',
                        fontFamily:'Poppins'}}
                    fullWidth type="submit" variant="contained" color="primary">
                    Save Surgery Plan
                </Button>
                </form>
                <Button
                    style={{width:'200px',
                        color:'white',
                        backgroundColor:'#1A4FBA',
                        border:'none',
                        fontFamily:'Poppins'}}
                    onClick={onClose} 
                    variant="contained" 
                    color="secondary" 
                    sx={{ mt: 2, alignSelf: 'flex-end' , mb:2}}
                    fullWidth
                    >
                    Close
                </Button>
                </CardContent></Card>
              </Grid>
         
            </Grid>

            </Card>
          
           
          </Box>
        
      );
      
      

    return (
         <Modal open={open} onClose={onClose}>
            <SurgeryPlanModalContent/>
        </Modal>
    );
}