// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.healthcare-models-container {
  padding: 20px;
  background: #f5f5f5;
  color: #333;
}

.title{
  margin-top: 20px;
}


.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e0e0e0;
  width: 300px;
  /* Width of the square card */
  height: 300px;
  /* Height of the square card */
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.models-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  /* space between the grid items */
  justify-content: center;
  padding: 20px;
}

.split-section {
  display: flex;
  justify-content: space-between;
}

.left-section,
.right-section {
  flex: 1 1;
  /* Makes both children take up equal space */
  padding: 20px;
  /* Add some padding for better appearance */
}
@keyframes fluidMotion {
  0% {
      background-image: radial-gradient(circle, rgba(247,150,192,0.6) 0%, rgba(118,174,241,0.6) 100%);
  }
  50% {
      background-image: radial-gradient(circle, rgba(118,174,241,0.6) 0%, rgba(247,150,192,0.6) 100%);
  }
  100% {
      background-image: radial-gradient(circle, rgba(247,150,192,0.6) 0%, rgba(118,174,241,0.6) 100%);
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screens/ModelsHub.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;;AAGA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;EACZ,6BAA6B;EAC7B,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;EACnB,2CAA2C;EAC3C,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,4DAA4D;EAC5D,SAAS;EACT,iCAAiC;EACjC,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;;EAEE,SAAO;EACP,4CAA4C;EAC5C,aAAa;EACb,2CAA2C;AAC7C;AACA;EACE;MACI,+FAA+F;EACnG;EACA;MACI,+FAA+F;EACnG;EACA;MACI,+FAA+F;EACnG;AACF","sourcesContent":[".healthcare-models-container {\n  padding: 20px;\n  background: #f5f5f5;\n  color: #333;\n}\n\n.title{\n  margin-top: 20px;\n}\n\n\n.card-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  border: 1px solid #e0e0e0;\n  width: 300px;\n  /* Width of the square card */\n  height: 300px;\n  /* Height of the square card */\n  padding: 15px;\n  border-radius: 10px;\n  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);\n  transition: transform 0.3s ease-in-out;\n}\n\n.models-grid {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));\n  gap: 20px;\n  /* space between the grid items */\n  justify-content: center;\n  padding: 20px;\n}\n\n.split-section {\n  display: flex;\n  justify-content: space-between;\n}\n\n.left-section,\n.right-section {\n  flex: 1;\n  /* Makes both children take up equal space */\n  padding: 20px;\n  /* Add some padding for better appearance */\n}\n@keyframes fluidMotion {\n  0% {\n      background-image: radial-gradient(circle, rgba(247,150,192,0.6) 0%, rgba(118,174,241,0.6) 100%);\n  }\n  50% {\n      background-image: radial-gradient(circle, rgba(118,174,241,0.6) 0%, rgba(247,150,192,0.6) 100%);\n  }\n  100% {\n      background-image: radial-gradient(circle, rgba(247,150,192,0.6) 0%, rgba(118,174,241,0.6) 100%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
